import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import IconProvider from "../IconProvider";
import Typography from "../Typography";
import { checkboxProps } from "./checkbox.types";
import { checkbox_indeterminate, checkbox_tik } from "../../../static";


const Checkbox: React.FC<checkboxProps> = ({size , disabled, className ,onClick,state="unchecked" ,style }: checkboxProps) => {

    const ss = state == "indeterminate" ? "checked" : (state == "checked"  ? "unchecked" : "checked");

    return (
        <div className={clsx(styles.Small , styles[state] , className && className , disabled && styles.disabled , ["checked","indeterminate"].indexOf(state) > -1 && " CheckboxDesignSystemBack")} onClick={()=>{onClick(ss)}} style={style ?style :{}}>
            {state == "checked" && <IconProvider Icon={checkbox_tik} />}
            {state == "indeterminate" && <IconProvider Icon={checkbox_indeterminate} />}

        </div>
    );
};

export default Checkbox;

