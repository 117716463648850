import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { getAllLanguages } from "../../../helper/module";
import { connect } from "react-redux";
import { get_cssFlag, get_lang, set_direction, set_lang ,set_cssFlag, set_country} from "../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  changeLanguage = (lang) => {
    this.props.dispatch({
      type: "changeDirection",
      direction: lang.direction,
      language: lang.lang,
    });
    set_lang(lang.lang);
    set_direction(lang.direction);
    set_cssFlag(lang.cssFlag)
    set_country(lang.country)
    this.props.i18n.changeLanguage(lang.lang);
    this.handleClose()
  };


  getActiveLang = ()=>{
    if(global.config.AllLanguages.find((ll)=>{
     return ll.lang == get_lang()
    })){
     return global.config.AllLanguages.find((ll)=>{
       return ll.lang == get_lang()
      }).i18Lang
    }else{
     return global.config.AllLanguages[0].i18Lang
    }
   }
 

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div className="SecondSectionHeader">
        <span
          className={`LoginRegisterLogin ${
            this.props.LoginIsActive == false && "LRHactiveTab"
          }`}
          onClick={() => {
            this.props.changeLogin(false);
          }}
        >
          {" "}
          <Trans i18nKey={"login"}> </Trans>{" "}
        </span>
        {(global.config.isIndividualSignupActive || global.config.isBusinessSignupActive) && <span
          className={`LoginRegisterLogin ${
            this.props.LoginIsActive == true && "LRHactiveTab"
          }`}
          onClick={() => {
            this.props.changeLogin(true);
          }}
        >
          {" "}
          <Trans i18nKey={"Register"}> </Trans>{" "}
        </span>}
        <span
          onClick={this.handleClick}
          className={`headerLanguagelabel ${get_cssFlag()}`}
        >
          {" "}
          <Trans i18nKey={this.getActiveLang()}> </Trans>{" "}
        </span>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            {global.config.AllLanguages.map((lang) => {
              return (
                <ListItem
                  onClick={() => {
                    this.changeLanguage(lang);
                  }}
                >
                  <span className={`headerLanguagelabelItem ${lang.cssFlag}`}>
                    {" "}
                    <Trans i18nKey={lang.i18Lang}> </Trans>{" "}
                  </span>
                </ListItem>
              );
            })}
          </List>
        </Popover>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authenticated: state.SharedData.access_token,
  direction: state.SharedData.direction,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
