import { get_accessToken, get_Id, get_lang } from "../../../helper/UserData/userdate";
import {httpCustomer} from "../axios";


class InvoiceDataService {
  getAll(page , perpage ) {
    return httpCustomer.get(`/api/customer/business/invoice/list?page=${page}&perpage=${perpage}&customerId=${get_Id()}`,{
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      }
    });
  }

  download(id , code) {
    return httpCustomer.get(`/api/v1/customer/business/invoice/download?id=${id}&&code=${code}`,{
      responseType: 'blob',
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      }
    });
  }
}

export default InvoiceDataService;