import React from "react";
import ReactDOM from "react-dom";
import Draggable from "react-draggable";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import Button from "@material-ui/core/Button";
import SettingsOverscanIcon from "@material-ui/icons/SettingsOverscan";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import onro from "../../static/logotype-onlight-passenger@3x.png";
import pickup from "../../static/pickup.png";
import deliro from "../../static/deliro.svg";
import YONI from "../../static/YONI.png";
import emrazgo from "../../static/emrazgoLogo.png";
import hellocourier from "../../static/hellocourier_logo.png";
import oto from "../../static/oto_logotype.png";
import vee from "../../static/vee_logotype.png";
import hio from "../../static/HiOlogotype.png";

import {
  get_defaultProjectDev,
  set_defaultProjectDev,
} from "../../helper/UserData/userdate";

class DevelopmentCenterControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minimize: true,
    };
  }
  controlMinimize = () => {
    this.setState({
      minimize: !this.state.minimize,
    });
  };

  checkActiveProject = (project) => {
    return project == get_defaultProjectDev();
  };

  controlReset = () => {
    let project = get_defaultProjectDev();
    localStorage.clear();
    set_defaultProjectDev(project);
    window.location.reload();

  };

  handleProject = (project) => {
    localStorage.clear();
    set_defaultProjectDev(project);
    window.location.reload();
  };
  render() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
      return (
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: 300, y: 0 }}
          position={null}
          grid={[25, 25]}
          scale={1}
          onStart={this.handleStart}
          onDrag={this.handleDrag}
          onStop={this.handleStop}
        >
          <div className={`handle ${this.state.minimize && "handleMinimize"}`}>
            {!this.state.minimize && (
              <React.Fragment>
                <p className="header">Development Control Panel</p>
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      All Project Lists
                    </ListSubheader>
                  }
                >
                             <ListItem
                    onClick={() => {
                      this.handleProject("restaurant.catrin-go.com");
                    }}
                    button
                    style={
                      this.checkActiveProject("restaurant.catrin-go.com")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={onro} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="catrin-go" />
                  </ListItem>
                  <ListItem
                    onClick={() => {
                      this.handleProject("deliver.loco.direct");
                    }}
                    button
                    style={
                      this.checkActiveProject("deliver.loco.direct")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={onro} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="loco" />
                  </ListItem>

                 <ListItem
                    onClick={() => {
                      this.handleProject("onro.test");
                    }}
                    button
                    style={
                      this.checkActiveProject("onro.test")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={onro} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="onro.test" />
                  </ListItem>
                  <ListItem
                    onClick={() => {
                      this.handleProject("app.onro.io");
                    }}
                    button
                    style={
                      this.checkActiveProject("app.onro.io")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={onro} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="onro" />
                  </ListItem>
                  <ListItem
                    onClick={() => {
                      this.handleProject("app.pickup.global");
                    }}
                    button
                    style={
                      this.checkActiveProject("app.pickup.global")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={pickup} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="pickup" />
                  </ListItem>

                  <ListItem
                    onClick={() => this.handleProject("app.deliro.ir")}
                    button
                    style={
                      this.checkActiveProject("app.deliro.ir")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={deliro} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="deliro" />
                  </ListItem>

                  <ListItem
                    onClick={() => this.handleProject("app.yoni-app.sn")}
                    button
                    style={
                      this.checkActiveProject("app.yoni-app.sn")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={YONI} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="YONI" />
                  </ListItem>

                  <ListItem
                    onClick={() => this.handleProject("app.emrazgo.com")}
                    button
                    style={
                      this.checkActiveProject("app.emrazgo.com")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={emrazgo} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="emrazgo" />
                  </ListItem>


                  <ListItem
                    onClick={() => this.handleProject("app.hio.ro")}
                    button
                    style={
                      this.checkActiveProject("app.hio.ro")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={hio} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="hio.ro" />
                  </ListItem>


                  <ListItem
                    onClick={() => this.handleProject("order.hellocourier.co.ke")}
                    button
                    style={
                      this.checkActiveProject("order.hellocourier.co.ke")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={hellocourier} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="hellocourier" />
                  </ListItem>

                  <ListItem
                    onClick={() => this.handleProject("app.otokh.com")}
                    button
                    style={
                      this.checkActiveProject("app.otokh.com")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={oto} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="OTO" />
                  </ListItem>
                  

                  <ListItem
                    onClick={() => this.handleProject("now.veedelivery.com")}
                    button
                    style={
                      this.checkActiveProject("now.veedelivery.com")
                        ? { backgroundColor: "#8080802e" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <img src={vee} style={{ width: "50px" }} />
                    </ListItemIcon>
                    <ListItemText primary="VEE" />
                  </ListItem>
                  


                </List>
              </React.Fragment>
            )}
            {!this.state.minimize && (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ marginBottom: "10px" }}
                  onClick={this.controlReset}
                  startIcon={<RotateLeftIcon />}
                >
                  Reset Local Data
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{}}
                  onClick={this.controlMinimize}
                  startIcon={<CloseRoundedIcon />}
                >
                  Minimize Panel
                </Button>
              </React.Fragment>
            )}

            {this.state.minimize && (
              <SettingsOverscanIcon
                onClick={this.controlMinimize}
                style={{
                  color: "white",
                  fontSize: "33px",
                  marginTop: "8px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
        </Draggable>
      );
    } else {
      return null;
    }
  }
}

export default DevelopmentCenterControlPanel;
