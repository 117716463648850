import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { SetCodAmount } from "../../../../Redux/actions/actionNames";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.inputOnchange = this.inputOnchange.bind(this)
  }


  inputOnchange(e){

    if( Number(e.target.value) <= Number(this.props.activeVehicleType.setting.maxCODLimit)){
      this.props.dispatch(SetCodAmount(e.target.value))
    }
  }


  render() {
    const { t } = this.props;
    let maxCODLimit = this.props.activeVehicleType ? (this.props.activeVehicleType.setting ? this.props.activeVehicleType.setting.maxCODLimit : null) : null ;

    if(this.props.activeVehicleType && !this.props.activeVehicleType.setting.isCODActive) {
      return null
    }
    return (
      <div className="codContainer">

          <span> {t("CashOnDelivery")} </span>
          <input onChange={this.inputOnchange} placeholder={t("EnterAmount")} type="number" min="0"  max={maxCODLimit} value={this.props.codAmount ? this.props.codAmount : ""}/>
      </div>
    
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  codAmount: state.NewOrder.newOrder.codAmount,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
