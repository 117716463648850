import React, { useCallback, useEffect, useRef } from 'react'
import styles from './style.module.css';

import { getTableOrderList } from '../../../../components/shared/table/action/getTableOrderList';
import Button from '../../../../design-system/lib/Button';
import IconProvider from '../../../../design-system/lib/IconProvider';
import Typography from '../../../../design-system/lib/Typography';
import { Ic_danger } from '../../../../static';
import { useSelector } from 'react-redux';







function ListError() {
  const OrderList = useSelector((state) => state.OrderList.OrderList)
  const OrderListLoading = useSelector((state) => state.OrderList.OrderListLoading)
  const OrderListError = useSelector((state) => state.OrderList.OrderListError )
  const page = useSelector((state) => state.OrderList.page)
 
  if (OrderListError) {
      return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out" ,justifyContent:"center",alignItems:"center"}}>
          <div className={styles.dialog}>
            <IconProvider Icon={Ic_danger} style={{marginBottom:"16px"}} />
            <Typography weight='Body_Middle_Bold' text='Error in loading data' style={{textAlign:"center" , marginBottom:"4px",color:"rgba(36, 36, 36, 1)"}} />
            <Typography weight='Body_Tiny_Regular' text='There was a problem loading data. please try again or contact support' style={{textAlign:"center",marginBottom:"16px",color:"rgba(128, 128, 128, 1)"}} />
            <Button className='NewOrderBtnsHeader' size='Medium' type='Primary' text='Try again' onClick={()=>getTableOrderList()}/>
          </div>
    </div>
  )
  }else{
    return null
  }


}
export default ListError