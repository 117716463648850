import LogoType from "../../../static/floti.png";
import Banner from "../../../static/floti_banner.png";

import FavIcon from "../../../static/PROJECTS/floti_fav_icon.png";

export default function Floti() {

    return global.config = {
        "project": "floti",
        "ShowPowerOnro": false,
        "flag": "Spanishflag",
        "ProjectName": "Portal Cliente",
        "LogoType": LogoType,
        "BaseApi": "https://rest.floti.io",
        "BaseSocket": "https://socket.floti.io",
        "BaseTrack": "https://admin.floti.io",
        "MapCenter": {
            "lat": 13.695397579624686,
            "lng": -89.21113187723346
        },
        "Lang": "es",
        "Direction": "Ltr",
        "AllLanguages": [
            {
                "lang": "es",
                "direction": "Ltr",
                "i18Lang": "spanish",
                "cssFlag": "Spanishflag",
                "country": "DO"
            },
            {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
                "cssFlag": "englishflag",
                "country": "US"
            }
        ],
        "FavIcon": "",
        "Country": "SV",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor: "floti",
        registerEnabled: true,
        Secondarycolor: "#0071FF",
        SecondaryDarkColor: "#0058C6",
        SecondaryLightColor: "#2988FF",
        SecondaryUltraLight: "#EBF4FF",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#0071FF",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#000000",
        icon_light_end:"#2E2E2E",
        icon_dark_start:"#0071FF",
        icon_dark_end:"#2988FF",
        enabledCustomColor: true,
        mapType: "osm", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText:true,
        loginBackgroundColorStart:"#000000",
        loginBackgroundColorEnd:"#2E2E2E",
        LoginTextColor :"#ffffff",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
    }
}