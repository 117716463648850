import * as actionTypes from "../actions/actionTypes";

const init = {
  OrderList: [],
  OrderListLoading: false,
  OrderListError: false,
  OpenDialoge: false,
  page: 0,
};
export const Transactions = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setTransactions:
      return {
        ...state,
        ...action.state.extra,
        OrderList: action.state.list
          ?  [...state.OrderList, ...action.state.list ]
          : state.OrderList,
      };
    case actionTypes.openCloseTransactionList:
      if (!action.state) {
        return { ...init };
      } else {
        return {
          ...state,
          OpenDialoge: action.state,
        };
      }

    default:
      return state;
  }
};
