import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import useScript from "../hook/useScript";
import googlelogo from "./../../static/icon-google.svg";

export default function GoogleLogin({
  onSuccess 
  // feel free to add more options here
}) {
  const googleSignInButton = useRef(null);
  const [googleLoaded , setGoogleLoaded] = useState(false)


  useScript("https://accounts.google.com/gsi/client", () => {
    window.google.accounts.id.initialize({
      client_id: global.config.GoogleLogincustomerId,
      callback: onSuccess,
    });

    setGoogleLoaded(true)
    window.google.accounts.id.renderButton(
      googleSignInButton.current,
      {type:"icon", size: "large",logo_alignment:"center",with:"50px",height:"50px" } // customization attributes
    );
  });

  function click() {
   window.google.accounts.id.prompt((notification) => {
    console.log(notification.getNotDisplayedReason())
    if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
      // continue with another identity provider.
    }
  });
  }
  return <div  ref={googleSignInButton}>
</div>

;
}