import LogoType from "../../../static/Ondelivery.png";
import Banner from "../../../static/Ondelivery_banner.png";

import FavIcon from "../../../static/PROJECTS/Ondelivery_fav_icon.png";

export default function Ondelivery() {
    
    return  global.config = {
        "project": "Ondelivery",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Portal",
        "LogoType": LogoType,
        "BaseApi": "https://rest.ondelivery.us",
        "BaseSocket": "https://socket.ondelivery.us",
        "BaseTrack": "https://admin.ondelivery.us",
        "MapCenter": {
            "lat": 42.33129264827773 ,
            "lng": -83.04864974934947
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Customer Dashboard",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Ondelivery",
        registerEnabled: true,
        Secondarycolor: "#1FFF00",
        SecondaryDarkColor: "#1FDB05",
        SecondaryLightColor: "#54FF3C",
        SecondaryUltraLight: "#EDFFEB",
        SecondaryTextColor: "#242424",
        Secondaryonlight: "#242424",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#000000",
        icon_light_end:"#343434",
        icon_dark_start:"#1FFF00",
        icon_dark_end:"#54FF3C",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#EDFFEB",
        loginBackgroundColorEnd:"#D7FFD3",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}