import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableCheckboxFilter_Add, TableCheckboxFilter_Delete, TableCheckboxFilter_Update, TableInputContainFilter_Add, TableInputContainFilter_Update } from '../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';




export function GetTableCheckboxFilter(selector, PureList, converter) {
  const [data, setData] = useState([]);
  const [list, setList] = useState(null);

  const pureData = useSelector((state) => state.Table.filters[selector])
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();


  useEffect(() => {

    // console.log("GetTableCheckboxFilter ",selector , PureList ,converter)

    if (PureList && !list) {
      let xx = []
      //{ key: "1", value: "1" }
      // setData({value : pureData[index].text})
      if (converter) {
        xx = converter(PureList)
        setList(xx)
      } else {
        PureList.forEach(element => {
          xx.push({ key: element.id, value: element.title })
        }); 
        setList(xx)
      }


    }

  }, [PureList]);

  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, []);

  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, [pureData]);


  const OnChange = React.useCallback((e) => {
    dispatch(TableCheckboxFilter_Update({ key: selector, value: e }))

  }, [data, pureData, selector]);


  return { [`${selector}_data`]: data, [`${selector}_OnChange`]: OnChange, [`${selector}_list`]: list? list : [] };
};

