import JoraIGO from "../../../static/Jora.png";
import FavIcon from "../../../static/PROJECTS/FavIcon_Jora.png";
import Banner from "../../../static/Jora_banner.png";

export default function Jora() {
    
    return  global.config = {
        "project": "Jora",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "JORA Delivery",
        "LogoType": JoraIGO,
        "BaseApi": "https://rest.joradelivery.com",
        "BaseSocket": "https://socket.joradelivery.com",
        "BaseTrack": "https://admin.joradelivery.com",
        "MapCenter": {
            "lat": 35.23824904738942,
            "lng": -80.84609487149466
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Jora",
        registerEnabled: true,
        Secondarycolor: "#FE5900",
        SecondaryDarkColor: "#CB5914",
        SecondaryLightColor: "#F8A16D",
        SecondaryUltraLight: "#FFE6D7",
        SecondaryTextColor: "#292728",
        Secondaryonlight: "#F37021",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FDB388",
        icon_light_end:"#FFD0B5",
        icon_dark_start:"#FE5900",
        icon_dark_end:"#F8A16D",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#9BE8FD",
        loginBackgroundColorEnd:"#78C2D6",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:JoraIGO,
        apkUrl:null,
        iosUrl:null
}}