import ViaheroIGO from "../../../static/Viahero_logotype.png";
import Viahero_banner from "../../../static/Viahero_banner.png";

import FavIcon from "../../../static/PROJECTS/Viahero.png";

export default function Viahero() {
    
    return global.config = {
        "project": "Viahero",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "customer portal",
        "LogoType": ViaheroIGO,
        "BaseApi": "https://rest.viahero.ph",
        "BaseSocket": "https://socket.viahero.ph",
        "BaseTrack": "https://manage.viahero.ph",
        "MapCenter": {
            "lat": 14.574400731056794,
            "lng": 121.08529112288042
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/Viahero.png",
        "Country": "PH",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Viahero_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Viahero",
        registerEnabled: true,
        Secondarycolor: "#22429C",
        SecondaryDarkColor: "#1C3785",
        SecondaryLightColor: "#274DB6",
        SecondaryUltraLight: "#EBF6FE",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#22429C",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FFB600",
        icon_light_end:"#FFCA45",
        icon_dark_start:"#22429C",
        icon_dark_end:"#274DB6",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#EFF3FF",
        loginBackgroundColorEnd:"#D0DCFF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:ViaheroIGO,
        apkUrl:null,
        iosUrl:null
} }