import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../static/icon-chevron-down.svg";
import { ReactComponent as Support } from "../../../../static/icon-ic-support.svg";
import { ReactComponent as Map } from "../../../../static/ic-map.svg";
import { ReactComponent as Receipt } from "../../../../static/receipt.svg";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import styles2 from './viewOrder.module.css';
import { v4 as uuidv4 } from 'uuid';

import {
  ChangeViewTravelDialoge,
  ResetViewTravel,
  ResetNewFav,
  ChooseoOnMapimg,
  show_eventTimeLine,
} from "../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import {
  CheckSocketOrderState,
  isOrderInDone,
  isOrderInProgress,
} from "../../../../helper/module";
import { get_Id, get_calendarType, get_lang } from "../../../../helper/UserData/userdate";
import { DeleteOrderFromLIST } from "../../../../socket/helper";
import { Api_OrderGetEvents_AGW } from "../../../../helper/api/ApiGateway";
import arrowDoenOrderId from '../../../../static/arrowDoenOrderId.svg';
import orderIDicon from '../../../../static/orderIDicon.svg';
import styles from './style.module.css';
import { Tooltip, styled, tooltipClasses } from 'newmaterial-ui';
import Typography from "../../../../design-system/lib/Typography";
import { ReactComponent as Barcode2 } from "../../../../static/barcode2.svg";

import momentJalaali from "moment-jalaali";
import { httpCustomer } from "../../../../service/api/axios";
import history from "../../../../service/history";
import clsx from 'clsx';
import ProgressIndicator from "../../../../design-system/lib/ProgressIndicator";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    borderRadius: "8px",
    padding: "0",
    border: 'none',
    boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.24)",
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    marginBottom: "0px !important"
  },
}));


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShipmentLabelLoading : false
    }
}
  OncLIck = () => {
    this.props.dispatch(ResetViewTravel());
    if(this.props.orderType == "PickupDelivery"){
      history.push('/table')
    }else{
      history.push('/')

    }
    // history.goBack()
    // try {
    //   let orderId = this.props.orderId;
    //   let status = this.props.status;
    //   if (status && orderId) {
    //     CheckSocketOrderState(status, (callx) => {
    //       if (!callx) {
    //         DeleteOrderFromLIST(orderId , true);
    //       }
    //     });
    //   }
    // } catch (error) {}

    // this.props.dispatch(ChangeViewTravelDialoge(false));
    // this.props.dispatch(ResetViewTravel());
    // this.props.dispatch(ResetNewFav());
    // this.props.dispatch(ChangeViewTravelDialoge(false));

  };

  ShowMap = () => {
    this.props.dispatch(
      ChooseoOnMapimg({
        showMap: true,
        pickupDropOff: {
          type: null,
          id: null,
        },
      })
    );
  };



  eventShowHandler = () => {
    Api_OrderGetEvents_AGW(this.props.dispatch, this.props.orderId)
    this.props.dispatch(show_eventTimeLine(!this.props.showEventTimeLine))

    // if(this.props.showEventTimeLine){
    //   this.props.dispatch(Set_New_Event({canSelectLocation : false}))
    //   this.props.dispatch(Set_New_Event({show : false}));
    //   this.props.dispatch(Set_New_Event({mapLocation : null}))
    //   this.props.dispatch(Set_viewOrder_events({ activeEventCordinator: null }))


    // }
  }


  
 ShipmentLabelPdfExport = async () => {

  this.setState({
    ShipmentLabelLoading : true
  })
    httpCustomer.post('/api/v1/customer/order/shipment-label/export-pdf', {
      ids: [`${this.props.orderId}`],
      customerId: get_Id()
    }, {
      responseType: 'blob',
      headers: {
        withCredentials: true,
        'x-bypass-auth': true
      }
    }).then( (response)=> {
      console.log(response);
      // window.open(URL.createObjectURL(response.data));

      var blob = new Blob([response.data], { type: 'application/pdf' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = response.headers["content-disposition"].replace("attachment; filename=", '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.setState({
        ShipmentLabelLoading : false
      })
    }).catch(function (err) {
      console.log(err);

      this.setState({
        ShipmentLabelLoading : false
      })
    })
 
  } ;



  render() {
    return (
      <vRHeader>
        <img onClick={this.OncLIck} className="close" src={close} />
        <span className="title titlepatch">
          <Trans i18nKey={"Order"}> </Trans>
          {this.props.createdAt &&<Typography weight="Body_Small_Regular" text={momentJalaali(this.props.createdAt).locale(get_lang()).format("D MMM HH:mm")}
            style={{ color: "#808080" }} />}

        </span>
        {!this.props.loading && !this.props.error && (
          <React.Fragment>
            {" "}

            {/* <span className={`vrHeaderEventsTitle ${this.props.showEventTimeLine && "vrHeaderEventsTitleActive"}`} onClick={this.eventShowHandler}> <Trans i18nKey={"Events"}> </Trans> </span> */}


            {/* <itemNbb>
              <HtmlTooltip title={
                <div className={styles.orderCodeTToolTipContainer}>
                  <span><img src={orderIDicon} /> {this.props.t("Orderid")}: {this.props.code}</span>
                  {(this.props.referenceID ? String(this.props.referenceID).length > 0 : false) && <span><img src={orderIDicon} /> {this.props.t("ReferenceID")}: {this.props.referenceID}</span>}

                </div>
              } placement="bottom" >
                <codeT> {this.props.code} <img src={arrowDoenOrderId} /></codeT>
              </HtmlTooltip>
            </itemNbb>{" "} */}

            <div className={styles2.headerButtonsContainer}>
             {(!isDesktop && !isTablet) && <div className={clsx(styles2.button , (!isDesktop && !isTablet) && styles2.buttonMobile )} onClick={this.ShowMap}>
                <Map className="img" />
                {(isDesktop || isTablet) && <Typography weight={"Body_Middle_Medium"} text={"Map"} textColor={"TextDark"} className={styles2.buttonText} />}
              </div>}
              {isOrderInProgress(this.props.status) && (
              <a
                href={"tel:" + this.props.supportPhone} 
              >
              <div className={clsx(styles2.button, (!isDesktop && !isTablet) && styles2.buttonMobile )} onClick={() => { }}>
                <Support className="viewOrderSupportBtn" />
                {(isDesktop || isTablet) &&  <Typography weight={"Body_Middle_Medium"} text={"Support"} textColor={"TextDark"} className={styles2.buttonText} />}
              </div>
              </a>
            )}

              <div className={clsx(styles2.button, (!isDesktop && !isTablet) && styles2.buttonMobile )} onClick={this.ShipmentLabelPdfExport}>
               {!this.state.ShipmentLabelLoading && <Barcode2 />}
                {this.state.ShipmentLabelLoading && <ProgressIndicator theme="On_Light" style={{width:"16px",height:"16px"}} />}
                {(isDesktop || isTablet) && <Typography weight={"Body_Middle_Medium"} text={"Shipment Label"} textColor={"TextDark"} className={styles2.buttonText} />}
              </div>
            </div>

            {/* 
            {(!isDesktop && !isTablet) && (
              <div className="vrHeaderButton" onClick={this.ShowMap}>
                <Map className="img" />
                <span>
                  <Trans i18nKey={"map"}> </Trans>
                </span>
              </div>
            )}
            {isOrderInProgress(this.props.status) && (
              <a
                href={"tel:" + this.props.supportPhone}
                className="vrHeaderButton mr10"
              >
                <Support className="viewOrderSupportBtn" />
                <span>
                  <Trans i18nKey={"support"}> </Trans>
                </span>
              </a>
            )}
            {(isOrderInDone(this.props.status) && this.props.customerReceiptUrl && this.props.customerReceiptUrl.length > 0) && (
              <div
                onClick={() => {
                  try {
                    var url = new URL(this.props.customerReceiptUrl);
                    url.searchParams.append("lng", get_lang());

                    window.open(url, "_blank");
                  } catch (error) { }
                }}
                className="vrHeaderButton mr10"
              >
                <Receipt className="img" style={{ margin: "4px 9px 0 9px", transform: "scale(1.1)" }} />
                <span>
                  <Trans i18nKey={"receipt"}> </Trans>
                </span>
              </div>
            )} */}
          </React.Fragment>
        )}
      </vRHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  center: state.Map.center,
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : null
    : null,
  orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,
  code: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.code
      ? state.ViewTravel.pureData.code
      : "#"
    : "#",
  referenceID: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.referenceId
      ? state.ViewTravel.pureData.referenceId
      : null
    : null,
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : ""
    : "",

  customerReceiptUrl: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customerReceiptUrl
      ? state.ViewTravel.pureData.customerReceiptUrl
      : ""
    : "",

  supportPhone: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.supportPhone
    : null,
  loading: state.ViewTravel.loading,
  error: state.ViewTravel.error,


  showEventTimeLine: state.ViewTravel.eventTimeLine.show,
  createdAt: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.createdAt
      ? state.ViewTravel.pureData.createdAt
      : null
    : null,
    orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
