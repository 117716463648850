import LogoType from "../../../static/Kwikrlogotype.png";
import Banner from "../../../static/Kwikr_banner.png";

import FavIcon from "../../../static/PROJECTS/Kwikr.png";

export default function Kwikr() {
    
    return  global.config = {
        "project": "Kwikr",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Portal",
        "LogoType": LogoType,
        "BaseApi": "https://rest.kwikr.com",
        "BaseSocket": "https://socket.kwikr.com",
        "BaseTrack": "https://portal.kwikr.com",
        "MapCenter": {
            "lat": "51.504251327098515",
            "lng": "-0.11538650727355824"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Customer Dashboard",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Kwikr",
        registerEnabled: true,
        Secondarycolor: "#000000",
        SecondaryDarkColor: "#29262E",
        SecondaryLightColor: "#000000",
        SecondaryUltraLight: "#EBEBEB",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#000000",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#A0A0A0",
        icon_light_end:"#A0A0A0",
        icon_dark_start:"#000000",
        icon_dark_end:"#000000",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#EAEAEA",
        loginBackgroundColorEnd:"#E3E3E3",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}