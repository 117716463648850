import AnypointIGO from "../../../static/Anypoint.png";
import Anypoint_banner from "../../../static/Anypoint_background.png";

import FavIcon from "../../../static/PROJECTS/FavIcon_Anypoint.png";

export default function Anypoint() {
    
    return  global.config = {
        "project": "app.anypoint.live",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Any Point",
        "LogoType": AnypointIGO,
        "BaseApi": "https://rest.anypoint.live",
        "BaseSocket": "https://socket.anypoint.live",
        "BaseTrack": "https://admin.anypoint.live",
        "MapCenter": {
            "lat": 24.73142866200158,
            "lng": 46.663227047365936
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "SA"
        }],
        "FavIcon": "/Anypoint.png",
        "Country": "SA",
        "AppleLoginCustomerId": null,
        "AppleLoginredirectURI": null,
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Anypoint_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"Anypoint",
        registerEnabled: true,
        Secondarycolor: "#662D90",
        SecondaryDarkColor: "#4B1573",
        SecondaryLightColor: "#8448B0",
        SecondaryUltraLight: "#F3EEF6",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#662D90",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#CC86FF",
        icon_light_end:"#E3BCFF",
        icon_dark_start:"#061153",
        icon_dark_end:"#8448B0",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ECD2FF",
        loginBackgroundColorEnd:"#E1B8FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:AnypointIGO,
        apkUrl:null,
        iosUrl:null,
}}