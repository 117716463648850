import React, { useState } from "react";
import SettingsItems from "./SettingsItems";
import Header from "./header";
import Footer from "./footer";

import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_LeftRight } from "../../../helper/UserData/userdate";
import { Api_Get_AcountInformation_AGW } from "../../../helper/api/ApiGateway";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone:"",
      familyName: "",
      givenName: "",
      email: "",
      username:"",
      title : "",
      address :"",
      canSaveInformation:false
    };
    
  }
  canSaveInformation=(bool,extra)=>{
    this.setState({
      canSaveInformation:bool,
      phone: extra.phone,
      familyName:extra.familyName,
      givenName: extra.givenName,
      email: extra.email,
      username:extra.username,
      title : extra.title,
      address : extra.address
    })
  }


  componentDidMount() {
    Api_Get_AcountInformation_AGW(this.props.dispatch, {}, (call) => {});
  }
  render() {
    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.open}
        mountOnEnter
        unmountOnExit
      >
        <div className="OrderHistoryContainer">
          <Header />
          <SettingsItems canSaveInformation={this.canSaveInformation} /> 
          <Footer canSaveInformation={this.state.canSaveInformation} data={this.state}/>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.Settings.SettingsOpenDialoge,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
