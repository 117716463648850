import React from "react";
import { connect } from "react-redux";
import { isNewOrderOpen } from "../../../ViewOrder/Map/osm";
var _ = require("lodash");




class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  componentWillReceiveProps() {
    this.boundsGenerator()
  }

  componentDidMount() {
    this.boundsGenerator()
  }



  boundsGenerator = () => {


    let originShow =
    this.props.pickupDropOffType && this.props.pickupDropOffType == "Pickup";
  let destinationShow =
    this.props.pickupDropOffType &&
    this.props.pickupDropOffType == "DropOff" &&
    !this.props.pickupDropOffId;
  let othersDestinationShow =
    this.props.pickupDropOffType &&
    this.props.pickupDropOffType == "DropOff" &&
    this.props.pickupDropOffId;

if(!originShow && !destinationShow && !othersDestinationShow ){
  try {
      let mapRef = this.props.mapRef;
      const bounds = new window.google.maps.LatLngBounds();
      console.log("boundsGenerator ", this.props, bounds)

      if (["PickupDelivery"].indexOf(this.props.orderType) > -1) {
        if (
          this.props.OpenVRDialoge &&
          this.props.VRpureData_pickup
        ) {

          bounds.extend(new window.google.maps.LatLng(this.props.VRpureData_pickup.coordinates[1], this.props.VRpureData_pickup.coordinates[0]));
          bounds.extend(new window.google.maps.LatLng(this.props.VRpureData_dropoff.coordinates[1], this.props.VRpureData_dropoff.coordinates[0]));

          this.props.VRpureData_dropoffs.map((drop) => {
            if (drop.coordinates[1] !== "") {
              bounds.extend(new window.google.maps.LatLng(drop.coordinates[1], drop.coordinates[0]));
            }
          });
          mapRef.fitBounds(bounds);

        } else if (isNewOrderOpen()) {
          if (
            this.props.NRpickupAdresss_loc.lng !== "" &&
            this.props.NRdropOffsAdresss_loc.lng !== ""
          ) {

            bounds.extend(new window.google.maps.LatLng(this.props.NRpickupAdresss_loc.lat, this.props.NRpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(this.props.NRdropOffsAdresss_loc.lat, this.props.NRdropOffsAdresss_loc.lng));
            this.props.NRotherDropOffs.map((drop) => {
              if (drop.adresss_loc.lng !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });
            mapRef.fitBounds(bounds);

          } else {

            return null
            // return null;
          }
        }
      } else {
        if (
          this.props.OpenVRDialoge &&
          this.props.VRpureData_pickup
        ) {

          bounds.extend(new window.google.maps.LatLng(this.props.VRpureData_pickup.coordinates[1], this.props.VRpureData_pickup.coordinates[0]));
          this.props.VRpureData_dropoffs.map((drop) => {
            if (drop.coordinates[1] !== "") {
              bounds.extend(new window.google.maps.LatLng(drop.coordinates[1], drop.coordinates[0]));
            }
          });
          mapRef.fitBounds(bounds);

        } else if (isNewOrderOpen()) {

          if (
            this.props.NRpickupAdresss_loc.lng !== "" &&
            this.props.NRdropOffsAdresss_loc.lng !== ""
          ) {

            bounds.extend(new window.google.maps.LatLng(this.props.NRpickupAdresss_loc.lat, this.props.NRpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(this.props.NRdropOffsAdresss_loc.lat, this.props.NRdropOffsAdresss_loc.lng));
            this.props.NRotherDropOffs.map((drop) => {
              if (drop.adresss_loc.lng !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });
            mapRef.fitBounds(bounds);

          } else {
            return null
          }
        }
      }
    } catch (error) {

    }
}

    

  };


  render() {

    return (
      null
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenNRDialoge: state.NewOrder.OpenDialoge,
  NRpickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  NRdropOffsAdresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  NRotherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  OpenVRDialoge: state.ViewTravel.OpenDialoge,


  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,

  VRpureData_dropoff: state.ViewTravel.pureData?.dropoff,
  VRpureData_pickup: state.ViewTravel.pureData?.pickup,
  VRpureData_dropoffs: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.dropoffs
      ? state.ViewTravel.pureData.dropoffs
      : []
    : [],
  mapRef: state.Map.mapRef,


  pickupDropOffType: state.NewOrder.newOrder.map_Data.pickupDropOff.type,
  pickupDropOffId: state.NewOrder.newOrder.map_Data.pickupDropOff.id,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});



export default connect(mapStateToProps, mapDispatchToProps)(App)