import onroIGO from "../../../static/logotype-on-light-client.svg";
import lightLogoType from "../../../static/onro-logotype.svg";
import darkLogoType from "../../../static/onro-logotype.svg";

import Defaultbanner from "../../../static/cp-resource@3x.png";

import FavIcon from "../../../static/PROJECTS/onro.png";

export default function onro() {
    
    return global.config = {
        "project": "app.onro.io",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Onro",
        "LogoType": darkLogoType,
        "BaseApi": "https://rest.onro.io",
        "BaseSocket": "https://socket.onro.io",
        "BaseTrack": "https://panel.onro.io",
        "MapCenter": {
            "lat": 27.709345,
            "lng": 85.327736
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },
        {
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "US"
        }, {
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }, {
            "lang": "ro",
            "direction": "Ltr",
            "i18Lang": "Romanian",
            "cssFlag": "romanianflag",
            "country": "RO"
        },{
            "lang": "nl",
            "direction": "Ltr",
            "i18Lang": "Dutch",
            "cssFlag": "dutchflag",
            "country": "NL"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        },{
            "lang": "pt",
            "direction": "Ltr",
            "i18Lang": "Portuguese",
            "cssFlag": "Portugueseflag",
            "country": "PT"
        }],
        "FavIcon": "/onro.png",
        "Country": "US",
        "AppleLoginCustomerId": "6N993LX47C",
        "AppleLoginredirectURI": "https://app.onro.io",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "49663940387-k08t0lc82equ88n64epp9klqspeatc27.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"onro",
        registerEnabled: true,
        Secondarycolor:"#1080FF",
        SecondaryDarkColor:"#0055cb",
        SecondaryLightColor:"#25B7FF",
        SecondaryUltraLight :"#D9EAFF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#1080FF",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true ,
        showLoginText:true,
        loginBackgroundColorStart:"#1080FF",
        loginBackgroundColorEnd:"#58A7FF",
        LoginTextColor :"#FFFFFF",
        showLightLogoType:true ,
        lightLogoType: lightLogoType,
        darkLogoType:darkLogoType,
        apkUrl:null,
        iosUrl:null

    }
}