import FabIGO from "../../../static/fablogotype.png";
import Defaultbanner from "../../../static/delivery-5.jpg";
import FavIcon from "../../../static/PROJECTS/fab.png";


export default function Fab() {

    return  global.config = {
        "project": "app.fab.com.np",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": FabIGO,
        "BaseApi": "https://rest.fab.com.np",
        "BaseSocket": "https://socket.fab.com.np",
        "BaseTrack": "https://panel.fab.com.np",
        "MapCenter": {
            "lat": 27.709345,
            "lng": 85.327736
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/fab.png",
        "Country": "NP",
        "AppleLoginCustomerId": "G4952Y49M3",
        "AppleLoginredirectURI": "https://app.fab.com.np",
        "FacebookLoginappId": "1947502115406579",
        "GoogleLogincustomerId": "772468790690-dcoubhjpmror0jeadm353dlji1pc7l73.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"fab",
        registerEnabled: true,
        Secondarycolor: "#FFCC29",
        SecondaryDarkColor: "#FABE00",
        SecondaryLightColor: "#FFD141",
        SecondaryUltraLight: "#FFFBEE",
        SecondaryTextColor: "#04062B",
        Secondaryonlight: "#04062B",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#04062B",
        icon_light_end:"#070A42",
        icon_dark_start:"#FFCC29",
        icon_dark_end:"#FFD141",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:FabIGO,
        apkUrl:null,
        iosUrl:null
}}
