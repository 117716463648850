import * as Sentry from "@sentry/react";
import { setGlobalSettings } from "./helper/module/projects";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./Redux/store/index";
import { SnackbarProvider } from "notistack";
import "./i18/i18n";
import Notifier from "./helper/Notification/notifier";

Sentry.init({
  dsn: "https://93a0403435605febdbef4c6643ae78e8@o300354.ingest.sentry.io/4506058596155392",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const GlobalSetting = setGlobalSettings()

const Loader = () => <div className="App">...</div>;

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <SnackbarProvider maxSnack={1}>
        <Notifier />
       {GlobalSetting && <App />}
      </SnackbarProvider>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
