import React, { useState } from "react";
import { getLogoTypeByUrl } from "../../../helper/module";
import ProfileMenu from "./ProfileMenu";
import Menu from "../../../static/ic_Hamburger_menu.svg";
import import2 from "../../../static/import2.svg";
import { Link } from 'react-router-dom';

import { OpenCloseMenu, Set_Table_ActiveOrderType } from "../../../Redux/actions/actionNames";
import { connect } from "react-redux";
import { NewOrderButton } from "./new-order-menu-button";
import styles from './header.module.css';
import Typography from "../../../design-system/lib/Typography";
import IconProvider from "../../../design-system/lib/IconProvider";
import ic_add from "../../../static/plus_white.svg";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  openMenu = () => {
    this.props.dispatch(OpenCloseMenu(true))
  }
  render() {

    return <div className="HeaderContainer" style={this.props.style ? this.props.style : {}}>
      <img className="HeaderMenu" src={Menu} onClick={this.openMenu} />
      <img className="HeaderLogoType" src={global.config.LogoType} />

      <div style={{ float: 'inherit', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px' }}> </div>

      <div className={styles.orderTypeContainer} >
        <Link to={`/`} ><Typography weight={this.props.type == "Ondemand" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={"On Demand"} textColor={this.props.type == "Ondemand" ? "Primary" : "TextDark"} className={this.props.type == "Ondemand" ? styles.orderTypeActive : styles.orderType} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("Ondemand")) }} /></Link>
        <Link to={`/table`} ><Typography weight={this.props.type == "PickupDelivery" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={"P_Hub_D"} textColor={this.props.type == "PickupDelivery" ? "Primary" : "TextDark"} className={this.props.type == "PickupDelivery" ? styles.orderTypeActive : styles.orderType} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("PickupDelivery")) }} /></Link>
        

      </div>
     



      <ProfileMenu />
      <div style={{ float: 'inline-end', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px', marginInlineEnd: '16px' }}> </div>
      <NewOrderButton />

    </div>;
  }
}


const mapStateToProps = (state) => ({
  type: state.Table.filters.type
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
