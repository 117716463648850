import KerryIGO from "../../../static/Kerry_logotype.png";
import Kerry_banner from "../../../static/Kerry_banner.png";

import FavIcon from "../../../static/PROJECTS/Kerry.png";

export default function KerryExpress() {
    
    return global.config = {
        "project": "Kerry Express",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "customer portal",
        "LogoType": KerryIGO,
        "BaseApi": "https://rest.kerryexpress.ph",
        "BaseSocket": "https://socket.kerryexpress.ph",
        "BaseTrack": "https://admin.kerryexpress.ph",
        "MapCenter": {
            "lat": 14.574400731056794,
            "lng": 121.08529112288042
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/Kerry.png",
        "Country": "PH",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Kerry_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Kerry",
        registerEnabled: true,
        Secondarycolor: "#F37021",
        SecondaryDarkColor: "#CB5914",
        SecondaryLightColor: "#F8A16D",
        SecondaryUltraLight: "#FFDFCC",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#F37021",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FDB388",
        icon_light_end:"#FFD0B5",
        icon_dark_start:"#E15E0F",
        icon_dark_end:"#F8A16D",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#E0E0E0",
        loginBackgroundColorEnd:"#FFFFFF",
        LoginTextColor :"#00000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:KerryIGO,
        apkUrl:null,
        iosUrl:null
} }