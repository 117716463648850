import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItem from "./driverOptions/list";
import SelectableItem from "./driverOptions/selectable";
import {
  Set_Manual_ActiveVehicleType,
  Set_Auto_ActiveVehicleType,
} from "../../../../Redux/actions/actionNames";
import { Warning_NOi18 } from "../../../../helper/Notification/sendNotification";
// import { SearchDriverAction } from "../../../../Redux/actions/actions";
import { getBaseTrackByUrl } from "../../../../helper/module";
import close from "../../../../static/ic-close.svg";
import { get_lang } from "../../../../helper/UserData/userdate";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "driver",
      showpoperAuto: false,
      showpoperManual: false,
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      loading: false,
      ordered: false,
      Auto: true,
      idd: this.idgenerator(),
      activeVehicleTypes: [
        {
          type: "list",
          title: "weight",
          id: "weight",
          data: [
            {
              name: "under 1 kg",
              id: "12qceqd",
            },
            {
              name: "under 2 kg",
              id: "12rcesd",
            },
            {
              name: "under 3 kg",
              id: "dwdqced",
            },
            {
              name: "under 4 kg",
              id: "1dwdced",
            },
          ],
        },
        {
          type: "boolean",
          title: "need packed",
          id: "need packed",
        },
      ],
    };
    this.anchorElAuto = React.createRef();
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentDidMount() {
    this.props.setId(this.state.id);
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    this.props.handleCollapse({
      type: "driver",
      id: "driver",
    });
  };

  handleshowpoperAuto = () => {
    if (this.props.auto_suggestListData.length > 0) {
      this.setState({
        showpoperAuto: !this.state.showpoperAuto,
      });
    } else {
      if (this.props.auto_suggestListLoading) {
        Warning_NOi18(
          <Trans i18nKey={"poperLoading"}> </Trans>,
          "networkError",
          <Trans i18nKey={"Close"}> </Trans>,
          () => {
            this.props.dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      } else {
        Warning_NOi18(
          <Trans i18nKey={"noVehicleType"}> </Trans>,
          "networkError",
          <Trans i18nKey={"Close"}> </Trans>,
          () => {
            this.props.dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    }
  };

  handleshowpoperManual = () => {
    this.setState({
      showpoperManual: !this.state.showpoperManual,
    });
  };

  handleAutoManual = () => {
    if (
      this.props.auto &&
      this.props.activePickupDropOff.id !== this.state.id
    ) {
      this.props.handleCollapse({
        type: "driver",
        id: "driver",
      });
      // this.props.dispatch(Set_Auto_Driver(!this.props.auto));
    } else {
      // this.props.dispatch(
      //   Set_Manual_ActiveVehicleType({
      //     manual_activeVehicleType: null,
      //     manual_name: "",
      //     manual_family: "",
      //     manual_number: "",
      //     manual_id: "",
      //     manual_code: "",
      //   })
      // );
      // this.props.dispatch(Set_Auto_Driver(!this.props.auto));
    }
  };

  handleAutoVehicleTypeSelect = (service) => {
    if( this.props.auto_activeVehicleType && service.id == this.props.auto_activeVehicleType.id) {
      this.props.dispatch(Set_Auto_ActiveVehicleType(null));
    }else{
      this.props.dispatch(Set_Auto_ActiveVehicleType(service));
    }
  };

  async handleManualVehicleTypeSelect(service) {
    // let index = -1;
    //   index = service.vehicletypes.length > 0  ? this.props.auto_suggestListData.findIndex((vehicleType) => {
    //   return vehicleType.id == service.vehicletypes[0];
    // }) : -1 ;
    this.FindLocalVehicletype(
      service.vehicletypes,
      this.props.auto_suggestListData,
      (call) => {
        if (call) {
          this.props.dispatch(
            Set_Manual_ActiveVehicleType({
              manual_activeVehicleType: {
                ...call,
              },
              manual_name: service.givenName,
              manual_family: service.familyNmae,
              manual_number: service.phone,
              manual_id: service.id,
              manual_code: service.username,
            })
          );
        }
      }
    );
    // if (index > -1) {
    //   VehicleType = this.props.auto_suggestListData[index];
    // }
    // this.props.dispatch(
    //   Set_Manual_ActiveVehicleType({
    //     manual_activeVehicleType: {
    //       ...VehicleType,
    //       options: [],
    //     },
    //     manual_name: service.givenName,
    //     manual_family: service.familyNmae,
    //     manual_number: service.phone,
    //     manual_id: service.id,
    //     manual_code: service.username,
    //   })
    // );

    //  console.log("VehicleTypexxxxx" ,VehicleType)
  }

  InputOnChange = (e) => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleType({
        [e.target.name.split("-")[0]]: e.target.value,
        manual_id: null,
      })
    );

    if (!this.state.showpoperManual) {
      this.setState({
        showpoperManual: true,
      });
    }

    // this.props.dispatch(
    //   SearchDriverAction(this.props.dispatch, {
    //     givenName: this.props.manual_name,
    //     username: this.props.manual_code,
    //     familyName: this.props.manual_family,
    //     phone: this.props.manual_number,
    //   })
    // );
  };

  FindLocalVehicletype = (driverVehicletypes, localservives, call) => {
    let vehicletype = null;
    if (driverVehicletypes.length > 0) {
      while (!vehicletype) {
        for (let i = 0; i < localservives.length; i++) {
          if (driverVehicletypes[0] == localservives[i].id) {
            vehicletype = localservives[i];
            call(vehicletype);
            break;
          }
        }
        driverVehicletypes.shift();
      }
      call(0);
    } else {
      call(0);
    }
  };

  resetDriver = () => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleType({
        manual_activeVehicleType: null,
        manual_name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
      })
    );
  };

  render() {
    const { t } = this.props;
    return (
      <passerngerContainer
        style={{ boxShadow: "rgba(128, 128, 128, 0.16) 0px 1px 6px 0px" }}
        className="VehicleTypeCustomized"
      >
        <pickuptitle>
          <Trans i18nKey={"Vehicletype"}> </Trans>
          <span className="driverTitleVehicleType">
            {this.props.auto
              ? this.props.auto_activeVehicleType &&
                this.props.auto_activeVehicleType.title
              : null
              ? ``
              : ""}
          </span>
        </pickuptitle>

        {this.props.auto_suggestListLoading ? (
          <React.Fragment>
            <div>
              <span
                className="NotravelsListHeader"
                style={{ marginTop: "10px" }}
              >
                <Trans i18nKey={"poperLoading"}> </Trans>
              </span>
              <span className="NotravelsListText">
                <Trans i18nKey={"fetchingdata"}> </Trans>
              </span>
            </div>
          </React.Fragment>
        ) : this.props.auto_suggestListData.length > 0 ? (
          <React.Fragment>
            <div className="vehicleTypeNewTravelContainer">
              {this.props.auto_suggestListData.map((item) => {
                return (
                  <div
                    onClick={() => {
                      this.handleAutoVehicleTypeSelect(item);
                    }}
                    className={`vehicleTypeItemNewTravel ${
                      this.props.auto_activeVehicleType &&  item.id == this.props.auto_activeVehicleType.id &&
                      "ActivevehicleTypeItemNewTravel"
                    } `}
                  >
                    <img
                      src={ item.icon}
                      className="vehicleTypeItemNewTravelImage"
                    />
                    <span className="vehicleTypeItemNewTravelName">
                      {item.title}
                    </span>
                  </div>
                );
              })}
            </div>

            <serviceOptions
              className={`${
                this.props.auto_activeVehicleType &&
                this.props.auto_activeVehicleType.options &&
                this.props.auto_activeVehicleType.options.length == 0 &&
                "displayNone"
              }`}
              style={{ marginLeft: "0px", width: "100%" }}
            >
              {this.props.auto_activeVehicleType &&
              this.props.auto_activeVehicleType.options &&
                this.props.auto_activeVehicleType.options.map((item) => {
                  return item.type === "list" ? (
                    <ListItem {...item} />
                  ) : (
                    <SelectableItem {...item} />
                  );
                })
                
                }
            </serviceOptions>
          </React.Fragment>
        ) : 
        <React.Fragment>
        <div>
          <span
            className="NotravelsListHeader"
            style={{ marginTop: "10px" }}
          >
            <Trans i18nKey={"Noinformationfound"}> </Trans>
          </span>

        </div>
      </React.Fragment>
        }
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  auto_suggestListLoading:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListLoading,
  auto_suggestListData:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,
  auto_suggestListOrdered:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListOrdered,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
