import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.loading) {
      return (
        <div className="NoOrderContainer">
          <div className="NoOrderInner mrb90">
          <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> 
            <span className="NoOrderTitle">
              {" "}
              <Trans i18nKey={"loadingList"}> </Trans>{" "}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  loading : state.AboutUS.loading
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
