import pickupIGO from "../../../static/pickup.png";
import Defaultbanner from "../../../static/delivery-5.jpg";

import FavIcon from "../../../static/PROJECTS/pickup.png";


export default function pickup() {
    
    return global.config = {
        "project": "app.pickup.global",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Pickup Customer Panel",
        "LogoType": pickupIGO,
        "BaseApi": "https://webservice.pickup.global",
        "BaseSocket": "https://socket.pickup.global",
        "BaseTrack": "https://panel.pickup.global",
        "MapCenter": {
            "lat": 27.709345,
            "lng": 85.327736
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/pickup.png",
        "Country": "US",
        "AppleLoginCustomerId": "global.pickup.customer",
        "AppleLoginredirectURI": "https://app.pickup.global/",
        "FacebookLoginappId": "3038885649533203",
        "GoogleLogincustomerId": "788214719383-jkhcnr57i6flkte8u1f8a56umc9v6jv8.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"pickup",
        registerEnabled: true,
        Secondarycolor:"#1080FF",
        SecondaryDarkColor:"#0055cb",
        SecondaryLightColor:"#25B7FF",
        SecondaryUltraLight :"#D9EAFF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#1080FF",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:pickupIGO,
        apkUrl:null,
        iosUrl:null
} }