import wadeliveryIGO from "../../../static/wadelivery_logotype.png";
import wadelivery_banner from "../../../static/wadelivery_background.png";


import FavIcon from "../../../static/PROJECTS/wadelivery.png";

export default function wadelivery() {
    
    return global.config = {
        "project": "my.wadelivery.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer App",
        "LogoType":wadeliveryIGO,
        "BaseApi": "https://rest.wadelivery.com",
        "BaseSocket": "https://socket.wadelivery.com",
        "BaseTrack": "https://dashboard.wadelivery.com",
        "MapCenter": {
            "lat": 18.533517,
            "lng": 73.864845
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/wadelivery.png",
        "Country": "IN",
        "AppleLoginCustomerId": null,
        "AppleLoginredirectURI": null,
        "FacebookLoginappId": null,
        "GoogleLogincustomerId": null,
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": wadelivery_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"wadelivery",
        registerEnabled: true,
        Secondarycolor:"#54264E",
        SecondaryDarkColor:"#3A1636",
        SecondaryLightColor:"#7A3D72",
        SecondaryUltraLight :"#EBE6EA",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#54264E",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:wadeliveryIGO,
        apkUrl:null,
        iosUrl:null
} }