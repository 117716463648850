import LogoType from "../../../static/RyderX.png";
import Banner from "../../../static/RyderX_banner.png";

import FavIcon from "../../../static/PROJECTS/RyderX_fav_icon.png";

export default function RyderX() {
    
    return  global.config = {
        "project": "RyderX",
        "ShowPowerOnro": false,
        "flag": "AustrianGermanflag",
        "ProjectName": "RyderX",
        "LogoType": LogoType,
        "BaseApi": "https://rest.ryderx.at",
        "BaseSocket": "https://socket.ryderx.at",
        "BaseTrack": "https://track.ryderx.at",
        "MapCenter": {
            "lat": 48.207163478689694,
            "lng": 16.372014684940364
        },
        "Lang": 'de-AT',
        "Direction": "Ltr",
        "AllLanguages": [   {
            "lang": 'de-AT',
            "direction": "Ltr",
            "i18Lang": "AustrianGerman",
            "cssFlag": "AustrianGermanflag",
            "country": "AT"
        },{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        } ],
        "FavIcon": "",
        "Country": "AT",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "RyderX",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"RyderX",
        registerEnabled: true,
        Secondarycolor: "#000000",
        SecondaryDarkColor: "#242424",
        SecondaryLightColor: "#242424",
        SecondaryUltraLight: "#E5E5E5",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#000000",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#97C4FF",
        icon_light_end:"#BCD5FF",
        icon_dark_start:"#5271FF",
        icon_dark_end:"#5F7BFF",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#EDF4FF",
        loginBackgroundColorEnd:"#B0D2FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}