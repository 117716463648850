import { createStore, applyMiddleware } from "redux";
import { reducers } from "../reducers";
import reduxThunk from "redux-thunk";
import logger from "redux-logger";

let createStoreWithMiddleware = null;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" ) {
  // dev code
  createStoreWithMiddleware = applyMiddleware(reduxThunk , logger)(createStore);
} else {
  // production code
  createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
}
export const store = createStoreWithMiddleware(reducers);
