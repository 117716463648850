import React, { useState } from "react";
import FirstSection from "./firstSection";
import SecondSection from "./SecondSection/index";
import poweredbyonro from "./../../static/poweredbyonro.svg";
import { withTranslation, Trans } from "react-i18next";
import {
  faviconUpdate,
  getProjectName,
  shouldShowPOWER,
} from "../../helper/module";
import DevelopmentCenterControlPanel from "../../Dev/DevelopmentCenterControlPanel";
import { get_lang } from "../../helper/UserData/userdate";
import axios from "axios";
// import { GoogleOAuthProvider } from '@react-oauth/google';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaStatus: null
    };
  }
  componentDidMount() {
    faviconUpdate();
    this.getRecaptchaKey()
  }

  getRecaptchaKey = async () => {

    let res = await axios.post(`${global.config.BaseApi}/api/v1/customer/application/web/initial-setting/`,null, {
      headers: {
        "Accept-Language": get_lang(),
        "X-Api-Source": "web",
      }
    })

    try {
      if (res.data.data) {
        global.config = { ...global.config, ...res.data.data, googleReCaptchaSiteKey: res.data.data.grsk }
        this.setState({
          recaptchaStatus: res.data.data.grsk
        })
      }
    } catch (error) {

    }
  }


  render() {

    if (!this.state.recaptchaStatus) {
      return <div className="NoOrderContainer">
        <div className="NoOrderInner mrb90">
          <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      </div>
    }
    return (


      <div className="LoginRegisterContainer">
        {/* <DevelopmentCenterControlPanel /> */}

        <SecondSection />
        <FirstSection />

        {/* {global.config.ShowPowerOnro &&  <div className=" LRFooter">
            <a className="PoweredBy" href="http://onro.io/">
              {" "}
              <Trans i18nKey={"PoweredBy"}> </Trans>
            </a>
        </div>} */}
      </div>

    );
  }
}

export default withTranslation()(App);
