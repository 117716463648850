import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import IconClose from "../../../../static/icon-close.svg";
import { getBaseTrackByUrl } from "../../../../helper/module";
import Dialog from "@material-ui/core/Dialog";
import { ReactComponent as Mutual } from "../../../../static/mutual-ic-photo.svg";
import { ReactComponent as Mutualadd } from "../../../../static/mutual-ic-add-photo.svg";
import ReactDOM from "react-dom";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import BottomSheet from 'bottom-sheet-react';
import { Api_get_Order_Info_AGW, Api_upload_order_service_phooto_AGW } from "../../../../helper/api/ApiGateway";
import { get_Id } from "../../../../helper/UserData/userdate";
import photos from "../../../../static/photos.svg";
import UploadPhoto from "../../../../static/UploadPhoto.svg";
import Typography from "../../../../design-system/lib/Typography";
import { pickup_isPickuped } from "./pickup";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#00000066",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showpoper: false,
      value: "",
      id: "_" + Math.random().toString(36).substr(2, 9),
      photoSelected: false,
      loading: false
    };
    this.input = React.createRef();
  }

  handleCollapse = (force) => {
    this.setState({
      showpoper: !this.state.showpoper,
    });

  };

  openInputDialoge = () => {
      var evObj = document.createEvent("MouseEvents");
      evObj.initMouseEvent("click", true, true, window);
      setTimeout(() => {
        this.input.current.dispatchEvent(evObj);
      }, 100);
    

  };

  profileChange = (e) => {
    this.setState({ file: e.target.files[0] });
    if (e.target.files && e.target.files[0]) {
      // this.props.dispatch(
      //   set_init_information({
      //     key: this.props.placeholder,
      //     value: e.target.files[0]
      //   })
      // );

      var img = document.createElement("img");

      var reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          let canvas = ReactDOM.findDOMNode(this.refs[this.state.id]);
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var MAX_WIDTH = 200;
          var MAX_HEIGHT = 200;
          var width = img.width;
          var height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          var dataurl = canvas.toDataURL("image/png");
          this.setState({ photoSelected: true, value: dataurl });
          //      this.props.dispatch(set_init_information({key:this.props.placeholder, value:dataurl}))
        };

        //  this.setState({ photoSelected: true, value: e.target.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      // this.props.dispatch(
      //   set_init_information({ key: this.props.placeholder, value: null })
      // );
    }
  };

  savePhoto = () => {
    if (this.state.value) {
      this.setState({
        loading: true
      })
      Api_upload_order_service_phooto_AGW(
        this.props.dispatch,
        {
          photo: this.state.file,
          orderId: this.props.ID,
          customerId: get_Id()
        },
        () => {
          this.setState({
            loading: false
          })
          Api_get_Order_Info_AGW(this.props.dispatch, this.props.ID)

        }
      );
    }
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {" "}
        <div
          className="addphotoService"
          onClick={ /* this.props.customerPhoto && */ this.handleCollapse}
        // style={{
        //   backgroundImage: `url(${this.props.customerPhoto ? this.props.customerPhoto : null})`,
        // }}
        style={{width : (!pickup_isPickuped(this.props.status) ? (!this.props.customerPhoto ? false : true) : true ) && "0px" }}
        >

          <React.Fragment>
            {/* <Mutual className="img"/> */}
            <img style={{ width: "12px" }} src={!this.props.customerPhoto ? UploadPhoto : photos} />
           {(!pickup_isPickuped(this.props.status) ? (!this.props.customerPhoto ? true : false) : false ) &&  <span>
              {" "}
              <Trans i18nKey={!pickup_isPickuped(this.props.status) ? (!this.props.customerPhoto ? "UploadPhoto" : "EditPhoto") : "Photos"}> </Trans>{" "}
            </span>}
          </React.Fragment>
        </div>
        {(!pickup_isPickuped(this.props.status) ? (!this.props.customerPhoto ? true : true) : false) && <InnerDialoge
          open={this.state.showpoper}
          handleCollapse={this.handleCollapse}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div className="podheader">
              <img
                src={IconClose}
                className="close"
                onClick={this.handleCollapse}
              />
              <span className="textheader">
                {" "}
                {this.props.t("ParcelPhoto")}{" "}
              </span>
            </div>

            <div
              id={this.state.id}
              className="AddPhotoConatiner"
              onClick={this.openInputDialoge}
              style={{
                backgroundImage: `url(${this.state.value ? this.state.value : (this.props.customerPhoto ? this.props.customerPhoto : this.state.value)})`,
                cursor: this.props.OrderListOpenDialoge ? "default" : !this.props.customerPhoto ? "" : "default"
              }}
            >
              {" "}
              {!this.props.customerPhoto && !this.state.value && (
                <React.Fragment>
                  <Mutualadd className="img" style={{ opacity: this.props.OrderListOpenDialoge ? "0" : "1", height: this.props.OrderListOpenDialoge ? "0" : "" }} />
                  {/* <img src={mutualadd} style={{opacity : this.props.OrderListOpenDialoge ? "0" :"1" , height: this.props.OrderListOpenDialoge ? "0" :""}} /> */}
                  <span style={{ margin: "6px 0px" }}>
                    {" "}
                    <Trans i18nKey={this.props.OrderListOpenDialoge ? "Nophoto" : "Addphoto"}> </Trans>
                  </span>
                </React.Fragment>
              )}
            </div>

            { <React.Fragment>   <hr
              style={{
                float: "left",
                width: "calc(100% - 2px)",
                border: "solid 1px rgba(128, 128, 128, 0.2)",
                margin: "0px",
              }}
            />
              <span className={`AddphotoBtnSave ${this.state.loading && "grey"}`} onClick={!this.state.loading && this.savePhoto}>
                {" "}
                <Trans i18nKey={"Save"}>
                  {" "}
                </Trans>{" "}
              </span>

            </React.Fragment>
            }
            <canvas style={{ display: "none" }} ref={this.state.id} />

            <input
              accept="image/png , image/jpeg , image/jpg"
              onChange={(e) => this.profileChange(e)}
              ref={this.input}
              style={{
                visibility: "hidden",
                width: "0px",
                height: "0px",
              }}
              type="file"
            ></input>
          </div>
        </InnerDialoge>}

        {(!pickup_isPickuped(this.props.status) ? (!this.props.customerPhoto ? false : false) : true) && <InnerDialoge
          open={this.state.showpoper}
          handleCollapse={this.handleCollapse}
          view={true}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div className="podheader">
              <img
                src={IconClose}
                className="close"
                onClick={this.handleCollapse}
              />
              <span className="textheader">
                {" "}
                {this.props.t("CustomerPhoto")}{" "}
              </span>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "24px", float: "left", gap: "16px" }}>
              <div
                id={this.state.id}
                className="AddPhotoConatiner"
                onClick={this.openInputDialoge}
                style={{
                  backgroundImage: `url(${this.props.customerPhoto ? this.props.customerPhoto : this.state.value})`,
                  width: "164px", margin: "0px"
                }}
              >
              </div>

            </div>




          </div>
        </InnerDialoge>}
      </React.Fragment>
    );
  }
}

function InnerDialoge(props) {
  if (!isDesktop && !isTablet) {
    return (
      props.open ?
        <BottomSheet
          isExpandable={false}
          customHeight={328}
          onClose={props.handleCollapse}
          backdropStyle={{ background: "rgb(13 13 13 / 55%)" }}
          containerStyle={{ borderRadius: "8px" }}
        >
          {props.children}
        </BottomSheet>
        :
        null
    );
  } else {
    return (
      <Dialog open={props.open} fullScreen={isMobile}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus >
        <Paper style={{ width: "376px", height: props.view ? "298px" : "325px", borderRadius: "8px", overflow: "hidden" }}>
          <ClickAwayListener onClickAway={props.handleCollapse}>
            {props.children}
          </ClickAwayListener>
        </Paper>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ID: state.ViewTravel.ID
    ? state.ViewTravel.ID
    : null,
  type: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
    : null,
  storageBaseUrl: state.SharedData.WebSettings ? state.SharedData.WebSettings.storageBaseUrl : null,
  customerPhoto: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customerPhoto
    : null,
  OrderListOpenDialoge: state.OrderHistory.OrderListOpenDialoge,
  driverPhoto: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driverPhoto
    : null,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
