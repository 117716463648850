import React, { useCallback, useEffect, useRef } from 'react'
import styles from './style.module.css';
import Typography from '../../../design-system/lib/Typography';
import IconProvider from '../../../design-system/lib/IconProvider';
// import { BOX, Ic_danger, Routes_deactive } from '../../../static';
import Button from '../../../design-system/lib/Button';
import { useDispatch } from 'react-redux';
import { clearAll_ActiveFilter } from '../../../Redux/actions/actionNames';







function TableNoOrderFilter() {
  const dispatch = useDispatch();



  const ClearAll = React.useCallback(() => {
    dispatch(clearAll_ActiveFilter())
  }, []);


  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100vw", flexDirection: 'column', transition: "all .3s ease-in-out" ,justifyContent:"center",alignItems:"center"}}>
          <div className={styles.dialog}>
            {/* <IconProvider Icon={Routes_deactive} style={{marginBottom:"16px"}} /> */}
            <Typography weight='Body_Middle_Bold' text='No result found' style={{textAlign:"center" , marginBottom:"4px"}} />
            <Typography weight='Body_Tiny_Regular' text='Please check your filters or keyword' style={{textAlign:"center",marginBottom:"16px"}} />
            <Button size='Medium' type='Primary' text='Clear All' className='orderTypesHeaderTabActive' onClick={ClearAll}/>
          </div>
    </div>
  )
}
export default TableNoOrderFilter