import LogoType from "../../../static/asankar.png";
import Banner from "../../../static/Asankar_banner.jpg";

import FavIcon from "../../../static/PROJECTS/Asankar_fav.png";

export default function Asankar() {
    
    return  global.config = {
        "project": "Asankar",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Dashboard",
        "LogoType": LogoType,
        "BaseApi": "https://rest.asankar.co",
        "BaseSocket": "https://socket.asankar.co",
        "BaseTrack": "https://panel.asankar.co",
        "MapCenter": {
            "lat": 36.1128,
            "lng": 44.033
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "IQ",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Asankar",
        registerEnabled: true,
        Secondarycolor:"",
        SecondaryDarkColor:"",
        SecondaryLightColor:"",
        SecondaryUltraLight :"",
        SecondaryTextColor:"",
        Secondaryonlight:"",
        TextDark:"",
        TextNormal:"",
        TextLight:"",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}
