import scootyIGO from "../../../static/scooty.png";
import FavIcon from "../../../static/PROJECTS/FavIcon_scooty.png";
import Banner from "../../../static/SCOOTY_banner.png";

export default function SCOOTY() {
    
    return  global.config = {
        "project": "SCOOTY",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "SCOOTY",
        "LogoType": scootyIGO,
        "BaseApi": "https://rest.scooty.gr",
        "BaseSocket": "https://socket.scooty.gr",
        "BaseTrack": "https://panel.scooty.gr",
        "MapCenter": {
            "lat": 38.24813812705293,
            "lng": 21.734104487679087
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "el",
            "direction": "Ltr",
            "i18Lang": "Greek",
            "cssFlag": "Greekflag",
            "country": "GR"
        }, {
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "DO"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        },{
            "lang": "nl",
            "direction": "Ltr",
            "i18Lang": "Dutch",
            "cssFlag": "dutchflag",
            "country": "NL"
        }],
        "FavIcon": "",
        "Country": "CY",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"SCOOTY",
        registerEnabled: true,
        Secondarycolor: "#61AE46",
        SecondaryDarkColor: "#61AE46",
        SecondaryLightColor: "#D2E6CC",
        SecondaryUltraLight: "#D2E6CC",
        SecondaryTextColor: "#242424",
        Secondaryonlight: "#111111",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#61AE46",
        icon_light_end:"#61AE46",
        icon_dark_start:"#A7DE97",
        icon_dark_end:"#A7DE97",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#fff2c2",
        loginBackgroundColorEnd:"#fff2c2",
        LoginTextColor :"#11293B",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:scootyIGO,
        apkUrl:null,
        iosUrl:null
}}