import React, { useState } from "react";
import service_box from "../../../../../static/service-box.jpg";
import pickup from "../../../../../static/mutual-ic-pickup.svg";
import dropoff from "../../../../../static/group-4-copy.svg";
import newMessageIcon from "../../../../../static/cp-ic-messages.svg";

import car from "../../../../../static/icon-car.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import {
  AddNewMessageFlagToOrder,
  ChangeViewTravelDialoge,
  openCloseOrderHistory,
} from "../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import jalali from "jalali-moment";
import { Api_get_balance_AGW, Api_get_Order_Info_AGW, Api_Get_Services_AGW } from "../../../../../helper/api/ApiGateway";
import { ReactComponent as Sion } from "../../../../../static/Mutual___ic_calender.svg";
import Pickup from "./OrderTypes_ListItem/Pickup";
import Delivery from "./OrderTypes_ListItem/Delivery";
import PickupDelivery from "./OrderTypes_ListItem/PickupDelivery";
import Ondemand from "./OrderTypes_ListItem/Ondemand";
import _, { get } from "lodash";
import Typography from "../../../../../design-system/lib/Typography";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.status = {
      showRipple: false,
    };
  }

  openOrderClick = () => {
    Api_get_balance_AGW(this.props.dispatch, {}, (call) => { });

    if (this.props.data.newMessage) {
      setTimeout(() => {
        this.props.dispatch(
          AddNewMessageFlagToOrder({
            id: this.props.data.id,
            bool: true,
          })
        );
      }, 1000);
    }
    this.props.dispatch(ChangeViewTravelDialoge(true, this.props.data.id));
    Api_get_Order_Info_AGW(this.props.dispatch, this.props.data.id);
    // this.props.dispatch(OrderInfoAction(this.props.dispatch,{
    //   orderId : this.props.id
    // }))
    Api_Get_Services_AGW(this.props.dispatch, null);

  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.data.status != this.props.data.status) {
    //   this.handleRipple();
    // }
  }

  handleRipple = () => {
    this.setState(
      {
        showRipple: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showRipple: false,
          });
        }, 2000);
      }
    );
  };

  render() {
    return (
      // <div
      //   className={`OrderItemContainer  ${
      //     this.state.showRipple ? "blink" : ""
      //   }`}
      <div
        className={`OrderItemContainer `}
        onClick={this.openOrderClick}
        id={this.props.data.id}
      >
        <div className="OrderItem_firstrow">
          {/* {this.props.data.service && (
            <img src={this.props.data.service.icon} />
          )} */}
          <span className="service_box">
            {" "}
            {this.props.data.service && this.props.data.service.title}
          </span>

          <span
            className={`OrderItemCarState OrderList_${this.props.data.status || "Pending"}`}
          >
            {this.props.data.statusDescription ? this.props.data.statusDescription : <Trans i18nKey={"statusPending"}></Trans>}

          </span>
        </div>
 

        {this.props.type == "Pickup" ? (
          <Pickup pickup={this.props.pickup} />
        ) : this.props.type == "Delivery" ? (
          <Delivery dropoff={this.props.dropoff} />
        ) : this.props.type == "PickupDelivery" ? (
          <PickupDelivery
            pickup={this.props.pickup}
            dropoff={this.props.dropoff}
          />
        ) : (
          <Ondemand pickup={this.props.pickup} dropoffs={this.props.dropoffs} />
        )}

        <div className="pickupdropoffscontainer">
          {/* {!_.isEmpty(this.props.data.vehicleType) &&
            <React.Fragment>
              <img
                src={this.props.data.vehicleType.icon}
                className="OrderItemCar"
              />
              <span className="OrderItemCarName">
                {" "}
                {this.props.data.vehicleType.title}{" "}
              </span>
            </React.Fragment>
          } */}

          

{this.props.type == "Ondemand" &&
          this.props.data.dropoffs.length > 1 ? (
          <Typography weight="Body_Small_Regular" text={`+${this.props.data.dropoffs.length - 1} dropoffs`} style={{color:"#484848"}} />
        ) : (
          ""
        )}
          {/* {this.props.data.pickup ? (this.props.data.pickup.scheduleDateAfter ||
            this.props.data.pickup.scheduleDateBefore ? (
            <div className="scheduledOrderTimeConatiner">
              <Sion className="img" />
              <span>
                {new Date(
                  Number(
                    this.props.data.pickup.scheduleDateAfter ||
                    this.props.data.pickup.scheduleDateBefore
                  )
                ).toLocaleDateString(get_lang(), {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hourCycle: 'h23'
                })}
              </span>
            </div>
          ) : null) : null} */}

          {this.props.data.newMessage && (
            <img src={newMessageIcon} style={{marginInlineStart:"auto"}} className={`UnreadMessage`} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
