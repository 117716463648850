import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import close from "../../../../../static/close.svg";
import Addressbook from "../../../../../static/icon-ic-address-book-menu.svg";
import {ReactComponent as NewAddressbook} from "../../../../../static/icon-ic-pin-dark.svg";
import searchicon from "../../../../../static/iconly-light-outline-search.svg";
import AddressItem from "./addressItem";
import NewAddress from "./newAddress";
import {
  get_customerPannel_type,
  get_LeftRight,
} from "../../../../../helper/UserData/userdate";
import {
  SetAddressBookSearchRegex,
  SetopenAddressBookInNewOrder,
} from "../../../../../Redux/actions/actionNames";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Api_Get_Addressbook_list_AGW } from "../../../../../helper/api/ApiGateway";
// import PerfectScrollbar from "perfect-scrollbar";
import { BottomScrollListener } from "react-bottom-scroll-listener";

var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAddressbook: false,
      openNewAddress: false,
      openEditAddress: false,
    };
    this.ps = null;
    this.loadMore = this.loadMore.bind(this);
  }

  loadMore = () => {
    Api_Get_Addressbook_list_AGW(
      this.props.dispatch,
      {
        page: this.props.page,
        perpage: 10,
        searchRegex: this.props.searchRegex,
      },
      true
    );
  };

  componentDidMount() {
    // try {
    //   const container = document.getElementById("AddressBookListOrder");
    //   this.ps = new PerfectScrollbar(container);
    //   container.addEventListener("ps-y-reach-end", () => {
    //     this.loadMore();
    //   });
    // } catch (error) {}
  }

  componentWillReceiveProps(nextProps) {
    // setTimeout(() => {
    //   if (nextProps.openAddressBook && this.ps == null && this.props.list.length > 0) {
    //     try {
    //       const container = document.getElementById("AddressBookListOrder");
    //       this.ps = new PerfectScrollbar(container);
    //       container.addEventListener("ps-y-reach-end", () => {
    //         this.loadMore();
    //       });
    //     } catch (error) {}
    //   }
    // }, 300);
  }

  handleOpenNewAddress = () => {
    this.setState({
      openNewAddress: !this.state.openNewAddress,
    });
  };

  handleEditAddress = () => {
    this.setState({
      openEditAddress: !this.state.openEditAddress,
    });
  };

  openAddressbook = () => {
    this.setState({
      openAddressbook: true,
    });
  };

  closeAddressbook = () => {
    this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
  };

  AddressbookSearchbar = (e) => {
    this.props.dispatch(SetAddressBookSearchRegex(e.target.value));

    Api_Get_Addressbook_list_AGW(
      this.props.dispatch,
      { page: 1, perpage: 20, searchRegex: e.target.value },
      false
    );
  };

  handleContainerOnBottom = () => {
    this.loadMore();
  };


  render() {
    return (
      <React.Fragment>
        {this.props.ShowComponentMenu ? (
          <div
            className="AddressbookContainerPickup"
            onClick={() => this.openAddressbook()}
          >
            <NewAddressbook className="img" />
            {/* <img src={newAddressbook} /> */}
            <span>
              {" "}
              <Trans i18nKey={"Addressbook"}> </Trans>{" "}
            </span>
          </div>
        ) : null}
        <Slide
          direction={get_LeftRight()}
          in={this.props.openAddressBook}
          mountOnEnter
          unmountOnExit
        >
          <div className="AddressbookNewOrderConatiner">
            <nRHeader style={{ boxShadow: "none" }}>
              <span className="title">
                <Trans
                  i18nKey={
                    this.props.sourceOfAddressBookInNewOrder
                      ? this.props.sourceOfAddressBookInNewOrder.i18
                      : "Addressbook"
                  }
                >
                  {" "}
                </Trans>
              </span>

              <div
                className="newAddressbookContainer mrgt19mrgr16"
                onClick={this.closeAddressbook}
              >
                <span>
                  {" "}
                  <Trans i18nKey={"hide"}> </Trans>{" "}
                </span>
              </div>
            </nRHeader>

            {(this.props.list.length > 0 ||
              this.props.searchRegex.length > 0) &&
              get_customerPannel_type() == "business" && (
                <div className={`NewOrderAddressbookSearchbarContainer`}>
                  <img src={searchicon} />
                  <input
                    value={this.props.searchRegex}
                    placeholder={this.props.t("AddressbookSearchbar")}
                    onChange={this.AddressbookSearchbar}
                  />
                </div>
              )}

            {this.props.listLoading && (
              <CircularProgress className="AddressbookCircularProgress" />
            )}
            <BottomScrollListener onBottom={this.handleContainerOnBottom}>
              {(scrollRef) => (
                <div
                  ref={scrollRef} 
                  id="AddressBookListOrder"
                  className={`AddressbookMenuListContainer NewOrderAddressbookMenuListContainer ${
                    get_customerPannel_type() != "business" && "searchbarHided"
                  }`}
                  style={{ display: this.props.list.length == 0 ? "none" : "" }}
                >
                  {this.props.list.map((item) => {
                    return (
                      <AddressItem
                        onEdit={this.handleEditAddress}
                        AddressData={item}
                      />
                    );
                  })}
                </div>
              )}
            </BottomScrollListener>
            {this.props.list.length == 0 && !this.props.listLoading && (
              <div
                className="nosavedaddressContainer"
                style={{ marginTop: "300px" }}
              >
               <NewAddressbook className="img" />
                <p className="nosavedtitle">
                  {" "}
                  <Trans i18nKey={"Nosavedaddress"}> </Trans>{" "}
                </p>
                <p className="NosavedaddressDesc">
                  {" "}
                  <Trans i18nKey={"NosavedaddressDesc"}> </Trans>{" "}
                </p>
              </div>
            )}
          </div>
        </Slide>

        <NewAddress
          open={this.state.openNewAddress}
          onClose={this.handleOpenNewAddress}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  openAddressBook: state.Addressbook.openAddressBookInNewOrder,
  sourceOfAddressBookInNewOrder:
    state.Addressbook.sourceOfAddressBookInNewOrder,
  list: state.Addressbook.list,
  listLoading: state.Addressbook.listLoading,
  listError: state.Addressbook.listError,
  perpage: state.Addressbook.perpage,
  page: state.Addressbook.page,
  searchRegex: state.Addressbook.searchRegex,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
