import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CalculatePrice from "./calculatePrice";
import CanSaveOrder from "./canSaveOrder";
import Header from "./components/header";
import Footer from "./components/footer";
import TimeMachine from "./components/TimeMachine";
import Service from "./components/service";
import PromotionCode from "./components//promotionCode";
import COD from "./components/COD";
import Pickup from "./components/pickup";
import Dropoff from "./components/dropoff";
import Driver from "./components/driver";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
// import { Add_dropoff, Set_ActivePickupDropOff, Remove_dropoff } from "../../../Redux/actions/actionNames";
// import { ServicesVehicletypeAction } from "../../../Redux/actions/actions";
import { get_customerPannel_type, get_LeftRight, Get_Permisssion } from "../../../helper/UserData/userdate";
import { Api_Get_Services_AGW } from "../../../helper/api/ApiGateway";
import {
  Add_dropoff,
  New_Travel_PaymentMethod,
  Remove_dropoff,
  Set_ActivePickupDropOff,
  Set_latlng,
  Set_Pickup_Inputs,
} from "../../../Redux/actions/actionNames";
import Addressbook from "./components/Addressbook";
import { DetectOndemandOrPickupDelivery } from "../../../helper/worker/DetectOndemandOrReserve copy";
import { getCenterMap, setCenterMap } from "../../../helper/module";
import ReferenceID from "./components/ReferenceID";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idArrays: [],
      dropoffs: [],
    };
  }
  handleCollapse = (id) => {
    this.props.dispatch(Set_ActivePickupDropOff(id));
    this.setState({
      [id]: !this.state[id],
    });

    this.setState((state) => {
      let x = {};
      state.idArrays.map((id) => {
        x[id] = false;
      });

      return { ...x, [id]: !this.state[id] };
    });
  };

  setId = (id) => {
    this.setState((state) => {
      return { idArrays: [...state.idArrays, id + ""], [id]: false };
    });
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentDidMount() {
    Api_Get_Services_AGW(this.props.dispatch, this.props.auto_activeVehicleType ? null : this.props.mapRef.current ? getCenterMap(this.props.mapRef, this.props.center) : this.props.center);
    // this.checkandSekectDefaultPickup()
    // this.props.dispatch(ServicesVehicletypeAction(this.props.dispatch , this.props.center))
  }

  removeDropOff = (id) => {
    this.props.dispatch(Remove_dropoff(id));
  };


  componentWillReceiveProps(nextProps) {
    if (nextProps.OpenDialoge && !this.props.OpenDialoge && get_customerPannel_type() == "business") {
      this.checkandSekectDefaultPickup()
    }
  }



  checkandSekectDefaultPickup = () => {
    this.props.list.forEach(element => {
      if (element.isDefaultPickupAddress) {

        try {

          setCenterMap(this.props.mapRef, {
            lng: element.coordinates[0],
            lat: element.coordinates[1],
          })

          // this.props.mapRef.current.leafletElement.setView(
          //   {
          //     lng: element.coordinates[0],
          //     lat: element.coordinates[1],
          //   },
          //   17
          // );
        } catch (error) {
          this.props.dispatch(
            Set_latlng({
              lng: element.coordinates[0],
              lat: element.coordinates[1],
            })
          );
        }

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "address",
            value: element.address,
          })
        );


        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "adresss_loc",
            value: { lng: element.coordinates[0], lat: element.coordinates[1] },
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "block",
            value: element.buildingBlock,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "floor",
            value: element.floor,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "senderFullName",
            value: element.fullName,
          })
        );


        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "senderPhoneNumber",
            value: element.phone,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "unit",
            value: element.room,
          })
        );

      }
    });
  }


  showVehicleType = () => {

    if (this.props.serviceactiveVehicleType ? this.props.serviceactiveVehicleType.orderTypes : [].indexOf("PickupDelivery") > -1) {
      if (this.props.isSelectVehicleTypeActive) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }

  }


  render() {

    return (
      <React.Fragment>
        <Slide
          direction={get_LeftRight()}
          in={this.props.OpenDialoge}
          mountOnEnter
          unmountOnExit
          timeout={750}
        >
          <div className="NewOrder">
            <Header />

            <itemContainers>
              <TimeMachine />
              <Service />
              <div className="noboxshadow">
                <Pickup
                  isAddressDetailsActive={this.props.isAddressDetailsActive}
                  setId={this.setId}
                  handleCollapse={this.handleCollapse}
                />
                <Dropoff
                  isAddressDetailsActive={this.props.isAddressDetailsActive}
                  setId={this.setId}
                  handleCollapse={this.handleCollapse}
                  firstDropOff={true}
                  {...this.props}
                  id="dropoff"
                  last={this.props.dropoffs.length === 0}
                  length={this.props.dropoffs.length + 1 }

                />

                {this.props.dropoffs.map((drop, i) => {
                  return (
                    <Dropoff
                      isAddressDetailsActive={this.props.isAddressDetailsActive}
                      id={drop.id}
                      handleCollapse={this.handleCollapse}
                      {...drop}
                      number={i + 2}
                      last={i + 1 === this.props.dropoffs.length}
                      removeDrop={this.removeDropOff}
                      length={this.props.dropoffs.length + 1 }
                    />
                  );
                })}
              </div>

              {this.showVehicleType() ? <Driver
                setId={this.setId}
                handleCollapse={this.handleCollapse}
                firstDropOff
                addDropOff={this.addDropOff}
              /> : null
              }
                <div style={{display:"flex",flexDirection:"row",float:"left",width:"calc(100% - 32px)",justifyContent:"space-between" ,float:"left",marginLeft:"16px"}}>
                <COD />
                <ReferenceID />
              </div>
              <PromotionCode />

            </itemContainers>
            <CalculatePrice />
            <CanSaveOrder />

            <Footer />


          </div>
        </Slide>
        <Addressbook />
      </React.Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  OpenDialoge: state.NewOrder.OpenDialoge,
  dropoffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  address: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  adresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  block: state.NewOrder.newOrder.dropOffs.firstDropOff.block,
  floor: state.NewOrder.newOrder.dropOffs.firstDropOff.floor,
  unit: state.NewOrder.newOrder.dropOffs.firstDropOff.unit,
  email: state.NewOrder.newOrder.dropOffs.firstDropOff.email,
  recieverFullName:
    state.NewOrder.newOrder.dropOffs.firstDropOff.recieverFullName,
  recieverPhoneNumber:
    state.NewOrder.newOrder.dropOffs.firstDropOff.recieverPhoneNumber,
  noteForDriver:
    state.NewOrder.newOrder.dropOffs.firstDropOff.noteForDriver,
  center: state.Map.center,
  scheduleDateAfter:
    state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateAfter,
  scheduleDateBefore:
    state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateBefore,

  isAddressDetailsActive: state.NewOrder.newOrder.service.activeVehicleType
    ? state.NewOrder.newOrder.service.activeVehicleType.setting &&
    state.NewOrder.newOrder.service.activeVehicleType.setting
      .isAddressDetailsActive
    : false,
  mapRef: state.Map.mapRef,
  list: state.Addressbook.list,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  auto_activeVehicleType:
  state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  isSelectVehicleTypeActive: state.NewOrder.newOrder.service.activeVehicleType
    ? state.NewOrder.newOrder.service.activeVehicleType.setting &&
    state.NewOrder.newOrder.service.activeVehicleType.setting.isVehicleTypeSelectionActive
    : false,


  balance: state.NewOrder.newOrder.balance.balance,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
