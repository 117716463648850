import React from 'react';
import { GetInvoiceListAction } from './Action/GetInvoiceListAction';
import { InvoiceownloadAction } from './Action/InvoiceDownloadAction';
import { View } from './view';


export class ViewHandler extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page:1,
            perPage:15
        }
        this.Download = this.Download.bind(this)
        this.handleContainerOnBottom = this.handleContainerOnBottom.bind(this)

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.props.open) {
            this.setState({
                list: [],
                page:1,
                perPage:15
            }, () => {
                GetInvoiceListAction(1, this.state.perPage, this.props.dispatch, (call) => {
                    this.setState({
                        list: call
                    })
                })
            })
        }

        if (!nextProps.open && this.props.open) {
            this.setState({
                list: [],
                page:1,
                perPage:15
            })
        }

    }

    Download(id, code) {
        InvoiceownloadAction(id, code, this.props.dispatch)
    }

    handleContainerOnBottom() {
        GetInvoiceListAction( this.state.page+1 , this.state.perPage, this.props.dispatch, (call) => {
            this.setState({
                list: [...this.state.list,...call],
                page:this.state.page+1
            })
        })
    }



    render() {
        return <View list={this.state.list} open={this.props.open} handleOpen={this.props.handleOpen} handleContainerOnBottom={this.handleContainerOnBottom} download={this.Download} />
    }

}


