import myWorker from "./DetectOndemandOrReserve.worker";

export const DetectOndemandOrPickupDelivery = (data, call) => {
  const worker = new myWorker();
  worker.postMessage(data);
  worker.addEventListener("message", (event) => {
    call(event.data);
    worker.terminate();
  });
};
