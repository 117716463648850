import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../../helper/worker/CalculatePriceWorker";
import { CalculatePrice_PickupDelivery_Worker } from "../../../../helper/worker/CalculatePrice_PickupDelivery_Worker";
import { CanOrderCalculateTravelCost } from "../../../../helper/worker/CanOrderCalculateTravelCost";
import momentJalaali from "moment-jalaali";
import { get_customerPannel_type, get_Id } from "../../../../helper/UserData/userdate";
import { DetectOndemandOrPickupDelivery } from "../../../../helper/worker/DetectOndemandOrReserve copy";

class App extends Component {
  componentDidMount() {
    DetectOndemandOrPickupDelivery(
      this.props.serviceactiveVehicleType ? this.props.serviceactiveVehicleType.orderTypes : [],
      (call) => {
        if (call === "ondeman") {
          CalculatePriceWorker(
            {
              serviceactiveVehicleType: this.props.serviceactiveVehicleType,
              serviceoptions: this.props.serviceoptions,
              driveroptions: this.props.driveroptions,
              auto_activeVehicleType: this.props.auto_activeVehicleType,
              pickup: {
                address: this.props.pickup.address,
                scheduleDateAfter: momentJalaali(
                  this.props.pickup.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateAfter + "000")
                  ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.pickup.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateBefore + "000")
                  ).unix()
                  : 0,
              },
              pickupadresss_loc: this.props.pickupadresss_loc,
              firstDropOff: {
                address: this.props.firstDropOff.address,
                scheduleDateAfter: momentJalaali(
                  this.props.firstDropOff.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateAfter + "000"
                    )
                  ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.firstDropOff.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateBefore + "000"
                    )
                  ).unix()
                  : 0,
              },
              firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
              otherdropOffs: this.props.otherdropOffs.map((drop) => {
                return {
                  ...{ address: drop.address },
                  ...{ adresss_loc: drop.adresss_loc },
                  ...{ block: drop.block },
                  ...{ floor: drop.floor },
                  ...{ id: drop.id },
                  ...{ recieverFullName: drop.recieverFullName },
                  ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
                  ...{ unit: drop.unit },
                  ...{
                    scheduleDateAfter: momentJalaali(
                      drop.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                        Number(drop.scheduleDateAfter + "000")
                      ).unix()
                      : 0,
                  },
                  ...{
                    scheduleDateBefore: momentJalaali(
                      drop.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                        Number(drop.scheduleDateBefore + "000")
                      ).unix()
                      : 0,
                  },
                };
              }),
            },
            this.props.dispatch
          );
        } else {
          CalculatePrice_PickupDelivery_Worker(
            {
              customerId: get_Id(),
              serviceactiveVehicleType: this.props.serviceactiveVehicleType,
              serviceoptions: this.props.serviceoptions,
              driveroptions: this.props.driveroptions,
              auto_activeVehicleType: this.props.auto_activeVehicleType,
              pickup: {
                address: this.props.pickup.address,
                scheduleDateAfter: momentJalaali(
                  this.props.pickup.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateAfter + "000")
                  ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.pickup.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateBefore + "000")
                  ).unix()
                  : 0,
                // senderFullName: this.props.pickup.senderFullName,
                // senderPhoneNumber: this.props.pickup.senderPhoneNumber,
                // unit: this.props.pickup.unit,
              },
              pickupadresss_loc: this.props.pickupadresss_loc,
              firstDropOff: {
                address: this.props.firstDropOff.address,
                scheduleDateAfter: momentJalaali(
                  this.props.firstDropOff.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateAfter + "000"
                    )
                  ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.firstDropOff.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateBefore + "000"
                    )
                  ).unix()
                  : 0,
              },
              firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,

            },
            this.props.dispatch
          );
        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    CanOrderCalculateTravelCost(
      {
        serviceactiveVehicleType: this.props.serviceactiveVehicleType,
        serviceoptions: this.props.serviceoptions,
        driveroptions: this.props.driveroptions,
        auto_activeVehicleType: this.props.auto_activeVehicleType,
        manual_activeVehicleType: this.props.auto
          ? 0
          : this.props.manual_activeVehicleType,
        pickupadresss_loc: this.props.pickupadresss_loc,
        pickupscheduleDateAfter: momentJalaali(
          this.props.pickup.scheduleDateAfter
        ).isValid()
          ? momentJalaali(
            Number(this.props.pickup.scheduleDateAfter + "000")
          ).unix()
          : 0,
        pickupscheduleDateBefore: momentJalaali(
          this.props.pickup.scheduleDateBefore
        ).isValid()
          ? momentJalaali(
            Number(this.props.pickup.scheduleDateBefore + "000")
          ).unix()
          : 0,
        firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
        otherdropOffs: this.props.otherdropOffs.map((drop) => {
          return {
            ...{ adresss_loc: drop.adresss_loc },
          };
        }),
      },
      {
        serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
        serviceoptions: nextProps.serviceoptions,
        driveroptions: nextProps.driveroptions,
        auto_activeVehicleType: nextProps.auto_activeVehicleType,
        manual_activeVehicleType: nextProps.auto
          ? 0
          : nextProps.manual_activeVehicleType,

        pickupadresss_loc: nextProps.pickupadresss_loc,
        pickupscheduleDateAfter: momentJalaali(
          nextProps.pickup.scheduleDateAfter
        ).isValid()
          ? momentJalaali(
            Number(nextProps.pickup.scheduleDateAfter + "000")
          ).unix()
          : 0,
        pickupscheduleDateBefore: momentJalaali(
          nextProps.pickup.scheduleDateBefore
        ).isValid()
          ? momentJalaali(
            Number(nextProps.pickup.scheduleDateBefore + "000")
          ).unix()
          : 0,
        firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
        otherdropOffs: nextProps.otherdropOffs.map((drop) => {
          return {
            ...{ adresss_loc: drop.adresss_loc },
          };
        }),
      },
      (call) => {
        if (call) {
          DetectOndemandOrPickupDelivery(
            nextProps.serviceactiveVehicleType ? nextProps.serviceactiveVehicleType.orderTypes : [],

            (callx) => {

              if (callx == "ondeman") {
                CalculatePriceWorker(this.removeEmpty({
                  promoCode: nextProps.promoCode,
                  serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
                  serviceoptions: nextProps.serviceoptions,
                  driveroptions: nextProps.driveroptions,
                  auto_activeVehicleType: nextProps.auto_activeVehicleType,
                  pickup: {
                    address: nextProps.pickup.address,
                    scheduleDateAfter: momentJalaali(
                      nextProps.pickup.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                        Number(nextProps.pickup.scheduleDateAfter + "000")
                      ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      nextProps.pickup.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                        Number(nextProps.pickup.scheduleDateBefore + "000")
                      ).unix()
                      : 0,
                    // senderFullName: nextProps.pickup.senderFullName,
                    // senderPhoneNumber: nextProps.pickup.senderPhoneNumber,
                    // unit: nextProps.pickup.unit,
                  },
                  pickupadresss_loc: nextProps.pickupadresss_loc,
                  firstDropOff: {
                    address: nextProps.firstDropOff.address,
                    scheduleDateAfter: momentJalaali(
                      nextProps.firstDropOff.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                        Number(
                          nextProps.firstDropOff.scheduleDateAfter + "000"
                        )
                      ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      nextProps.firstDropOff.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                        Number(
                          nextProps.firstDropOff.scheduleDateBefore + "000"
                        )
                      ).unix()
                      : 0,
                    // block: nextProps.firstDropOff.block,
                    // floor: nextProps.firstDropOff.floor,
                    // recieverFullName: nextProps.firstDropOff.recieverFullName,
                    // recieverPhoneNumber: nextProps.firstDropOff.recieverPhoneNumber,
                    // unit: nextProps.firstDropOff.unit,
                  },
                  firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
                  otherdropOffs: nextProps.otherdropOffs.map((drop) => {
                    return {
                      ...{ address: drop.address },
                      ...{ adresss_loc: drop.adresss_loc },
                      ...{ block: drop.block },
                      ...{ floor: drop.floor },
                      ...{ id: drop.id },
                      ...{ recieverFullName: drop.recieverFullName },
                      ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
                      ...{ unit: drop.unit },
                      ...{
                        scheduleDateAfter: momentJalaali(
                          drop.scheduleDateAfter
                        ).isValid()
                          ? momentJalaali(
                            Number(drop.scheduleDateAfter + "000")
                          ).unix()
                          : 0,
                      },
                      ...{
                        scheduleDateBefore: momentJalaali(
                          drop.scheduleDateBefore
                        ).isValid()
                          ? momentJalaali(
                            Number(drop.scheduleDateBefore + "000")
                          ).unix()
                          : 0,
                      },
                    };
                  }),
                })
                  ,
                  this.props.dispatch
                );
              } else {
                CalculatePrice_PickupDelivery_Worker(this.removeEmpty(
                  {
                    customerId: get_Id(),
                    promoCode: this.props.promoCode,
                    serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
                    serviceoptions: nextProps.serviceoptions,
                    driveroptions: nextProps.driveroptions,
                    auto_activeVehicleType: nextProps.auto_activeVehicleType,
                    pickup: {
                      address: nextProps.pickup.address,
                      scheduleDateAfter: momentJalaali(
                        nextProps.pickup.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                          Number(nextProps.pickup.scheduleDateAfter + "000")
                        ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        nextProps.pickup.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                          Number(nextProps.pickup.scheduleDateBefore + "000")
                        ).unix()
                        : 0,
                      // senderFullName: nextProps.pickup.senderFullName,
                      // senderPhoneNumber: nextProps.pickup.senderPhoneNumber,
                      // unit: nextProps.pickup.unit,
                    },
                    pickupadresss_loc: nextProps.pickupadresss_loc,
                    firstDropOff: {
                      address: nextProps.firstDropOff.address,
                      scheduleDateAfter: momentJalaali(
                        nextProps.firstDropOff.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                          Number(
                            nextProps.firstDropOff.scheduleDateAfter + "000"
                          )
                        ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        nextProps.firstDropOff.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                          Number(
                            nextProps.firstDropOff.scheduleDateBefore + "000"
                          )
                        ).unix()
                        : 0,
                      // block: nextProps.firstDropOff.block,
                      // floor: nextProps.firstDropOff.floor,
                      // recieverFullName: nextProps.firstDropOff.recieverFullName,
                      // recieverPhoneNumber: nextProps.firstDropOff.recieverPhoneNumber,
                      // unit: nextProps.firstDropOff.unit,
                    },
                    firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
                  })


                  ,
                  this.props.dispatch
                );
              }
            }
          );
        }
      }
    );
  }

  removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  promoCodeValid: state.NewOrder.newOrder.promotionCode.valid,
  promoCode: state.NewOrder.newOrder.promotionCode.code,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
