import SwiftitExpressIGO from "../../../static/SwiftitExpress.png";
import Defaultbanner from "../../../static/banner_SwiftitExpress.png";

import FavIcon from "../../../static/PROJECTS/FavIcon_SwiftitExpress.png";


export default function SwiftitExpress() {
    
    return global.config = {
        "project": "Swiftit Express",
        "ShowPowerOnro": false ,
        "flag": "englishflag",
        "ProjectName": "Swiftit Express",
        "LogoType": SwiftitExpress,
        "BaseApi": "https://rest.swiftitexpress.com",
        "BaseSocket": "https://socket.swiftitexpress.com",
        "BaseTrack": "https://adminbackoffice.swiftitexpress.com",
        "MapCenter": {
            "lat": 40.675064424536075,
            "lng": -73.94328093758607
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/SwiftitExpress.png",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"SwiftitExpress",
        registerEnabled: true,
        Secondarycolor: "#0284C7",
        SecondaryDarkColor: "#006599",
        SecondaryLightColor: "#0096E3",
        SecondaryUltraLight: "#EBF5FB",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#0284C7",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#7DC9FC",
        icon_light_end:"#94D4FF",
        icon_dark_start:"#0284C7",
        icon_dark_end:"#0096E3",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#7DC9FC",
        loginBackgroundColorEnd:"#94D4FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:SwiftitExpressIGO,
        apkUrl:null,
        iosUrl:null
} }