
import cartingoIGO from "../../../static/cartingo_logotype.png";
import cartingo_banner from "../../../static/cartingo_banner.png";
import FavIcon from "../../../static/PROJECTS/cartingo.png";

export default function cartingo() {
    
    return global.config = {
        "project": "restaurant.catrin-go.com",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Catringo Restaurant",
        "LogoType": cartingoIGO,
        "BaseApi": "https://rest.catrin-go.com",
        "BaseSocket": "https://socket.catrin-go.com",
        "BaseTrack": "https://admin.catrin-go.com",
        "MapCenter": {
            "lat": 33.680788,
            "lng": -117.821799
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/cartingo.png",
        "Country": "US",
        "AppleLoginCustomerId": "6N993LX47C",
        "AppleLoginredirectURI": "https://restaurant.catrin-go.com",
        "FacebookLoginappId": "299979168545056",
        "GoogleLogincustomerId": "297915523265-eidtdh15t7epdo1o1jq1nnahtshomlpt.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": cartingo_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"cartingo",
        registerEnabled: true,
        Secondarycolor:"#004900",
        SecondaryDarkColor:"#003800",
        SecondaryLightColor:"#006600",
        SecondaryUltraLight :"#F0F5F0",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#004900",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:cartingoIGO,
        apkUrl:null,
        iosUrl:null
} }