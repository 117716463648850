
import logotype from "../../../static/Geshikt_logotype.png";
import banner from "../../../static/Cp_background_Geshikt.png";
import FavIcon from "../../../static/PROJECTS/Geshikt.png";

export default function Geshikt() {
    
    return global.config = {
        "project": "app.geshikt.be",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Geshikt Logistics",
        "LogoType": logotype,
        "BaseApi": "https://rest.geshikt.be",
        "BaseSocket": "https://socket.geshikt.be",
        "BaseTrack": "https://admin.geshikt.be",
        "MapCenter": {
            "lat": 51.235121623532386,
            "lng": 4.41751081005547
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "nl",
            "direction": "Ltr",
            "i18Lang": "Dutch",
            "cssFlag": "dutchflag",
            "country": "NL"
        },],
        "FavIcon": "",
        "Country": "BE",
        "AppleLoginCustomerId": '',
        "AppleLoginredirectURI": '',
        "FacebookLoginappId": null,
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Geshikt",
        registerEnabled: true,
        Secondarycolor: "#C0563C",
        SecondaryDarkColor: "#97412B",
        SecondaryLightColor: "#D65F42",
        SecondaryUltraLight: "#FAF1EF",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#395594",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FF9075",
        icon_light_end:"#FFAB97",
        icon_dark_start:"#C0563C",
        icon_dark_end:"#D65F42",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFD8CE",
        loginBackgroundColorEnd:"#FFCEC1",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:logotype,
        apkUrl:null,
        iosUrl:null
} }