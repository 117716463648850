import React, { useState } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import debounce from "lodash/debounce";
import Order from "./Order";
import plus from "../../../../static/plus1.svg";
import { connect } from "react-redux";
import Fade from "@material-ui/core/Fade";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ps = null;
    this.debounceLoadMore = debounce(this.loadMore.bind(this), 300);

  }

  loadMore = () => {
    this.props.loadMore();
  };

  componentDidMount() {
    const container = document.getElementById("OrderListsTransactions");
    this.ps = new PerfectScrollbar(container);
    container.addEventListener("ps-y-reach-end", () => {
      this.debounceLoadMore();
    });
  }

  componentWillReceiveProps(){
 
  }

  getList = () => {
    return this.props.List;
  };

  render() {
    return (
      <div className="OrderListMaperContainer">
        <div
          className="OrderLists OrderHistory"
          id="OrderListsTransactions"
          style={{overflowX:"hidden"}}
        >
          {this.getList().map((order, i) => {
            return <Order key={i} data={order} />;
          })}
        </div>

        <Fade
          in={this.props.ListLoading && this.props.List.length > 0}
        >
          <div
            className="NoOrderContainer"
            style={{ position: "absolute", backgroundColor: "#e3eef76b" }}
          >
            <div className="NoOrderInner mrb90">
              <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.MessageBox.openDialoge,
  List: state.MessageBox.data,
  ListLoading: state.MessageBox.ListLoading,
  ListError: state.MessageBox.ListError,
  page: state.MessageBox.page,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
