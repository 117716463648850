import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Add_Service_Option, Remove_Service_Option } from "../../../../../Redux/actions/actionNames";
import { withTranslation } from "react-i18next";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active:false,
      selected:null
    };
    this.anchorEl = React.createRef();
  }

  handleactive = (e) => {
    if (this.optionisSaved() && String(e.target.value).trim().length == 0) {
      this.props.dispatch(Remove_Service_Option(this.props.id))
    } else {
      this.props.dispatch(Add_Service_Option({
        id:this.props.id,
        name:this.props.name,
        inputValue:e.target.value,
        type:this.props.type,
        update:this.optionisSaved()
      }))
    }

  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
        <div className="serviceOptionInputTypeContainer" style={{margin:"0px"}}>
           <p> {this.props.title} :</p>
          <input placeholder={`${this.props.hint}`} type={this.props.inputType} onChange={this.handleactive} />
          {(this.props.isRequired ? (!this.optionisSaved()) : false) && <span>{this.props.t("RequiredText")}</span>}
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activeVehicleType:state.NewOrder.newOrder.service.activeVehicleType,
  options:state.NewOrder.newOrder.service.options,
  serviceVehicleTypes:state.NewOrder.newOrder.service.serviceVehicleTypes,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
