import BeyondDeliveryIGO from "../../../static/BeyondDelivery_logo.png";
import BeyondDelivery_banner from "../../../static/BeyondDelivery_banner.png";
import FavIcon from "../../../static/PROJECTS/BeyondDelivery.png";

export default function BeyondDelivery() {
    
    return  global.config = {
        "project": "BEYOND DELIVERY",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "BEYOND DELIVERY",
        "LogoType": BeyondDeliveryIGO,
        "BaseApi": "https://rest.beyond-delivery.com",
        "BaseSocket": "https://socket.beyond-delivery.com",
        "BaseTrack": "https://admin.beyond-delivery.com",
        "MapCenter": {
            "lat": "29.375167662347614",
            "lng": "47.97568694063233"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "KW"
        }],
        "FavIcon": "/BeyondDelivery.png",
        "Country": "KW",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": BeyondDelivery_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"BeyondDelivery",
        registerEnabled: true,
        Secondarycolor: "#C0D85E",
        SecondaryDarkColor: "#9FBB2F",
        SecondaryLightColor: "#CFE47A",
        SecondaryUltraLight: "#F9FBEF",
        SecondaryTextColor: "#242424",
        Secondaryonlight: "#9FBB2F",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#4C5388",
        icon_light_end:"#5D65A4",
        icon_dark_start:"#C0D85E",
        icon_dark_end:"#CFE47A",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#F9FFE0",
        loginBackgroundColorEnd:"#EBF3C9",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:BeyondDeliveryIGO,
        apkUrl:null,
        iosUrl:null
} }