import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import Header from "./header";
import SwipeableViews from 'react-swipeable-views';
import { connect } from "react-redux";
import Login from "./Login";
import Register from "./Register";
import ClientAppsDownloadBadge from "../clientAppsDownloadBadge";
import VerificationCode from "../../VerificationCode";



const styles = {
  tabs: {
    background: '#fff',
  },
  slide: {
    padding: 15,
    minHeight: 100,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LoginIsActive: false
    }
  }

  changeLogin = (tab) => {
    this.setState({
      LoginIsActive: tab
    })
  }


  render() {
    let axis = this.props.direction == "Ltr" ? "x" : "x-reverse";
    return <div className="SecondSectionContainer">
      <img src={global.config.darkLogoType} className="LoginlogoType" />

      <div className={`SecondSection ${this.state.LoginIsActive && "SecondSectionRegister"}`}>

        {!this.props.VerificationCode_step &&
          <>  <Header changeLogin={this.changeLogin} LoginIsActive={this.state.LoginIsActive} />



            {!this.state.LoginIsActive && <div className="SwipeableViewsLoginRegister"   >
              <Login LoginIsActive={this.state.LoginIsActive} />
            </div>
            }

            {(this.state.LoginIsActive && (global.config.isIndividualSignupActive || global.config.isBusinessSignupActive) ) && <div className="SwipeableViewsLoginRegister"   >
              <Register LoginIsActive={this.state.LoginIsActive} />
            </div>}
          </>
        }


        {this.props.VerificationCode_step && <VerificationCode  />  }



        {/* <ClientAppsDownloadBadge /> */}
        {/* <div className="loginDownloadAppContainer">
          <p className="loginDownloadAppText"> Download Onro application </p>
          <div className="loginDownloadAppBadgeContainer">  
            <a href="/"><img style={{ height: '48px',borderRadius:"10px" }} src={this.googleBadgeM()} /></a> 
            <a  href="/"><img style={{ height: '48px' , borderRadius:"10px"}} src={this.appleBadgeM()} /></a>  
          </div>
        </div> */}

      </div>
      {global.config.isPoweredByOnroActive  && <a className="PoweredBy" href="http://onro.io/" >
        {" "}
        <Trans i18nKey={"PoweredBy"}> </Trans> <span style={{ fontWeight: "bold" }}>Onro.io</span>
      </a>}
    </div>;
  }
}
const mapStateToProps = (state) => ({
  authenticated: state.SharedData.access_token,
  direction: state.SharedData.direction,
  VerificationCode_step: state.LoginRegister.VerificationCode.VerificationCode_step,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));


