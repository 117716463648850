import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { get_country } from "./../../../../../helper/UserData/userdate";
import CircularProgress from "@material-ui/core/CircularProgress";
import googlelogo from "./../../../../../static/icon-google.svg";
import facebooklogo from "./../../../../../static/icon-fb.svg";
import applelogo from "./../../../../../static/icon-ic-apple.svg";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "../../../../../innerLibrary/facebookLogin/facebook";
import FacebookLogin from 'react-login-facebook'
import AppleLogin from "react-apple-login";
import { connect } from "react-redux";
import { LoginIndivsualSetKeyValue } from "../../../../../Redux/actions/actionNames";
import { AppleLoginCustomerId, AppleLoginredirectURI, FacebookLoginappId, LoginGateWay, GoogleLogincustomerId, getCountryWithDomain, ShowThirdPartyLogin } from "../../../../../helper/module";
import { LoginByApple, LoginByFacebook, LoginByGoogle } from "../../../../../Redux/actions/actions";
import { UnSucceessFul_i18 } from "../../../../../helper/Notification/sendNotification";
// import { GoogleLoginBtn } from "../../googleLoginBtn";
import GoogleLogin from "../../../../../helper/module/GoogleLogin";
import { decodeToken } from "react-jwt";
import loadDynamicScript from "../../../../../helper/module/loadDynamicScript";
// import ReCAPTCHA from "react-google-recaptcha-enterprise";




class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Captcha: null,
      PrePhoneInput: 0,
      numberNotRegistered: false,
      loading: false,
      googleClientId: global.config.GoogleLogincustomerId ,
      ts: null
    };
    this.recaptchaRef = React.createRef();
    this.CaptchaOnChange = this.CaptchaOnChange.bind(this);
  }

  ErrresponseGoogle = (res) => {
    console.log("App -> ErrresponseGoogle -> res", res)


  }

  responseGoogle = (res) => {
    const myDecodedToken = decodeToken(res.credential);


    try {
      if (!res.error) {
        // this.RegisterSetKeyValue({
        //   googleIsActive: true,
        //   facebookIsActive: false,
        //   appleIsActive: false,
        //   googleToken: idToken,
        //   givenName: res.profileObj.givenName,
        //   familyName: res.profileObj.familyName,
        //   email: res.profileObj.email,
        //   thirdPartyLogin: "googleConnected",
        // });
        this.props.dispatch(LoginByGoogle(this.props.dispatch, { idToken: res.credential }, (xx) => {
          this.setState({
            ...xx,
          });
        }))
      } else {
        UnSucceessFul_i18(
          "googletokenerror",
          "networkError",
          "close",
          () => {
            this.props.dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    } catch (error) {

    }
  };

  responseFacebook = (res) => {

    if (res.status == "connected" || !res.status) {
      // let name = res.name.split(" ");
      // this.RegisterSetKeyValue({
      //   googleIsActive: false,
      //   facebookIsActive: true,
      //   appleIsActive: false,
      //   facebookToken: res.accessToken,
      //   givenName: name.length > 0 ? name[0] : "",
      //   familyName: name.length > 1 ? name[1] : "",
      //   email: res.email,
      //   thirdPartyLogin: "facebookConnected",
      // });
      this.props.dispatch(LoginByFacebook(this.props.dispatch, { accessToken: res.accessToken }, (xx) => {
        this.setState({
          ...xx,
        });
      }))

    }
  };

  applecallback = (res) => {

    if (!res.error) {
      // this.RegisterSetKeyValue({
      //   googleIsActive: false,
      //   facebookIsActive: false,
      //   appleIsActive: true,
      //   appleToken: res.authorization.id_token,
      //   givenName: res.user ? res.user.name.firstName : "",
      //   familyName: res.user ? res.user.name.lastName : "",
      //   email: res.user ? res.user.email : "",
      //   thirdPartyLogin: "appleConnected",
      // });
      this.props.dispatch(LoginByApple(this.props.dispatch, { idToken: res.authorization.id_token }, (xx) => {
        this.setState({
          ...xx,
        });
      }))

    } else {
      UnSucceessFul_i18(
        "appletokenerror",
        "networkError",
        "close",
        () => {
          this.props.dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };

  LoginSetKeyValue = (value) => {
    this.props.dispatch(LoginIndivsualSetKeyValue(value));
  };

  phoneNumberOnChange = (res) => {
    console.log("res", res)
    res &&
      this.LoginSetKeyValue({
        phone: res,
      });

    if (res) {
      if (res.length < this.props.phone.length) {
        this.setState({
          numberNotRegistered: false
        })
      }
    }
  };

  login = () => {
    if (!this.state.loading) {
      LoginGateWay(this.props.dispatch, { ...this.props, ...this.state }, (res) => {
        console.log("LoginGateWay ",res)

        this.setState({
          ...res,
          Captcha:null,
          ts:null
        },()=>{
          this.componentDidMount()
        });
      });
    }
  };

  enetroninput = (event) => {
    if (event.keyCode === 13 && this.props.phone > 6) {
      event.preventDefault();
      this.login()
    }
  }

  componentDidMount() {
    this.setState({
      googleClientId: global.config.GoogleLogincustomerId,
      Captcha: null ,
      ts:null
    },()=>{
       loadDynamicScript(
      `https://www.google.com/recaptcha/enterprise.js?render=${global.config.googleReCaptchaSiteKey}`,
      "recaptchaEnterprise",
      async () => {
        
        window.grecaptcha.enterprise.ready(() => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise
            .execute(global.config.googleReCaptchaSiteKey, {
              action: `custom_customer_login_${ts}`
            })
            .then((token) => {
              this.setState({
                Captcha: token ,
                ts : ts
              })
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    );
    })


   
  }

  componentWillReceiveProps() {
    this.setState({
      googleClientId: global.config.GoogleLogincustomerId
    })
  }


  CaptchaOnChange(value) {
    this.setState({
      Captcha: value
    })
  }




  render() {
    let country = get_country();
    let registerBtnActive = this.props.phone.length > 6;


    return (
      <div className="loginpersonContainer">
        <p className="LP_PhoneNumberLabel">
          {" "}
          <Trans i18nKey={"Phonenumber"}> </Trans>{" "}
        </p>
        <div
          className={`PhoneNumberContainer ${this.state.numberNotRegistered && "PhoneNumberContainerError"
            }`}
        >
          <PhoneInput
            defaultCountry={global.config.Country}
            placeholder="123456789"
            value={this.props.phone}
            onChange={(PrePhoneInput) =>
              this.phoneNumberOnChange(PrePhoneInput)
            }
            onKeyUp={this.enetroninput}
            className="loginpersonPhoneInput"
          />
        </div>
        {this.state.numberNotRegistered && (
          <span className="pnhnr">
            {" "}
            <Trans i18nKey={"phonenumberhasnotregisterd"}> </Trans>{" "}
          </span>
        )}

        <div style={{ float: "left", marginLeft: "10px", marginTop: "19px" }}>
          {/* <ReCAPTCHA
            sitekey={global.config.googleReCaptchaSiteKey}
            onChange={this.CaptchaOnChange}
            ref={this.recaptchaRef}
          /> */}
        </div>



        <span
          onClick={
            (registerBtnActive && this.state.Captcha) && !this.state.loading || 1
              ? () => {
                this.login();
              }
              : null
          }
          className={`${this.state.loading
            ? "loginregistercontinueloading"
            : (!registerBtnActive || !this.state.Captcha)
              ? "deactivebtnregister loginregistercontinue"
              : "loginregistercontinue"
            }`}
        >
          {this.state.loading ? (
            <CircularProgress size="28px" />
          ) : (
            <Trans i18nKey={"Continue"}> </Trans>
          )}
        </span>

        <div className="continuesWay">

          {(global.config.thirdPartyLoginSupport && this.state.googleClientId && this.state.googleClientId.length > 0) &&
            <GoogleLogin onSuccess={(res) => this.responseGoogle(res)}
              onFailure={this.ErrresponseGoogle} />}
          {/* {(global.config.thirdPartyLoginSupport && this.state.googleClientId && this.state.googleClientId.length > 0 ) && 
              <GoogleLoginBtn    />
          } */}

          {/* {(global.config.thirdPartyLoginSupport && this.state.googleClientId && this.state.googleClientId.length > 0 && this.props.LoginIsActive == 0 ) && <GoogleLogin
            //pickup   customerId="149556072176-dtvqa0t2ruacr6frraaesg3u5984n82n.apps.googleusercontent.com"
            //onro    customerId="254715138014-f09tqcnr4ks6ovs00i6rkss4bdko2qcd.apps.googleusercontent.com"
            //Deliro   customerId="51088687197-n8okfrg0r9ulb9m4hu8e2tlu4l8sbrt8.apps.googleusercontent.com"
            render={(renderProps) => (
              <div
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="continuesWayItem"
              >
                <img src={googlelogo} />
              </div>
            )}
            clientId={this.state.googleClientId}
            buttonText="Login"
            onSuccess={this.responseGoogle}
            onFailure={this.ErrresponseGoogle}
            cookiePolicy={"single_host_origin"}
            autoLoad={false}
          />} */}

          {global.config.thirdPartyLoginSupport && global.config.FacebookLoginappId && <FacebookLogin
            //OnroIR  id="1194837280918142"
            //Onro  id="666630160807861"
            //Pickup  id="3038885649533203"
            appId={global.config.FacebookLoginappId}
            autoLoad={false}
            fields="name,email,picture"
            callback={this.responseFacebook}
            className="FacebookLogin"
            containerStyle={{ opacity: "0", width: "1px", height: "0px" }}
            version="9.0"
            cookie={true}
            xfbml={true}
            render={(renderProps) => (
              <div
                className="continuesWayItem facebook"
                onClick={renderProps.onClick}
              >
                <img src={facebooklogo} />
              </div>
            )}
          />}

          {global.config.thirdPartyLoginSupport && global.config.AppleLoginCustomerId && this.props.LoginIsActive == 0 ? (
            <AppleLogin
              clientId={global.config.AppleLoginCustomerId}
              redirectURI={global.config.AppleLoginredirectURI}
              state="initial"
              responseType="string"
              callback={this.applecallback}
              usePopup={true}
              scope="name email"
              render={(renderProps) => (
                <div
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className="continuesWayItem apple"
                >
                  <img src={applelogo} />
                </div>
              )}
            />
          ) : null}


        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  phone: state.LoginRegister.login.indivisual.phone,
  googleIsActive: state.LoginRegister.login.indivisual.googleIsActive,
  facebookIsActive: state.LoginRegister.login.indivisual.facebookIsActive,
  appleIsActive: state.LoginRegister.login.indivisual.appleIsActive,
  googleToken: state.LoginRegister.login.indivisual.googleToken,
  facebookToken: state.LoginRegister.login.indivisual.facebookToken,
  appleToken: state.LoginRegister.login.indivisual.appleToken,
  thirdPartyLogin: state.LoginRegister.login.indivisual.thirdPartyLogin,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
