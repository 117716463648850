import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { SetCodAmount, SetNotesValue } from "../../../../Redux/actions/actionNames";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.inputOnchange = this.inputOnchange.bind(this)
  }


  inputOnchange(e){
    this.props.dispatch(SetNotesValue(e.target.value))

  }


  render() {
    const { t } = this.props;

    if(this.props.serviceactiveVehicleType && this.props.serviceactiveVehicleType.orderTypes.indexOf("PickupDelivery") == -1) {
      return null
    }
    return (
      <div className="codContainer"  style={{width:"calc(100% - 30px)",display:"flex",flexDirection:"column",marginInlineStart:"15px"}}>

          <span> {t("Notes")} </span>
          <input onChange={this.inputOnchange} placeholder={t("Notes")} type="text" value={this.props.notes ? this.props.notes : ""}  style={{width:"calc(100% - 49px)"}}/>
      </div>
    
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  notes: state.NewOrder.newOrder.notes,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
