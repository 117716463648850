
import React from 'react';
import styles from './style.module.css';
import Slide from "@material-ui/core/Slide";
import close from "../../../static/close.svg";
import { useTranslation } from 'react-i18next'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { InvoiceItem } from './components/invoiceItem';
import { get_LeftRight } from '../../../helper/UserData/userdate';



export function View({ open, handleOpen, handleContainerOnBottom, list ,download}) {

    const { t, i18n } = useTranslation();
    const containerRef = useBottomScrollListener(handleContainerOnBottom);

    return <>
        <Slide
            timeout={650}
            direction={get_LeftRight()}
            in={open}
            mountOnEnter
            unmountOnExit
        >
            <div className="NewAddressbookConatiner">
                <nRHeader style={{ boxShadow: "none" }}>
                    <img
                        onClick={handleOpen}
                        className="close"
                        src={close}
                    />
                    <span className="title">
                        {t("Invoices")}
                    </span>

                </nRHeader>

                <div ref={containerRef} className={styles.invoiceListContainer}>
                    {list.map((item) => {
                        return <InvoiceItem {...item} download={download} />
                    })}
                </div>


            </div>

        </Slide>

    </>

}



