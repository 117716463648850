
import logotype from "../../../static/ZoomCurier_logotype.png";
import banner from "../../../static/ZoomCurier_banner.png";
import FavIcon from "../../../static/PROJECTS/ZoomCurier_fav_icon.png";

export default function ZoomCurier() {
    
    return global.config = {
        "project": "app.curier-local.ro",
        "ShowPowerOnro": false,
        "flag": "romanianflag",
        "ProjectName": "Zoom Curier",
        "LogoType": logotype,
        "BaseApi": "https://rest.curier-local.ro",
        "BaseSocket": "https://socket.curier-local.ro",
        "BaseTrack": "https://panel.curier-local.ro",
        "MapCenter": {
            "lat": 46.56731361015399,
            "lng": 26.914722315152293
        },
        "Lang": "ro",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }, {
            "lang": "ro",
            "direction": "Ltr",
            "i18Lang": "Romanian",
            "cssFlag": "romanianflag",
            "country": "RO"
        }],
        "FavIcon": "",
        "Country": "RO",
        "AppleLoginCustomerId": '25T5M6S6F7',
        "AppleLoginredirectURI": 'https://app.curier-local.ro',
        "FacebookLoginappId": "478161077107190",
        "GoogleLogincustomerId": "553862170659-gvu1un60bnn280icusu6vhnbkgqhjssh.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"ZoomCurier",
        registerEnabled: true,
        Secondarycolor:"#EB2328",
        SecondaryDarkColor:"#D31419",
        SecondaryLightColor:"#FF4D51",
        SecondaryUltraLight :"#FEF2F2",
        SecondaryTextColor:"#FFFFFF",
        Secondaryonlight:"#EB2328",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true  ,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:logotype,
        apkUrl:null,
        iosUrl:null
} }