import React from "react";
import { OverlayView } from '@react-google-maps/api';
import L from "leaflet";
import { connect } from "react-redux";
// import { VehicletypesMonitoringDriversAction, TrackOrderDriverAction } from "../../../Redux/actions/actions";
import { CanTrackDriver, getBaseTrackByUrl } from "../../../../helper/module";
import { Api_get_order_trackDriver_AGW } from "../../../../helper/api/ApiGateway";
import deliveryProfile from "./../../../../static/delivery-man.png";
import {ReactComponent as Driverpin} from "./../../../../static/pin_driver.svg";
import axios from "axios";
import  DeliveryProfile  from "./../../../../static/driver_place_holder.svg";
import  {ReactComponent as DeliveryProfile2}  from "./../../../../static/driver_place_holder.svg";
import { Marker } from '@react-google-maps/api';




let createtIcon = (data, driverImage , errorImage , funcError ) => {
  return <div className="pic-pin-driver-container">
    <Driverpin className="DriverpinSass"/>
      {!driverImage  ? 
      <DeliveryProfile2 className="pic-pin-driver" />
      :
      <img className="pic-pin-driver" src={"data:image/png;base64,"+driverImage}
        onError={({ currentTarget }) => {
          funcError()
        }}
      />
      }

  </div>
};

// const createtIcon = (data, driverImage) => {
//   return new L.divIcon({
//     className: "markerIcon ",
//     html: `<div style='' class='marker-pin pin-driver'>
//     <img class="base-pin-driver" src='${driverpin}' />
//     <img class="pic-pin-driver" src=${
//       "data:image/png;base64,"+driverImage 
//       } onerror="this.onerror=null;this.src='${deliveryProfile}';" />
//       </div>
//       `,
//     iconSize: [65, 65],
//     iconAnchor: [26, 42],
//   });
// };





class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      location: null,
      last_location: null,
      deg: 0,
      driverImage: null,
      imageUrl: null
    };
    this.interval = null;
  }

  componentDidMount() {
    if (CanTrackDriver(this.props.status)) {
      Api_get_order_trackDriver_AGW(this.props.dispatch, this.props.id, (call) => {
        this.setState({
          data: call,
          location: call.location.reverse(),
        });
        this.driverImageToBinary(call.avatarPhoto)
      })

      this.interval = setInterval(() => {
        Api_get_order_trackDriver_AGW(this.props.dispatch, this.props.id, (call) => {
          this.setState({
            data: call,
            location: call.location.reverse(),
          });
          this.driverImageToBinary(call.avatarPhoto)
        })
      }, 20000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
    this.setState({
      driverImage: null,
      imageUrl: null
    })
  }

  driverImageToBinary(img) {
    if (img != this.state.imageUrl) {
      this.setState({
        imageUrl: img
      })

      axios
        .get(String(img), {
          responseType: 'arraybuffer'
        })
        .then(response => {
          Buffer.from(response.data, 'binary').toString('base64')
          this.setState({
            driverImage: Buffer.from(response.data, 'binary').toString('base64')
          })

        }).catch((error) => {

        });

      // var request = new XMLHttpRequest();
      // request.open('GET', img, true);
      // request.responseType = 'blob';
      // request.onload = () => {
      //   var reader = new FileReader();
      //   reader.readAsDataURL(request.response);
      //   reader.onload = (e) => {
      //     this.setState({
      //       driverImage: e.target.result
      //     })
      //     console.log('DataURL:', e.target.result);
      //   };
      // };
      // request.send();
      /// secend way
      // console.log("App -> driverImageToBinary -> img", img)
      // var reader = new FileReader();

      // reader.onload = (event) => {
      //   console.log("App -> reader.onload -> event", event)

      //   console.log("App -> render -> this.state.driverImage", event.target.result)

      //   this.setState({
      //     driverImage: event.target.result
      //   })
      // };

      // reader.onerror = function (event) {
      //   console.log("File could not be read: " + event.target.error.code);
      // };

      // reader.readAsDataURL(String(img));
    }


  }



  componentWillReceiveProps(nextProps) {
    if (
      CanTrackDriver(nextProps.status)
    ) {

      if (!this.interval) {
        Api_get_order_trackDriver_AGW(this.props.dispatch, this.props.id, (call) => {
          this.setState({
            data: call,
            location: call.location.reverse(),
          });
          this.driverImageToBinary(call.avatarPhoto)
        })

        this.interval = setInterval(() => {
          Api_get_order_trackDriver_AGW(this.props.dispatch, this.props.id, (call) => {
            this.setState({
              data: call,
              location: call.location.reverse(),
            });
            this.driverImageToBinary(call.avatarPhoto)

          })
        }, 20000);
      }


    } else {
      clearInterval(this.interval);
      this.interval = null;
      this.setState({
        driverImage: null,
        imageUrl: null,
      })
    }
  }

  render() {
    let errorImage = null ;

    if (
      this.props.OpenVRDialoge &&
      CanTrackDriver(this.props.status) &&
      this.state.data
    ) {
      return (
        <React.Fragment>
          <OverlayView
            position={{
              lat: this.state.location[0],
              lng: this.state.location[1],
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}

          >
            {createtIcon(this.state.data, this.state.driverImage ,errorImage , ()=>{errorImage = true})}

          </OverlayView>

        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  id: state.ViewTravel.ID,
  socket: state.Socket.socket,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
