import React, { useCallback, useRef, memo, forwardRef, useState, useEffect } from 'react'
import styles from './style.module.css';
import { SnackbarContent ,closeSnackbar} from "notistack3";
import Typography from '../../../design-system/lib/Typography';
import { useDispatch } from 'react-redux';
import { Table_setSelectedBulkEditStatusSummary } from '../../../Redux/actions/actionNames';
import { close_white } from '../../../static';
import BatchService from '../../../service/api/batch';





export const BatchOperationalSnackbar = forwardRef((props, forwardedRef) => {
  const { message, variant , id} = props;
  const [batchData, setBatchData] = useState(JSON.parse(message));
  const dispatch = useDispatch();


  useEffect(() => {
    let interval;

    if (batchData.status != "done") {
      interval = setInterval(async () => {
        let Batch = new BatchService()
        let data = await Batch.getBatchOperationInfo(batchData.id)
        setBatchData(data)
      }, 1000)
    }

    return () => {
      clearInterval(interval);
    }
  }, [batchData])

 
  const openSummary = React.useCallback(() => {
    dispatch(Table_setSelectedBulkEditStatusSummary(batchData))
    closeSnackbar(id)
  }, [batchData , id]);


  return (
    <SnackbarContent
      ref={forwardedRef}
      className={styles.container}
    >
      {batchData.status == "done" && <img src={close_white} className={styles.close} onClick={()=>{closeSnackbar(id)}} />}
      <div className={styles.first}>
        <Typography weight='Body_Middle_Medium' text={`Bulk Edit Status ${batchData.lastProcessedRecordIndex + 1}/${batchData.totalRecordsCount}`} textColor='White' />
        <Typography weight='Body_Tiny_Regular' text={`${batchData.successfulRecordsCount} Successfull, ${batchData.failedRecordsCount} Failed`} textColor='White' />
      </div>
      <Typography weight='Body_Small_Medium' text={batchData.status} className={styles[batchData.status]} />
      {batchData.status == "done" && <>      <div className={styles.divider} />
        <Typography weight='Body_Middle_Bold' text='Summary' textColor='White' style={{ cursor: "pointer" }} onClick={openSummary} /></>}

    </SnackbarContent>
  );
});

