import LogoType from "../../../static/Sprint.png";
import Banner from "../../../static/Sprint_banner.png";

import FavIcon from "../../../static/PROJECTS/Sprint_fav_icon.png";

export default function Sprint() {
    
    return  global.config = {
        "project": "Sprint",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Control Tower – مركز التحكم",
        "LogoType": LogoType,
        "BaseApi": "https://odrest.sprint.xyz",
        "BaseSocket": "https://odsocket.sprint.xyz",
        "BaseTrack": "https://odadmin.sprint.xyz",
        "MapCenter": {
            "lat": "30.046502028113764",
            "lng": "31.24459347271293"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        } , {
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }],
        "FavIcon": "",
        "Country": "EG",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Control Tower – مركز التحكم",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Sprint",
        registerEnabled: true,
        Secondarycolor: "#A40F86",
        SecondaryDarkColor: "#A40F86",
        SecondaryLightColor: "#FF007E",
        SecondaryUltraLight: "#FFF0F7",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#FF007E",
        TextDark: "#383838",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#EEC0E5",
        icon_light_end:"#FFC1E0",
        icon_dark_start:"#A40F86",
        icon_dark_end:"#FF007E",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}