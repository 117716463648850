import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import timer from "../../../../static/ic-timer-x.svg";
import delay from "../../../../static//ic-timer-delay.svg";
import { withTranslation, Trans } from "react-i18next";
import { Api_GET_ETA_AGW } from "../../../../helper/api/ApiGateway";
import momentJalaali from "moment-jalaali";
import { get_calendarType } from "../../../../helper/UserData/userdate";
import { GetNextStatusListItem } from "../../../../helper/module/getNextStatus_ListItem";

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data : null,
      minutes: 0

    }
    this.intervalId = null
  }

  componentDidMount(){
    if (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(this.props.status) > -1) {
      if (this.props.realETA) {
          if (this.props.isPossibleForDelay) {
            this.setState({
              minutes:Math.round(parseInt(Math.abs(this.props.realETA - this.props.delayAt) / 1000) / 60)
            })
          }else if (this.props.isDelayed) {
            this.setState({
              minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
            })
            this.intervalId = setInterval(() => {
              this.setState({
                minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
              })
          }, 50000);
          }

        }
      }
  }



  componentWillReceiveProps(){
 
   if (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(this.props.status) > -1) {
      if (this.props.realETA) {
        if(!this.intervalId){
          if (this.props.isDelayed && !this.props.isPossibleForDelay) {
            this.setState({
              minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
            })
            this.intervalId = setInterval(() => {
              this.setState({
                minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
              })
          }, 50000);
          }
        }
        }
      }else{
        clearInterval(this.intervalId)
      }
  }


  componentWillUnmount(){
    clearInterval(this.intervalId)

  }

  render() {
    let canShowEtaDelay = (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(this.props.status) > -1 && this.props.orderType == "Ondemand" && this.props.realETA)


    if(canShowEtaDelay){
      return (
        <React.Fragment>
        {<extraDetail>
          <img src={timer} style={{width:"24px",height:"24px"}} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <value> {momentJalaali(this.props.realETA).locale(get_calendarType()).format("HH:mm")} </value>
            <key>
              {String(this.props.t(`${GetNextStatusListItem(this.props.status, this.props.orderType)}`)).replace("xxx", "")}
            </key>{" "}
          </div>
        </extraDetail>}


        {(this.props.isPossibleForDelay && Number(this.state.minutes) > 0) && <extraDetail>
          <img src={delay} style={{width:"24px",height:"24px",marginTop:"2px"}} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <value> {String(this.props.t(`min`)).replace("xxx" , this.state.minutes)} </value>
            <key>
              {String(this.props.t(`late`))}
            </key>
          </div>
        </extraDetail>}

        {(this.props.isDelayed && !this.props.isPossibleForDelay && Number(this.state.minutes) > 0) && <extraDetail>
          <img src={delay} style={{width:"24px",height:"24px",marginTop:"2px"}} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <value> {String(this.props.t(`min`)).replace("xxx" , this.state.minutes)} </value>
            <key>
              {String(this.props.t(`delay`))}
            </key>
          </div>
        </extraDetail>}
        </React.Fragment>
    );
    }else{
      return null
    }

  }
}

const mapStateToProps = (state) => ({
  timer: state.SharedData.WebSettings? state.SharedData.WebSettings.timer :null,

    status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
    : "",
    type: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
    : "",
    id: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
    : null,
    realETA: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.realETA
    : null,
    delayAt: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.delayAt
    : null,
    orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
    : null,
    isPossibleForDelay: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.isPossibleForDelay
    : null,
    isDelayed: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.isDelayed
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
