import LogoType from "../../../static/pinkExpress.png";
import Banner from "../../../static/pinkExpress_background.png";

import FavIcon from "../../../static/PROJECTS/pinkExpress_fav_icon.png";

export default function PinkExpress() {
    
    return  global.config = {
        "project": "PinkExpress",
        "ShowPowerOnro": true,
        "flag": "AustrianGermanflag",
        "ProjectName": "PiNK.Express",
        "LogoType": LogoType,
        "BaseApi": "https://rest.pink.express",
        "BaseSocket": "https://socket.pink.express",
        "BaseTrack": "https://admin.pink.express",
        "MapCenter": {
            "lat": 48.209089137900904,
            "lng": 16.37791009677984
        },
        "Lang": 'de-AT',
        "Direction": "Ltr",
        "AllLanguages": [
            {
                "lang": 'de-AT',
                "direction": "Ltr",
                "i18Lang": "AustrianGerman",
                "cssFlag": "AustrianGermanflag",
                "country": "AT"
            },
            {
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "AT",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "",
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"PinkExpress",
        registerEnabled: true,
        Secondarycolor: "#EA2E81",
        SecondaryDarkColor: "#C20156",
        SecondaryLightColor: "#F3559B",
        SecondaryUltraLight: "#FDEEF5",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#EA2E81",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FFACD1",
        icon_light_end:"#FFC5DE",
        icon_dark_start:"#EA2E81",
        icon_dark_end:"#F3559B",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#EA2E81",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}