import TEZRAFTAARIGO from "../../../static/TEZRAFTAAR_logotype.png";
import TEZRAFTAAR_banner from "../../../static/TEZRAFTAAR_banner.png";

import FavIcon from "../../../static/PROJECTS/TEZRAFTAAR.png";

export default function TEZRAFTAAR() {
    
    return global.config = {
        "project": "business.trcs.pk",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "TEZRAFTAAR",
        "LogoType": TEZRAFTAARIGO,
        "BaseApi": "https://rest.trcs.pk",
        "BaseSocket": "https://socket.trcs.pk",
        "BaseTrack": "https://manager.trcs.pk",
        "MapCenter": {
            "lat": 33.712284,
            "lng": 73.091881
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/TEZRAFTAAR.png",
        "Country": "PK",
        "AppleLoginCustomerId": "6N993LX47C",
        "AppleLoginredirectURI": "https://app.onro.io",
        "FacebookLoginappId": "666630160807861",
        "GoogleLogincustomerId": "49663940387-k08t0lc82equ88n64epp9klqspeatc27.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": TEZRAFTAAR_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"TEZRAFTAAR",
        registerEnabled: true,
        Secondarycolor:"#F81C1C",
        SecondaryDarkColor:"#C30000",
        SecondaryLightColor:"#FF7878",
        SecondaryUltraLight :"#FFF6F6",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#F81C1C",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:TEZRAFTAARIGO,
        apkUrl:null,
        iosUrl:null
}
}