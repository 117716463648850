import HiOIGO from "../../../static/HiOlogotype.png";
import HiO_banner from "../../../static/HiOdelivery5.png";
import FavIcon from "../../../static/PROJECTS/HiOfav_icon.png";

export default function HiO() {
    
    return  global.config = {
        "project": "app.hio.ro",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Client ",
        "LogoType": HiOIGO,
        "BaseApi": "https://rest.hio.ro",
        "BaseSocket": "https://socket.veedelivery.com",
        "BaseTrack": "https://panel.hio.ro",
        "MapCenter": {
            "lat": 47.163279,
            "lng": 27.586531
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }, {
            "lang": "ro",
            "direction": "Ltr",
            "i18Lang": "Romanian",
            "cssFlag": "romanianflag",
            "country": "RO"
        }],
        "FavIcon": "/HiOfav_icon.png",
        "Country": "RO",
        "AppleLoginCustomerId": "8C74K5NHWM",
        "AppleLoginredirectURI": "https://app.hio.ro",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "710578742791-clvd4bifo6k0guns07gjvvkgbr1ml08o.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": HiO_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"hio",
        registerEnabled: true,
        Secondarycolor:"#DD1E30",
        SecondaryDarkColor:"#C91324",
        SecondaryLightColor:"#FF4A5B",
        SecondaryUltraLight :"#FEF6F7",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#DD1E30",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:HiOIGO,
        apkUrl:null,
        iosUrl:null
}}