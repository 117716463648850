
import React from 'react';
import styles from './style.module.css';
import icInvoice from "../../../../../static/ic-invoice.svg";
import icDownload from "../../../../../static/ic-download.svg";
import { useTranslation } from 'react-i18next';
import momentJalaali from "moment-jalaali";
import { get_lang } from '../../../../../helper/UserData/userdate';

export function InvoiceItem({code ,orderThroughStart , orderThroughEnd , download , id}) {
    const { t, i18n } = useTranslation();

    return <>
        <div className={styles.invoiceItemContainer}>
            <img src={icInvoice} className={styles.invoiceItemImg} />
            <p className={styles.invoiceItemNo}>{String(t("noInvoice")).replace("xxx",code)}</p>
            <p className={styles.invoiceItemDate}>{momentJalaali(orderThroughStart).locale(get_lang()).format("YY/M/D") + " - " + momentJalaali(orderThroughEnd).locale(get_lang()).format("YY/M/D")}</p>
            <img onClick={()=>download(id , code)} src={icDownload} className={styles.icDownload} />
        </div>

    </>

}


