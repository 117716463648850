import YONIIGO from "../../../static/YONI.png";
import Yonibanner from "../../../static/Banner.jpeg";

import FavIcon from "../../../static/PROJECTS/yoni.png";

export default function YONI() {
    
    return  global.config = {
        "project": "app.yoni-app.sn",
        "ShowPowerOnro": false,
        "flag": "frenchflag",
        "ProjectName": "yoni Tableau de bord Customer",
        "LogoType": YONIIGO,
        "BaseApi": "https://rest.yoni-app.sn",
        "BaseSocket": "https://socket.yoni-app.sn",
        "BaseTrack": "https://panel.yoni-app.sn",
        "MapCenter": {
            "lat": 14.694153,
            "lng": -17.450331
        },
        "Lang": "fr",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        }],
        "FavIcon": "/yoni.png",
        "Country": "SN",
        "AppleLoginCustomerId": "6N993LX47C",
        "AppleLoginredirectURI": "https://app.yoni-app.sn",
        "FacebookLoginappId": "490664338886171",
        "GoogleLogincustomerId": "782235285987-g912ee36dvlq9prrpugmbgn0eubammhs.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Yonibanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"yoni",
        registerEnabled: true,
        Secondarycolor:"#23A136",
        SecondaryDarkColor:"#28831A",
        SecondaryLightColor:"#49CF66",
        SecondaryUltraLight :"#F3FBF2",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#23A136",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:YONIIGO,
        apkUrl:null,
        iosUrl:null
} }