import locoIGO from "../../../static/loc_logotype.png";
import loco_banner from "../../../static/loco_banner.png";

import FavIcon from "../../../static/PROJECTS/loco.png";

export default function loco() {
    
    return global.config = {
        "project": "deliver.loco.direct",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "customer portal",
        "LogoType": locoIGO,
        "BaseApi": "https://rest.loco.direct",
        "BaseSocket": "https://socket.loco.direct",
        "BaseTrack": "https://portal.loco.direct",
        "MapCenter": {
            "lat": 3.146436,
            "lng": 101.687713
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/loco.png",
        "Country": "US",
        "AppleLoginCustomerId": "G4952Y49M3",
        "AppleLoginredirectURI": "https://deliver.loco.direct",
        "FacebookLoginappId": "1947502115406579",
        "GoogleLogincustomerId": "753117356992-40661os0v91645i8302e38vvteabdept.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": loco_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"loco",
        registerEnabled: true,
        Secondarycolor:"#00C99A",
        SecondaryDarkColor:"#00AF86",
        SecondaryLightColor:"#16DEAF",
        SecondaryUltraLight :"#F5FDFB",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#00C99A",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:locoIGO,
        apkUrl:null,
        iosUrl:null
} }