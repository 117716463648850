import React, { useCallback, useEffect, useRef } from 'react'
import {

  flexRender
} from '@tanstack/react-table'
import { debounce } from 'lodash'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styles from './style.module.css';
import clsx from 'clsx'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import Typography from '../../../design-system/lib/Typography'
import IconProvider from '../../../design-system/lib/IconProvider'
import { ReactComponent as Sort_up } from '../../../static/sort_up.svg'
import { ReactComponent as Sort_down } from '../../../static/sort_down.svg'
import sort_down_deactive from '../../../static/sort_down_deactive.svg'

import DropDown from '../../../design-system/lib/DropDown'
import { ARROW_LEFT } from '../../../static'
import { useDispatch, useSelector } from 'react-redux'
import { ChangeViewTravelDialoge, TableRangeFilter_Update, Table_ClearSelectedOrders, Table_DeSelectOrder, Table_Init, Table_SelectOrder, Table_SelecteMultiOrders, Table_setData, Table_setStatus } from '../../../Redux/actions/actionNames'
import { TableInstance } from '../table-instance'
import { getTableOrderList } from './action/getTableOrderList'
// import { getTableDefaultColumn } from './action/getTableDefaultColumn'
import { useContainerDimensions } from './useContainerDimensions'
import { Helmet } from "react-helmet";
import $ from 'jquery';
import TableError from '../../table/table-error'
import TableNoOrder from '../../table/table-noOrder'
import TableNoOrderFilter from '../../table/table-noOrder-filter'
import TableLoading from '../../table/table-loading'
import useDimensions from "react-use-dimensions";
import { Api_Get_Services_AGW, Api_get_Order_Info_AGW, Api_get_balance_AGW } from '../../../helper/api/ApiGateway'
import { Link } from 'react-router-dom';
import history from '../../../service/history'

const staticColumns = ["select"]
const staticcursor = ['createdAt', 'dueDate', 'code', 'statusUpdatedAt']


const reorderColumn = (
  draggedColumnId,
  targetColumnId,
  columnOrder
) => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
  )
  return [...columnOrder]
}

const DraggableColumnHeader = ({ header, table, isLeft ,isRight}) => {
  const { getState, setColumnOrder, setColumnPinning } = table
  const { columnOrder, columnPinning } = getState()
  const { column } = header
  const debounceHoverHandle = useCallback(debounce(hoverHandle, 50), []);
  const cursor = useSelector((state) => state.Table.filters.cursor)
  const cursorSort = useSelector((state) => state.Table.filters.cursorSort)
  const dispatch = useDispatch();




  const getSortIcon = React.useCallback((head) => {
    if(head?.id == cursor){

      if(cursorSort == "asc"){
        return <Sort_up style={{marginInline :"8px auto"}}/> 
      }else{
        return <Sort_down style={{marginInline :"8px auto"}}/>
      }

    }else{
      return sort_down_deactive
    }
  }, [cursorSort , cursor]);


  let SoortIcoon= getSortIcon(header)
  // console.log("hoverHandle ", getState(), header)


  function hoverHandle(draggedColumn) {
    // console.log("hoverHandle ", getState(), column, draggedColumn)

    if (isLeft) {
      if (staticColumns.indexOf(column.id) == -1 && staticColumns.indexOf(draggedColumn.id) == -1) {
        const newColumnOrder = reorderColumn(
          draggedColumn.id,
          column.id,
          columnPinning.left
        )
        setColumnPinning({ ...columnPinning, left: newColumnOrder })
      }

    } else {
      if (staticColumns.indexOf(column.id) == -1 && staticColumns.indexOf(draggedColumn.id) == -1) {
        const newColumnOrder = reorderColumn(
          draggedColumn.id,
          column.id,
          columnOrder
        )
        setColumnOrder(newColumnOrder)
      }

    }


  }

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: hoverHandle,
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  })

  function attachRef(el) {
    if (staticColumns.indexOf(column.id) == -1) {
      dropRef(el)
      dragRef(el)
    } else {

    }

  }

  function canSort(head) {
    if (staticcursor.indexOf(head?.id) > -1) {
      return  true
    } else {
      return  false
    }

  }



  const handleSortIcon = React.useCallback((hed) => {
    if(header?.id == cursor){

      if(cursorSort == "asc"){
        dispatch(TableRangeFilter_Update({key:"cursorSort",value:"desc"}))
      }else{
        dispatch(TableRangeFilter_Update({key:"cursorSort",value:"asc"}))
      }

    }else{
      dispatch(TableRangeFilter_Update({key:"cursor",value:header?.id}))
      dispatch(TableRangeFilter_Update({key:"cursorSort",value:"asc"}))
    }
  }, [cursorSort , cursor , header]);



  return (
    // <th
    //   ref={attachRef}
    //   colSpan={header.colSpan}
    //   style={{ opacity: isDragging ? 0.5 : 1 }}
    //   {...{
    //     className: header.column.getCanSort()
    //       ? (staticColumns.indexOf(column.id) == -1 ? 'cursor-ew-resize select-none' : 'select-none')
    //       : '',
    //     onClick: header.column.getToggleSortingHandler(),
    //   }}
    // >
    //   <div ref={previewRef}>
    //     {header.isPlaceholder
    //       ? null
    //       : flexRender(header.column.columnDef.header, header.getContext())}

    //     {{
    //       asc: ' (asc 🔼) ',
    //       desc: ' (desc 🔽) ',
    //     }[header.column.getIsSorted()] ?? null}

    //   </div>
    // </th>

    <th key={header.id} colSpan={header.colSpan} onClick={()=>{ canSort(header) && handleSortIcon()}} style={{ opacity: isDragging ? 0.5 : 1, minWidth: `calc(var(--header-${header?.id}-size) * 1px)`, maxWidth: `calc(var(--header-${header?.id}-size) * 1px)` , cursor: canSort(header) ? "pointer" :"" }}

      {...{
        className: header.column.getCanSort()
          ? (staticColumns.indexOf(column.id) == -1 ? clsx("cursor-ew-resize select-none ", styles.th) : clsx('select-none', styles.th))
          : styles.th,

      }}

    >
      <div className={styles.thContainer} style={isRight ? {paddingInlineStart: '10px'} : {}}>

        <div className={styles.thspanContainer} ref={attachRef} >


          {header.isPlaceholder
            ? null
            : <Typography weight='Body_Small_Bold'  ref={previewRef} textColor='TextDark' text={flexRender(
              header.column.columnDef.header,
              header.getContext()
            )} 
            style={{
              width: canSort(header) ? "auto" : "100%"
            }}
            
            />}

            {canSort(header) ? (typeof  getSortIcon(header) == "string" ?  <IconProvider onClick={null} Icon={getSortIcon(header)} size='16' style={{ marginInlineStart: "8px", marginInlineEnd: "auto" }} /> : <IconProvider onClick={null} Icon={getSortIcon(header)} size='16' style={{ marginInlineStart: "8px", marginInlineEnd: "auto" }} /> ) : null}

          {/* {{
            asc: <IconProvider onClick={header.column.getToggleSortingHandler()} Icon={sort_up} size='16' style={{ marginInlineStart: "8px", marginInlineEnd: "auto" }} />,
            desc: <IconProvider onClick={header.column.getToggleSortingHandler()} Icon={sort_down} size='16' style={{ marginInlineStart: "8px", marginInlineEnd: "auto" }} />,
          }[header.column.getIsSorted()] ?? null} */}

        </div>

        <div
          {...{
            onMouseDown: header?.id != "select" ? header.getResizeHandler() : null,
            onTouchStart: header?.id != "select" ? header.getResizeHandler() : null,
            className: header?.id != "select" ? `resizer ${header.column.getIsResizing() ? 'isResizing' : ''
              }` : null,
          }}
        />
        {/* {!header.isPlaceholder && header.column.getCanPin() && (
          <div className="flex gap-1 justify-center">
            {header.column.getIsPinned() !== 'left' ? (
              <button
                className="border rounded px-2"
                onClick={() => {
                  header.column.pin('left')
                }}
              >
                {'<='}
              </button>
            ) : null}
            {header.column.getIsPinned() ? (
              <button
                className="border rounded px-2"
                onClick={() => {
                  header.column.pin(false)
                }}
              >
                X
              </button>
            ) : null}
          </div>
        )} */}

      </div>

    </th>
  )
}

function TableList({table , TablehasError , canClearAll, initTableRef , selectedOrders , data , hasNextPage ,tableColumns , hasPreviousPage , totalCount , perPage , page , TableisLoading , conatinerWidth , conatinerHeight }) {

  const dispatch = useDispatch();
  const [deCol, setDeCol] = React.useState(tableColumns)

  const [columns] = React.useState(() => [...deCol])
  // const containerRef = useRef(null);
  // const { width, height } = useContainerDimensions(containerRef)
  const [containerRef, { x, y, width }] = useDimensions();
  const [rowSelection, setRowSelection] = React.useState({})

  const lastRowSelected = useRef(null); // ref => { current: null }
  const [sorting, setSorting] = React.useState([])
  const [lastSelectedId, setLastSelectedId] = React.useState(null)
  const [rowHovered, setRowHovered] = React.useState(null)

  const [columnVisibility, setColumnVisibility] =
    React.useState({})
  const [columnOrder, setColumnOrder] = React.useState(
    columns.map(column => column.id) //must start out with populated columnOrder so we can splice
  )

  const [columnPinning, setColumnPinning] = React.useState({
    "left": [
      "select",
      "OrderID"
    ],
    "right": ["more"]
  })

  const initTable = TableInstance({
    data: data || [], defaultColumns: deCol, rowSelection, setRowSelection, sorting, setSorting
    , columnVisibility, setColumnVisibility, columnOrder, setColumnOrder, columnPinning, setColumnPinning
  })




  const [isSplit, setIsSplit] = React.useState(true)


  

  const handleRowSelect = useCallback((evt, row) => {
    // console.log("handleRowSelect " , row)
    if (evt.ctrlKey) {
      // row.toggleSelected()
      // if (selectedOrders[row.original.id]) {
      //   dispatch(Table_DeSelectOrder(row.original.id))
      // } else {
      //   dispatch(Table_SelectOrder(row.original.id))
      // }

    } else if (evt.shiftKey) {
      // const lrIndex = lastRowSelected?.current?.index ?? row.index
      // const fromIndex = Math.min(lrIndex, row.index)
      // const toIndex = Math.max(lrIndex, row.index)
      // const rowselection = {}
      // for (let i = fromIndex; i <= toIndex; i++) {
      //   rowselection[i] = true
      // }
      // setRowSelection(rowselection)
      const { rows, rowsById } = table.getSortedRowModel()

      try {
        const rowsToToggle = getRowRange(rows, row.id, lastSelectedId)
        const isLastSelected = rowsById[lastSelectedId].getIsSelected()
        rowsToToggle.forEach((row) => {
          // row.toggleSelected(isLastSelected)
          dispatch(Table_SelectOrder(row.original))
        })
        // setRowIdSelection([...rowIdSelection, row.original.id])

      } catch (e) {
        // Show the user a message
        // useToast().toast({
        //   description: 'Multi-select with shift+click is disabled across multiple pages'
        // })
        if (row.getIsSelected()) {
          // row.toggleSelected(false)
          // setRowIdSelection(rowIdSelection.filter((rrow) => rrow != row.original.id))
          dispatch(Table_DeSelectOrder(row.original.id))

        } else {
          // row.toggleSelected(true)
          // setRowIdSelection([...rowIdSelection, row.original.id])
          dispatch(Table_SelectOrder(row.original))

        }


      }


    }
    else {

      // if (selectedOrders[row.original.id]) {
      //   dispatch(Table_DeSelectOrder(row.original.id))
      // } else {
      //   dispatch(Table_SelectOrder(row.original.id))
      // }

    }

    lastRowSelected.current = row   // lastRowSelected is a ref
    setLastSelectedId(row.id)

  }, [lastSelectedId, selectedOrders]);


  useEffect(() => {
    if (!data && !TableisLoading) {
      getTableOrderList()
    }
  }, [data, TableisLoading])

  useEffect(() => {
    getTableOrderList()
    dispatch(Table_setStatus({ key: "page", value: 1 }))
  }, [perPage])

  const columnSizeVars = React.useMemo(() => {
    if (table) {
      const headers = table.getFlatHeaders()
      const colSizes = {}
      for (let i = 0; i < headers.length; i++) {

        const header = headers[i]
        // console.log(header.id, header.getSize())

        colSizes[`--header-${header.id}-size`] = `${header.getSize()}`
        colSizes[`--col-${header.column.id}-size`] = `${header.column.getSize()}`
      }
      return colSizes
    } else {
      return {}
    }
  }, [table?.getState().columnSizingInfo])

  // useEffect(() => {
  //   dispatch(Table_Init(table))
  // }, [table])

  // useEffect(() => {
  //   setTable(tableInit)
  // }, [tableInit])


  useEffect(() => {

    initTableRef(initTable)

  }, [initTable])



  const debouceSetRowHovered = debounce(value => {
    setRowHovered(value)
  }, 10);

  const handleHoverChange = value => {
    debouceSetRowHovered(value)
  };


  useEffect(() => {

    if (data && data.length > 0) {
      // let trs = []
      // const tables = document.querySelectorAll('table.tbodyTbale');
      // const mainTable = document.querySelector('.maintable');

      // console.log("useEffecst ",tables)

      // for (let i = 0; i < tables.length; i++) {
      //   trs = tables[i].querySelectorAll('tr');
      //   console.log("useEffecst trs",trs)

      //   for (let j = 0; j < trs.length; j++) {
      //     let tr = trs[j];
      //     tr.addEventListener('mouseover', function () {
      //       mainTable.classList = 'maintable td' + j;
      //       handleHoverChange(tr.id)
      //     });
      //   }
      // }

      // element.addEventListener('click', handleClick);
      // element.removeEventListener('click', handleClick);

      // return () => {
      //   for (let i = 0; i < tables.length; i++) {
      //     trs = tables[i].querySelectorAll('tr');
      //     for (let j = 0; j < trs.length; j++) {
      //       let tr = trs[j];
      //       tr.removeEventListener('mouseover', function () {
      //       });
      //     }
      //   }
      // };

      // $(".ayx9meGKL2N2-TB2-ln6AU").on("hover", function(e) {
      //   $(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "rgba(128, 128, 128, 0.12)");
      //   $(this).prevAll(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "rgba(128, 128, 128, 0.12)");
      //   $(this).nextAll(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "rgba(128, 128, 128, 0.12)");
      // });

      // $(".ayx9meGKL2N2-TB2-ln6AU").hover(()=>{
      //   $(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "rgba(128, 128, 128, 0.12)");
      //   $(this).prevAll(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "rgba(128, 128, 128, 0.12)");
      //   $(this).nextAll(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "rgba(128, 128, 128, 0.12)");
      // },()=>{
      //   $(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "");
      //   $(this).prevAll(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "");
      //   $(this).nextAll(".ayx9meGKL2N2-TB2-ln6AU").css("background-color", "");
      // })

      $("tr").hover(
        function() {
            var itemId = $(this).attr("id");
            // itemId && alert(itemId)
            itemId && $(`.${itemId}`).css("background-color", "rgba(128, 128, 128, 0.12)");
            itemId && $(this).prevAll(`.${itemId}`).css("background-color", "rgba(128, 128, 128, 0.12)");
            itemId && $(this).nextAll(`.${itemId}`).css("background-color", "rgba(128, 128, 128, 0.12)");
            
        },
        function() {
            var itemId = $(this).attr("id");
            itemId && $(`.${itemId}`).css("background-color", "");
            itemId && $(this).prevAll(`.${itemId}`).css("background-color", "");
            itemId && $(this).nextAll(`.${itemId}`).css("background-color", "");
            
        }
    );

    }
  }, [data]);



  const getFromToPage = useCallback(() => {
    let start = 1;
    let end = totalCount;

    start = (page == 1) ? 1 : perPage * (page - 1) + 1 ;
    end = hasNextPage ? (perPage * page) : totalCount;


    return `${start}-${end}`

  }, [totalCount, perPage, page, hasNextPage, hasPreviousPage]);


  const getStyle = useCallback(() => {
    let ss = '';
 
    data?.forEach((item)=>{
      ss+=` 

      //  .a${item.id}:hover {background-color: rgba(128, 128, 128, 0.12);}
       
      `
    })
    return ss

  }, [data]);

  const openViewOrder = React.useCallback((orderId) => {
    // window.open('/order/'+orderId, '_blank').focus();
    history.push(`/order/${orderId}`)
    // Api_get_balance_AGW(dispatch, {}, (call) => { });

    // dispatch(ChangeViewTravelDialoge(true, orderId));
    // Api_get_Order_Info_AGW(dispatch, orderId);
    // // this.props.dispatch(OrderInfoAction(this.props.dispatch,{
    // //   orderId : this.props.id
    // // }))
    // Api_Get_Services_AGW(dispatch, null);
  }, []);
  


  const wwid = table ? (width - (table.getLeftTotalSize() + table.getRightTotalSize())) : 0
  const wwid2 = table ? (width - (table.getLeftTotalSize() + table.getRightTotalSize())) : 0


  if (!table) {
    return <TableLoading />
  }
  return (
<>
<Helmet>
            <style type="text/css">{getStyle()}</style>
     </Helmet>
    <DndProvider backend={HTML5Backend}>


      <ScrollSync>
        <div
          ref={containerRef}
          className={styles.tableContainer}
          style={{ display: 'flex', position: 'relative', height: conatinerHeight, width: conatinerWidth, flexDirection: 'column', transition: "all .3s ease-in-out" }}
        >

          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            {isSplit ? (


              <table className="LeftHeader container" style={{ borderCollapse: 'collapse', ...columnSizeVars, width: table.getLeftTotalSize() }}>
                <thead style={{ display: 'block', overflow: 'auto', width: "100%", ...columnSizeVars, }}>
                 
                {/* <tr key={'select'} className={styles.tr}>
                        <DraggableColumnHeader
                          key={selectColumn.id}
                          header={selectColumn}
                          table={table}
                          isLeft={true}
                        />
                    </tr> */}
                  {table.getLeftHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} className={styles.tr}>
                      {headerGroup.headers.map(header => (
                        <DraggableColumnHeader
                          key={header.id}
                          header={header}
                          table={table}
                          isLeft={true}
                        />
                      ))}
                    </tr>
                  ))}
                </thead>

              </table>
            ) : null}

            <table className="centerHeader container" style={{ display: 'block', borderCollapse: 'collapse', width: wwid, ...columnSizeVars, overflow: "auto" }} border="0" cellspacing="0" cellpadding="0">
              <ScrollSyncPane group="horizontal">
                <thead style={{ display: 'block', overflow: 'auto', color: 'white', width: wwid2 }}>
                  {(isSplit
                    ? table.getCenterHeaderGroups()
                    : table.getHeaderGroups()
                  ).map(headerGroup => (
                    <tr className={styles.tr}>
                      {headerGroup.headers.map(header => (
                        <DraggableColumnHeader
                          key={header.id}
                          header={header}
                          table={table}
                        />
                      ))}
                    </tr>
                  ))}
                </thead>
              </ScrollSyncPane>

            </table>

            {isSplit ? (
            <table className="LeftHeader container" style={{ borderCollapse: 'collapse', ...columnSizeVars, width: table.getRightTotalSize() }}>
              <thead style={{ display: 'block', overflow: 'auto', width: "100%", ...columnSizeVars, }}>
            
                {table.getRightHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id} className={styles.tr}>
                    {headerGroup.headers.map(header => (
                      <DraggableColumnHeader
                        key={header.id}
                        header={header}
                        table={table}
                        isLeft={true}
                        isRight={true}
                      />
                    ))}
                  </tr>
                ))}
              </thead>

            </table>
            ) : null}

          </div>


         { (!TableisLoading && data && data.length > 0)  && <div style={{ display: "flex", flexDirection: "row", height: "calc(100% - 87px)", overflowY: "scroll" }} class="maintable">
            {isSplit ? (


              <table className="LeftHeader container tbodyTbale" style={{ borderCollapse: 'collapse', ...columnSizeVars, width: table.getLeftTotalSize() }}>
                <ScrollSyncPane group="vertical">
                  <tbody style={{ display: 'block', height: "calc(100vh - 52px)", overflowY: 'auto', ...columnSizeVars,width:"199px",borderRight:"1px solid #F4F4F7" }}>
                    {table
                      .getSortedRowModel()?.rows
                      // .slice(0, 20)
                      .map(row => {
                        return (
                          
                          <tr key={row.id} id={"a"+row?.original?.id} onClick={(evt) => {/*handleRowSelect(evt, row)*/}} className={"a"+row?.original?.id+" "+styles.tr}  >
                            {row.getLeftVisibleCells().map(cell => {
                              return (
                                <td className={clsx(styles.td /*, rowHovered == row?.original?.id && styles.tdHovered */)} key={cell.id} style={{ minWidth: `calc(var(--header-${cell.column?.id}-size) * 1px - 2px)`, maxWidth: `calc(var(--header-${cell.column?.id}-size) * 1px - 2px)` , margin:"0px",padding:"0px" }}>
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                  </tbody>
                </ScrollSyncPane>
              </table>
            ) : null}

            <div>
              <table className="centerHeader container tbodyTbale" style={{ borderCollapse: 'collapse', width: wwid, ...columnSizeVars }} border="0" cellspacing="0" cellpadding="0">

                <ScrollSyncPane group={["horizontal", "vertical"]} >
                  <tbody style={{ display: 'block', height: "calc(100vh - 52px)", overflow: 'auto', width: wwid2 }} id="maintbody">
                    {table
                      .getSortedRowModel().rows
                      // .slice(0, 20)
                      .map(row => {
                        return (
                          <tr key={row.id} id={"a"+row?.original?.id}  onClick={(evt) => {handleRowSelect(evt, row) ; }} className={"a"+row?.original?.id+" "+styles.tr} >
                            {(isSplit
                              ? row.getCenterVisibleCells()
                              : row.getVisibleCells()
                            ).map(cell => {
                              return (
                                <td className={clsx(styles.td /*, rowHovered == row?.original?.id && styles.tdHovered */)} key={cell.id} style={{ minWidth: `calc(var(--header-${cell.column?.id}-size) * 1px)`, maxWidth: `calc(var(--header-${cell.column?.id}-size) * 1px)` ,borderRight:cell.column?.id == 'OrderID' && "1px solid rgba(244, 244, 247, 1)" }} >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              )
                            })}
                          </tr> 
                        )
                      })}
                  </tbody>
                </ScrollSyncPane>
              </table>
            </div>
            {isSplit ? (


        <table className="LeftHeader container tbodyTbale" style={{ borderCollapse: 'collapse', ...columnSizeVars, width: table.getRightTotalSize() ,borderLeft:"1px solid #F4F4F7"}} >
          <ScrollSyncPane group="vertical">
            <tbody style={{ display: 'block', height: "calc(100vh - 52px)", overflowY: 'auto', ...columnSizeVars, }}>
              {table
                .getSortedRowModel()?.rows
                // .slice(0, 20)
                .map(row => {
                  return (
                    <tr key={row.id} id={"a"+row?.original?.id} onClick={(evt) => handleRowSelect(evt, row)} className={"a"+row?.original?.id+" "+styles.tr}  >
                      {row.getRightVisibleCells().map(cell => {
                        return (
                          <td className={clsx(styles.td /*, rowHovered == row?.original?.id && styles.tdHovered */)} key={cell.id} style={{ minWidth: `calc(var(--header-${cell.column?.id}-size) * 1px)`, maxWidth: `calc(var(--header-${cell.column?.id}-size) * 1px)` }}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </ScrollSyncPane>
        </table>
        ) : null}

          </div>}


          {(TablehasError && !TableisLoading) && <div className={styles.innerApp}>
                    <TableError />
                </div>}

                {(!TablehasError && !canClearAll() && (data && data.length == 0 && !TableisLoading)) && <div className={styles.innerApp}>
                    <TableNoOrder />
                </div>}

                {(!TablehasError && canClearAll() && (data && data.length == 0) && !TableisLoading) && <div className={styles.innerApp}>
                    <TableNoOrderFilter />
                </div>}




          {(!TableisLoading && data && data.length > 0)  && <div className={styles.paginationContainer}>

            <div className={styles.firstSection}>     
                        <Typography weight='Body_Middle_Regular' text={`${getFromToPage()} from ${totalCount}`} textColor='TextDark' />
                        <DropDown onClick={item => {
                          dispatch(Table_setStatus({ key: "perPage", value: Number(item) }))
                        }} placement='topStart' size='Medium' text={`${perPage} rows`} data={[30, 50, 100, 200]} title={perPage} />

            </div>
            <div className={styles.paginationButtonContainer}>
              <div className={clsx(styles.paginationButton, !hasPreviousPage && styles.DisabledpaginationButton)} onClick={hasPreviousPage ? () => { getTableOrderList(true, false) } : null} >
                <img src={ARROW_LEFT} />
                <Typography weight='Body_Middle_Medium' text='Prev' textColor='TextDark' />

              </div>

              <div className={clsx(styles.paginationButton, !hasNextPage && styles.DisabledpaginationButton)} onClick={hasNextPage ? () => { getTableOrderList(false, true) } : null} >
                <Typography weight='Body_Middle_Medium' text='Next' textColor='TextDark' />
                <img src={ARROW_LEFT} style={{ transform: "rotate(180deg)" }} />
              </div>
            </div>
          </div>}

          {TableisLoading && <TableLoading />}

        </div>

      </ScrollSync>
    </DndProvider></>
  )
}



// function IndeterminateCheckbox({
//   indeterminate,
//   className = '',
//   checked,
//   onChange,
//   disabled,
//   style,
//   isheader

// }) {
//   const ref = React.useRef(null)
//   const dispatch = useDispatch();
//   const data = useSelector((state) => state.Table.list)

//   const checkAll = useCallback((e) => {
//     onChange(e);
//     isheader && dispatch(Table_SelecteMultiOrders(data))

//   }, [data]);

//   const uncheckAll = useCallback((e) => {
//     onChange(e);
//     isheader && dispatch(Table_ClearSelectedOrders())
//   }, [data]);

//   React.useEffect(() => {
//     if (typeof indeterminate === 'boolean') {
//       ref.current.indeterminate = !checked && indeterminate
//     }
//   }, [ref, indeterminate])

//   return (
//     <input
//       type="checkbox"
//       ref={ref}
//       className={className + ' cursor-pointer ' + styles.checkbox}
//       checked={checked}
//       style={style}
//       indeterminate
//       disabled={disabled}
//       onChange={(e) => { checked ? uncheckAll(e) : checkAll(e) }}
//     />
//   )
// }




function getRowRange(rows, idA, idB) {
  const range = []
  let foundStart = false
  let foundEnd = false
  for (let index = 0; index < rows.length; index += 1) {
    const row = rows[index]
    if (row.id === idA || row.id === idB) {
      if (foundStart) {
        foundEnd = true
      }
      if (!foundStart) {
        foundStart = true
      }
    }
    if (foundStart) {
      range.push(row)
    }
    if (foundEnd) {
      break
    }
  }
  // added this check
  if (!foundEnd) {
    throw Error('Could not find whole row range')
  }
  return range
}

export default TableList











  // let selectColumn =()=> {
  //   return {
  //     id: 'select',
  //     isFirst: true,
  //     size: 50,
  //     minSize: 50,
  //     maxSize: 50,
  //     header: ({ table }) => (
  //       <IndeterminateCheckboxHeader
  //         {...{
  //           checked: table.getIsAllRowsSelected(),
  //           indeterminate: table.getIsSomeRowsSelected(),
  //           onChange: table.getToggleAllRowsSelectedHandler(),
  //           isheader: true
  //         }}
  //       />
  //     ),
  //     cell: ({ row }) => (
  //       <div>
  //         <IndeterminateCheckbox
  //           {...{
  //             checked: row.getIsSelected(),
  //             disabled: !row.getCanSelect(),
  //             indeterminate: row.getIsSomeSelected(),
  //             onChange: row.getToggleSelectedHandler(),
  //             style: { marginInlineStart: "16px" },
  //             isheader: false,
  //             ...row,
  //           }}

  //         /></div>
  //     ),
  //   }
  // }




  


// function IndeterminateCheckbox({
//   indeterminate,
//   className = '',
//   checked,
//   onChange,
//   disabled,
//   style,
//   isheader,
//   ...row

// }) {
//   const ref = React.useRef(null)
//   const dispatch = useDispatch();
//   const data = useSelector((state) => state.Table.list.orders)
//   const selectedOrders = useSelector((state) => state.Table.selectedOrders)


//   const onChangex = useCallback((e) => {

//     if (row?.original) {
//       if (ischecked()) {
//         dispatch(Table_DeSelectOrder(row.original.id))
//       } else {
//         dispatch(Table_SelectOrder(row.original))
//       }
//     } else {
//       return false
//     }
//   }, [data, row]);



//   const ischecked = useCallback((e) => {

//     console.log(" ischecked ", row)
//     if (row) {
//       if (row.original) {
//         return selectedOrders[row.original.id]
//       } else {
//         return false
//       }
//     } else {
//       return false
//     }

//   }, [data, row]);

//   return (
//     <Checkbox size='Small' state={ischecked() ? "checked" : "unchecked"} onClick={(e) => { onChangex(e) }} style={{ marginLeft: "16px" }} />

//   )
// }


// function IndeterminateCheckboxHeader({
//   indeterminate,
//   className = '',
//   checked,
//   onChange,
//   disabled,
//   style,
//   isheader,

// }) {
//   const ref = React.useRef(null)
//   const dispatch = useDispatch();
//   const data = useSelector((state) => state.Table.list.orders)
//   const selectedOrders = useSelector((state) => state.Table.selectedOrders)

//   const checkAll = useCallback((e) => {
//     dispatch(Table_SelecteMultiOrders(data))

//   }, [data]);

//   const uncheckAll = useCallback((e) => {
//     dispatch(Table_ClearSelectedOrders())
//   }, [data]);



//   const allischecked = useCallback((e) => {

//     let all = true

//     data.forEach((key2) => {
//       if (!selectedOrders[key2.id]) {

//         all = false;
//       }
//     })

//     return all

//   }, [data, selectedOrders]);


//   const someischecked = useCallback((e) => {

//     let all = false

//     data.forEach((key2) => {
//       if (selectedOrders[key2.id]) {

//         all = true;
//       }
//     })

//     return all

//   }, [data, selectedOrders]);

//   return (

//     <Checkbox size='Small' state={(!allischecked() && someischecked()) ? "indeterminate" : (allischecked() ? "checked" : "unchecked")} onClick={(e) => { allischecked() ? uncheckAll(e) : checkAll(e) }} style={{ marginLeft: "3px" }} />

//   )
// }