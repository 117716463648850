import otoIGO from "../../../static/oto_logotype.png";
import oto_banner from "../../../static/oto_banner.jpg";

import FavIcon from "../../../static/PROJECTS/oto.png";

export default function oto() {
    
    return global.config = {
        "project": "app.otokh.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": otoIGO,
        "BaseApi": "https://rest.otokh.com",
        "BaseSocket": "https://socket.otokh.com",
        "BaseTrack": "https://panel.otokh.com",
        "MapCenter": {
            "lat": 11.605189,
            "lng": 104.911424
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/oto.png",
        "Country": "US",
        "AppleLoginCustomerId": "5JR437GB5K",
        "AppleLoginredirectURI": "https://app.otokh.com",
        "FacebookLoginappId": "493437251955359",
        "GoogleLogincustomerId": "356487147167-690neruhlnijtc4005c93nk3955u6in5.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": oto_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"oto",
        registerEnabled: true,
        Secondarycolor:"#0066FF",
        SecondaryDarkColor:"#004BBD",
        SecondaryLightColor:"#009FFF",
        SecondaryUltraLight :"#F0F6FF",
        SecondaryTextColor:"#FFFFFF",
        Secondaryonlight:"#0066FF",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:otoIGO,
        apkUrl:null,
        iosUrl:null
} }