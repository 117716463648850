import {
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR
} from "../actions/actionTypes";

const defaultState = {
  notifications: [],
  req :[]
};

export const notifications = (state = defaultState, action) => {
  switch (action.type) {
    case "removeNetworkErrorSnack":
      return {
        ...state,
        notifications: state.notifications.map(noti =>
          noti.type === action.key
            ? {
                ...noti,
                dismissed: true,
                options: {
                  ...noti.options,
                  persist: false
                }
              }
            : noti
        )
      };

      case "removeNetworkErrorSnackBykey":
            return {
              ...state,
              notifications: state.notifications.map(noti =>
                noti.key === action.key
                  ? {
                      ...noti,
                      dismissed: true,
                      options: {
                        ...noti.options,
                        persist: false
                      }
                    }
                  : noti
              )
            };

    case ENQUEUE_SNACKBAR:
      
  let blahblah=false;
      state.notifications.map((noti)=>{
        if(noti.type===action.notification.type){
          blahblah=true
        }
      })
      if(blahblah){
        return {
          ...state
        };
      }else{
        return {
          ...state,
          notifications: [
            ...state.notifications,
            {
              key: action.key,
              ...action.notification
            }
          ]
        };
      }
      /*
   
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification
          }
        ]
      };   */
    case CLOSE_SNACKBAR:
      
      return {
        ...state,
        notifications: state.notifications.map(notification =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };

    default:
      return state;
  }
};
