import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import DropZone from '../DragOver/DropZone'
import Preview from '../DragOver/preview'
import Uploading from '../DragOver/uploading'
import Proccessing from '../DragOver/proccessing'
import Details from '../DragOver/details'




const init = {
    percentUpload: 0
}



class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            File: null,
            percentUpload: 0

        }
    }

    setPercentUpload = (percentUpload) => {
        // this.setState({
        //     percentUpload
        // })
    }


    setFile = (File) => {
        this.setState({ File })
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.ActiveItem.id != this.props.ActiveItem.id) {
            this.setState({
                ...init
            })
        }
    }


    render() {
        let { t } = this.props;
        let ActiveItem = (this.props.ActiveItem && this.props.ActiveItem.length > 0) ? this.props.ActiveItem[0] : null

        if ((this.props.selectedItem && ActiveItem && (ActiveItem.status == "done" || ActiveItem.status == "failed"))) {
            return <Details ActiveItem={ActiveItem} />
        } else {
            return (
                <div className="DragOverContainer ">
                    {!this.props.selectedItem && <DropZone setFile={this.setFile} ActiveItem={ActiveItem} />}
                    {(this.props.selectedItem && ActiveItem && ActiveItem.File && (ActiveItem.status != "done" || ActiveItem.status != "failed") && !ActiveItem.uploading && !ActiveItem.uploaded) && <Preview setPercentUpload={this.setPercentUpload} File={this.state.File} ActiveItem={ActiveItem} />}
                    {(this.props.selectedItem && ActiveItem && ActiveItem.uploading) && <Uploading ActiveItem={ActiveItem} />}
                    {(this.props.selectedItem && ActiveItem && (ActiveItem.uploaded || ActiveItem.status == "processing")) && <Proccessing ActiveItem={ActiveItem} />}


                </div>
            );
        }

    }
}

const mapStateToProps = (state) => ({
    draftList: state.BulkImport.draftList,
    List: state.BulkImport.List,
    selectedItem: state.BulkImport.selectedItem,
    ActiveItem: state.BulkImport.selectedItemType == "draft" ? state.BulkImport.draftList.filter(item => { return item.id == state.BulkImport.selectedItem }) : [state.BulkImport.List[state.BulkImport.selectedItem]]

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
