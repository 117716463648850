import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../static/chevron-down.svg";
import dropoffDone from "../../../../static/icon-dropoff-done.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import Pod from "./pod";
import jalali from "jalali-moment";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../helper/UserData/userdate";
import { ReactComponent as Sion } from "../../../../static/Mutual___ic_calender.svg";
import drop from "../../../../static/icon-ic-dropoff.svg";
import pickupDropDone from "../../../../static/doneDropoff.svg";
import moment from 'jalali-moment'
import Typography from "../../../../design-system/lib/Typography";
import styles2 from './viewOrder.module.css';

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
    this.anchorEl = React.createRef();
  }


  handleCollapse = () => {
    this.props.handleCollapse(this.state.id);
  };



  render() {
    const { t } = this.props;
    let reserveIsValid = this.props.drop
      ? (this.props.drop.scheduleDateBefore
        ? momentJalaali(this.props.drop.scheduleDateBefore).isValid()
        : false) ||
      (this.props.drop.scheduleDateAfter
        ? momentJalaali(this.props.drop.scheduleDateAfter).isValid()
        : false)
      : false;


    let scheduleDateBeforeIsValid = this.props.drop
      ? this.props.drop.scheduleDateBefore > 0
        ? momentJalaali(this.props.drop.scheduleDateBefore).isValid()
        : false
      : false;

    let scheduleDateAfterIsValid = this.props.drop
      ? this.props.drop.scheduleDateAfter > 0
        ? momentJalaali(this.props.drop.scheduleDateAfter).isValid()
        : false
      : false;

    return (
      <vrpasserngerContainer

        className={`dropOffCustomized   ${this.props.last && "LastdropOffCustomized"
          }`}
      >
        <vrpickuptitle
          style={{ width: "calc(100% - 16px)" }}
          className={` `}
        >



          {(this.props.drop.status && this.props.drop.status == "Done") ? <img src={pickupDropDone} className="puci" /> : <vrPickupCircle style={{ borderRadius: "2.5px" }} className="Dropoff" />}
          <span className="title">
            {" "}
            <Trans i18nKey={"Dropoff2"}> </Trans>{" "}
            {this.props.drop ? (this.props.drop.scheduleDateAfter ||
              this.props.drop.scheduleDateBefore ? (
              <div className="scheduledOrderTimeConatiner">
                <Sion className="img" />
                <span>

                  {/* {moment(Number(
                    this.props.drop.scheduleDateAfter ||
                      this.props.drop.scheduleDateBefore
                  )).locale(get_lang()).format("D MMM hh:mm")} */}
                  {momentJalaali(Number(
                    this.props.drop.scheduleDateAfter ||
                    this.props.drop.scheduleDateBefore
                  )).locale(get_lang()).format("D MMM HH:mm")}


                  {/* {new Date(Number(
                    this.props.drop.scheduleDateAfter ||
                    this.props.drop.scheduleDateBefore
                  )).toLocaleString(get_lang(), {
                    hour: "2-digit",
                    minute: "2-digit",
                    month: 'long',
                    day: 'numeric',
                    hour12:false
                  })
                  } */}

                  {/* {new Date(
                  Number(
                    this.props.drop.scheduleDateAfter ||
                      this.props.drop.scheduleDateBefore
                  )
                ).toLocaleDateString(get_lang(), {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hourCycle: 'h23'
                })} */}
                </span>
              </div>
            ) : null) : null}
          </span>


          <img
            onClick={this.handleCollapse}
            src={arrow}
            className={`vrpickupCollIconVR ${this.props.activeCollapse === this.state.id && "rotate180"
              }`}
          />

          {((this.props.orderType == "Ondemand" || this.props.orderType == "PickupDelivery") && (this.props.orderType == "PickupDelivery" ? this.props?.status == "Done" : this.props.drop?.status == "Done")) && (
            <Pod {...this.props.drop} title={"pod"} />
          )}

          <span
            onClick={this.handleCollapse}
            className={`copey`}
          >
            {this.props.drop && this.props.drop.address}
          </span>


          {/* {reserveIsValid ? (
            <span className={`miniAddressVR ViewOrderReservText`}>
              {this.props.t("for") + "  "+ 
               (this.props.drop.scheduleDateAfter ?  momentJalaali(Number(this.props.drop.scheduleDateAfter)).format(
                  get_lang() == "fa"
                    ? "HH:mm jYYYY/jMM/jDD"
                    : "YYYY/MM/DD HH:mm"
                ):"") +((this.props.drop.scheduleDateAfter && this.props.drop.scheduleDateBefore) ?
                " - " : "") +
               (this.props.drop.scheduleDateBefore ? momentJalaali(Number(this.props.drop.scheduleDateBefore)).format(
                  get_lang() == "fa"
                    ? "HH:mm jYYYY/jMM/jDD"
                    : "YYYY/MM/DD HH:mm"
                ):"")}
            </span>
          ) : null} */}


        </vrpickuptitle>

        {this.props.drop && (

this.props.orderType == "PickupDelivery" ? <Collapse
className="pickupcollapsecontainervr"
in={this.props.activeCollapse === this.state.id}
timeout="auto"
unmountOnExit
style={{ marginTop: this.props.drop?.status !== "Pending" ? "10px" : "" }}
>

<div className={styles2.pickup_dropOff_collapseRowContainer}>
  <div className={styles2.pickup_dropOff_collapseRow}>
    <div className={styles2.pickup_dropOff_collapseSubRow}>
        <Typography weight="Body_Tiny_Medium" text={this.props.drop.addressDetail.length > 0
        ? this.props.drop.addressDetail
        : "-"} style={{color:"#242424"}} />
        <Typography weight="Body_Tiny_Regular" text={"Address Details"} style={{color:"#808080"}} />
    </div>
  </div>
  <div className={styles2.pickup_dropOff_collapseRow}>
  <div className={styles2.pickup_dropOff_collapseSubRow}>
        <Typography weight="Body_Tiny_Medium" text={this.props.drop.fullName.length > 0
                  ? this.props.drop.fullName
                  : "-"} style={{color:"#242424"}} />
        <Typography weight="Body_Tiny_Regular" text={this.props.t("Recieverfullname")} style={{color:"#808080"}} />
    </div>
    <div className={styles2.pickup_dropOff_collapseSubRow}>
        <Typography weight="Body_Tiny_Medium" text={this.props.drop.phone.length > 0
                  ? (String(this.props.drop.phone).startsWith("+") ? 
                  this.props.drop.phone : 
                  (String(this.props.drop.phone).startsWith("00") 
                  ? "+" + String(this.props.drop.phone).slice(2, this.props.drop.phone.length)  : "" ) )
                  : "-"} style={{color:"#242424"}} />
        <Typography weight="Body_Tiny_Regular" text={this.props.t("Recieverphonenumber")} style={{color:"#808080"}} />
    </div>
    <div className={styles2.pickup_dropOff_collapseSubRow}>
        <Typography weight="Body_Tiny_Medium" text={this.props.drop.email.length > 0
                  ? this.props.drop.email
                  : "-"} />
        <Typography weight="Body_Tiny_Regular" text={this.props.t("email")} style={{color:"#808080"}} />
    </div>
  </div>
</div>


</Collapse> :
          <Collapse
            className="pickupcollapsecontainervr"
            in={this.props.activeCollapse === this.state.id}
            timeout="auto"
            unmountOnExit
            style={{ marginTop: this.props.drop?.status !== "Pending" ? "10px" : "" }}
          >
            {/* {this.props.drop.status !== "Pending" && (
              <vrpa> {`${this.props.t("Deliveredat") + jalali(new Date(this.props.drop.deliveryDate)).locale(get_lang()).format(" HH:mm") }`}</vrpa>
            )} */}

            {/* <vrpa>
              {this.props.drop &&
                this.props.t("address") + " : " + this.props.drop.address}
            </vrpa>
            <vrbfr
              style={{
                display:
                  this.props.drop &&
                  (this.props.drop.buildingBlock.length > 0 ||
                    this.props.drop.floor.length > 0 ||
                    this.props.drop.room.length > 0)
                    ? ""
                    : "none",
              }}
            >
              {`${
                this.props.drop.buildingBlock.length > 0
                  ? this.props.t("block") +
                    " : " +
                    this.props.drop.buildingBlock
                  : ""
              } ${
                this.props.drop &&
                (this.props.drop.buildingBlock.length > 0 ? "  |   " : "") +
                  (this.props.drop.floor.length > 0
                    ? this.props.t("Floor") + " : " + this.props.drop.floor
                    : "")
              } ${
                this.props.drop &&
                (this.props.drop.room.length > 0 ? "  |   " : "") +
                  (this.props.drop.room.length > 0
                    ? this.props.t("Unit") + " :  " + this.props.drop.room
                    : "")
              }`}
            </vrbfr>
            <vrbfr
              style={{
                display:
                  this.props.drop &&
                  (this.props.drop.fullName > 0 ||
                    this.props.drop.phone.length > 0)
                    ? ""
                    : "none",
              }}
            >
              {`${
                this.props.drop &&
                this.props.t("Recieverfullname") +
                  " : " +
                  this.props.drop.fullName
              } ${
                this.props.drop &&
                (this.props.drop.phone.length > 0 ? "  |   " : "") +
                  (this.props.t("Recieverphonenumber") +
                    " : " +
                    this.props.drop.phone)
              }`}
            </vrbfr>
            <vrnote
              style={{
                display:
                  this.props.drop &&
                  this.props.drop.customerDescription.length > 0
                    ? ""
                    : "none",
              }}
            >
              {this.props.drop &&
                this.props.t("notesfordriver") +
                  " : " +
                  this.props.drop.customerDescription}
            </vrnote>


            
            <vrnote
              style={{
                display: reserveIsValid ? "" : "none",
              }}
            >
              {`${
                scheduleDateAfterIsValid &&
                this.props.t("after") +
                  momentJalaali(
                    Number(this.props.drop.scheduleDateAfter)
                  ).format(
                    get_lang() == "fa"
                    ? "HH:mm jMM/jDD"
                    : "HH:mm MM/DD"
                  )
              } ${
                scheduleDateAfterIsValid &&
                (scheduleDateBeforeIsValid ? "  |   " : "") + (scheduleDateBeforeIsValid ?
                  (this.props.t("before") +
                    momentJalaali(
                      Number(this.props.drop.scheduleDateBefore)
                    ).format(
                      get_lang() == "fa"
                        ? "HH:mm jMM/jDD"
                        : "HH:mm MM/DD"
                    ))
             :"") }`}
            </vrnote>
 */}
            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.buildingBlock.length > 0
                  ? this.props.drop.buildingBlock
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("block")} </span>
            </div>
            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.floor.length > 0
                  ? this.props.drop.floor
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Floor")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.room.length > 0
                  ? this.props.drop.room
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Unit")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.fullName.length > 0
                  ? this.props.drop.fullName
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Recieverfullname")} </span>
            </div>

            <div className="vrPickupCollapseDetail" style={{}}>
              <span className="xyu">
                {" "}
                {this.props.drop.phone.length > 0
                  ? (String(this.props.drop.phone).startsWith("+") ? 
                  this.props.drop.phone : 
                  (String(this.props.drop.phone).startsWith("00") 
                  ? "+" + String(this.props.drop.phone).slice(2, this.props.drop.phone.length)  : "" ) )
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Recieverphonenumber")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.email.length > 0
                  ? this.props.drop.email
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("email")} </span>
            </div>


            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.customerDescription.length > 0
                  ? this.props.drop.customerDescription
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("notesfordriver")} </span>
            </div>

          </Collapse>
        )}
      </vrpasserngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",

  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
