import DroppXIGO from "../../../static/JPCarrier_logotype.png";
import DroppX_banner from "../../../static/JPCarrier_background.png";

import FavIcon from "../../../static/PROJECTS/JPCarrier.png";

export default function JPCarrier() {
    
    return  global.config = {
        "project": "JP Last Mile Solutions",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "",
        "LogoType": DroppXIGO,
        "BaseApi": "https://rest.jpcarrier.ca",
        "BaseSocket": "https://socket.jpcarrier.ca",
        "BaseTrack": "https://tr.jpcarrier.ca",
        "MapCenter": {
            "lat": "43.82618767737808",
            "lng": "-79.55656010096088"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        }],
        "FavIcon": "/JPCarrier.png",
        "Country": "CA",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": DroppX_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"JPCarrier",
        registerEnabled: true,
        Secondarycolor:"#CBA36F",
        SecondaryDarkColor:"#C19254",
        SecondaryLightColor:"#E4B679",
        SecondaryUltraLight :"#FAF6F1",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#FF3537",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FDF3E7",
        loginBackgroundColorEnd:"#F2DABC",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:DroppXIGO,
        apkUrl:null,
        iosUrl:null
}}