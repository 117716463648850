import * as actionTypes from "../actions/actionTypes";
const init = {
  SettingsOpenDialoge : false ,
  OpenDialogeWalletInMenu : false ,
  OpenDialogeAddressBookInMenu : false ,
};
export const Settings = (state = init, action) => {
  switch (action.type) {
    case actionTypes.openCloseSetting:
      return {
        ...state,
        SettingsOpenDialoge:action.state
      };
      
    default:
      return state;
  }
};

