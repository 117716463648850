import loadmanexpressIGO from "../../../static/loadmanexpress_logo.png";
import loadmanexpress_banner from "../../../static/loadmanexpress_banner.png";
import FavIcon from "../../../static/PROJECTS/loadmanexpress.png";

export default function LoadmanExpress() {
    
    return  global.config = {
        "project": "LoadmanExpress",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Loadman Express",
        "LogoType": loadmanexpressIGO,
        "BaseApi": "https://rest.loadmanexpress.com",
        "BaseSocket": "https://socket.loadmanexpress.com",
        "BaseTrack": "https://track.loadmanexpress.com",
        "MapCenter": {
            "lat": 9.453303557151596,
            "lng": 77.80190662036814
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/loadmanexpress.png",
        "Country": "IN",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": loadmanexpress_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"loadmanexpress",
        registerEnabled: true,
        Secondarycolor: "#1080FF",
        SecondaryDarkColor: "#0055cb",
        SecondaryLightColor: "#25B7FF",
        SecondaryUltraLight: "#D9EAFF",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#1080FF",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        icon_light_start:"#B7D9FF",
        icon_light_end:"#BDE9FF",
        icon_dark_start:"#004CC4",
        icon_dark_end:"#0083E4",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#1080FF",
        loginBackgroundColorEnd:"#58A7FF",
        LoginTextColor :"#FFFFFF",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:loadmanexpressIGO,
        apkUrl:null,
        iosUrl:null
} }