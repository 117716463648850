import ReflexcourierIGO from "../../../static/Reflexcourier.png";
import Reflexcourier_banner from "../../../static/Reflexcourier_background.png";

import FavIcon from "../../../static/PROJECTS/FavIcon_Reflexcourier.png";

export default function Reflexcourier() {
    
    return  global.config = {
        "project": "app.reflexcourier.ca",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Reflex Courier",
        "LogoType": ReflexcourierIGO,
        "BaseApi": "https://rest.reflexcourier.ca",
        "BaseSocket": "https://socket.reflexcourier.ca",
        "BaseTrack": "https://admin.reflexcourier.ca",
        "MapCenter": {
            "lat": 43.66263306012005,
            "lng": -79.3821779229343
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/Reflexcourier.png",
        "Country": "CA",
        "AppleLoginCustomerId": null,
        "AppleLoginredirectURI": null,
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Reflexcourier_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"Reflexcourier",
        registerEnabled: true,
        Secondarycolor: "#710408",
        SecondaryDarkColor: "#660508",
        SecondaryLightColor: "#9B060C",
        SecondaryUltraLight: "#F4EBEB",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#710408",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#D2686B",
        icon_light_end:"#EF9CA2",
        icon_dark_start:"#710408",
        icon_dark_end:"#9B060C",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFF1F2",
        loginBackgroundColorEnd:"#F3CFD0",
        LoginTextColor :"#03154A",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:ReflexcourierIGO,
        apkUrl:null,
        iosUrl:null
}}