import React, { useState } from "react";
import service_box from "../../../../../static/service-box.jpg";
import pickup from "../../../../../static/icon-ic-pickup.svg";
import dropoff from "../../../../../static/group-4-copy.svg";
import car from "../../../../../static/icon-car.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import jalali from "jalali-moment";
import { get_lang } from "../../../../../helper/UserData/userdate";



class App extends React.Component {


  render() {
    return <div className="OrderItemContainer TransactionItemContainer">
      <div className="TransactionItem_firstrow">  
          <span className={`price ${this.props.data.type}`}>  {this.props.data.currencyCode} {this.props.data.amount} </span>
          <span className="date"> 
          {this.props.data.createdAt && new Date(this.props.data.createdAt).toLocaleDateString(get_lang(), {  month: 'long',hourCycle: 'h23' , day: 'numeric', hour:'numeric', minute:'numeric'})}  
           {/* {this.props.data.createdAt &&  
              jalali(this.props.data.createdAt)
                .locale("sn")
                .format("DD MMM hh:mm")} */}
                   </span>
      </div>

          <span className="TransactionItemText"> {this.props.data.shortDescription } </span>

    </div>;
  }
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
