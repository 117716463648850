import io from "socket.io-client";
import {
  get_customerPannel_type,
  get_Id,
  get_id,
  get_lang,
} from "../helper/UserData/userdate";
import {
  SetSocket,
  UpdateTravelInList,
  DeleteTravelInList,
  UpdateStateOrderInList,
  SetSeenMessageInChat,
} from "../Redux/actions/actionNames";
import {
  CheckIncomeMessages,
  CheckIncomeMessagesSeen,
  CheckThisTravelIsActive,
  CheckThisTravelIsActiveWithOutOrder,
  DeleteOrderFromLIST,
} from "./helper";
import {
  GetProjectHashName,
  getBaseSocketByUrl,
  GetSocketOptions,
  CheckSocketOrderState,
} from "../helper/module";
import { OrderInfoAction } from "../Redux/actions/actions";
import { updateStateorderInList } from "../Redux/actions/actionTypes";
import { SucceessFul_i18 } from "../helper/Notification/sendNotification";
import { store } from "../Redux/store";
import { Customer_get_Order_Info_Api, Customer_get_Order_Info_Api_call, Customer_get_Order_Info_Api_PD_Update } from "../helper/api";

const socketUrl = global.config.BaseSocket;

export const setupSocket = (dispatch) => {
  console.log("setupSocket init");
  const socket = io(socketUrl, GetSocketOptions());
  let tryConnection = 3;
  var reasonOfDisconnect = null;

  socket.on("connect", (res) => {
    console.log("setupSocket connect-> res", res);
    dispatch(SetSocket(socket));
  });

  socket.on("disconnect", (reason) => {
    console.log("connect_error", reason)

    if (reason === "ping timeout") {
      reasonOfDisconnect = "net";
    }
    if (reason === "transport close" || reason === "io server disconnect") {
      reasonOfDisconnect = "server";
    }
    console.log("setupSocket disconnect-> res", reason);
  });

  socket.on("connect_error", (res) => {
    console.log("connect_error", res)
    if (tryConnection === 1) {
      if (reasonOfDisconnect === "net") {
      }

      if (reasonOfDisconnect === "server") {
      }
    }
    if (tryConnection > 0) {
      tryConnection--;
    } else {
      if (navigator.onLine && reasonOfDisconnect === "net") {
        reasonOfDisconnect = "server";
      }

      if (!navigator.onLine && reasonOfDisconnect === "server") {
        reasonOfDisconnect = "net";
      }
    }
    console.log("setupSocket connect_error-> res", res);
  });

  socket.on("Error ", (res) => {
    console.log("connect_error", res)
  });

  socket.on("customer:order:unassigned", (res) => {

    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:driver-assigned", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:started", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:picked-up", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:driver-arrived", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:done", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:driver-canceled", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:support-canceled", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });



  socket.on("customer:order:schedule:unassigned", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:driver-assigned", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:started", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:picked-up", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:driver-arrived", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:done", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:driver-canceled", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:support-canceled", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:schedule:updated", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)
    CheckSocketOrderState(res.data.status, (callx) => {
      if (!callx) {
        DeleteOrderFromLIST(res.data.orderId ? res.data.orderId : res.data.id, false, {
          status: res.data.status,
          id: res.data.orderId ? res.data.orderId : res.data.id,
          data: res.data,
        });
      } else {
        dispatch(
          UpdateStateOrderInList({
            status: res.data.status,
            id: res.data.orderId ? res.data.orderId : res.data.id,
            data: res.data,
          })
        );
      }
    });
  });

  socket.on("customer:order:updated", (res) => {
    console.log("customer:order:updated ",res)
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)

    let storeData = store.getState();
    let type = storeData.Table.filters.type

    if(String(window.location.href).includes(res.data.orderId ? res.data.orderId : res.data.id)){
      Customer_get_Order_Info_Api(dispatch, res.data.orderId ? res.data.orderId : res.data.id, ()=>{

      });
    }

    if (type == res.data.type && type == "PickupDelivery") {
      Customer_get_Order_Info_Api_PD_Update(dispatch, res.data.orderId ? res.data.orderId : res.data.id)
    } else {

      Customer_get_Order_Info_Api_call(res.data.orderId ? res.data.orderId : res.data.id, (call) => {
        dispatch(
          UpdateStateOrderInList({
            status: call.status,
            id: call.id,
            data: call,
          })
        );
        // CheckSocketOrderState(call.status, (callx) => {
        //   if (!callx) {
        //     DeleteOrderFromLIST(call.orderId, false, {
        //       status: call.status,
        //       id: call.orderId,
        //       data: call,
        //     });
        //   } else {
        //     dispatch(
        //       UpdateStateOrderInList({
        //         status: call.status,
        //         id: call.id,
        //         data: call,
        //       })
        //     );
        //   }
        // });
      })

    }


  });



  socket.on("customer:order:created", (res) => {
    CheckThisTravelIsActiveWithOutOrder(res.data.orderId ? res.data.orderId : res.data.id)

    let storeData = store.getState();
    let type = storeData.Table.filters.type

    if (type == res.data.type && type == "PickupDelivery") {
      Customer_get_Order_Info_Api_PD_Update(dispatch, res.data.orderId ? res.data.orderId : res.data.id)
    } else {

      Customer_get_Order_Info_Api_call(res.data.orderId ? res.data.orderId : res.data.id, (call) => {
        CheckSocketOrderState(call.status, (callx) => {
          if (!callx) {
            DeleteOrderFromLIST(call.orderId, false, {
              status: call.status,
              id: call.orderId,
              data: call,
            });
          } else {
            dispatch(
              UpdateStateOrderInList({
                status: call.status,
                id: call.id,
                data: call,
              })
            );
          }
        });
      })

    }
  });

  socket.on("customer:chat:newMessage", (res) => {

    CheckIncomeMessages(dispatch, res.data);

    // this.props.dispatch(ADDMESSAGETOCHATS({
    //   text:this.state.input,
    //   createdAt:new Date(),
    //   source:"customer",
    //   "uid" :uuid
    // }))
  });

  socket.on("customer:chat:new-message", (res) => {

    CheckIncomeMessages(dispatch, res.data);

    // this.props.dispatch(ADDMESSAGETOCHATS({
    //   text:this.state.input,
    //   createdAt:new Date(),
    //   source:"customer",
    //   "uid" :uuid
    // }))
  });

  // socket.on("customer:chat:seen-message", (res) => {

  //   CheckIncomeMessagesSeen(dispatch, res.data);
  // });

  socket.on("customer:chat:seen-message", (res) => {

    CheckIncomeMessagesSeen(dispatch, res.data);
  });

  return socket;
};
