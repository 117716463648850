import PrivitridesIGO from "../../../static/Privitrides_logo.png";
import Privitrides_banner from "../../../static/Privitrides_banner.png";
import FavIcon from "../../../static/PROJECTS/Privitrides.png";

export default function Privitrides() {
    
    return  global.config = {
        "project": "Privit",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Privit",
        "LogoType": PrivitridesIGO,
        "BaseApi": "https://rest.privitrides.com",
        "BaseSocket": "https://socket.privitrides.com",
        "BaseTrack": "https://admin.privitrides.com",
        "MapCenter": {
            "lat": "36.09991641189004",
            "lng": "-80.24420675144678"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },
        {
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "US"
        }],
        "FavIcon": "/Privitrides.png",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Privitrides_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Privitrides",
        registerEnabled: true,
        Secondarycolor: "#69B3B7",
        SecondaryDarkColor: "#4E9094",
        SecondaryLightColor: "#6EC1C6",
        SecondaryUltraLight: "#F0F7F8",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#69B3B7",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#173865",
        icon_light_end:"#235497",
        icon_dark_start:"#69B3B7",
        icon_dark_end:"#6EC1C6",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#DCF1F2",
        loginBackgroundColorEnd:"#BAEAED",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:PrivitridesIGO,
        apkUrl:null,
        iosUrl:null
} }