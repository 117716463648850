import LogoType from "../../../static/ClaraBiofuels.png";
import Banner from "../../../static/cp-resource@3x.png";

import FavIcon from "../../../static/PROJECTS/ClaraBiofuels_fav_icon.png";

export default function clarabiofuels() {
    
    return  global.config = {
        "project": "clarabiofuels",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Clara Biofuels Customer Panel",
        "LogoType": LogoType,
        "BaseApi": "https://rest.clarabiofuels.com",
        "BaseSocket": "https://socket.clarabiofuels.com",
        "BaseTrack": "https://panel.clarabiofuels.com",
        "MapCenter": {
            "lat": 14.634358724054849,
            "lng": -90.50800997393661
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }, {
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "DO"
        }],
        "FavIcon": "",
        "Country": "GT",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Clara Biofuels Customer Panel",
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:false,
        registerEnabled: true,
        Secondarycolor:"#FFD700",
        SecondaryDarkColor:"#F6D000",
        SecondaryLightColor:"#FFDF36",
        SecondaryUltraLight :"#FFFBE5",
        SecondaryTextColor:"#11293B",
        Secondaryonlight:"#11293B",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#1080FF",
        loginBackgroundColorEnd:"#58A7FF",
        LoginTextColor :"#FFFFFF",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}