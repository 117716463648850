import NOBODIIGO from "../../../static/landeber_logo.png";
import FavIcon from "../../../static/PROJECTS/landeber.png";
import Banner from "../../../static/Landeber Onro 1.png";

export default function landeber() {
    
    return  global.config = {
        "project": "Landebér",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Landebér",
        "LogoType": NOBODIIGO,
        "BaseApi": "https://rest.landeber.com",
        "BaseSocket": "https://socket.landeber.com",
        "BaseTrack": "https://admin.landeber.com",
        "MapCenter": {
            "lat": 51.5062092709252,
            "lng": -0.13112440635871198
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"landeber",
        registerEnabled: true,
        Secondarycolor: "#0E0937",
        SecondaryDarkColor: "#0A0626",
        SecondaryLightColor: "#190E6C",
        SecondaryUltraLight: "#ECEBEF",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#0E0937",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#B6994C",
        icon_light_end:"#D6B359",
        icon_dark_start:"#0E0937",
        icon_dark_end:"#190E6C",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#0b1036",
        loginBackgroundColorEnd:"#0b1036",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:NOBODIIGO,
        apkUrl:null,
        iosUrl:null
}}