import React, { useState } from "react";
import close from "../../../../static/ic-close.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { openCloseAboutUs, openCloseOrderHistory, OpenCloseSupport, SetSelectedSupportData } from "../../../../Redux/actions/actionNames";

class App extends React.Component {

  openOrderHistory=()=>{
    if(this.props.selectedSubject){
      this.props.dispatch(SetSelectedSupportData(null))
    }else{
      this.props.dispatch(OpenCloseSupport(false))
    }
  }

  render() {
    return (
      <div className="OrderListHeader">
        <img onClick={this.openOrderHistory} src={close} className="OrderListHeaderClose" />
        <span className="OrderListHeaderTitle">
          <Trans i18nKey={this.props.selectedSubject ? "SendRequest" : "Support"}>
          </Trans>
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedSubject : state.Support.selectedSubject
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
