import LogoType from "../../../static/Kwikdash.png";
import Banner from "../../../static/Kwikdash_banner.png";

import FavIcon from "../../../static/PROJECTS/Kwikdash_fav_icon.png";

export default function Kwikdash() {
    
    return  global.config = {
        "project": "Kwikdash",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Dashboard",
        "LogoType": LogoType,
        "BaseApi": "https://rest.kwikdash.co",
        "BaseSocket": "https://socket.kwikdash.co",
        "BaseTrack": "https://admin.kwikdash.co",
        "MapCenter": {
            "lat": 51.509528366866924,
            "lng": -0.12619703861819864
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Customer Dashboard",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Kwikdash",
        registerEnabled: true,
        Secondarycolor: "#000000",
        SecondaryDarkColor: "#2A2A2A",
        SecondaryLightColor: "#2B2B2B",
        SecondaryUltraLight: "#F5F5F5",
        SecondaryTextColor: "#F7BA00",
        Secondaryonlight: "#000000",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#F7BA00",
        icon_light_end:"#FFC822",
        icon_dark_start:"#000000",
        icon_dark_end:"#2B2B2B",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#F7BA00",
        loginBackgroundColorEnd:"#FFC822",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}