import TheTruckingFormeIGO from "../../../static/TheTruckingForme_logo.png";
import TheTruckingForme_banner from "../../../static/TheTruckingForme_banner.png";
import FavIcon from "../../../static/PROJECTS/TheTruckingForme.png";

export default function Thetruckingforme() {
    
    return  global.config = {
        "project": "TheTruckingForme",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "TheTruckingForme",
        "LogoType": TheTruckingFormeIGO,
        "BaseApi": "https://rest.thetruckingforme.com",
        "BaseSocket": "https://socket.thetruckingforme.com",
        "BaseTrack": "https://admin.thetruckingforme.com",
        "MapCenter": {
            "lat": "28.536081365425943",
            "lng": "-81.38592386557374"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },
        {
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "US"
        }, {
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }, {
            "lang": "ro",
            "direction": "Ltr",
            "i18Lang": "Romanian",
            "cssFlag": "romanianflag",
            "country": "RO"
        },{
            "lang": "nl",
            "direction": "Ltr",
            "i18Lang": "Dutch",
            "cssFlag": "dutchflag",
            "country": "NL"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        },{
            "lang": "pt",
            "direction": "Ltr",
            "i18Lang": "Portuguese",
            "cssFlag": "Portugueseflag",
            "country": "PT"
    }],
        "FavIcon": "/TheTruckingForme.png",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": TheTruckingForme_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"TheTruckingForme",
        registerEnabled: true,
        Secondarycolor: "#FF6BB9",
        SecondaryDarkColor: "#EE007D",
        SecondaryLightColor: "#FF80C3",
        SecondaryUltraLight: "#FFF3F9",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#FF6BB9",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#EBD8B4",
        icon_light_end:"#EDE3D1",
        icon_dark_start:"#FF6BB9",
        icon_dark_end:"#FF80C3",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#F2EBDE",
        loginBackgroundColorEnd:"#EDE3D1",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:TheTruckingFormeIGO,
        apkUrl:null,
        iosUrl:null
} }