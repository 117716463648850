import LogoType from "../../../static/latreqi.png";
import Banner from "../../../static/latreqi_banner.png";

import FavIcon from "../../../static/PROJECTS/latreqi_fav_icon.png";

export default function Latreqi() {

    return global.config = {
        "project": "3latreqi",
        "ShowPowerOnro": false,
        "flag": "arabicflag",
        "ProjectName": "3latreqi",
        "LogoType": LogoType,
        "BaseApi": "https://rest.3latreqi.com",
        "BaseSocket": "https://socket.3latreqi.com",
        "BaseTrack": "https://panel.3latreqi.com",
        "MapCenter": {
            "lat": 20.019661017984113,
            "lng": 41.46562106368876
        },
        "Lang": "ar",
        "Direction": "Rtl",
        "AllLanguages": [
            {
                "lang": "ar",
                "direction": "Rtl",
                "i18Lang": "Arabic",
                "cssFlag": "arabicflag",
                "country": "SA"
            },
            {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
                "cssFlag": "englishflag",
                "country": "US"
            }],
        "FavIcon": "",
        "Country": "SA",
        "AppleLoginCustomerId": "com.3latreqi.customer",
        "AppleLoginredirectURI": "https://app.3latreqi.com",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "com.3latreqi.customer",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: "latreqi",
        customColor: "Latreqi",
        registerEnabled: true,
        Secondarycolor: "#285B5E",
        SecondaryDarkColor: "#1E4547",
        SecondaryLightColor: "#2B7B80",
        SecondaryUltraLight: "#E9EFEF",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#285B5E",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FBBE5D",
        icon_light_end:"#FFCD7D",
        icon_dark_start:"#285B5E",
        icon_dark_end:"#2B7B80",
        enabledCustomColor: true,
        mapType: "osm", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText:true,
        loginBackgroundColorStart:"#242424",
        loginBackgroundColorEnd:"#AFECEF",
        LoginTextColor :"#B9F0BF",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
    }
}