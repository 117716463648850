import React, { useState } from "react";
import NoOrder from "./NoOrder";
import OrderListMaper from "./OrderListMaper";
import Header from "./header";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_LeftRight } from "../../../helper/UserData/userdate";
import Loading from "./loading";
import Error from "./error";
import { Api_Get_MessageBox_AGW, Api_Get_Transactions_AGW } from "../../../helper/api/ApiGateway";
import { isEmpty } from "lodash";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canLoadmore:true
    };
    this.loadMore = this.loadMore.bind(this)
  }

  // page: data.page + 1, perpage: 10

  loadMore = () => {
    Api_Get_MessageBox_AGW(
      this.props.dispatch,
      {
        page: this.props.page + 1,perpage: 50 ,deviceType:"web"
      },
      (err) => {
        if(err){
          this.setState({
            canLoadmore:false
          })
        }
      }
    );
  };

  componentDidMount() {

    if (isEmpty(this.props.data) && !this.props.ListLoading && this.state.canLoadmore) {
      this.loadMore();
    }
  }

  render() {
    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.open}
        mountOnEnter
        unmountOnExit
      >
        <div className="OrderHistoryContainer" style={{backgroundColor:"#fbfdff"}}>
          <Header />
          <NoOrder />
          <Error />
          <Loading />

          {!this.props.ListError && this.props.open && this.props.data.length != 0 ? (
          <OrderListMaper loadMore={this.loadMore} />
        ) : null}


        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.MessageBox.openDialoge,
  data: state.MessageBox.data,
  ListLoading: state.MessageBox.ListLoading,
  ListError: state.MessageBox.ListError,
  page: state.MessageBox.page,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
