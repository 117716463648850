import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { get_LeftRight } from "../../../../helper/UserData/userdate";
import close from "../../../../static/close.svg";
import { Setfeedback } from "../../../../Redux/actions/actionNames";
import { getBaseTrackByUrl } from "../../../../helper/module";
import star_empty from "../../../../static/customer-ic-star-empty.svg";
import star_filled from "../../../../static/mutual-ic-star-filled.svg";
import { Api_get_Order_Info_AGW, Api_Set_Feedback_AGW, Api_Set_hasFeedbackPageSeen_AGW } from "../../../../helper/api/ApiGateway";
import comment from "../../../../static/client-ic-comment.svg";

var _ = require("lodash");

class App extends React.Component {
    Setfeedback = () => {
    this.props.dispatch(
      Setfeedback({
        open: !this.props.OpenDialoge,
      })
    );
  };

  SetfeedbackRateText = (e) => {
    this.props.dispatch(
      Setfeedback({
        rateText : e.target.value 
      })
    );
  };

  


  handlerate =(rate)=>{
    this.props.dispatch(
      Setfeedback({
        rate
      })
    );
  }

  save =()=>{
    Api_Set_Feedback_AGW(this.props.dispatch, {
      rateText: this.props.rateText,
      rate: this.props.rate,
      orderId:this.props.id
    }, ()=>{
      this.props.dispatch(
        Setfeedback({
          open : false
        })
      );

      Api_Set_hasFeedbackPageSeen_AGW(this.props.dispatch , {type:this.props.type , orderId:this.props.id}, ()=>{
              Api_get_Order_Info_AGW(this.props.dispatch , this.props.id)
      })

    })
  }
  render() {
    if (!_.isEmpty(this.props.driver)) {
          return (
            <Slide
              direction={get_LeftRight()}
              in={this.props.OpenDialoge}
              mountOnEnter
              unmountOnExit
            >
              <div className="NewOrder" >
                <nRHeader style={{boxShadow:"none"}}>
                  <img
                    onClick={this.Setfeedback}
                    className="close"
                    src={close}
                  />
                  <span className="title">
                    <Trans i18nKey={"Feedback"}> </Trans>
                  </span>
                </nRHeader>

                <img
                  src={ this.props.driver.avatarPhoto}
                  className="feedbackdriveravatar"
                />
                <span className="feedbackdrivername">
                  {this.props.driver.name}
                </span>
               {
                 !this.props.Data.hasFeedbackPageSeen  ?
                 <React.Fragment>
                    <div className="feedbackstarcontainer">
                  <img onClick={()=>{this.handlerate(1)}} src={this.props.rate > 0 ? star_filled : star_empty} />
                  <img onClick={()=>{this.handlerate(2)}} src={this.props.rate > 1 ? star_filled : star_empty} />
                  <img onClick={()=>{this.handlerate(3)}} src={this.props.rate > 2 ? star_filled : star_empty} />
                  <img onClick={()=>{this.handlerate(4)}} src={this.props.rate > 3 ? star_filled : star_empty} />
                  <img onClick={()=>{this.handlerate(5)}} src={this.props.rate > 4 ? star_filled : star_empty} />
                </div>

                <div className="feedbackinputbuttoncontainer">
                     <span className="title">
                        <Trans i18nKey={"Comment"}> </Trans>
                      </span>
                      <input placeholder={this.props.t("Exfeedbackinput")} onChange={this.SetfeedbackRateText}/>
                      <span className="save" onClick={this.save}>
                        <Trans i18nKey={"Save"}> </Trans>
                      </span>
                </div>  
                 </React.Fragment> :
                  <React.Fragment>
                    <div className="yourRateContainer">
                          <img className="icon" src={star_filled} />
                          <span className="starCount"> {`${this.props.Data.customerRate} ${this.props.t("star")}`} </span> 
                          <span className="starCountYourrate">  {`${this.props.t("Yourrate")}`} </span>
                    </div>  

                    <div className="yourRateContainer yourCommentContainer">
                          <img className="icon" src={comment} />
                          <span className="starCount"> {`${this.props.Data.customerRateText ?( this.props.Data.customerRateText.length > 0 ? this.props.Data.customerRateText : "----") : "----"} `} </span> 
                          <span className="starCountYourrate">  {`${this.props.t("Comment")}`} </span>
                    </div>  

              </React.Fragment>
               }
                
              </div>
            </Slide>
          );
    }else {
        return null;
      }

  
  }
}

const mapStateToProps = (state) => ({
  id: state.ViewTravel.ID,
  OpenDialoge: state.ViewTravel.feedback.open,
  driver: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.driver
    ? state.ViewTravel.pureData.driver
    : null
  : null,
  rate:state.ViewTravel.feedback.rate,
  rateText:state.ViewTravel.feedback.rateText,
  type: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
  : null,
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
