import * as actionTypes from "../actions/actionTypes";
import _ from "lodash";


const init = {
  openAddressBookInMenu: false,
  openEditAddressBookInMenu: false,
  openAddAddressBookInMenu: false,
  openAddressBookInNewOrder: false,
  sourceOfAddressBookInNewOrder: null,
  openNewAddressBookInNewOrder: false,
  selectedAB : false ,
  sourceOfNewAddressBookInNewOrder: null,
  NewAddressBookInNewOrderDetails: {
    title: "",
    code: "",
    address: "",
    adresss_loc: null,
    block: "",
    floor: "",
    unit: "",
    FullName: "",
    PhoneNumber: "",
    description:"",
    email:""
  },
  EditAddressBookInMenuDetails: {
    title: "",
    id: 0,
    address: "",
    adresss_loc: null,
    block: "",
    floor: "",
    unit: "",
    FullName: "",
    PhoneNumber: "",
  },
  list: [],
  listLoading: false ,
  listError: false,
  perpage:20,
  page:1,
  searchRegex:"",
  defaultPickup: {},
};
export const Addressbook = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setopenAddressBookInNewOrder:
      return {
        ...state,
        openAddressBookInNewOrder: action.state,
        sourceOfAddressBookInNewOrder: {
          type: action.Stype,
          id: action.id,
          i18: action.i18,
        },
      };

    case actionTypes.setsourceOfAddressBookInNewOrder:
      return {
        ...state,
        sourceOfAddressBookInNewOrder: action.state,
      };

    case actionTypes.openNewAddressBookInNewOrder:
      return {
        ...state,
        openNewAddressBookInNewOrder: action.state,
        sourceOfNewAddressBookInNewOrder: action.data || null,
      };

    case actionTypes.NewAddressBookInNewOrderDetails:
      return {
        ...state,
        NewAddressBookInNewOrderDetails: {
          ...state.NewAddressBookInNewOrderDetails,
          ...action.state
        },
      };

    case actionTypes.OnChangeNewAddressBookInNewOrderDetails:
      return {
        ...state,
        NewAddressBookInNewOrderDetails: {
          ...state.NewAddressBookInNewOrderDetails,
          [action.state.key]: action.state.value,
        },
      };

    case actionTypes.openAddAddressBookInMenu:
      return {
        ...state,
        openAddAddressBookInMenu: action.state,

        NewAddressBookInNewOrderDetails:action.state  ? state.NewAddressBookInNewOrderDetails : init.NewAddressBookInNewOrderDetails
      };

      case actionTypes.openEditAddressBookInMenu:
        return {
          ...state,
          openEditAddressBookInMenu: action.state,
          };
  

      

    case actionTypes.openAddressBookInMenu:
      return {
        ...state,
        openAddressBookInMenu: action.state,
        NewAddressBookInNewOrderDetails : init.NewAddressBookInNewOrderDetails
      };

      case actionTypes.SetAddressBookListData:
        return {
          ...state,
          ...action.state,
          list:( action.state.hasOwnProperty('update') && action.state.update ) ? [...state.list , ...action.state.list] : action.state.list || state.list
        };

        case actionTypes.SetAddressBookSearchRegex:
          return {
            ...state,
            searchRegex:action.state
          };
  


          case actionTypes.removeAddressBookFromList:
            return {
              ...state,
              list : state.list.filter((address)=>{
                return address.id != action.state
              })
            };
      
          
            case actionTypes.removeDefaultAddressBookFromList:
              return {
                ...state,
                list : state.list.map((address)=>{
                  return address.id != action.state ? address :{...address ,isDefaultPickupAddress:false}
                })
              };
        
          
              
          case actionTypes.setDefaultAddressBookFromList:
            return {
              ...state,
              list : state.list.map((address)=>{
                return address.id != action.state ? {...address ,isDefaultPickupAddress:false} :{...address ,isDefaultPickupAddress:true}
              })
            };
      
                        
            case actionTypes.setDetailsEdit:
              return {
                ...state,
                EditAddressBookInMenuDetails :{
                  ...state.EditAddressBookInMenuDetails  ,
                  ...action.state
                }
              };
        

                                      
            case actionTypes.editAddressbookInList:
              return {
                ...state,
                list : state.list.map((item)=>{
                  return item.id == action.state.id ? {...item , ...action.state.pure} : item
                })
              };

              case actionTypes.resetNewAddressBookInNewOrderDetails:
                return {
                  ...state,
                  NewAddressBookInNewOrderDetails : init.NewAddressBookInNewOrderDetails
                };
  
                case actionTypes.setSelectedAB:

                  return {
                    ...state,
                    selectedAB : action.state
                  };
             
  
    default:
      return state;
  }
};
