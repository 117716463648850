import { useSelector } from "react-redux";
import { Table_setData, Table_setStatus } from "../../../../Redux/actions/actionNames";
import { store } from "../../../../Redux/store";
import momentJalaali from "moment-jalaali";
import { httpCustomer } from "../../../../service/api/axios";
import { get_Id, get_accessToken, get_lang } from "../../../../helper/UserData/userdate";
import { RefreshToken } from "../../../../helper/api";
import { Warning_NOi18 } from "../../../../helper/Notification/sendNotification";
const _ = require("lodash");

export const getTableOrderList = async (prev , next ) => {
  // let lastElement = _.last(Listdata);
    let storeData = store.getState();
    const table = storeData.Table.tableInit

    table?.toggleAllRowsSelected(false)


    store.dispatch(Table_setStatus({key:"isLoading" , value:true}))

// if(!storeData.Table.list.orders){
//     store.dispatch(Table_setStatus({key:"isLoading" , value:true}))
// }
try {
  const data = JSON.parse(JSON.stringify({
    after:next ? storeData.Table.list.endCursor : null,
    before :prev ? storeData.Table.list.startCursor : null,
    "size": storeData.Table.perPage,
    customerId:get_Id(),
    // "status[]": "Pending",
    // "dueDate[start]": "1688224655137",
    // "dueDate[end]": "1706714255137",
    ...storeData.Table.filters,
    "type" :"PickupDelivery",
    "columns": ['customerId' , 'pickup' , 'dropoff' , 'dropoffs' , 'delivery' , 'isScheduled' , 'currentCandidateDrivers' , 'driverAssignmentModel' , 'driverId' , 'delayAt' , 'baseETA' , 'realETA' , 'isPinned' , 'isDelayed' , 'isPossibleForDelay' , 'candidateDriversCount' , 'dispatchAt' , 'isDispatching' , 'vehicleType' , 'isUrgent' , 'service' , 'routeName' , 'paymentMethod' , 'paymentSide' , 'paymentProvider' , 'codAmount' , 'note' , 'referenceId' , 'stage' , 'customer' , 'zone' , 'price' , 'trackOrder' , 'customerType' , 'estimatedDistanceInMeters' , 'estimatedDuration' , 'customerReceiptUrl' , 'source' , 'statusUpdatedAt' , 'shipmentLabelUrl']
  }));



    const response = await httpCustomer.request({
      method: 'get',
      url: "/api/v1/customer/order/cursor-based",
      params: gtelteSetter(removeNullFields(data)),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: `Bearer ${get_accessToken()}`,
      }
    });
    store.dispatch(Table_setData(response.data.data))
    store.dispatch(Table_setStatus({key:"isLoading" , value:false}))
    store.dispatch(Table_setStatus({ key: "hasError", value: false }))

    // store.dispatch(Table_setStatus({key:"page" , value:(next || prev) ? (next ? storeData.Table.page + 1 : storeData.Table.page - 1) : 1}))

} catch (error) {
  store.dispatch(Table_setStatus({key:"isLoading" , value:false}))
  store.dispatch(Table_setStatus({key:"hasError" , value:true}))
  if (error.response) {
    if (error.response.status === 401 && error.response.data.code == "-9") {
      RefreshToken(() => {
        getTableOrderList(prev , next );
      });
      //token exchange
    } else {
      Warning_NOi18(
        error.response.data.message,
        "networkError",
        "close",
        () => {
          store.dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  }
}




};




function removeNullFields(obj) {
  for (var key in obj) {
      if (obj[key] === null) {
          delete obj[key];
      } else if (typeof obj[key] === 'object') {
          removeNullFields(obj[key]);
      }
  }
  return obj;
}

function gtelteSetter(obj) {
  for (var key in obj) {
      if (["gte","equal"].indexOf(key) > -1) {
        if(_.isObject(obj[key])){
          obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year +" 00:01", "DD/MM/YYYY HH:mm").format("x")
        }
      } else if (["lte"].indexOf(key) > -1) {
        if(_.isObject(obj[key])){
          obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year +" 23:59", "DD/MM/YYYY HH:mm").format("x")
        }
      } else if (typeof obj[key] === 'object') {
        gtelteSetter(obj[key]);
      }
  }
  return obj;
}
