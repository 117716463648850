import React, { useState } from "react";
import onroLogo from "./../../static/logotype-on-light-customer.svg";
import poweredbyonro from "./../../static/poweredbyonro.svg";
import banerback from "./../../static/delivery-5.jpg";
import { withTranslation, Trans } from "react-i18next";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {
  get_cssFlag,
  get_lang,
  set_direction,
  set_lang,
  set_cssFlag,
  set_country,
} from "../../helper/UserData/userdate";
import { getAllLanguages, getLogoTypeByUrl, LoginBannerWithDomain, shouldShowPOWER } from "../../helper/module";
import { connect } from "react-redux";
import { CodeVerification, ResendCode } from "../../Redux/actions/actions";
import loadDynamicScript from "../../helper/module/loadDynamicScript";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Captcha: null,
      anchorEl: null,
      timer: 0,
      verificationcode: "",
      ts: null,
      CaptchaResend: null,
      tsResend: null
    };
    this.recaptchaRef = React.createRef();
    this.CaptchaOnChange = this.CaptchaOnChange.bind(this);
    this.interval = null;
  }



  CaptchaOnChange(value) {
    this.setState({
      Captcha: value
    })
  }



  verificationcodeOnChange = (e) => {
    this.setState(
      {
        verificationcode: e.target.value,
      },
      () => {
        if (this.state.verificationcode.length > 3) {
          this.props.dispatch(
            CodeVerification(
              this.props.dispatch,
              {
                phone: this.props.phone,
                verificationCode: this.state.verificationcode,
              },{
                "x-captcha-token" : this.state.Captcha,
                "x-request-ts" : this.state.ts
              },
              (res) => {
                this.generateCode()
               }
            )
          );
        }
      }
    );
  };


  startCountDown = () => {
    this.interval = setInterval(() => {
      if (this.state.timer == 0) {
        this.stopCountdown();
      } else {
        this.setState({
          timer: this.state.timer - 1,
        });
      }
    }, 1000);
  };

  stopCountdown = () => {
    clearInterval(this.interval);
  };

  getMinSec = () => {
    return `${this.str_pad_left(
      Math.floor(this.state.timer / 60),
      "0",
      2
    )}:${this.str_pad_left(this.state.timer % 60, "0", 2)}`;
  };

  str_pad_left = (string, pad, length) => {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  changeLanguage = (lang) => {
    this.props.dispatch({
      type: "changeDirection",
      direction: lang.direction,
      language: lang.lang,
    });
    set_lang(lang.lang);
    set_direction(lang.direction);
    set_cssFlag(lang.cssFlag);
    set_country(lang.country);
    this.props.i18n.changeLanguage(lang.lang);
    this.handleClose();
  };

  componentDidMount() {

    this.startCountDown();

    this.generateCode()

    this.generateCodeResend()
  }

  generateCode = () => {
    this.setState({
      Captcha: null,
      ts: null
    }, () => {
      loadDynamicScript(
        `https://www.google.com/recaptcha/enterprise.js?render=${global.config.googleReCaptchaSiteKey}`,
        "recaptchaEnterprise",
        async () => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise
              .execute(global.config.googleReCaptchaSiteKey, {
                action: `custom_customer_code_verification_${ts}`
              })
              .then((token) => {
                this.setState({
                  Captcha: token,
                  ts: ts
                })
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      );
    })
  }

  generateCodeResend = () => {
    this.setState({
      CaptchaResend: null,
      tsResend: null
    }, () => {
      loadDynamicScript(
        `https://www.google.com/recaptcha/enterprise.js?render=${global.config.googleReCaptchaSiteKey}`,
        "recaptchaEnterprise",
        async () => {
          const ts = Number(String(Number(Date.now())))

          window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise
              .execute(global.config.googleReCaptchaSiteKey, {
                action: `custom_customer_resend_verification_code_${ts}`
              })
              .then((token) => {
                this.setState({
                  CaptchaResend: token,
                  tsResend: ts
                })
              })
              .catch((error) => {
                console.log(error);
              });
          });
        }
      );
    })
  }

  resendCode = () => {
    if (this.state.timer == 0) {
      this.props.dispatch(ResendCode(this.props.dispatch, { phone: this.props.phone }, (res) => {
        setTimeout(() => {
          this.generateCodeResend()
        }, 200);
      }, { Captcha: this.state.CaptchaResend, ts: this.state.tsResend }))
    }
  };

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    let VCVCtitletext = this.props
      .t("VerificationcodeText")
      .replace("xxx", this.props.phone);

    let resendCode =
      this.state.timer == 0
        ? this.props.t("resendCode")
        : this.props.t("resendCodeWithTime").replace("xxx", this.getMinSec);

    return (
      <div className="VC_CardContainer">
        <span className="VCtitle">
          {" "}
          <Trans i18nKey="Verificationcode"> </Trans>{" "}
        </span>
        <span className="VCVCtitletext"> {VCVCtitletext} </span>

        <div className="firstnamelastnameContainer emailcontainer rt32px">
          <p className="LP_PhoneNumberLabel VCLABELINPUT">
            <Trans i18nKey={"Verificationcode"}> </Trans>{" "}
          </p>
          <div className={`PhoneNumberContainer `}>
            <input
              autoFocus
              placeholder={this.props.t("VerificationcodePlaceHolder")}
              className="emailinputregister"
              value={this.state.verificationcode}
              onChange={this.verificationcodeOnChange}
            />
          </div>
        </div>
        <div className="resendCodeContainer">
          <span
            onClick={this.resendCode}
            className={this.state.timer == 0 && "resendcodeActive"}
          >
            {" "}
            {resendCode}
          </span>
        </div>
      </div>
      // <div className="VerificationCodeContainer">
      //   <div className="VerificationCodeInner">
      //     <div className="VClogonamecontainer">
      //       <img src={global.config.LogoType} className="LoginlogoType" />
      //       <span className="customerPanel">
      //         {" "}
      //         <Trans i18nKey={"Cutomerpanel"}> </Trans>{" "}
      //       </span>
      //     </div>
      //     <div className="VClanguageContainer">
      //       <span
      //         onClick={this.handleClick}
      //         className={`headerLanguagelabel ${get_cssFlag()}`}
      //       >
      //         {" "}
      //         <Trans i18nKey={get_lang()}> </Trans>{" "}
      //       </span>
      //       <Popover
      //         id={id}
      //         open={open}
      //         anchorEl={this.state.anchorEl}
      //         onClose={this.handleClose}
      //         anchorOrigin={{
      //           vertical: "bottom",
      //           horizontal: "center",
      //         }}
      //         transformOrigin={{
      //           vertical: "top",
      //           horizontal: "center",
      //         }}
      //       >
      //         <List>
      //           {global.config.AllLanguages.map((lang) => {
      //             return (
      //               <ListItem
      //                 onClick={() => {
      //                   this.changeLanguage(lang);
      //                 }}
      //               >
      //                 <span
      //                   className={`headerLanguagelabelItem ${lang.cssFlag}`}
      //                 >
      //                   {" "}
      //                   <Trans i18nKey={lang.lang}> </Trans>{" "}
      //                 </span>
      //               </ListItem>
      //             );
      //           })}
      //         </List>
      //       </Popover>
      //     </div>

      //     <img className="VCBackBaner" src={global.config.LoginBannerWithDomain} />
      //     <div className="VC_CardContainer">
      //       <span className="VCtitle">
      //         {" "}
      //         <Trans i18nKey="Verificationcode"> </Trans>{" "}
      //       </span>
      //       <span className="VCVCtitletext"> {VCVCtitletext} </span>

      //       <div className="firstnamelastnameContainer emailcontainer rt32px">
      //         <p className="LP_PhoneNumberLabel VCLABELINPUT">
      //           <Trans i18nKey={"Verificationcode"}> </Trans>{" "}
      //         </p>
      //         <div className={`PhoneNumberContainer `}>
      //           <input
      //             autoFocus
      //             placeholder={this.props.t("VerificationcodePlaceHolder")}
      //             className="emailinputregister"
      //             value={this.state.verificationcode}
      //             onChange={this.verificationcodeOnChange}
      //           />
      //         </div>
      //       </div>
      //       <div className="resendCodeContainer">
      //         <span
      //           onClick={this.resendCode}
      //           className={this.state.timer == 0 && "resendcodeActive"}
      //         >
      //           {" "}
      //           {resendCode}
      //         </span>
      //       </div>
      //     </div>
      //   </div>

      //   <div className="VCFooter">
      //     {global.config.ShowPowerOnro && <img src={poweredbyonro} />}
      //   </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  phone: state.LoginRegister.VerificationCode.phone,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
