import {
  AppleTokenInfoApi,
  Bussiness_Edit_AccountInformation,
  Bussiness_Get_AboutUs,
  Bussiness_Get_AccountInformation,
  Bussiness_Get_OrderHistoryList_Api,
  Bussiness_Get_OrderList_Api,
  Bussiness_Get_Transactions,
  Customer_Edit_AccountInformation,
  Customer_Get_AboutUs,
  Customer_Get_AccountInformation,
  Customer_Get_Services,
  Customer_Get_OrderHistoryList_Api,
  Customer_Get_OrderList_Api,
  Customer_Get_Transactions,
  CodeVerificationApi,
  FacebookTokenInfoApi,
  GoogleTokenInfoApi,
  LoginBusinessApi,
  LoginByAppleApi,
  LoginByFacebookApi,
  LoginByGooglepi,
  LoginPureApi,
  RegisterAppleApi,
  RegisterFacebookApi,
  RegisterGoogleApi,
  RegisterPureApi,
  ResendCodeApi,
  Customer_SearchAutoComplete_Api,
  Customer_PlaceIdDetails_Api,
  Customer_GetVehicleTypeByService_Api,
  Customer_ondemand_promotion_verify_Api,
  Customer_pickupDelivery_promotion_verify_Api,
  Customer_map_geocoding_reverse_Api,
  Customer_calculateOrderCost_Ondemand_Api,
  Customer_calculateOrderCost_Reserved_Api,
  Customer_get_balance_Api,
  Customer_Create_Ondemand_Order_Api,
  Customer_Get_Web_Setting_Api,
  Customer_New_Payment_Order_Api,
  Customer_pickupDelivery_promotion_set_Api,
  Customer_ondemand_promotion_set_Api,
  Bussiness_Get_Services,
  Bussiness_GetVehicleTypeByService_Api,
  Bussiness_calculateOrderCost_Ondemand_Api,
  Bussiness_calculateOrderCost_PickupDelivery_Api,
  Bussiness_Create_Ondemand_Order_Api,
  Bussiness_Create_PickupDelivery_Order_Api,
  Bussiness_Get_Web_Setting_Api,
  Bussiness_map_geocoding_reverse_Api,
  Bussiness_PlaceIdDetails_Api,
  Bussiness_SearchAutoComplete_Api ,
  Bussiness_New_Payment_Order_Api,
  Bussiness_get_balance_Api,
  Bussiness_pickupDelivery_promotion_set_Api,
  Bussiness_ondemand_promotion_set_Api,
  Bussiness_ondemand_promotion_verify_Api,
  Bussiness_pickupDelivery_promotion_verify_Api,
  Customer_get_Order_Info_Api,
  Bussiness_get_Order_Info_Api,
  Bussiness_upload_order_service_phooto_Api,
  Customer_Ondemand_upload_order_service_phooto_Api,
  Customer_Schedule_upload_order_service_phooto_Api,
  Bussiness_change_payment_method_Api,
  Customer_Ondemand_change_payment_method_Api,
  Customer_Schedule_change_payment_method_Api,
  Bussiness_Set_Feedback_Api,
  Customer_Set_Feedback_Api,
  Customer_Schedule_Set_hasFeedbackPageSeen_Api,
  Customer_Ondemand_Set_hasFeedbackPageSeen_Api, 
  Bussiness_hasFeedbackPageSeen_Api,
  Customer_get_order_trackDriver_Api,
  Bussiness_get_order_trackDriver_Api,
  Customer_Ondemand_Cancel_Order_Api,
  Customer_pickupDelivery_Cancel_Order_Api,
  Bussiness_pickupDelivery_Cancel_Order_Api,
  Bussiness_Ondemand_Cancel_Order_Api,
  Customer_Ondemand_get_estimated_distance_Api ,
  Customer_Schedule_get_estimated_distance_Api,
  Business_Ondemand_get_estimated_distance_Api,
  Business_Schedule_get_estimated_distance_Api,
  Business_get_quick_message_Api,
  Customer_get_quick_message_Api,
  Customer_get_chats_Api,
  Business_get_chats_Api,
  Business_Send_Message_Api ,
  Customer_seen_message_Api ,
  Business_seen_message_Api,
  Customer_Send_Message_Api,
  Business_Get_Addressbook_list_Api,
  Customer_Get_Addressbook_list_Api,
  Customer_Add_Addressbook_Api,
  Business_Add_Addressbook_Api,
  Business_Delete_Addressbook_Api,
  Customer_Delete_Addressbook_Api,
  Business_Edit_Addressbook_Api,
  Customer_Edit_Addressbook_Api,
  Customer_Create_PickupDelivery_Order_Api ,
  Customer_calculateOrderCost_PickupDelivery_Api,
  Bussiness_PickupDelivery_change_payment_method_Api,
  Customer_Get_Order_Events_Api,
  Bussiness_Get_Order_Events_Api,
  Customer_GET_ETA_Api,
  Bussiness_GET_ETA_Api,
  Customer_add_Payment_Method_Api,
  Bussiness_add_Payment_Method_Api,
  Customer_get_Payment_Methods_Api ,
  Bussiness_get_Payment_Methods_Api,
  Customer_Delete_Payment_Method_Api,
  Bussiness_Delete_Payment_Method_Api,
  Bussiness_Get_Support_Message_Api,
  Customer_Get_Support_Message_Api,
  Customer_Reply_Support_Message_Api,
  Bussiness_Reply_Support_Message_Api,
  Bussiness_Get_MessageBox_Api,
  Customer_Get_MessageBox_Api,
  Customer_Set_Default_Addressbook_Api,
  VehicletypeForMonitoring,
  ServicesVehicletype
} from "../../helper/api/index";
import { UnSucceessFul_i18 } from "../../helper/Notification/sendNotification";
import { SetOrderInfoError, Set_SearchAutoCompleteLoading } from "./actionNames";

export function GoogleTokenInfo(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await GoogleTokenInfoApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function FacebookTokenInfo(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await FacebookTokenInfoApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function AppleTokenInfo(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await AppleTokenInfoApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function RegisterPure(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await RegisterPureApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function RegisterByGoogle(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await RegisterGoogleApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function RegisterByFacebook(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await RegisterFacebookApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}
export function RegisterByApple(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await RegisterAppleApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function LoginPure(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await LoginPureApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function LoginByGoogle(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await LoginByGooglepi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function LoginByFacebook(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await LoginByFacebookApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function LoginByApple(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await LoginByAppleApi(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function LoginBusiness(dispatch, data,header, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await LoginBusinessApi(dispatch, data,header, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function CodeVerification(dispatch, data,header, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await CodeVerificationApi(dispatch, data,header, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function ResendCode(dispatch, data, call , header) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await ResendCodeApi(dispatch, data, call, header);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Get_OrderList_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_OrderList_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Get_OrderList_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_OrderList_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Get_OrderHistoryList_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_OrderHistoryList_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Get_OrderHistoryList_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_OrderHistoryList_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Get_AboutUs_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_AboutUs(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_Get_AboutUs_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_AboutUs(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Bussiness_Get_Transactions_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_Transactions(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_Get_Transactions_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_Transactions(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_Get_AccountInformation_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_AccountInformation(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Bussiness_Get_AccountInformation_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_AccountInformation(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Edit_AccountInformation_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Edit_AccountInformation(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Bussiness_Edit_AccountInformation_Action(dispatch, data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Edit_AccountInformation(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}





export function Customer_Get_Services_Action(dispatch , data) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_Services(dispatch , data);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Bussiness_Get_Services_Action(dispatch, data) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_Services(dispatch, data);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_SearchAutoComplete_Action(dispatch , data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_SearchAutoComplete_Api(dispatch, data, call);
    } else {
      dispatch(Set_SearchAutoCompleteLoading(false));
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_SearchAutoComplete_Action(dispatch , data, call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_SearchAutoComplete_Api(dispatch, data, call);
    } else {
      dispatch(Set_SearchAutoCompleteLoading(false));
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_PlaceIdDetails_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_PlaceIdDetails_Api(dispatch, data, call);
    } else {
      dispatch(Set_SearchAutoCompleteLoading(false));
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_PlaceIdDetails_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_PlaceIdDetails_Api(dispatch, data, call);
    } else {
      dispatch(Set_SearchAutoCompleteLoading(false));
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_GetVehicleTypeByService_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_GetVehicleTypeByService_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}
export function Bussiness_GetVehicleTypeByService_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_GetVehicleTypeByService_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_ondemand_promotion_verify_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_ondemand_promotion_verify_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_ondemand_promotion_verify_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_ondemand_promotion_verify_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_pickupDelivery_promotion_verify_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_pickupDelivery_promotion_verify_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_pickupDelivery_promotion_verify_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_pickupDelivery_promotion_verify_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_ondemand_promotion_set_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_ondemand_promotion_set_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_ondemand_promotion_set_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_ondemand_promotion_set_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_pickupDelivery_promotion_set_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_pickupDelivery_promotion_set_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Bussiness_pickupDelivery_promotion_set_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_pickupDelivery_promotion_set_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}





export function Customer_map_geocoding_reverse_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_map_geocoding_reverse_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Bussiness_map_geocoding_reverse_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_map_geocoding_reverse_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Customer_calculateOrderCost_Ondemand_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_calculateOrderCost_Ondemand_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Bussiness_calculateOrderCost_Ondemand_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_calculateOrderCost_Ondemand_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_calculateOrderCost_PickupDelivery_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_calculateOrderCost_PickupDelivery_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_calculateOrderCost_PickupDelivery_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_calculateOrderCost_PickupDelivery_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_get_balance_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_get_balance_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Bussiness_get_balance_Action(dispatch , data , call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_get_balance_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}





export function Customer_Create_Ondemand_Order_Action(dispatch , data , extraData,call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Create_Ondemand_Order_Api(dispatch, data, extraData,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Create_Ondemand_Order_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Create_Ondemand_Order_Api(dispatch, data, extraData);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Create_PickupDelivery_Order_Action(dispatch , data , extraData,call) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Create_PickupDelivery_Order_Api(dispatch, data, extraData,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Create_PickupDelivery_Order_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Create_PickupDelivery_Order_Api(dispatch, data, extraData);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Get_Web_Setting_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_Web_Setting_Api(dispatch, data, extraData);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Bussiness_Get_Web_Setting_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_Web_Setting_Api(dispatch, data, extraData);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_New_Payment_Order_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_New_Payment_Order_Api(dispatch, data, extraData);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Bussiness_New_Payment_Order_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_New_Payment_Order_Api(dispatch, data, extraData);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}






export function Customer_get_Order_Info_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_get_Order_Info_Api(dispatch, data, extraData);
    } else {
      dispatch(SetOrderInfoError(true));

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}
export function Bussiness_get_Order_Info_Action(dispatch , data , extraData) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_get_Order_Info_Api(dispatch, data, extraData);
    } else {
      dispatch(SetOrderInfoError(true));

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Ondemand_upload_order_service_phooto_Action(dispatch, data, orderId , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Ondemand_upload_order_service_phooto_Api(dispatch, data, orderId , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_Schedule_upload_order_service_phooto_Action(dispatch, data, orderId , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Schedule_upload_order_service_phooto_Api(dispatch, data, orderId , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_upload_order_service_phooto_Action(dispatch, data, orderId , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_upload_order_service_phooto_Api(dispatch, data, orderId , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_change_payment_method_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_change_payment_method_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Bussiness_PickupDelivery_change_payment_method_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_PickupDelivery_change_payment_method_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Ondemand_change_payment_method_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Ondemand_change_payment_method_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_Schedule_change_payment_method_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Schedule_change_payment_method_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Set_Feedback_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_Set_Feedback_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Set_Feedback_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Set_Feedback_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}







export function Bussiness_hasFeedbackPageSeen_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_hasFeedbackPageSeen_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Ondemand_Set_hasFeedbackPageSeen_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Ondemand_Set_hasFeedbackPageSeen_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Schedule_Set_hasFeedbackPageSeen_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Schedule_Set_hasFeedbackPageSeen_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}






export function Bussiness_get_order_trackDriver_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_get_order_trackDriver_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_get_order_trackDriver_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_get_order_trackDriver_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Bussiness_pickupDelivery_Cancel_Order_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_pickupDelivery_Cancel_Order_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Bussiness_Ondemand_Cancel_Order_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Bussiness_Ondemand_Cancel_Order_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Customer_pickupDelivery_Cancel_Order_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_pickupDelivery_Cancel_Order_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Customer_Ondemand_Cancel_Order_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Ondemand_Cancel_Order_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}





export function Customer_Ondemand_get_estimated_distance_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Ondemand_get_estimated_distance_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}

export function Customer_Schedule_get_estimated_distance_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Schedule_get_estimated_distance_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Ondemand_get_estimated_distance_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_Ondemand_get_estimated_distance_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Schedule_get_estimated_distance_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_Schedule_get_estimated_distance_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_get_quick_message_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_get_quick_message_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_get_quick_message_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_get_quick_message_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_get_chats_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_get_chats_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_get_chats_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_get_chats_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Send_Message_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_Send_Message_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_Send_Message_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Send_Message_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_seen_message_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_seen_message_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_seen_message_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_seen_message_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Get_Addressbook_list_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_Addressbook_list_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Get_Addressbook_list_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_Get_Addressbook_list_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}













export function Customer_Add_Addressbook_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Add_Addressbook_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Add_Addressbook_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_Add_Addressbook_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_Delete_Addressbook_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Delete_Addressbook_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Delete_Addressbook_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_Delete_Addressbook_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Customer_Set_Default_Addressbook_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Customer_Set_Default_Addressbook_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}





export function Customer_Edit_Addressbook_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Edit_Addressbook_Api(dispatch, data , call);

    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function Bussiness_Edit_Addressbook_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (navigator.onLine) {

      await Business_Edit_Addressbook_Api(dispatch, data , call);
    } else {

      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_OrderGetEventsAction(
  dispatch,
  id
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_Order_Events_Api(dispatch , id);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_OrderGetEventsAction(
  dispatch,
  id
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_Order_Events_Api(dispatch , id);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_GET_ETA_Action(
  dispatch,
  id,call
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_GET_ETA_Api(dispatch , id,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_GET_ETA_Action(
  dispatch,
  id,call
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_GET_ETA_Api(dispatch , id,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_add_Payment_Method_Action(
  call , dispatch
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_add_Payment_Method_Api(call , dispatch);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_add_Payment_Method_Action(
  call , dispatch
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_add_Payment_Method_Api(call , dispatch);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}






export function Customer_Get_Payment_Methods_Action(
  dispatch,call
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_get_Payment_Methods_Api(dispatch ,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Get_Payment_Methods_Action(
  dispatch,
  id,call
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_get_Payment_Methods_Api(dispatch , id,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}







export function Customer_Delete_Payment_Method_Action(
  dispatch,
  id,call
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Delete_Payment_Method_Api(dispatch , id,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Delete_Payment_Method_Action(
  dispatch,
  id,call
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Delete_Payment_Method_Api(dispatch , id,call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_Get_Support_Message_Action(
  dispatch
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_Support_Message_Api(dispatch);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Get_Support_Message_Action(
  dispatch 
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_Support_Message_Api(dispatch );
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Customer_Reply_Support_Message_Action(
  dispatch ,data
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Reply_Support_Message_Api(dispatch ,data);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Reply_Support_Message_Action(
  dispatch ,data 
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Reply_Support_Message_Api(dispatch ,data );
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}




export function Customer_Get_MessageBox_Action(
  dispatch, data, call
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Customer_Get_MessageBox_Api(dispatch, data, call);
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}



export function Bussiness_Get_MessageBox_Action(
  dispatch, data, call 
) {
  return async (dispatch) => {
    if (navigator.onLine) {
      await Bussiness_Get_MessageBox_Api(dispatch, data, call );
    } else {
      UnSucceessFul_i18(
        "Nointernetconnection",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };
}


export function GetMonitoringVehicleTypeAction(dispatch, data, func) {
  return async(dispatch) => {
      if (/* navigator.onLine */ true) {
          await VehicletypeForMonitoring(dispatch, data, func);
      } else {
      }
  };
}



export function ServicesVehicletypeAction(dispatch, center, customerId) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await ServicesVehicletype(dispatch, center, customerId);
    } else {
    }
  };
}
