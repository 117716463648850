import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { ReactComponent as Doc }  from "../../../../../src/static/doc.svg";
import addbulkimport from "../../../../../src/static/addbulkimport.svg";
import addbulkimport_white from "../../../../../src/static/adddwdw.svg";
import docdwd from "../../../../../src/static/docdwd.svg";
import doc_white_dwdw from "../../../../../src/static/doc_white_dwdw.svg";
import jalali from "jalali-moment";
import { get_lang } from "../../../../helper/UserData/userdate";
import { SetActiveImport } from "../../../../Redux/actions/actionNames";
import { BulkImportGetInfo } from "../../../../helper/api";
import { orderBy } from "lodash";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showpoper: false,
        };

    }


    handleClick=(id,type)=>{
        this.props.dispatch(SetActiveImport(id,type))
    }

    handleClickList=(id)=>{
        this.props.dispatch(SetActiveImport(id, "imported"))
        BulkImportGetInfo(this.props.dispatch,id)
    }

    newDraft=()=>{
        this.props.dispatch(SetActiveImport(null,null))

    }

    render() {
        let { t } = this.props;
        let Lists = Object.keys(this.props.List).map((id) => this.props.List[id]);
        let SortedList = orderBy(Lists , "createdAt" ,"desc");
        let newBtnActive = !this.props.selectedItem
        return (
            <div className="bulkImportlistContainer">

                {(this.props.draftList.length > 0 || Lists.length > 0) && (<React.Fragment>

                    <div className={`addnnewbulkimportrow ${newBtnActive && "addnnewbulkimportrowActive"} `} onClick={this.newDraft}>
                        <img src={newBtnActive ? addbulkimport_white : addbulkimport } />
                        <span className="yplkht"><Trans i18nKey={"new"}> </Trans> </span>
                    </div>

                    
                    {/* <div className="addnnewbulkimportrow addnnewbulkimportrowActive">
                        <img src={addbulkimport_white} />
                        <span className="yplkht"><Trans i18nKey={"new"}> </Trans> </span>
                    </div> */}
                    {/* 
                    <div className="addnnewbulkimportrow">
                        <img src={docdwd || doc_white_dwdw} />
                        <span className="filename"> Filename </span>
                        <span className="date"> 5 July 22:25 </span>
                        <span className="tuloiym Proccessing"><Trans i18nKey={"Proccessing"}> </Trans> </span>
                    </div>

                    <div className="addnnewbulkimportrow">
                        <img src={docdwd || doc_white_dwdw} />
                        <span className="filename"> Filename </span>
                        <span className="date"> 5 July 22:25 </span>
                        <span className="tuloiym Finished"><Trans i18nKey={"Finished"}> </Trans> </span>
                    </div>


                    <div className="addnnewbulkimportrow">
                        <img src={docdwd || doc_white_dwdw} />
                        <span className="filename"> Filename </span>
                        <span className="date"> 5 July 22:25 </span>
                        <span className="tuloiym Draft"><Trans i18nKey={"Draft"}> </Trans> </span>
                    </div> */}

                    {this.props.draftList.map((item) => {
                        return <div onClick={()=>{this.handleClick(item.id , "draft")}} className={`addnnewbulkimportrow ${(this.props.selectedItem == item.id) && "addnnewbulkimportrowActive"}`}>
                            <img src={(this.props.selectedItem == item.id) ? doc_white_dwdw:docdwd} />
                            <span className="filename"> {item.File.name} </span>
                            <span className="date"> {item.createdAt &&
                                jalali(item.createdAt)
                                    .locale(get_lang())
                                    .format("D  MMM ,  HH:mm")}</span>
                            <span className={`tuloiym ${item.status}`}><Trans i18nKey={item.status}> </Trans> </span>
                        </div>
                    })}

                    { SortedList.map((item) => {
                        return <div className={`addnnewbulkimportrow ${(this.props.selectedItem == item.id) && "addnnewbulkimportrowActive"}`} onClick={()=>this.handleClickList(item.id)}>
                            <img src={(this.props.selectedItem == item.id) ? doc_white_dwdw:docdwd} />
                            <span className="filename"> {item.fileName} </span>
                            <span className="date"> {item.createdAt &&
                                jalali(item.createdAt)
                                    .locale(get_lang())
                                    .format("D  MMM ,  HH:mm")} </span>
                            <span className={`tuloiym ${item.status}`}><Trans i18nKey={item.status}> </Trans> </span>
                        </div>
                    })}


                    {/* <div className="addnnewbulkimportrow addnnewbulkimportrowActive">
                        <img src={doc_white_dwdw} />
                        <span className="filename"> Filename </span>
                        <span className="date"> 5 July 22:25 </span>
                        <span className="tuloiym Uploading"><Trans i18nKey={"Uploading"}> </Trans> </span>
                    </div>   */}

                </React.Fragment>

                )}


                {(!this.props.draftList.length > 0 && !Lists.length > 0) && <div className="NoImportsYetListContainer">

                    <Doc />
                    <span className="Noimportsyet"><Trans i18nKey={"Noimportsyet"}> </Trans> </span>
                    <span className="Noimportsyetdesc"><Trans i18nKey={"NoimportsyetDesc"}> </Trans> </span>
                </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    draftList: state.BulkImport.draftList,
    List: state.BulkImport.List,
    selectedItem: state.BulkImport.selectedItem
});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
