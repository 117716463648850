import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import originIcon from "../../../../static/pin_pickup.svg";
import { ReactComponent as OriginIconSvg } from "../../../../static/pin_pickup.svg";
import destinationIcon from "../../../../static/pin_dropoff.svg";
import { ReactComponent as DestinationIconSvg } from "../../../../static/pin_dropoff.svg";
import { renderToStaticMarkup } from 'react-dom/server'
import ReactDOMServer from "react-dom/server";
import DropOffPin from "../../../../components/shared/map-dropoff-pin/Pin";
import PickupPin from "../../../../components/shared/map-pickup-pin/Pin";
import DropOffPinWithNumber from "../../../../components/shared/map-dropoff-pin-with-number/Pin";

const createtIconPickup = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<PickupPin color={"#" + color} bigSize={true}  style={{marginLeft:"-31px",marginTop:"-13px"}}/>),
    iconSize: [54, 57],
    iconAnchor: [10, 35],
  });
};


const createtIconDropOff = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<DropOffPin color={"#" + color} bigSize={true} style={{marginLeft:"-32px",marginTop:"-15px"}}/>),
    iconSize: [54, 57],
    iconAnchor: [10, 35],
  });
};


const createtIconNumberDropoff = (number, numberStyle, color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(CreateIconSelectedOsm( number, numberStyle, color)),
    iconSize: [54, 57],
    iconAnchor: [10, 35],
  });
};


const CreateIconSelectedOsm = ( number, numberStyle, color) => {
  return <DropOffPinWithNumber color={color} bigSize={true}
  style={{marginLeft:"-5px",marginTop:"-16px"}} number={number} numberStyle={numberStyle} />

};


// const createtOriginIconDiv = () => {
//   return new L.divIcon({
//     className: "markerIcon ",
//     html: `${renderToStaticMarkup( <OriginIconSvg />)}`,
//     iconAnchor: [20.5, 60],
//     popupAnchor: [0, -35],
//     iconSize: [65, 65],
//     shadowSize: [29, 40],
//     shadowAnchor: [7, 40],
//   });
// };



// const createtDestinationIconDiv = () => {
//   return new L.divIcon({
//     className: "markerIcon ",
//     html: `${renderToStaticMarkup( <DestinationIconSvg />)}`,
//     iconAnchor: [20.5, 60],
//     popupAnchor: [0, -35],
//     iconSize: [65, 65],
//     shadowSize: [29, 40],
//     shadowAnchor: [7, 40],
//   });
// };




class App extends React.Component {

  render() {
    let originShow = this.props.pickupAdresss_loc.lng !== "" && this.props.pickupDropOffType != "Pickup";
    let destinationShow = this.props.dropOffsAdresss_loc.lng !== "" && (this.props.pickupDropOffType == "DropOff" ? this.props.pickupDropOffId == null ? false : true : true);
    let OthersdestinationShow = this.props.pickupDropOffType == "DropOff" ? this.props.pickupDropOffId == null ? this.props.otherDropOffs : this.props.otherDropOffs.filter((drop) => { return drop.id != this.props.pickupDropOffId }) : this.props.otherDropOffs;

    return (
      <React.Fragment>
        {/* {originShow ? (
            <Marker
              animate={true}
              position={this.props.pickupAdresss_loc}
              icon={createtIcon(OriginIconSelected)}
            ></Marker>
          ) : null} */}


        {originShow ? (
          <Marker
            animate={true}
            position={this.props.pickupAdresss_loc}
            icon={createtIconPickup("1795ff")}
          ></Marker>

          // <Marker
          //   animate={true}
          //   position={this.props.pickupAdresss_loc}
          //   icon={createtOriginIconDiv()}
          //   ></Marker>
        ) : null}
      

        {/* {destinationShow ? (
            <Marker
              animate={true}
              position={this.props.dropOffsAdresss_loc}
              icon={createtIcon(DestinationIconSelected)}
            ></Marker>
          ) : null} */}


        {destinationShow ? (
          this.props.otherDropOffs.length == 0 ?
            <Marker
              animate={true}
              position={this.props.dropOffsAdresss_loc}
              icon={createtIconDropOff("1795ff")}
            ></Marker>
            :
            <Marker
              animate={true}
              position={this.props.dropOffsAdresss_loc}
              icon={createtIconNumberDropoff(1, { position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" } , "#1795ff")}
            >

            </Marker>



          // <Marker
          //   animate={true}
          //   position={this.props.dropOffsAdresss_loc}
          //   icon={createtDestinationIconDiv()}
          //   ></Marker>
        ) : null}


        {/* {OthersdestinationShow.map((dropoff) => {
                return (
                  <Marker
                    animate={true}
                    position={dropoff.adresss_loc}
                    icon={createtIcon(DestinationIcon )}
                  ></Marker>
                );
              })
              } */}


        {OthersdestinationShow.map((dropoff , i) => {
          return (

            <Marker
            animate={true}
            position={dropoff.adresss_loc}
            icon={createtIconNumberDropoff(i + 2, { position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" } , "#1795ff")}
          >

          </Marker>
            // <Marker
            //   animate={true}
            //   position={dropoff.adresss_loc}
            //   icon={createtDestinationIconDiv()}
            // ></Marker>
          );
        })
        }


      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  pickupDropOffType: state.NewOrder.newOrder.map_Data.pickupDropOff.type,
  pickupDropOffId: state.NewOrder.newOrder.map_Data.pickupDropOff.id,

  pickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  dropOffsAdresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
