import * as actionTypes from "../actions/actionTypes";
const init = {
  openDialoge: false,
  messages: [
    // {
    //   message: "hi, i arrived",
    //   time: new Date(),
    //   me: true,
    // },
    
  ],
  input: "",
  predefinedMessages: [
  ],
  loading: false,
  error: false,
};
export const chatWithDriver = (state = init, action) => {
  switch (action.type) {
    case actionTypes.openclosechatdriver:
      return {
        ...state,
        openDialoge: action.state,
      };

    case actionTypes.addmessagetochat:
      return {
        ...state,
        messages: [...state.messages, action.state],
      };

    case actionTypes.SETQUICKESSAGE:
      return {
        ...state,
        predefinedMessages: action.state,
      };
      case actionTypes.setmessagetochat:
        return {
          ...state,
          messages: action.state,
        };
        case actionTypes.setmessageErrorLoading:
        return {
          ...state,
          loading: action.state.loading,
          error: action.state.error,
        };

        case actionTypes.setSeenMessageInChat:
          return {
            ...state,
            messages: state.messages.map((mess)=>{
              if(mess.id == action.state.messageId){
                return {...mess ,isSeen:true ,seenAt:new Date()}
              }else{
                return mess
              }
            }),
          };
    default:
      return state;
  }
};
