import * as actionTypes from "../actions/actionTypes";

export const initTable = {
  selectedOrders: {},
  routeList: {
    open: false,
    list: [],
  },
  tableInit: null,
  list: {
    endCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
    orders: null,
    startCursor: null,
    totalCount: null
  },
  isLoading: false,
  hasError: false,
  perPage: 30,
  page: 1,

  selectedBulkEditStatusSummary: null,
  activeFilters:[],
  filters: {
    type:"Ondemand",
    cursor: "createdAt",
    cursorSort: "asc",
    services: [] /* [""] */,
    zones: [] /* [""] */,
    vehicleTypes: [] /* [""] */,
    status: [] /* [""] */,
    stages: [] /* [""] */,
    paymentMethods: [] /* [""] */,
    dueDate: { equal: null, gte: null, lte: null },
    createdAt: { equal: null, gte: null, lte: null },
    statusUpdatedAt: { equal: null, gte: null, lte: null },
    price: { equal: null, gte: null, lte: null },
    codAmount: { equal: null, gte: null, lte: null },
    pickup: {
      completeAfter: {
        equal: null,
        gte: null,
        lte: null
      },
      completeBefore: {
        equal: null,
        gte: null,
        lte: null
      },
    },
    delivery: {
      completeAfter: {
        equal: null,
        gte: null,
        lte: null
      },
      completeBefore: {
        equal: null,
        gte: null,
        lte: null
      },
    },
    regex: [] /*[
      {
        field :"",
        text:""
      },
      {
        field :"",
        text:""
      }
    ]*/

  }

};
export const Table = (state = initTable, action) => {
  switch (action.type) {
    case actionTypes.Table_SelectOrder:

      const orderIsSelectedNow = state.selectedOrders[action.state];

      if (orderIsSelectedNow) {
        return state;
      } else {
        return {
          ...state,
          selectedOrders: { ...state.selectedOrders, [action.state.id]: action.state }
        };
      }


    case actionTypes.Table_SelecteMultiOrders:
      let newList = {}
      action.state.forEach((od) => {
        newList[od.id] = od
      });


      return {
        ...state,
        selectedOrders: { ...state.selectedOrders, ...newList }
      };


    case actionTypes.Table_DeSelectOrder:
      let xx = state.selectedOrders;
      delete xx[action.state]
      return {
        ...state,
        selectedOrders: { ...xx }
      };


      
      case actionTypes.set_Table_ActiveOrderType:
        return {
          ...state,
          filters : {
            ...state.filters,
            type : action.state
          }
        };
  
    case actionTypes.table_setRouteList:
      return {
        ...state,
        routeList: {
          ...state.routeList,
          [action.state.key]: action.state.value
        }
      };


    case actionTypes.Table_ClearSelectedOrders:

      return {
        ...state,
        selectedOrders: {}
      };

    case actionTypes.Table_setData:

      return {
        ...state,
        list: action.state
      };


      case actionTypes.set_Order_Info_Api_PD_Update:

      return {
        ...state,
        list: {
          ...state.list,
          orders : state.list.orders?.map((order)=>{
            return order.id == action.state.id ? {...order , ...action.state} : order
          })
        }
      };

    case actionTypes.Table_Init:

      return {
        ...state,
        tableInit: action.state
      };

    case actionTypes.Table_setStatus:

      return {
        ...state,
        [action.state.key]: action.state.value
      };
    case actionTypes.Table_setSelectedBulkEditStatusSummary:

      return {
        ...state,
        selectedBulkEditStatusSummary: action.state
      };

    case actionTypes.TableInputContainFilter_Add:

      return {
        ...state,
        filters: {
          ...state.filters,
          regex: [...state.filters.regex, action.state]
        }
      };

    case actionTypes.TableInputContainFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          regex: state.filters.regex.map((item) => {
            if (item.field == action.state.field) {
              return action.state
            } else {
              return item
            }
          })
          // regex : state.filters.regex.filter((item)=>{
          //   return item.field != 
          // })
        }
      };

    case actionTypes.TableInputContainFilter_Delete:

      return {
        ...state,
        filters: {
          ...state.filters,
          regex: state.filters.regex.filter((item) => {
            return item.field != action.state
          })
        }
      };


    case actionTypes.TableCheckboxFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

    case actionTypes.TableRadioButtonFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

    case actionTypes.TableRangeFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

      case actionTypes.TableSubRangeFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: {
            ...state.filters[action.state.key],
            [action.state.sub]:action.state.value
          }
        }
      };


      case actionTypes.Add_ActiveFilter:

      return {
        ...state,
        activeFilters: [...state.activeFilters,action.state]
      };
      case actionTypes.clearAll_ActiveFilter:
      let ofj ={
        cursor: state.filters.cursor,
        cursorSort: state.filters.cursorSort,
      }
      return {
        ...state,
        filters: {...initTable.filters , type: "PickupDelivery" , ...ofj}
      };


    case actionTypes.Table_reset:

      return { ...initTable };
    default:
      return state;
  }
};

export default Table;