import LogoType from "../../../static/Pickmydrop.png";
import Banner from "../../../static/Pickmydrop_banner.png";

import FavIcon from "../../../static/PROJECTS/Pickmydrop_fav_icon.png";

export default function Pickmydrop() {
    
    return  global.config = {
        "project": "Pickmydrop",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Pick My Drop",
        "LogoType": LogoType,
        "BaseApi": "https://rest.pickmydrop.co.uk",
        "BaseSocket": "https://socket.pickmydrop.co.uk",
        "BaseTrack": "https://manage.pickmydrop.co.uk",
        "MapCenter": {
            "lat": "53.003733735771824",
            "lng": "-2.1837788408263186"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Pickmydrop",
        enabledCustomColor: true,
        registerEnabled: true,
        Secondarycolor:"#0B91F4",
        SecondaryDarkColor:"#0080DE",
        SecondaryLightColor:"#229FFB",
        SecondaryUltraLight :"#EBF6FE",
        SecondaryTextColor:"#FFFFFF",
        Secondaryonlight:"#0B91F4",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFFFFF",
        loginBackgroundColorEnd:"#C9C9C9",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}