import * as actionTypes from "../actions/actionTypes";
const init = {
  VerificationCode:{
     VerificationCode_step: false,
  }
 ,
  register: {
    phone: "",
    givenName: "",
    familyName: "",
    email: "",
    countryCode: "00",
    googleIsActive: false,
    facebookIsActive: false,
    appleIsActive: false,
    googleToken: null,
    facebookToken: null,
    appleToken: null,
    thirdPartyLogin: null,
  },
  login: {
    indivisual: {
      phone: "",
      googleIsActive: false,
      facebookIsActive: false,
      appleIsActive: false,
      googleToken: null,
      facebookToken: null,
      appleToken: null,
      thirdPartyLogin: null,
    },
    business: {
      username: "",
      password: "",
    },
  },
};
export const LoginRegister = (state = init, action) => {
  switch (action.type) {
    case actionTypes.REGISTERSETKEYVALUE:
      return {
        ...state,
        register: {
          ...state.register,
          ...action.state,
        },
      };

    case actionTypes.LOGININDIVISUALSETKEYVALUE:
      return {
        ...state,
        login: {
          ...state.login,
          indivisual: {
            ...state.login.indivisual,
            ...action.state,
          },
        },
      };

      case actionTypes.VERIFICATIONCODESETKEYVALUE:
        return {
          ...state,
          VerificationCode: {
            ...state.VerificationCode,
            ...action.state
          },
        };
  
    default:
      return state;
  }
};
