// import { ChangeViewTravelDialoge, ResetViewTravel } from "../Redux/actions/actionNames";
import { store } from "../Redux/store";
// import { OrderInfoAction } from "../Redux/actions/actions";
// import { get_id } from "../helper/UserData/userdate";
import {ChangeViewTravelDialoge ,ResetViewTravel ,DeleteTravelInList, UpdateStateOrderInList, ADDMESSAGETOCHATS, AddNewMessageFlagToOrder, SetSeenMessageInChat}  from "../Redux/actions/actionNames";
import { SucceessFul_i18 } from "../helper/Notification/sendNotification";
import { Api_OrderGetEvents_AGW } from "../helper/api/ApiGateway";
import { isViewOrderOpen } from "../Pages/ViewOrder/Map/osm";


// export function CheckThisTravelIsActive(data , call) {
//     const state = store.getState();
//     if(isViewOrderOpen() && (state.ViewTravel.ID == data.id )){
//         store.dispatch(OrderInfoAction(store.dispatch,{
//             orderId : data.id
//       }))
//       call && call(true)
//     }else{
//         call && call(false)  
//     }
// }


export function CheckThisTravelIsActiveWithOutOrder(id ) {
    const state = store.getState();
    if(isViewOrderOpen() && (state.ViewTravel.ID == id )){
      if(state.ViewTravel.eventTimeLine.show){
        Api_OrderGetEvents_AGW(store.dispatch , id)
      }
}
}




export function DeleteOrderFromLIST(id , forceDelete ,data) {
    const state = store.getState();
    if((isViewOrderOpen() /* || state.EditOrder.OpenDialoge*/) && (state.ViewTravel.ID == id && forceDelete)){
        //of edit page
        // if(state.EditOrder.OpenDialoge){
        //             try {
        //                 store.dispatch(OpenCloseEditTravel());
        //                 store.dispatch(Edit_Travel_Reset());    

        //                 setTimeout(() => {
        //                     store.dispatch(ChangeViewTravelDialoge(false));  
        //                     store.dispatch(ResetViewTravel());
        //                     store.dispatch(ResetNewFav()); 
        //                 }, 500);
        //             } catch (error) {
                        
        //             }
        // }else{
        //     store.dispatch(ChangeViewTravelDialoge(false));  
        //     store.dispatch(ResetViewTravel());
        //     store.dispatch(ResetNewFav())
        // }
        store.dispatch(ChangeViewTravelDialoge(false));  
        store.dispatch(ResetViewTravel());
        // store.dispatch(ResetNewFav())

        //delete data
        store.dispatch(DeleteTravelInList(id))
    }else if(!isViewOrderOpen()){
        // data && store.dispatch(UpdateStateOrderInList(data))
         //delete data
        store.dispatch(DeleteTravelInList(id))
    }else{
        data && store.dispatch(UpdateStateOrderInList(data))

    }


}
  
  





export function CheckIncomeMessages(dispatch, message) {
  const state = store.getState();

  if (
    isViewOrderOpen() && state.chatWithDriver.openDialoge ) {

    if(state.ViewTravel.ID == message.message.orderId){
            dispatch(
            ADDMESSAGETOCHATS({
                text: message.message.text,
                createdAt: message.message.createdAt,
                source: message.message.source,
                uid: message.message.uid,
                id:message.message.id,
            })
            );
    }else{
        SucceessFul_i18(
            "newincomemessageanother",
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
    }

  }else{
    SucceessFul_i18(
        "newincomemessage",
        "networkError",
        "close",
        () => {
          dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );

      dispatch(AddNewMessageFlagToOrder({
          id: message.message.orderId,
          bool : true
      }))
  }
}





export function CheckIncomeMessagesSeen(dispatch, message) {
  const state = store.getState();

    if(state.ViewTravel.ID == message.orderId){
      dispatch(SetSeenMessageInChat(message))
    }

}
