import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { read, writeFileXLSX, utils } from "xlsx";
import { ReactComponent as Csv } from "../../../../../src/static/csv.svg";
import { ReactComponent as Open_file } from "../../../../../src/static/open_file.svg";
import info_error from "../../../../../src/static/info_error.svg";
import { useTranslation } from 'react-i18next';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { CreateNewDraft, SetActiveImport } from "../../../../Redux/actions/actionNames";
import { connect } from "react-redux";
import { UnSucceessFul_i18 } from "../../../../helper/Notification/sendNotification";

function App(props) {

  const [fileNames, setFileNames] = useState([]);
  const [notSupport, setnotSupport] = useState({status:false , type:""});

  const handleDrop = (acceptedFiles) => {

      acceptedFiles.map((item , i)=>{
        if(getFileExtension(item.path) != "csv"){
          setnotSupport({status:true , type:getFileExtension(item.path)})
        }else{
          insertFile(item , i == acceptedFiles.length - 1 )
        }
      })
  }



  const insertFile = (acceptedFile , lastItem) => {
  

    let fileReader = new FileReader();
    let chunkSizeToUse = (400 * 1024) // 1 MB chunks
    let offset = (0 - chunkSizeToUse)
    offset += chunkSizeToUse
    fileReader.readAsText(acceptedFile.slice(offset, (offset + chunkSizeToUse)))

    fileReader.onload = (event) => {
      let data = event.target.result;
      let workbook = read(data, { type: "string" /*, sheetRows: 2 */ });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let rowObject = utils.sheet_to_json(worksheet, {
        raw: true,
      });
      

      console.log(workbook , workbook.SheetNames , worksheet , rowObject)
      // let css = {}
      
      // Object.keys(rowObject[0]).forEach(function (key) {  
      //     css[CSV.parse(key)[0][0]] = CSV.parse(String(rowObject[0][key]))[0][0];
      //   })
 
      if(rowObject.length > 0){
        let id = 'id' + (new Date()).getTime() + Math.random().toString(16).slice(2)  ;

        rowObject.length > 0 && props.dispatch(CreateNewDraft({
          type :"Draft" ,
          status : "Draft" ,
          createdAt: new Date(),
          File : acceptedFile ,
          id
        }))
        lastItem && props.dispatch(SetActiveImport(id , "draft"))
      }else{
        UnSucceessFul_i18(
          "orderInfoError",
          "networkError",
          "close",
          () => {
            props.dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
      
    }
    // props.setFile(acceptedFile)

  }


  const { t, i18n } = useTranslation();


  return (
    <React.Fragment>
    
    <Dropzone onDrop={handleDrop} >

      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
      }) => {
        const additionalClass = isDragAccept
          ? "accept"
          : isDragReject
            ? "reject"
            : "";

        return (
          <div
            {...getRootProps({
              className: ` DragOverStartContainer ${isDragActive ? "DragOverStartActiveContainer" : ''}`
            })}
          >
            <input {...getInputProps()} />
            
            <Csv  />
            <p className="ppkkgttrvvlp"> {t('Draganddropyourfile')} </p>
            <p className="eovvlsyr">  {t('Onlycsvformatissupported')} </p>
            <p className="odpvnts">  {t('ORyoucan')}</p>
            <span className="rolgprtu">
            <Open_file />
            <span>  {t('Openfile')}</span>
             </span>
          </div>
        );
      }}
    </Dropzone>

    <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={ notSupport.status }
          onClose={null}
          className="logoutContainer noSupportFileContainer"
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          <img src={info_error} className="titleIcon" />
          <p
            className="title"
          >
            {t("nosupportdialogeTitle")}
          </p>

            <DialogContentText
              id="alert-dialog-description"
              className="nosupportdialogetext"
            >
              {String(t("nosupportdialogeText")).replace("xxx", notSupport.type)}
            </DialogContentText>
            <Button
              onClick={() => {
                setnotSupport({status:false , type:""})
              }}
              className="ok"
              variant="contained"
            >
              {t("ok")}
            </Button>
    
        </Dialog>

    </React.Fragment>

  );
}


function getFileExtension(filename) {
  return filename.split('.').pop();

}

const mapStateToProps = (state) => ({
 
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
