import React, { useState } from "react";
import Header from "./header";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_lang, get_LeftRight } from "../../../helper/UserData/userdate";
import Loading from "./loading";
import Error from "./error";
import { getLogoTypeByUrl } from "../../../helper/module";
import { withTranslation, Trans } from "react-i18next";
import email from "../../../static/email.svg";
import link from "../../../static/link.svg";
import insta from "../../../static/instagram.svg";
import twiter from "../../../static/twitter.svg";
import facebook from "../../../static/facebook-f.svg";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.open}
        mountOnEnter
        unmountOnExit
      >
        <div className="OrderHistoryContainer">
          <Header />
          <Error />
          <Loading />

          {!this.props.error && !this.props.loading && this.props.data ? (
            <div className="aboutusinner">
              <img src={global.config.LogoType} className="aboutuslogo" />
              <span className="aboutusversion"> V {global.appVersion} </span>
              <span className="aboutustext">
                {" "}
                {this.props.data.text}{" "}
              </span>
              <span className="aboutustext">
                {" "}
                <Trans i18nKey={"Readour"}> </Trans>{" "}
                <a className="privaypolicy" href={this.props.data.privacyPolicyUrl} >
                  {" "}
                  <Trans i18nKey={"privaypolicy"}></Trans>{" "}
                </a>{" "}
              </span>

              <div className="aboutusLinkContainer">
               {this.props.data.facebookUrl && <a className="aboutusLinkdiv" href={this.props.data.facebookUrl} >
                  <img src={facebook} className="aboutusLink" />
                </a>}
               {this.props.data.twitterUrl && <a className="aboutusLinkdiv"  href={this.props.data.twitterUrl} >
                  <img src={twiter} className="aboutusLink" />
                </a>}
                {this.props.data.instagramUrl && <a className="aboutusLinkdiv" href={this.props.data.instagramUrl} >
                  <img src={insta} className="aboutusLink" />
                </a>}
                {this.props.data.companyWebsiteUrl && <a className="aboutusLinkdiv" href={this.props.data.companyWebsiteUrl} >
                  <img src={link} className="aboutusLink" />
                </a>}
               {this.props.data.email && <a className="aboutusLinkdiv" href={"mailto:"+this.props.data.email}>
                  <img src={email} className="aboutusLink" />
                </a>}
              </div>
              </div>
          ) : (
            ""
          )}
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.AboutUS.openDialoge,
  error: state.AboutUS.error,
  loading: state.AboutUS.loading,
  data: state.AboutUS.data,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
