/* eslint import/no-webpack-loader-syntax: off */
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { get_lang } from "../UserData/userdate";
import { GetDefaultMaincss } from "../../service/hooks/GetDefaultMaincss";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
const { version } = require('../../../package.json');

//STEP 1:
//create components using React.lazy
// const RtlCss = React.lazy(() => import("./RtlCss"));
// const LtrCss = React.lazy(() => import("./LtrCss"));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
export const RtlLtrCssSelector = (props) => {

  console.log("RtlLtrCssSelector ",props , global)
  const direction = useSelector((state) => state.SharedData.direction)

  // const Maincss = require(`!!raw-loader!./Css/${props.Direcrion}.css`);


  useEffect(() => {
try {
  
      var head = document.head;
      var linkSass_Font = document.createElement("link");
  
      linkSass_Font.type = "text/css";
      linkSass_Font.rel = "stylesheet";
  
      if (global.config.customFontColor) {
        linkSass_Font.href = `/RtlLtrCssSelector/Css/${global.config.customFontColor}/${props.Direcrion}.css`;
      } else {
        linkSass_Font.href = `/RtlLtrCssSelector/Projects/default/${props.Direcrion}.css?v=${version}`;
      }
      
      head.appendChild(linkSass_Font);
  
      return () => { head.removeChild(linkSass_Font); }
} catch (error) {
  
}

  }, [direction])


  
  useEffect(() => {

  try {
      var head = document.head;
      var linkDirection = document.createElement("link");
  
      linkDirection.type = "text/css";
      linkDirection.rel = "stylesheet";
      linkDirection.href = `/RtlLtrCssSelector/Css/${direction || global.config.Direction}.css?v=${version}`;
  
      head.appendChild(linkDirection);
  
      return () => { head.removeChild(linkDirection); }
  
  } catch (error) {
    
  }
  }, [direction])


  useEffect(() => {

  try {
      var head = document.head;
      var linkSass_Color = document.createElement("link");
  
      linkSass_Color.type = "text/css";
      linkSass_Color.rel = "stylesheet";
      linkSass_Color.href = `/RtlLtrCssSelector/Projects/sassColors/${global.config.customColor}.css?v=${version}`;
  
      head.appendChild(linkSass_Color);
  
      return () => { head.removeChild(linkSass_Color); }
  
  } catch (error) {
    
  }
  }, [direction])


  useEffect(() => {

 try {
     var head = document.head;
     var linkProjectLanguagePatch = document.createElement("link");
 
     linkProjectLanguagePatch.type = "text/css";
     linkProjectLanguagePatch.rel = "stylesheet";
 
     if (get_lang() == "ar") {
       linkProjectLanguagePatch.href = `/RtlLtrCssSelector/Css/ar.css`;
     } else if (get_lang() == "fr") {
       linkProjectLanguagePatch.href = `/RtlLtrCssSelector/Css/fr.css`;
     } else if (get_lang() == "ro") {
       linkProjectLanguagePatch.href = `/RtlLtrCssSelector/Css/ro.css`;
     }
 
     head.appendChild(linkProjectLanguagePatch);
 
     return () => { head.removeChild(linkProjectLanguagePatch); }
 
 } catch (error) {
  
 }
  }, [direction])

  return (
    <div className="App">
      <React.Suspense fallback={<></>}>
        {/* <style>{Maincss}</style> */}
        {/* <style>{Sass_Font(props)}</style>
        <style>{Sass_Color()}</style>
        <style>{ProjectLanguagePatch()}</style> */}
      {/* <Helmet
        style={[{
          "cssText": Maincss
        }
      ]}
      /> */}
            {/* <Helmet
        style={[{
          "cssText": Sass_Font(props)
        },{
          "cssText": Sass_Color()
        },{
          "cssText": ProjectLanguagePatch()
        }
      ]}
      /> */}
      </React.Suspense>
      {props.children}
    </div>
  );
};


export const ProjectLanguagePatch = (props) => {
  if (get_lang() == "ar") {
    const css = require(`!!raw-loader!./Css/ar.css`);
    return css.default
  } else if (get_lang() == "fr") {
    const css = require(`!!raw-loader!./Css/fr.css`);
    return css.default
  } else if (get_lang() == "ro") {
    const css = require(`!!raw-loader!./Css/ro.css`);
    return css.default
  }
};

export const Sass_Font = (props) => {
  if (global.config.customFontColor) {
    const css = require(`!!raw-loader!./Projects/${global.config.customFontColor}/${props.Direcrion}.css`);
    return css.default
  } else {
    const css = require(`!!raw-loader!./Projects/default/${props.Direcrion}.css`);
    return css.default
  }
};

export const Sass_Color = (props) => {
  // const css = require(`!!raw-loader!./Projects/sassColors/swiftee.css`);
  // return css.default

  if (global.config.enabledCustomColor) {
    const css = require(`!!raw-loader!./Projects/sassColors/${global.config.customColor}.css`);
    // const css = require(`!!raw-loader!./Projects/sassColors/ASKERI.css`);
    return css.default
  }

};