import React, { useState } from "react";
import Header from "./header";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_Id, get_LeftRight } from "../../../helper/UserData/userdate";
import { withTranslation, Trans } from "react-i18next";
import ic_chevron_right from "../../../static/ic_chevron_right.svg";
import Loading from "./loading";
import { SetSelectedSupportData } from "../../../Redux/actions/actionNames";
import { Api_Reply_Support_Message_AGW } from "../../../helper/api/ApiGateway";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ""
    };
  }


  itemOnclick = (item) => {
    this.props.dispatch(SetSelectedSupportData(item))
  }

  inputChange = (e) => {
    this.setState({
      input: e.target.value
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({
        input: ""
      })
    }
  }


  sendSupport = () => {
    Api_Reply_Support_Message_AGW(this.props.dispatch, {
      "customerId": get_Id(),
      "supportMessageId": this.props.selectedSubject.id,
      "replyText": this.state.input
    })
  }


  render() {
    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.open}
        mountOnEnter
        unmountOnExit
      >
        <div className="OrderHistoryContainer" style={{ backgroundColor: "#fbfdff" }}>
          <Header />
          <Loading />


          {(this.props.data && !this.props.selectedSubject) && <div className="supportContainer">
            <p className="ChooseTheSubject">  <Trans i18nKey={"Choosethesubject"}></Trans> </p>

            <div className="SubjectContainer">
              {this.props.data.messages.map((item, i) => {
                return <div className={i == this.props.data.messages.length - 1 ? "SubjectItemNoBorder" : "SubjectItem"} onClick={() => { this.itemOnclick(item) }}>
                  <span>{item.title}</span>
                  <img src={ic_chevron_right} />
                </div>
              })}

            </div>

            <div className="extraSubjectContainer">
              <a className="callSupport" href={`tel:${this.props.data.supportContactInformation.supportPhone}`}>
                <span> <Trans i18nKey={"CallTheSupport"}></Trans></span>
                <img src={ic_chevron_right} />
              </a>
              <div className="supportQuestions" onClick={() => {
                window.open(this.props.data.supportContactInformation.faqUrl, '_blank').focus();
              }}>
                <span> <Trans i18nKey={"Seefrequentquestions"}></Trans></span>
                <img src={ic_chevron_right} />
              </div>
            </div>

          </div>
          }

          {this.props.selectedSubject && <div className="supportContainer">
            <div className="sendSupportContainer">
              <p className="sendSupportTitle">{this.props.selectedSubject.title}</p>
              <p className="sendSupportDescription">{this.props.selectedSubject.description}</p>
              <p className="sendSupportInputTitle">  <Trans i18nKey={"Details"}></Trans></p>
              <input placeholder={this.props.t("Exstart")} className="sendSupportInput" onChange={this.inputChange} />
              <span className="sendSupportBtn" style={{ backgroundImage: String(this.state.input).length == 0 ? "unset" : "" }} onClick={() => { this.sendSupport() }}>  <Trans i18nKey={"Details"}></Trans></span>
            </div>
          </div>}


        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.Support.openDialoge,
  data: state.Support.data,
  selectedSubject: state.Support.selectedSubject

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
