import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table_setData, Table_setStatus } from '../../Redux/actions/actionNames';
import momentJalaali from "moment-jalaali";

import _, { isNumber, isObject } from 'lodash';
import { httpCustomer } from '../../service/api/axios';
import { get_Id, get_accessToken, get_lang } from '../UserData/userdate';
import { RefreshToken } from '../api';
import { Warning_NOi18 } from '../Notification/sendNotification';


function removeNullFields(obj) {
  for (var key in obj) {
      if (obj[key] === null) {
          delete obj[key];
      } else if (typeof obj[key] === 'object') {
          removeNullFields(obj[key]);
      }
  }
  return obj;
}

function gtelteSetter(obj) {
  for (var key in obj) {
      if (["gte","equal"].indexOf(key) > -1) {
        if(isObject(obj[key])){
          obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year +" 00:01", "DD/MM/YYYY HH:mm").format("x")
        }
      } else if (["lte"].indexOf(key) > -1) {
        if(isObject(obj[key])){
          obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year +" 23:59", "DD/MM/YYYY HH:mm").format("x")
        }
      } else if (typeof obj[key] === 'object') {
        gtelteSetter(obj[key]);
      }
  }
  return obj;
}




export function GetTableOrderListWithFilters() {
  const filters = useSelector((state) => state.Table.filters)
  const table = useSelector((state) => state.Table.tableInit)
  const perPage = useSelector((state) => state.Table.perPage)
  const ActiveOrderType = useSelector((state) => state.Table.filters.type)

  const [currentFilter, setCurrentFilter] = useState(filters);

  const dispatch = useDispatch();

  useEffect(() => {

    if(!_.isEqual(filters , currentFilter)){
      console.log("filters ",filters , currentFilter)

      setCurrentFilter(filters)
      OnChange()
    }
    // OnChange()

  }, [filters])


  const OnChange = React.useCallback(async(e) => {
    dispatch(Table_setStatus({key:"isLoading" , value:true}))
    table?.toggleAllRowsSelected(false)

    try {

      const data = JSON.parse(JSON.stringify({
        "size": perPage,
        customerId:get_Id(),

        // "status[]": "Pending",
        // "dueDate[start]": "1688224655137",
        // "dueDate[end]": "1706714255137",
        ...filters,
        "type":ActiveOrderType,
        "columns": ['customerId' , 'driver' , 'pickup' , 'dropoff' , 'dropoffs' , 'delivery' , 'isScheduled' , 'currentCandidateDrivers' , 'driverAssignmentModel' , 'driverId' , 'delayAt' , 'baseETA' , 'realETA' , 'isPinned' , 'isDelayed' , 'isPossibleForDelay' , 'candidateDriversCount' , 'dispatchAt' , 'isDispatching' , 'vehicleType' , 'isUrgent' , 'service' , 'routeName' , 'paymentMethod' , 'paymentSide' , 'paymentProvider' , 'codAmount' , 'note' , 'referenceId' , 'stage' , 'customer' , 'zone' , 'price' , 'trackOrder' , 'customerType' , 'estimatedDistanceInMeters' , 'estimatedDuration' , 'customerReceiptUrl' , 'source' , 'statusUpdatedAt' , 'shipmentLabelUrl']
      }));



      const response = await httpCustomer.request({
        method: 'get',
        url: "/api/v1/customer/order/cursor-based",
        params: gtelteSetter(removeNullFields(data)),
        headers: {
          "Accept-Language": get_lang(),
          Authorization: `Bearer ${get_accessToken()}`,
        }
      });
      dispatch(Table_setData(response.data.data))
      dispatch(Table_setStatus({key:"isLoading" , value:false}))
      dispatch(Table_setStatus({key:"page" , value: 1}))
  
  } catch (error) {
    dispatch(Table_setStatus({key:"isLoading" , value:false}))
    dispatch(Table_setStatus({key:"hasError" , value:true}))
  


    if (error.response) {
      if (error.response.status === 401 && error.response.data.code == "-9") {
        RefreshToken(() => {
          GetTableOrderListWithFilters();
        });
        //token exchange
      } else {
        Warning_NOi18(
          error.response.data.message,
          "networkError",
          "close",
          () => {
            dispatch({
              type: "removeNetworkErrorSnack",
              key: "networkError",
            });
          },
          new Date().getTime() + Math.random()
        );
      }
    }
  }
    
  }, [filters  ,perPage , ActiveOrderType, table]);



  return {};
};

