
import deliroIGO from "../../../static/deliro.svg";
import Defaultbanner from "../../../static/delivery-5.jpg";
import FavIcon from "../../../static/PROJECTS/deliro.png";

export default function deliro() {
    
    return  global.config = {
        "project": "app.deliro.ir",
        "ShowPowerOnro": true,
        "flag": "persianflag",
        "ProjectName": "دلیرو پنل مشتری",
        "LogoType": deliroIGO,
        "BaseApi": "https://rest.deliro.ir",
        "BaseSocket": "https://socket.deliro.ir",
        "BaseTrack": "https://panel.deliro.ir",
        "MapCenter": {
            "lat": 27.709345,
            "lng": 85.327736
        },
        "Lang": "fa",
        "Direction": "Rtl",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }, {
            "lang": "fa",
            "direction": "Rtl",
            "i18Lang": "Persian",
            "cssFlag": "persianflag",
            "country": "IR"
        }, {
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        }],
        "FavIcon": "/deliro.png",
        "Country": "IR",
        "AppleLoginCustomerId": "6N993LX47C",
        "AppleLoginredirectURI": "https://app.deliro.ir",
        "FacebookLoginappId": "1194837280918142",
        "GoogleLogincustomerId": "51088687197-bv0alhejledkq9lb6qq4n0r9hqnj523o.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"deliro",
        registerEnabled: true,
        Secondarycolor:"#1080FF",
        SecondaryDarkColor:"#0055cb",
        SecondaryLightColor:"#25B7FF",
        SecondaryUltraLight :"#D9EAFF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#1080FF",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:deliroIGO,
        apkUrl:null,
        iosUrl:null
} }