import PickGoIGO from "../../../static/PickGoogotype.png";
import PickGo_banner from "../../../static/PickGo_banner.jpg";

import FavIcon from "../../../static/PROJECTS/PickGo.png";

export default function PickGo() {
    
    return global.config = {
        "project": "deliver.pickngosg.com",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Delivery • Rides • Buy 4 Me",
        "LogoType": PickGoIGO,
        "BaseApi": "https://rest.pickngosg.com",
        "BaseSocket": "https://socket.pickngosg.com",
        "BaseTrack": "https://panel.pickngosg.com",
        "MapCenter": {
            "lat": 1.364196,
            "lng": 103.868759
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/PickGo.png",
        "Country": "SG",
        "AppleLoginCustomerId": null,
        "AppleLoginredirectURI": "https://app.onro.io",
        "FacebookLoginappId": "587040889263267",
        "GoogleLogincustomerId": "362688129311-rsut2e8er0cfnielbrrp275brem5c453.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": PickGo_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"PickGo",
        registerEnabled: true,
        Secondarycolor:"#1080FF",
        SecondaryDarkColor:"#0055cb",
        SecondaryLightColor:"#25B7FF",
        SecondaryUltraLight :"#D9EAFF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#1080FF",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:PickGoIGO,
        apkUrl:null,
        iosUrl:null
}}