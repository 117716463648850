import React, { useState } from "react";
import service_box from "../../../../../static/service-box.jpg";
import pickup from "../../../../../static/icon-ic-pickup.svg";
import dropoff from "../../../../../static/group-4-copy.svg";
import car from "../../../../../static/icon-car.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import jalali from "jalali-moment";
import { get_lang } from "../../../../../helper/UserData/userdate";



class App extends React.Component {


  render() {
    return <div className="OrderItemContainer TransactionItemContainer" style={{border:"none", backgroundColor:"#fff" , boxShadow:"0 1px 4px 0 rgba(128, 128, 128, 0.2)"}}>
      <div className="TransactionItem_firstrow">  
          <span className={`price`}>  {this.props.data.title} </span>
      </div>

          <span className="TransactionItemText" style={{height:"auto"}}> {this.props.data.description } </span>

    </div>;
  }
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
