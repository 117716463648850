import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { getBaseTrackByUrl } from "../../../../helper/module";
import { get_lang } from "../../../../helper/UserData/userdate";
import arrow from "../../../../static/chevron-down.svg";
import { Collapse } from "newmaterial-ui";


var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      activeCollapse: false,
    }
  }


  handleCollapse = () => {
    this.setState({
      activeCollapse: !this.state.activeCollapse
    })
  };



  serviceOptionsNameExtracter = () => {
    let names = [];
    if (this.props.options) {
      this.props.options.map((option) => { 
        if (option.type === "boolean") {
          names.push(option.title) ;
         } else {
          names.push(option.dataTitle) ;
         }
      });  
      return names.join(" , ")
    } else {
      return "";
    }
  };

  render() {
    if (!_.isEmpty(this.props.driver) || _.isEmpty(this.props.vehicleType)) {
      return null;
    } else {
      const { t } = this.props;
      return (
      <serviceVr style={{minHeight:"60px", height:"auto"}}>
          <img   src={
            this.props.vehicleType &&
             this.props.vehicleType.icon
          }/>
        <name style={{flexDirection:"row",fontWeight:"100"}}>
           <span> {this.props.vehicleType && this.props.vehicleType.title} </span>
           {this.props.options.length > 0 ? <span style={{backgroundColor:"#8080801A",marginInlineStart:"8px",padding:"1.5px 6px",borderRadius:"9px"}}>{`${this.props.options.length} ${this.props.t("Options")}`}</span> : ""}

          {/* <optionss>{this.serviceOptionsNameExtracter()}</optionss> */}
          </name>

          {this.props.options.length > 0 && <img
          onClick={this.handleCollapse}
          src={arrow}
          className={`vrpickupCollIconVR ${this.state.activeCollapse && "rotate180"
            }`}

          style={{ marginInlineEnd: "15px", marginInlineStart: "6px", marginTop: "18px" }}
        />}


        
<Collapse
          className="pickupcollapsecontainervr"
          in={this.state.activeCollapse}
          timeout="auto"
          unmountOnExit
        >
          <div className="vrPickupCollapseDetail" style={{height:"auto" ,width:"100%",marginTop:"0px"}}>

          {this.props.options.map((opt)=>{
            return <div style={{borderBottom:"1px solid #F4F4F7" , width:"calc(100%)" ,marginLeft:"0px",marginRight:"0px",minHeight:"36px",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
              <span style={{color:"#242424" ,fontWeight:"500",fontSize:"12px"}}>{opt.title}</span>
              <span style={{fontWeight:"500",color:"#242424",fontSize:"12px"}}>{opt.type == "list" ? (opt.dataTitle) : (opt.type == "boolean" ? this.props.t("confirmCancel") : opt.inputValue ) }</span>
            </div>
          })}
          </div>
        </Collapse>
        </serviceVr>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  driver:state.ViewTravel.pureData
  ? state.ViewTravel.pureData.driver
    ? state.ViewTravel.pureData.driver
    : null
  : null,
  vehicleType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.vehicleType
      ? state.ViewTravel.pureData.vehicleType
      : null
    : null,
    options: state.ViewTravel.pureData && state.ViewTravel.pureData.vehicleType
    ? state.ViewTravel.pureData.vehicleType.options
      ? state.ViewTravel.pureData.vehicleType.options
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
