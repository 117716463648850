import LogoType from "../../../static/Rappid.png";
import Banner from "../../../static/Rappid_banner.png";

import FavIcon from "../../../static/PROJECTS/Rappid_fav_icon.png";

export default function Rappid() {
    
    return  global.config = {
        "project": "Rappid",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Dashboard",
        "LogoType": LogoType,
        "BaseApi": "https://rest.tryrappid.com",
        "BaseSocket": "https://socket.tryrappid.com",
        "BaseTrack": "https://admin.tryrappid.com",
        "MapCenter": {
            "lat": 40.678709675204104,
            "lng": -73.94568419551865
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }, {
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "DO"
        }, {
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }],
        "FavIcon": "",
        "Country": "US",
        "AppleLoginCustomerId": "XF5AATML3J",
        "AppleLoginredirectURI": "https://dashboard.tryrappid.com",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "1043003291558-epcm6499k1bjmpmjaaral0i819r7ed2n.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: false,
        customColor:"Rappid",
        registerEnabled: true,
        Secondarycolor: "#18EE73",
        SecondaryDarkColor: "#0BBF58",
        SecondaryLightColor: "#54F999",
        SecondaryUltraLight: "#EDFEF4",
        SecondaryTextColor: "#242424",
        Secondaryonlight: "#0BBF58",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#004BF0",
        icon_light_end:"#4C7EEC",
        icon_dark_start:"#18EE73",
        icon_dark_end:"#54F999",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#0155e9",
        loginBackgroundColorEnd:"#0155e9",
        LoginTextColor :"#11293B",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}