import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import icon_ic_fail from "../../../../../static/icon-ic-fail.svg";
import icon_done from "../../../../../static/icon-ic-done.svg";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Fade";
import { get_customerPannel_type, get_Id, get_LeftRight } from "../../../../../helper/UserData/userdate";
import { DetectOndemandOrReserve } from "../../../../../helper/worker/DetectOndemandOrReserve";
import { Api_ondemand_promotion_verify_AGW, Api_pickupDelivery_promotion_verify_AGW } from "../../../../../helper/api/ApiGateway";
import momentJalaali from "moment-jalaali";
import { Set_PromoCode } from "../../../../../Redux/actions/actionNames";
import { DetectOndemandOrPickupDelivery } from "../../../../../helper/worker/DetectOndemandOrReserve copy";
import debounce from "lodash/debounce";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPromo: false,
    };

    this.debounceSave = debounce(this.Save.bind(this), 1000);

  }

  HandlePropmoOpen = () => {
    this.props.code.length == 0 &&
      this.setState({
        openPromo: !this.state.openPromo,
      });
  };


  promoCodeOnchange=(e)=>{
    this.promoCodeDelete()
    this.props.dispatch(Set_PromoCode({
      code:e.target.value,
      valid : false,
      data:null
    }))

    this.debounceSave()
  }


  promoCodeDelete=()=>{
    if(this.props.code.length == 0){
      this.setState({
        openPromo: !this.state.openPromo,
      });
    }else{
        this.props.dispatch(Set_PromoCode({
      code:"",
      valid : false,
      data:null
    })) 
    }
 
  }


  removeEmpty= (obj)=> {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }


  Save=()=>{
    DetectOndemandOrPickupDelivery(
      this.props.serviceactiveVehicleType ? this.props.serviceactiveVehicleType.orderTypes : [],
      (call) => {
        if (call === "ondeman") {
          Api_ondemand_promotion_verify_AGW(
            this.props.dispatch,
            this.removeEmpty({
              // "vehicletypeId": this.props.activeVehicleType.id,
              isScheduled:   this.props.pickup.scheduleDateAfter ||
              this.props.pickup.scheduleDateBefore
              ? true
              : false,
              vehicleType: {
                id: this.props.activeVehicleType.id,
                options: this.props.VehicleType_options.map((item) => {
                  return { dataId: item.item ? item.item.id : "", id: item.id };
                }),
              },
              pickup: {
                coordinates: [
                  this.props.pickup_adresss_loc.lng,
                  this.props.pickup_adresss_loc.lat,
                ],
                scheduleDateAfter: momentJalaali(
                  this.props.pickup.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                      Number(this.props.pickup.scheduleDateAfter + "000")
                    ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.pickup.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                      Number(this.props.pickup.scheduleDateBefore + "000")
                    ).unix()
                  : 0,
                schedulePickupNow: false,
              },
              dropoffs: [
                {
                  coordinates: [
                    this.props.firstDropOff.adresss_loc.lng,
                    this.props.firstDropOff.adresss_loc.lat,
                  ],
                  scheduleDateAfter: momentJalaali(
                    this.props.firstDropOff.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                        Number(
                          this.props.firstDropOff.scheduleDateAfter + "000"
                        )
                      ).unix()
                    : 0,
                  scheduleDateBefore: momentJalaali(
                    this.props.firstDropOff.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                        Number(
                          this.props.firstDropOff.scheduleDateBefore + "000"
                        )
                      ).unix()
                    : 0,
                },
                ...this.props.otherdropOffs.map((drop) => {
                  return {
                    coordinates: [drop.adresss_loc.lng, drop.adresss_loc.lat],
                    scheduleDateAfter: momentJalaali(
                      drop.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                          Number(drop.scheduleDateAfter + "000")
                        ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      drop.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                          Number(drop.scheduleDateBefore + "000")
                        ).unix()
                      : 0,
                  };
                }),
              ],
              service: {
                id: this.props.activeservice.id,
                options: this.props.service_options.map((item) => {
                  return { dataId: item.item ? item.item.id : "", id: item.id };
                }),
              },

              promoCode: this.props.code,
              customerId: get_Id(),
            })
          );
        } else {

          Api_pickupDelivery_promotion_verify_AGW(this.props.dispatch , this.removeEmpty({
            isScheduled:   this.props.pickup.scheduleDateAfter ||
            this.props.pickup.scheduleDateBefore
            ? true
            : false,
            vehicleType: {
              id: this.props.activeVehicleType.id,
              options: this.props.VehicleType_options.map((item) => {
                return { dataId: item.item ? item.item.id : "", id: item.id };
              }),
            },
            pickup: {
              coordinates: [
                this.props.pickup_adresss_loc.lng,
                this.props.pickup_adresss_loc.lat,
              ],
              scheduleDateAfter: momentJalaali(
                this.props.pickup.scheduleDateAfter
              ).isValid()
                ? momentJalaali(
                    Number(this.props.pickup.scheduleDateAfter + "000")
                  ).unix()
                : 0,
              scheduleDateBefore: momentJalaali(
                this.props.pickup.scheduleDateBefore
              ).isValid()
                ? momentJalaali(
                    Number(this.props.pickup.scheduleDateBefore + "000")
                  ).unix()
                : 0,
            },
            dropoff: {
              coordinates: [
                this.props.firstDropOff.adresss_loc.lng,
                this.props.firstDropOff.adresss_loc.lat,
              ],
              scheduleDateAfter: momentJalaali(
                this.props.firstDropOff.scheduleDateAfter
              ).isValid()
                ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateAfter + "000"
                    )
                  ).unix()
                : 0,
              scheduleDateBefore: momentJalaali(
                this.props.firstDropOff.scheduleDateBefore
              ).isValid()
                ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateBefore + "000"
                    )
                  ).unix()
                : 0,
            },
            "service": {
              "id": this.props.activeservice.id,
              "options": this.props.service_options.map((item)=>{
                return {dataId: item.item ? item.item.id : "" ,
                id: item.id }
              })
            },
            "promoCode": this.props.code,
            customerId:  get_Id(),
          
          }))

        }
      }
    );
  }

  render() {
    const { t } = this.props;

    if(!this.props.canShowPromoCode){
      return null
    }
    return (

      <div className="PromotionCodeContainer">
          <span> {t("PromotionCode")} </span>
          <div>
            <input onChange={this.promoCodeOnchange}  value={this.props.code} placeholder={t("EnterCode")}  /> 
           {this.props.code.length > 0 && <img src={this.props.valid ? icon_done : icon_ic_fail} className={"icon_discount"} /> }
          </div>
      </div>



      // <Slide
      //   timeout={650}
      //   direction={get_LeftRight()}
      //   in={this.props.canShowPromoCode}
      //   mountOnEnter
      //   unmountOnExit
      // >
      //   <div
      //   onClick={!this.state.openPromo && this.HandlePropmoOpen}
      //     className={`PromotionCodeContainer ${
      //       this.props.code.length > 0
      //         ? "ActivePromotionCodeContainer"
      //         : this.state.openPromo
      //         ? "ActivePromotionCodeContainer"
      //         : ""
      //     }`}
      //   >
      //     <img onClick={this.HandlePropmoOpen} src={this.props.valid ? icon_done : icon_discount} className={"icon_discount"} />
      //     <span onClick={this.HandlePropmoOpen} className="icon_discountHeaderText">
      //       <Trans i18nKey={"PromotionCode"}> </Trans>{" "}
      //     </span>

      //     <div className="promoCodeFileldsContainer">

      //         <input onChange={this.promoCodeOnchange} value={this.props.code} placeholder={this.props.t("PromotionCode")}  />
      //         <div className="promoCodeButtonsContainer">
      //             {!this.props.valid &&  <span className="buttonpromocode" onClick={this.Save}> <Trans i18nKey={"Save"}> </Trans>{" "} </span>}
      //               <span className={`buttonpromocode deletebuttonpromocode ${this.props.valid && "mrglr0px"}`} onClick={this.promoCodeDelete}> <Trans i18nKey={"Delete"}> </Trans>{" "} </span>

      //         </div>  
      //     </div>
      //   </div>
      // </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  code: state.NewOrder.newOrder.promotionCode.code,
  valid : state.NewOrder.newOrder.promotionCode.valid,
  pickup: state.NewOrder.newOrder.pickup,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  activeservice : state.NewOrder.newOrder.service.activeVehicleType ,
  service_options :state.NewOrder.newOrder.service.options ,
  activeVehicleType: state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  VehicleType_options:state.NewOrder.newOrder.driver.options,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  pickup_adresss_loc:state.NewOrder.newOrder.pickup.adresss_loc ,
  dropOffs_adresss_loc : state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc ,
  canShowPromoCode:
    state.NewOrder.newOrder.service.activeVehicleType &&
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType &&
    state.NewOrder.newOrder.pickup.adresss_loc.lng != "" &&
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc.lng != "",
    serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
