import myWorker from "./CalculatePriceWorker.worker";
import { SetPriceNewOrder } from "../../../Redux/actions/actionNames";
import { Api_calculateOrderCost_Ondemand_AGW } from "../../api/ApiGateway";
import { get_Id } from "../../UserData/userdate";

export const CalculatePriceWorker = (data, dispatch, noHttpOrder) => {

  const worker = new myWorker();
  worker.postMessage(data);
  worker.addEventListener("message", (event) => {
    if (event.data.succeessfull) {
      if (noHttpOrder) {
        dispatch(SetPriceNewOrder({}, { done: true }));
      } else {
        Api_calculateOrderCost_Ondemand_AGW(dispatch, {...event.data.data, customerId:get_Id()});
      }
    } else {
      dispatch(SetPriceNewOrder({ done: false }, { done: false }));
    }
    worker.terminate();
  });
};
