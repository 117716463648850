import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow2 from "../../../../../../../src/static/arrow-triangle-down.svg";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { SetActiveImportData } from "../../../../../../Redux/actions/actionNames";
import ListItem from "./Options/list";
import SelectableItem from "./Options/selectable";
import InputItem from "./Options/input";
import { Api_GetVehicleTypeByService_AGW } from "../../../../../../helper/api/ApiGateway";


const init = {
    showpoper: false,
    showpoper2: false,
    availableOrderTypes: ["PickupDelivery", "Delivery", "Pickup", "Ondemand"]
}

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = init;
        this.anchorEl = React.createRef();
        this.anchorEl2 = React.createRef();
    }

    handleSelecttOrderType = (type) => {
        this.props.dispatch(SetActiveImportData({
            id: this.props.ActiveItem.id,
            orderType: type
        }))
        this.hidepoperOrderType()
        setTimeout(() => {
            this.props.requiredsCheck()
        }, 1000);
    }


    handleSelecttService = (service) => {
        this.hidepoperService()

        Api_GetVehicleTypeByService_AGW(this.props.dispatch, {
            latitude: this.props.center.lat,
            longitude: this.props.center.lng,
            serviceId: service.id,
          });

        this.props.dispatch(SetActiveImportData({
            id: this.props.ActiveItem.id,
            service: {
                selectedService: service,
                options: []
            },
            vehicleType :{
                selectedVehicleType : null ,
                options : []
            },
        }))
        this.setState({
            showpoper: false,
            availableOrderTypes: service.orderTypes
        })

        if (this.props.ActiveItem.orderType && service.orderTypes.indexOf(this.props.ActiveItem.orderType) == -1) {
            this.handleSelecttOrderType(service.orderTypes[0])
        }
    }

    showpoperService = (e) => {
        this.setState({
            showpoper: true,
        });
    };
    hidepoperService = (e) => {
        this.setState({
            showpoper: false,
        });
    };

    showpoperOrderType = (e) => {
        this.setState({
            showpoper2: true,
        });
    };
    hidepoperOrderType = (e) => {
        this.setState({
            showpoper2: false,
        });
    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.ActiveItem.id != this.props.ActiveItem.id) {
            if (nextProps.ActiveItem.service.selectedService) {
                this.setState({
                    availableOrderTypes: nextProps.ActiveItem.service.selectedService.orderTypes
                })

                if (nextProps.ActiveItem.orderType && nextProps.ActiveItem.service.selectedService.orderTypes.indexOf(nextProps.ActiveItem.orderType) == -1) {
                    this.handleSelecttOrderType(nextProps.ActiveItem.service.selectedService.orderTypes[0])
                }
            }
        }
    }

    render() {
        let { t } = this.props;


        return (
            <React.Fragment>
                <div className="previewServiceAndOrderTypeContainer"  >
                    <p className="qopdlyuo"> <Trans i18nKey={"ServiceandOrdertype"}> </Trans> </p>
                    <div className="previewServiceContainer" ref={this.anchorEl}>

                        {!this.state.showpoper ? <div style={{ width: "100%", height: "32px" }} onClick={this.showpoperService} >  <img src={this.props.ActiveItem.service.selectedService && this.props.ActiveItem.service.selectedService.icon} className="srvimg" />
                            <span> {this.props.ActiveItem.service.selectedService ? this.props.ActiveItem.service.selectedService.title : <Trans i18nKey={"pleaseSellectParsel"}> </Trans>} </span>
                            <img className="srcolllapse" src={arrow2} />
                        </div> :

                            <ClickAwayListener onClickAway={this.hidepoperService}>
                                <MenuList
                                    autoFocusItem={this.state.activestop}
                                    id="menu-list-grow"
                                    style={{ padding: "0px", backgroundColor: "white", zIndex: "5", boxShadow: "0 2px 24px 0 rgba(128, 128, 128, 0.24)", borderRadius: "8px", maxHeight: "50vh", overflowY: "auto" }}
                                >
                                    {this.props.serviceVehicleTypes.map((item) => {
                                        return (
                                            <div
                                                className="pkifhifs"
                                                onClick={() => this.handleSelecttService(item)}
                                            >
                                                <img src={item.icon} className="serviceimage" />
                                                <span style={{
                                                    fontWeight: this.props.ActiveItem.service.selectedService
                                                        ? this.props.ActiveItem.service.selectedService.title == item.title
                                                            ? "bold"
                                                            : ""
                                                        : ""
                                                }}> {item.title} </span>
                                            </div>
                                        );
                                    })}
                                </MenuList>
                            </ClickAwayListener>

                        }

                    </div>
                    <div className="previewOrderTypes" ref={this.anchorEl2}>
                        {this.state.showpoper2 ? <ClickAwayListener onClickAway={this.hidepoperOrderType}>
                            <MenuList
                                autoFocusItem={this.state.activestop}
                                id="menu-list-grow"
                                style={{ padding: "0px", backgroundColor: "white", zIndex: "5", boxShadow: "0 2px 24px 0 rgba(128, 128, 128, 0.24)", borderRadius: "8px" }}
                            >
                                {
                                    this.state.availableOrderTypes.map((item) => {
                                        return <div
                                            className="pkifhifs feihefioef"
                                            onClick={() => this.handleSelecttOrderType(item)}
                                        >
                                            <span style={{
                                                fontWeight: this.props.ActiveItem.orderType
                                                    ? this.props.ActiveItem.orderType == item
                                                        ? "bold"
                                                        : ""
                                                    : ""
                                            }}>  <Trans i18nKey={item}> </Trans> </span>
                                        </div>
                                    })
                                }

                            </MenuList>
                        </ClickAwayListener> :

                            <div onClick={this.showpoperOrderType} style={{ width: "100%", height: "32px" }}>
                                <span> {this.props.ActiveItem.orderType ? <Trans i18nKey={this.props.ActiveItem.orderType}> </Trans> : <Trans i18nKey={"PleaseSelect"}> </Trans>} </span>
                                {/* PleaseSelect */}
                                <img src={arrow2} className="srcolllapse" />
                            </div>

                        }
                    </div>

                    <serviceOptions className={(!this.props.ActiveItem.service.selectedService) ? "displayNone" : ""} style={{
                        display: "flex", justifyContent: "flex-end", gap: "10px",
                        flexDirection: "row", flexWrap: "wrap", marginTop: "10px"
                    }}>
                        {this.props.ActiveItem.service.selectedService && this.props.ActiveItem.service.selectedService.options.map((item) => {
                            return item.type === "list" ? (
                                <ListItem ActiveItem={this.props.ActiveItem} {...item} />
                            ) : item.type === "input" ? (
                                <InputItem ActiveItem={this.props.ActiveItem} {...item} />
                            ) : <SelectableItem ActiveItem={this.props.ActiveItem} {...item} />

                        })}
                    </serviceOptions>





                </div>

                {/* <Popper
                    open={this.state.showpoper}
                    anchorEl={this.anchorEl.current}
                    role={undefined}
                    placement={"bottom-start"}
                    className="poperDialogePriviewSelectService"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            timeout={{ enter: 150, exit: 150 }}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper style={{ borderRadius: "9px" }}>
                                <ClickAwayListener onClickAway={this.hidepoperService}>
                                    <MenuList
                                        autoFocusItem={this.state.activestop}
                                        id="menu-list-grow"
                                        style={{ padding: "0px" }}
                                    >
                                        {this.props.serviceVehicleTypes.map((item) => {
                                            return (
                                                <div
                                                    className="pkifhifs"
                                                    onClick={() => this.handleSelecttService(item)}
                                                >
                                                    <img src={item.icon} className="serviceimage" />
                                                    <span style={{
                                                        fontWeight: this.props.ActiveItem.service.selectedService
                                                            ? this.props.ActiveItem.service.selectedService.title == item.title
                                                                ? "bold"
                                                                : ""
                                                            : ""
                                                    }}> {item.title} </span>
                                                </div>
                                            );
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper> */}
                {/* 
                <Popper
                    open={this.state.showpoper2}
                    anchorEl={this.anchorEl2.current}
                    role={undefined}
                    placement={"bottom-start"}
                    className="poperDialogePriviewSelectService"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            timeout={{ enter: 150, exit: 150 }}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper style={{ borderRadius: "9px" }}>
                                <ClickAwayListener onClickAway={this.hidepoperOrderType}>
                                    <MenuList
                                        autoFocusItem={this.state.activestop}
                                        id="menu-list-grow"
                                        style={{ padding: "0px" }}
                                    >
                                        {
                                            this.state.availableOrderTypes.map((item) => {
                                                return <div
                                                    className="pkifhifs feihefioef"
                                                    onClick={() => this.handleSelecttOrderType(item)}
                                                >
                                                    <span style={{
                                                        fontWeight: this.props.ActiveItem.orderType
                                                            ? this.props.ActiveItem.orderType == item
                                                                ? "bold"
                                                                : ""
                                                            : ""
                                                    }}>  <Trans i18nKey={item}> </Trans> </span>
                                                </div>
                                            })
                                        }

                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    vehicleTypes: state.Map.monitoring.vehicleTypes,
    serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,
    draftList: state.BulkImport.draftList,
    List: state.BulkImport.List,
    selectedItem: state.BulkImport.selectedItem,
    center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
