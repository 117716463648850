import DroppXIGO from "../../../static/AurelPack_logotype.png";
import DroppX_banner from "../../../static/AurelPack_background.png";

import FavIcon from "../../../static/PROJECTS/AurelPack.png";

export default function AurelPack() {
    
    return  global.config = {
        "project": "Portal de Clientes",
        "ShowPowerOnro": false,
        "flag": "Spanishflag",
        "ProjectName": "",
        "LogoType": DroppXIGO,
        "BaseApi": "https://rest.aurelapp.com",
        "BaseSocket": "https://socket.aurelapp.com",
        "BaseTrack": "https://tr.aurelapp.com",
        "MapCenter": {
            "lat": "18.888906899683207",
            "lng": "-70.1641798985629"
        },
        "Lang": "es",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "DO"
        },{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        },{
            "lang": "pt",
            "direction": "Ltr",
            "i18Lang": "Portuguese",
            "cssFlag": "Portugueseflag",
            "country": "PT"
    }],
        "FavIcon": "/AurelPack.png",
        "Country": "DO",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": DroppX_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"AurelPack",
        registerEnabled: true,
        Secondarycolor:"#FF6B00",
        SecondaryDarkColor:"#DC5D00",
        SecondaryLightColor:"#FF7F23",
        SecondaryUltraLight :"#FFF3EB",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#FF3537",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFF1E6",
        loginBackgroundColorEnd:"#FFDEC6",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:DroppXIGO,
        apkUrl:null,
        iosUrl:null
}}