export const EnglishLabel = {
   "Cutomerpanel": "Customer panel",
   "login": "Login",
   "Register": "Register",
   "en": "English",
   "fa": "Persian",
   "individual": "individual",
   "Business": "Business",
   "Phonenumber": "Phone number",
   "Continue": "Continue",
   "phonenumberhasnotregisterd": "Phone number hasn’t registerd",
   "Username": "Username",
   "title": "Title",
   "Password": "Password",
   "ExOnro": "Ex. company",
   "Exstart": "Ex. ******",
   "googleConnected": "Google connected",
   "facebookConnected": "Facebook connected",
   "appleConnected": "Apple Id connected",
   "plsenterphonnumber": "Please enter your phone number",
   "firstnnameloginplaceholder": "Ex. John",
   "businessNamePlaceholder": "Ex. ABC Company",
   "registerBusinessNameLabel":"Business Name",
   "lastnameloginplaceholder": "Ex. Doe",
   "Firstname": "First name",
   "Lastname": "Last name",
   "email": "Email",
   "emailPlaceholder": "(optional) Ex. example@example.com",
   "comingsoon": "Coming soon :",
   "comingsoondesc": "To Register your business Please click here",
   "comingsoondesc2": "To Create Business account please contact us",
   "contactUs": "contact us",   "CustomerAlreadyRegistered": "Customer has already registered, please log in",
   "registerCompleted": "Enter Verification Code",
   "googletokenerror": "Problem with Google Token",
   "facebooktokenerror": "Problem with Facebook Token",
   "appletokenerror": "Problem with Apple Token",
   "CustomerNotRegistered": "Customer Not Registered",
   "fr": "french",
   "InvalidVerificationCode": "Invalid Verification Code",
   "a": "Your information could not be found",
   "welcome": "welcome",
   "SmsLimitationError": "Please try again in a few moments",
   "resendCodeWithTime": "resend code (xxx)",
   "resendCode": "resend code",
   "VerificationcodePlaceHolder": "Ex. 1234",
   "Verificationcode": "Verification code",
   "VerificationcodeText": "Enter the code sent to xxx",
   "VerificationCodeExpired": "Verification Code Expired",
   "Pending": "Pending",
   "Unassigned": "Unassigned",
   "Assigned": "In Progress",
   "PickedUp": "In Progress",
   "Started": "In Progress",
   "statusInProgress": "In Progress",
   "Done": "Done",
   "CustomerCanceled": "Canceled",
   "DriverCanceled": "Canceled",
   "SupportCanceled": "Canceled",
   "Arrived": "In Progress",
   "new": "new",
   "Historyofyourorderswillbehere": "History of your orders will be here",
   "otherdropoffs": "other dropoffs",
   "Orderhistory": "Order History",
   "Noorder": "No order",
   "loadingList": "Be patient while receiving information",
   "loadingError": "Problem on getting information",
   "retry": "Try again",
   "Create_your_first_requst": "Create a new order",
   "List_of_you_active_orders_will_be_here":
      "List of your active orders will be here",
   "Aboutus": "About us",
   "Readour": "Read our",
   "privaypolicy": "privay policy",
   "Transactions": "Transactions",
   "NoTransaction": "You do not have a transaction",
   "Historyofyourransactionswillbehere":
      "History of your transactions will be here",
   "Settings": "Settings",
   "Language": "Language",
   "English": "English",
   "Persian": "Persian",
   "French": "French",
   "Logout": "Log out",
   "logotTitle": "Log out form account?",
   "logoutDescription":
      "You will be logged out from account and you should login later",
   "confirmLogout": "Yes, log out",
   "skipLogout": "Cancel",
   "savesettings": "Save settings",
   "emailInvalid": "email is invalid",
   "Neworder": "New Order",
   "Service": "Service",
   "poperLoading": "please wait",
   "Pickup": "Pickup",
   "SetPickup": "Set Pickup",
   "ChooseoOnMap": "Map",
   "Fornow": "For now",
   "Scheduled": "Scheduled",
   "before": "Before :",
   "after": "After :",
   "confirm": "Confirm",
   "Settime": "Set time",
   "fo": "Fo",
   "Cancel": "Cancel",
   "Shipmentlabel": "Shipment label",

   "Pleasetryanotherkeyword": "Please try another keyword",
   "Noinformationfound": "No information found",
   "address": "Address",
   "Floor": "Floor",
   "Unit": "Unit",
   "notesfordriver": "Notes for driver",
   "DropOff": "Drop off",
   "SetDropOff": "Set DropOff",
   "fetchingdata": "Receiving information...",
   "undefined": "Service type",
   "PromotionCode": "Promo code",
   "Delete": "Delete",
   "Save": "Save",
   "PromoCodeValid": "Promo Code Saved",
   "SenderWallet": "Wallet",
   "SenderCash": "Cash",
   "ReceiverCash": "Pay by Receiver",
   "lowBalance": "low balance",
   "Balance": "Balance",
   "Methods": "Methods",
   "balanceError": "Your balance must be at least equal to order price",
   "SendOrder": "Send Order",
   "feedback": "Feedback",
   "Feedback": "Feedback",
   "Orderagain": "Order again",
   "Createdsuccessfully": "Created successfully",
   "chooseAmount": "Choose Amount",
   "orenterthepreferedamount": "Preferred amount:",
   "enteramount": "your desired price",
   "pay": "Pay",
   "selectPaymentGateway": "Select Payment Gateway",
   "Time_MachineText":
      "Do you want to recover your previously unregistered order information ?",
   "yes": "Yes",
   "ignore": "ignore",
   "Order": "Order",
   "statusPending": "Pending",
   "statusUnassigned": "Unassigned",
   "statusAssigned": "Assigned",
   "statusStarted": "Started",
   "statusPickedUp": "PickedUp",
   "statusDone": "Done",
   "statusCustomerCanceled": "Customer Canceled",
   "statusDriverCanceled": "Driver Canceled",
   "statusArrived": "Arrived",
   "statusSupportCanceled": "Support Canceled",
   "statusCanceled": "Canceled",
   "Phone": "Phone",
   "additonalservice": "additonal service",
   "CallDriver": "Call Driver",
   "Message": "Message",
   "orderInfoError": "Error receiving information",
   "support": "Support",
   "map": "Map",
   "receipt": "Receipt",
   "photo": "photo",
   "ServicePhoto": "Service Photo",
   "Addphoto": "Add photo",
   "Estime": "Es. time",
   "Espath": "Es. path",
   "pod": "Proof of delivery",
   "Notes": "Notes",
   "Photo": "Photo",
   "Signature": "Signature",
   "Nonotes": "No notes",
   "Nosignature": "No signature",
   "Nophoto": "No photo",
   "Price": "Price",
   "Receiver": "By Receiver",
   "Sender": "By Sender",
   "Cash": "Cash",
   "Canceltravel": "Cancel Order",
   "CancellText": "Order will be canceled, are you sure? ",
   "Estimated": "Estimated",
   "Wallet": "Wallet",
   "Copylink": "Copy link",
   "repeatOrder": "Repeat Order",
   "Edit": "Edit",
   "confirmCancel": "Yes",
   "paymentmethod": "Payment method",
   "SucceessFul": "done successfully",
   "errorinServer": "Server problem",
   "Comment": "Comment",
   "Exfeedbackinput": "Ex. he delivered very fast",
   "Wallett": "Wallet",
   "TypeHere": "Type Here",
   "Loadingmessages": "Loading messages",
   "PoweredBy": "Powered By",
   "newincomemessage": "You received a new message",
   "newincomemessageanother": "You have a new message in another order",
   "youhaveunreadmessage": "Unread message",
   "Nointernetconnection": "No internet connection",
   "close": "close",
   "seen": "Seen",
   "controlThatbeforeLoweEqualAfter":
      "Time 'Before' cannot be less than 'After'",
   "controlThatbeforeAndAfterMustBiggerthanCurrentDate":
      "The 'before' and 'after' times could not be shorter than the current time",
   "errorInPanelSetting": "Error in reservation settings, please contact support",
   "Arabic": "Arabic",
   "ar": "Arabic",
   "veeDeliveryLoginText1": "Send Anything",
   "veeDeliveryLoginText2": "To Anyone, NOW!",
   "Schedule": "Schedule",
   "Date": "Date",
   "Time": "Time ",
   "noDateToShow": "There is no Date to display",
   "noTimeToShow": "There is no Time to display",
   "Apply": "Apply",
   "Increaseyourbalance": "Increase your balance",
   "AddBalance": "Add Balance",
   "Addressbook": "Address Book",
   "AddressbookSearchbar": "Search by address, title and code",
   "Removedefault": "Remove default",
   "Setdefaultpickup": "Set default pickup",
   "Remove": "Remove",
   "newAddress": "New Address",
   "NewAddressbookInfoTitle": "Info",
   "Addressdetails": "Address details",
   "Title": "Title",
   "Code": "Code",
   "editAddress": "Edit Address",
   "AddressbookDropOf": "Address book (Drop off)",
   "AddressbookPickup": "Address book (Pickup)",
   "hide": "Hide",
   "Nosavedaddress": "No saved address",
   "NosavedaddressDesc":
      "You Can Create One, by clicking the bookmark icon next to address or go to address book page",
   "Romanian": "Romanian",
   "ro": "Romanian",
   "Vehicletype": "Vehicle Type",
   "Pickup2": "Pickup",
   "Dropoff2": "Dropoff",
   "senderfullname": "Sender Name",
   "block": "Block",
   "senderphonenumber": "Sender number",
   "Recieverfullname": "Receiver Name",
   "Recieverphonenumber": "Receiver Number",
   "Events": "Events",
   "allEvents": "All Events",
   "star": "star",
   "Yourrate": "Your rate",
   "Comment": "Comment",
   "CopyOrderlink": "Copy Order link",
   "CopyPickuplink": "Copy Pickup link",
   "CopyDeliverylink": "Copy Delivery link",
   "Dutch": "dutch",
   "nl": "dutch",
   "paymentmethod": "Payment method",
   "Name": "Name",
   "CashOnDelivery": "Cash on Delivery",
   "EnterAmount": "Enter amount",
   "COD": "Cash on Delivery",
   "Surchargexxx": "Surcharge xxx",
   "Pricedetails": "Price details",
   "minxxx": "xxx min ",
   "toPickup": "to Pickup",
   "toDropOff": "to DropOff",
   "AddCreditCard": "Add Credit/Debit Card",
   "CustomerWallet": "Customer Wallet",
   "Add": "Add",
   "Addedsuccessfully": "Added successfully",
   "paymentmethods": "Payment methods",
   "NoCards": "No Cards",
   "Listofyourcardswillbehere": "List of your cards will be here",
   "CardInfo": "Card info",
   "ExpireDate": "Expire date",
   "CVC": "CVC",
   "PostalCode": "Postal Code",
   "RemoveCardq": "Remove Card?",
   "RemoveCardA": "The card will be removed and can’t be used anymore",
   "YesRemove": "Yes, Remove",
   "Cardisinuse": "Card is in use",
   "ok": "Ok",
   "Pleasetypeyourwordstosearch": "Please type your keyword to search",
   "spanish": "Spanish",
   "es": "Spanish",
   "Invoices": "Invoices",
   "noInvoice": "No. xxx",
   "germany": "Germany",
   "de": "Germany",
   "downloadClientApplication": "Download xxx application",
   "AssignedAt": "Assign till xxx",
   "StartedAt": "Start till xxx",
   "ArrivedAt": "Arrive till xxx",
   "PickedUpAt": "Pickup at xxx",
   "DoneAt": "Finish at xxx",
   "Russian": "Russian",
   "Azerbaijani": "Azerbaijani",
   "rus": "Russian",
   "aze": "Azerbaijani",
   "Support": "Support",
   "SendRequest": "Send request",
   "CallTheSupport": "Call the support",
   "Choosethesubject": "Choose the subject",
   "Seefrequentquestions": "See frequent questions",
   "Details": "Details",
   "Send": "Send",
   "MessageBox": "Message Box",
   "NoMessages": "No messages",
   "Yourmessageswillbehere": "Your messages will be here",
   "Portuguese": "Portuguese",
   "pt": "Portuguese",
   "Greek":"Greek",
   "el":"Greek",
   "changePassword" : "Change password",
   "changePasswordp1" : "You will leave all sessions, with the exception of this, to protect your account, who is trying to access",
   "changePasswordp2" : "Your password should be at least 6 characters",
   "currentPassword":"Current Password",
   "newpassword":"New Password",
   "Retypenewpassword":"Enter a new password again",
   "account": "Account (wallet)",
   "Required":"Necessary",
   "registerBussinessUsername":"User name (at least 5 characters)",
   "registerBussinessPassword":"Password (at least 6 characters)",
   "ReferenceID":"Reference ID",
   "EnterId":"Enter id",
   "Orderid": "Order id",
   "EnterCode":"Enter Code",
   "AustrianGerman":"Austrian German",
   "deu":"Austrian German",
   "pop":"Proof of Pickup",
   "Options":"Options",
   "RequiredText":"Required",
   "PleaseSelect":"Please Select",
   "Optimizedropoffs":"Optimize dropoffs",
   "Optimizedropoffs?":"Optimize dropoffs?",
   "OptimizedropoffsDesc":"The sequence of dropoffs will be changed to optimized sequence and it may change price",
   "Optimize":"Optimize", 
   "zh":"Chinese Simplified",
   "ChineseSimplified":"Chinese Simplified",
   "Bulkimport":"Bulk Import",
   "processing":"Proccessing" ,
    "done":"Finished" ,
    "failed":"Failed",
    "Draft":"Draft" ,
    "Uploading":"Uploading" ,
    "Draganddropyourfile":"Drag and drop your file",
    "Onlycsvformatissupported":"Only .csv format is supported" ,
    "ORyoucan":"OR you can",
    "Openfile":"Open file" ,
    "Noimportsyet":"No imports yet" ,
    "NoimportsyetDesc":"You can see the list of your imports here and check their status",
    "nosupportdialogeTitle":"File Formart not supported",
    "nosupportdialogeText":"xxx file is not supported. you can only upload .csv file including order info" ,
    "Import":"Import",
    "previewTitle":"Preview and choose Options",
    "previewDesc":"This is the first item of your files to check your info is in the right structure" ,
    "Upload":"Upload", 
    "Remove":"Remove" ,
    "ServiceandOrdertype":"Service and Order type" ,
    "Fileisproccessing":"File is proccessing ...",
    "proccessingDesc":"You can refresh to see if the result is ready",
    "serverError":"Server Error",
    "isRequired":"xxx is required" ,
    "Result":"Result",
    "ResultText":"You can see the problems here and download these rows to fix seperatly",
    "ErrorDetails":"Orders with error",
    "ErrorDetailsText":"Orders with errors are listed in the file that you can download below. It contains error details for each row.",
    "DownloadErroredRows":"Download errored rows" ,
    "importedsuccessfully":"xxx orders are imported successfully" ,
    "ordershaserrors":"xxx orders has errors",
    "refresh":"Refresh",
    "pleaseSellectParsel":"Please Select Service",
    "Ondemand":"On Demand",
    "PickupandDelivery":"Pickup and Delivery",
    "PickupDelivery":"Pickup and Delivery",
    "Delivery":"Delivery",
    "Pickup":"Pickup",
    "tab_Delivery":"Delivery",
    "tab_Ondemand":"On Demand",
    "tab_Pickup":"Pickup",
    "tab_PickupDelivery":"P&D",
    "Downloadsamplecsv":"Download sample CSV" ,
   "Distance":"Distance" ,
   "Duration" :"Duration",
   "driverPhoto":"Driver Photo",
   "yourPhoto":"Your Photo",
   "No":"No",
   "confirmCancel2":"Yes, Cancel order",
   "UploadPhoto":"Upload Photo",
   "Photos":"Photos",
   "ParcelPhoto":"Parcel Photo",
   "ProofofPickup":"Proof of Pickup",
   "EditPhoto":"Edit Photo",


   "TrackLink":"Track Link",
   "ShipmentLabel":"Shipment Label",
   "Draft":"Draft",
   "Receipt":"Receipt",
   "Confirm":"Confirm",
   "Cancel":"Cancel",
   "BulkEditStatusSummary":"Bulk Edit Status Summary",
   "Successful":"Successful",
   "Failed":"Failed",
   "OrderID":"Order ID",
   "Details":"Details",
   "Result":"Result",
   "New":"New",
   "Import":"Import",
   "Apply":"Apply",
   "Clear":"Clear",
   "Noresultfound":"No result found",
   "Typeyourkeyword":"Type your keyword",
   "Typeintheinputtostartsearch":"Type in the input to start search",
   "OnDemand":"On Demand",
   "P_Hub_D":"P_Hub_D",
   "AddressDetails":"Address Details",
   "Map":"Map",
   "Support":"Support",
   "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
   "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
   "xxxfromyyy":"xxx from yyy",
   "xxxrows":"xxx rows",
   "xxxselected":"xxx selected",
   "xxxdropoffs":"+xxx dropoffs",
   "Status Updated at xxx":"Status Updated at xxx",
   'More':'More',
   'Summary':'Summary',
   'Prev':'Prev',
   'Next':'Next',
   'Done':'Done',
   "ClearAll":"Clear All",
   'Errorinloadingdata':'Error in loading data',
   'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
   'Tryagain':'Try again',
   'ConfigTable' :'Config Table' ,
   'ActiveColumns':'Active Columns',
   'Createyourorder':'Create your order',
   'Yourorderswillbeshownhere':'Your orders will be shown here',
   'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   'CustomerPhoto':'Customer Photo',
   "NoAttachments":"No Attachments",
   "AllAttachmentswillbeshownhere":"All Attachments will be shown here",
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap"


};
