import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";



var _ = require("lodash");

class App extends React.Component {

  render() {
    return (
        <div className="NoOrderContainer">
          <div className="NoOrderInner mrb90">
          <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> 
            <span className="NoOrderTitle">
              {" "}
              <Trans i18nKey={"loadingList"}> </Trans>{" "}
            </span>
          </div>
        </div>)
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
