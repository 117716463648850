import mannaevIGO from "../../../static/mannaev_logo.png";
import mannaev_banner from "../../../static/mannaev_banner.png";
import FavIcon from "../../../static/PROJECTS/mannaev.ico";

export default function Mannaev() {
    
    return  global.config = {
        "project": "Client Panel",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "MannaEV Delivery | Fast Delivery, Anytime, Anywhere",
        "LogoType": mannaevIGO,
        "BaseApi": "https://rest.mannaev.com",
        "BaseSocket": "https://socket.mannaev.com",
        "BaseTrack": "https://admin.mannaev.com",
        "MapCenter": {
            "lat": 35.89889744137674,
            "lng":14.51367707540481
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "zh",
            "direction": "Ltr",
            "i18Lang": "ChineseSimplified",
            "cssFlag": "ChineseSimplifiedflag",
            "country": "US"
        }],
        "FavIcon": "/mannaev.png",
        "Country": "MT",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": mannaev_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"mannaev",
        registerEnabled: true,
        Secondarycolor: "#FFCD30",
        SecondaryDarkColor: "#EFB500",
        SecondaryLightColor: "#FFD348",
        SecondaryUltraLight: "#fffaea",
        SecondaryTextColor: "#111111",
        Secondaryonlight: "#111111",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#111111",
        icon_light_end:"#2E2E31",
        icon_dark_start:"#FFCD30",
        icon_dark_end:"#FFD348",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FBF2D6",
        loginBackgroundColorEnd:"#FFEEB7",
        LoginTextColor :"#111111",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:mannaevIGO,
        apkUrl:null,
        iosUrl:null
} }