import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow from "../../../../../../static/chevron-down.svg";
import event_flash from "../../../../../../static/event_arrow.png";
import momentJalaali from "moment-jalaali";
import Collapse from "@material-ui/core/Collapse";
import { get_lang } from "../../../../../../helper/UserData/userdate";

class App extends React.Component {




  render() {
    return (
      // <div className="EventTimeLine_ListItem">
      //   <div className="lineContainer">
      //     <div className="upper" style={{ backgroundColor: this.props.isFirst && "white" }}></div>
      //     <div className="loweer" style={{ backgroundColor: this.props.isLast && "white" }}></div>
      //   </div>
      //   <div className="circule"></div>
      //   <div className="detailsContainer">
      //     <div className="detailsContainer_details">
      //       <span className="status"> {this.props.title} </span>
      //       <span className="statusDescription"> {this.props.description} </span>
      //     </div>
      //   </div>
      // </div>

      <div className="EventTimeLine_ListItem">

        <div className="lineContainer">
          <div className="upper" style={{ backgroundColor: this.props.isFirst && "white" }}></div>
          <div className="loweer" style={{ backgroundColor: this.props.isLast && "white" }}></div>
        </div>
        <div className="circule"></div>
        {this.props.firstItemInArray && <span className="ltext">
          {new Date(this.props.createdAt).toLocaleString(get_lang(), {

            year: "numeric",
            month: 'long',
            day: 'numeric',
          })

          }
          {/* {momentJalaali(this.props.createdAt).locale(get_lang()).format("D MMM YY")}  */}

        </span>
        }
        <div className="detailsContainer" >

          <div className="detailsContainer_details">
            <span className="status"> {this.props.title} </span>
            <span className="time">  {momentJalaali(this.props.createdAt).locale(get_lang()).format("HH:mm")} </span>
            <span className="statusDescription"> {this.props.description} </span>
          </div>
        </div>



      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
