import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {ReactComponent as Add1} from "../../../../../static/ic1.svg";
import {ReactComponent as Add2} from "../../../../../static/icon-ic-notes1.svg";
import {ReactComponent as Add3} from "../../../../../static/icon-ic-person.svg";
import {ReactComponent as Note} from "../../../../../static/ic-note-for-driver.svg";
import {ReactComponent as Email} from "../../../../../static/ic_person.svg";
import removeD from "../../../../../static/icon-ic-close-bold.svg";
import setD from "../../../../../static/iconly-bold-tick-square.svg";
import removeAd from "../../../../../static/icon-link-copy-3.svg";
import editAd from "../../../../../static/icon-ic-edit.svg";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Set_DropOff_Inputs, Set_latlng, Set_Pickup_Inputs ,ChooseoOnMapimg, SetopenAddressBookInNewOrder, OnChangeNewAddressBookInNewOrderDetails, SetSelectedAB } from "../../../../../Redux/actions/actionNames";
import { get_customerPannel_type } from "../../../../../helper/UserData/userdate";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { setCenterMap } from "../../../../../helper/module";

var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.anchorEl = React.createRef();
  }

  showPoper = () => {
    this.setState({ showpoper: true });
  };

  setAddressToPD = () => {

    this.props.dispatch(
      ChooseoOnMapimg({
        showMap: false,
        pickupDropOff: {
          type: null,
          id: null,
        },
      })
    );

    (!isDesktop && !isTablet )&& this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));


    try {
      setCenterMap(this.props.mapRef , {lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]} )
      // this.props.mapRef.current.leafletElement.setView({lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]}, 17);
    } catch (error) {
      this.props.dispatch(
        Set_latlng({
          lng: this.props.AddressData.coordinates[0],
          lat: this.props.AddressData.coordinates[1],
        })
      );
    }

    if (this.props.sourceOfAddressBookInNewOrder.type == "dropoff") {


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: this.props.AddressData.address,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value:  {lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]} ,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "block",
          value: this.props.AddressData.buildingBlock,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "floor",
          value: this.props.AddressData.floor,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "recieverFullName",
          value: this.props.AddressData.fullName,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "recieverPhoneNumber",
          value: (this.props.AddressData.phone && this.props.AddressData.phone.length > 0) ?(String(this.props.AddressData.phone).startsWith("00") ? "+"+String(this.props.AddressData.phone).slice(2, this.props.AddressData.phone.length) :String(this.props.AddressData.phone) ) : "" ,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "noteForDriver",
          value: this.props.AddressData.description,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "email",
          value: this.props.AddressData.email,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "dropoff",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );




    } else {


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "address",
          value: this.props.AddressData.address,
        })
      );


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "adresss_loc",
          value:  {lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]} ,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "block",
          value: this.props.AddressData.buildingBlock,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "floor",
          value: this.props.AddressData.floor,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "senderFullName",
          value: this.props.AddressData.fullName,
        })
      );


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "senderPhoneNumber",
          value: (this.props.AddressData.phone && this.props.AddressData.phone.length > 0) ? (String(this.props.AddressData.phone).startsWith("00") ? "+"+String(this.props.AddressData.phone).slice(2, this.props.AddressData.phone.length) : String(this.props.AddressData.phone) ) : "" ,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "unit",
          value: this.props.AddressData.room,
        })
      );


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "noteForDriver",
          value: this.props.AddressData.description,
        })
      );
      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "email",
          value: this.props.AddressData.email,
        })
      );

      
    }
    this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
  
  };


  getBlockFloorRoom =()=>{
    if(this.props.AddressData.buildingBlock.length > 0 || this.props.AddressData.room.length > 0 || this.props.AddressData.floor.length > 0){
      let arr=[];
      this.props.AddressData.buildingBlock.length > 0 && arr.push(this.props.AddressData.buildingBlock)
      this.props.AddressData.room.length > 0 && arr.push(this.props.AddressData.room)
      this.props.AddressData.floor.length > 0 && arr.push(this.props.AddressData.floor)


      return arr.join(" , ")
    }else {
      return false
    }
  }

  getNamePhone =()=>{
    if(this.props.AddressData.phone.length > 0 || this.props.AddressData.fullName.length > 0 ){

      let arr=[];

      this.props.AddressData.phone.length > 0 && arr.push(this.props.AddressData.phone)
      this.props.AddressData.fullName.length > 0 && arr.push(this.props.AddressData.fullName)

      return arr.join(" , ")
    }else {
      return false
    }
  }

  getNote = () => {
    if (
      this.props.AddressData.description.length > 0 
    ) {
      return this.props.AddressData.description
    } else {
      return false;
    }
  };


  getEmail = () => {
    if (
      this.props.AddressData.email.length > 0 
    ) {
      return this.props.AddressData.email
    } else {
      return false;
    }
  };


  render() {
    return (
      <div className="addressItemMenuContainer" onClick={this.setAddressToPD}>
        <span className="admcT"> {this.props.AddressData.title}</span>
       {(get_customerPannel_type() == "business" && this.props.AddressData.isDefaultPickupAddress) && <span className="admcDFP"> Default pickup</span>}
        {/* <span className="admcMM" onClick={this.showPoper} ref={this.anchorEl}>
          {" "}
          ...
        </span> */}

        <div className="admcI">
        <Add1 className="img add1" style={{borderRadius:"50%"}}/>
          <span> {this.props.AddressData.address}</span>
        </div>

        {this.getBlockFloorRoom() && <div className="admcI">
        <Add2 className="img add2" />
          <span> {this.getBlockFloorRoom()}</span>
        </div>}

       {this.getNamePhone() && <div className="admcI">
       <Add3 className="img add3" />
          <span> {this.getNamePhone()}</span>
        </div>}

        {this.getNote() && (
          <div className="admcI">
            <Note className="img add4" />
            <span> {this.getNote()}</span>
          </div>
        )}

      {this.getEmail() && (
          <div className="admcI">
            <Email  className="img add5"/>
            <span> {this.getEmail()}</span>
          </div>
        )}

        {/* <Popper
          open={this.state.showpoper}
          anchorEl={this.anchorEl.current}
          role={undefined}
          style={{
            width: "160px",
            marginBottom: "4px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "10px",
            // position: "absolute",
            // left: "17px",
            backgroundColor: "white",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
          }}
          className={"PopperAddressbookMenuContainer"}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Paper>
              <ClickAwayListener
                onClickAway={(e) => {
                  this.setState({ showpoper: false });
                }}
              >
                <div autoFocusItem={this.state.activestop} id="menu-list-grow">
                  <thdotItem>
                    {" "}
                    <img src={removeD} />{" "}
                    <span className="spanRED">
                      {" "}
                      <Trans i18nKey={"Removedefault"}> </Trans>{" "}
                    </span>
                  </thdotItem>
                  <thdotItem>
                    {" "}
                    <img src={setD} />{" "}
                    <span className="spanblue">
                      {" "}
                      <Trans i18nKey={"Setdefaultpickup"}> </Trans>{" "}
                    </span>
                  </thdotItem>
                  <thdotItem>
                    {" "}
                    <img src={removeAd} />{" "}
                    <span>
                      {" "}
                      <Trans i18nKey={"Remove"}> </Trans>{" "}
                    </span>
                  </thdotItem>
                  <thdotItem onClick={this.props.onEdit}>
                    {" "}
                    <img src={editAd} />{" "}
                    <span>
                      {" "}
                      <Trans i18nKey={"Edit"}> </Trans>{" "}
                    </span>
                  </thdotItem>
                </div>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mapRef: state.Map.mapRef,
  sourceOfAddressBookInNewOrder:
  state.Addressbook.sourceOfAddressBookInNewOrder,
  center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
