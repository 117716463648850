import React, { useState } from "react";
import service_box from "../../../../../static/service-box.jpg";
import pickup from "../../../../../static/icon-ic-pickup.svg";
import dropoff from "../../../../../static/group-4-copy.svg";
import car from "../../../../../static/icon-car.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { ChangeViewTravelDialoge, openCloseOrderHistory } from "../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import jalali from "jalali-moment";
import { Api_get_Order_Info_AGW } from "../../../../../helper/api/ApiGateway";


import {ReactComponent as Sion} from "../../../../../static/Mutual___ic_calender.svg";
import Pickup from "./OrderTypes_ListItem/Pickup";
import Delivery from "./OrderTypes_ListItem/Delivery";
import PickupDelivery from "./OrderTypes_ListItem/PickupDelivery";
import Ondemand from "./OrderTypes_ListItem/Ondemand";
import _, { get } from "lodash";
import history from "../../../../../service/history";

class App extends React.Component {

  openOrderClick = () => {
    history.push(`/order/${this.props.id}`);
    // // this.props.dispatch(openCloseOrderHistory(false))
    // this.props.dispatch(ChangeViewTravelDialoge(true, this.props.id));
    // Api_get_Order_Info_AGW(this.props.dispatch, this.props.id);
  }

  render() {
    return <div
      className={`OrderItemContainer `}
      onClick={this.openOrderClick}
      id={this.props.id}
    >
      <div className="OrderItem_firstrow">
        {/* {this.props.data.service && (
        <img src={this.props.data.service.icon} />
      )} */}
        <span className="service_box">
          {" "}
          {this.props.data.service && this.props.data.service.title}
        </span>

        <span
          className={`OrderItemCarState OrderList_${this.props.data.status}`}
        >
          {this.props.data.statusDescription}
        </span>
      </div>
      {this.props.type == "Ondemand" &&
        this.props.data.dropoffs.length > 1 ? (
          <span className="otherdropoffs">
            {`+${this.props.data.dropoffs.length - 1}`}
          </span>
        ) : (
          ""
        )}

      {this.props.type == "Pickup" ? (
        <Pickup pickup={this.props.pickup} />
      ) : this.props.type == "Delivery" ? (
        <Delivery dropoff={this.props.dropoff} />
      ) : this.props.type == "PickupDelivery" ? (
        <PickupDelivery
          pickup={this.props.pickup}
          dropoff={this.props.dropoff}
        />
      ) : (
              <Ondemand pickup={this.props.pickup} dropoffs={this.props.dropoffs} />
            )}

      <div className="pickupdropoffscontainer">

      <span className="coprfu">
                {new Date(
                  Number(
                    this.props.data.createdAt 
                  )
                ).toLocaleDateString(get_lang(), {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hourCycle: 'h23'
                })}
              </span>


        {/* {!_.isEmpty(this.props.data.vehicleType) &&
          <React.Fragment>
            <img
              src={this.props.data.vehicleType.icon}
              className="OrderItemCar"
            />
            <span className="OrderItemCarName">
              {" "}
              {this.props.data.vehicleType.title}{" "}
            </span>
          </React.Fragment>
        } */}
        {this.props.data.pickup ? (this.props.data.pickup.scheduleDateAfter ||
          this.props.data.pickup.scheduleDateBefore ? (
            <div className="scheduledOrderTimeConatiner">
             <Sion className="img" />
              <span>
                {new Date(
                  Number(
                    this.props.data.pickup.scheduleDateAfter ||
                    this.props.data.pickup.scheduleDateBefore
                  )
                ).toLocaleDateString(get_lang(), {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hourCycle: 'h23'
                })}
              </span>
            </div>
          ) : null) : null}


      </div>
    </div>
  }
}

const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
