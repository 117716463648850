import LogoType from "../../../static/Solutions1000.png";
import Banner from "../../../static/Solutions1000@3x.png";

import FavIcon from "../../../static/PROJECTS/Solutions1000_fav_icon.png";

export default function Solutions1000() {
    
    return  global.config = {
        "project": "1000 Solutions Concierge Services",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "1000 Solutions Concierge Services",
        "LogoType": LogoType,
        "BaseApi": "https://rest.1000solutions.ae",
        "BaseSocket": "https://socket.1000solutions.ae",
        "BaseTrack": "https://track.1000solutions.ae",
        "MapCenter": {
            "lat": 23.849715915483884,
            "lng": 54.14074233520282
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "AE",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Solutions1000 County Couriers ",
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Solutions1000",
        registerEnabled: false,
        Secondarycolor:"#5F6062",
        SecondaryDarkColor:"#414042",
        SecondaryLightColor:"#7B7D80",
        SecondaryUltraLight :"#EFEFEF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#5F6062",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#EEEEEE",
        loginBackgroundColorEnd:"#D9D9D9",
        LoginTextColor :"#5F6062",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}