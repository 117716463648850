import myWorker from "./DetectOndemandOrReserve.worker";

export const DetectOndemandOrReserve = (data, call) => {
  const worker = new myWorker();
  worker.postMessage(JSON.stringify(data));
  worker.addEventListener("message", (event) => {
    call(event.data);
    worker.terminate();
  });
};
