import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import link from "../../../../static/icon-link.svg";
import route_optimize from "../../../../static/route_optimize.svg";
import cancell from "../../../../static/ic_cancel.svg";
import warning from "../../../../static/warning.svg";
import linkicon from "../../../../static/icon-link-copy-3.svg";
import end from "../../../../static/ic-close-copy.svg";
import price from "../../../../static/ic-edit-price.svg";
import edit from "../../../../static/icon-edit.svg";
import shipment_label from "../../../../static/ic_shipment_label.svg";
import { withTranslation, Trans } from "react-i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
// import {
//   OpenCloseEditTravel,
//   EditTravelSetPrice,
//   EditTRravelSetServiceVehicleTypes,
//   EditTravelSetServiceActiveVehicleType,
//   EditTravelSetCustomer,
//   EditTravelAddServiceOption,
//   EditTravelSetDriverAuto,
//   EditTravelSetManualDriver,
//   Edit_Travel_Set_Auto_ActiveVehicleType,
//   EditTravelSetAutoDriver,
//   Edit_Driver_Add_Driver_Option,
//   Edit_Travel_Set_Pickup,
//   Edit_Travel_Set_Dropoffs,
//   Edit_Travel_PaymentMethod,
//   EditTravelFeeChange,
//   EditTravelSurchargesChange,
//   EditTravelSurcharges,
//   Edit_Travel_Operate_Changed,
// } from "../../../../../Redux/actions/actionNames";
// import {
//   CanEditTravel,
//   CanEndTravel,
//   CanCancelTravel,
//   isOrderInProgress,
//   isOrderInDone,
//   isOrderInProgressOrCancelDone,
//   CanRepeatTravel,
//   repeatReuqest,
// } from "../../../../helper/module";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { get_currencyCode, get_lang } from "../../../../helper/UserData/userdate";
// import {
//   SocketOrderCancel,
//   SocketOrderDone,
// } from "../../../../../socket/Emit_handelers";
// import {
//   get_accessToken,
//   get_id,
//   get_currencyCode,
//   get_lang,
// } from "../../../../../helper/UserData/userdate";
// import { OrderInfoAction, OrderEditondemandAction, CancelOrderAction, DoneOrderAction } from "../../../../../Redux/actions/actions";
import ChangeMethod from "./changeMethod";
import { getCenterMap, isOrderInCancel, isOrderInDone, isOrderInProgress, repeatReuqest } from "../../../../helper/module";
import { Api_Cancel_Order_AGW, Api_Get_AcountInformation_AGW, Api_Get_Services_AGW, Api_get_Order_Info_AGW } from "../../../../helper/api/ApiGateway";
import { Setfeedback, UpdateStateOrderInList } from "../../../../Redux/actions/actionNames";
import Factor_Price from "./factor_Price";
import priceinfo from "../../../../static/icon-photo-copy.svg";
import { CircularProgress } from "@material-ui/core";
import { Customer_ondemand_optimize_dropoffs_Api } from "../../../../helper/api";
import ViewOrderEditStatusOrder from "./footer/editStatus";

var _ = require("lodash");
var numeral = require("numeral");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showpoper: false,
      cancelTravel: false,
      finishTravel: false,
      priceChange: false,
      showOptimizedropoffs: false,
      loadingOptimizedropoffs: false,
      fee: "0",
      surcharges: [],
    };
    this.anchorEl = React.createRef();
  }

  // savePrice = () => {
  //   this.props.dispatch(
  //     OrderEditondemandAction(
  //       this.props.dispatch,
  //       {
  //         baseFarePrice: Number(this.state.fee),
  //         surcharges: this.state.surcharges.map((item) => {
  //           return { price: Number(item.price), id: item.id };
  //         }),
  //         id: this.props.Data.id,
  //         userId: get_id(),
  //       },
  //       null,
  //       null
  //     )
  //   );
  // };

  repeatOrder = () => {
    // Api_Get_Services_AGW(
    //   this.props.dispatch,
    //   this.props.mapRef.current
    //     ? getCenterMap(this.props.mapRef, this.props.center)
    //     : this.props.center
    // );

    repeatReuqest(this.props.pureData, this.props.dispatch);
  };

  // feeOnchange = (e) => {
  //   this.props.dispatch(Edit_Travel_Operate_Changed());

  //   this.setState({
  //     fee: e.target.value,
  //   });
  // };

  // SurchargesOnchange = (e, id) => {
  //   this.props.dispatch(Edit_Travel_Operate_Changed());

  //   this.setState({
  //     surcharges: this.state.surcharges.map((item) => {
  //       return item.id == id ? { ...item, price: e.target.value } : item;
  //     }),
  //   });
  // };

  // EditpriceOnChange = () => {
  //   if (this.state.priceChange) {
  //     this.setState({
  //       priceChange: !this.state.priceChange,
  //       surcharges: [],
  //       fee: "0",
  //     });
  //   } else {
  //     this.setState({
  //       priceChange: !this.state.priceChange,
  //       surcharges: this.props.surcharges,
  //       fee: this.props.baseFarePrice,
  //     });
  //   }
  // };

  // EditPrice = () => {};

  // EditTravel = () => {
  //   this.setState({ showpoper: false });
  //   if (this.props.Data) {
  //     this.props.dispatch(
  //       EditTravelSetPrice({
  //         price: this.props.Data.price,
  //         currencyCode: this.props.Data.currencyCode,
  //       })
  //     );
  //     this.props.dispatch(
  //       EditTRravelSetServiceVehicleTypes(this.props.serviceVehicleTypes)
  //     );
  //     try {
  //       this.props.dispatch(
  //         EditTravelSetServiceActiveVehicleType(
  //           this.props.serviceVehicleTypes.filter((service) => {
  //             return service.id == this.props.Data.service.id;
  //           })[0]
  //         )
  //       );
  //     } catch (error) {}
  //     this.props.dispatch(
  //       EditTravelSetCustomer({
  //         ...this.props.Data.customer,
  //         person: !!this.props.Data.customerId,
  //         BusinessName: this.props.Data.business ? this.props.Data.business.title : "",
  //         BusinessNumber: this.props.Data.business ? this.props.Data.business.phone : "",
  //         customerId: this.props.Data.customerId,
  //         id: this.props.Data.customerId,
  //         balance: this.props.Data.customerWallet.balance,
  //       })
  //     );
  //     this.props.Data.service.options.map((service) => {
  //       return this.props.dispatch(
  //         EditTravelAddServiceOption(
  //           service.type === "list"
  //             ? {
  //                 id: service.id,
  //                 item: {
  //                   id: service.dataId,
  //                   title: service.dataTitle,
  //                 },
  //                 name: undefined,
  //                 type: "list",
  //               }
  //             : {
  //                 id: service.id,
  //                 name: undefined,
  //                 type: "boolean",
  //               }
  //         )
  //       );
  //     });
  //     this.props.dispatch(
  //       EditTravelSetDriverAuto(
  //         _.isEmpty(this.props.Data.driver) ? true : false
  //       )
  //     );
  //     if (!_.isEmpty(this.props.Data.driver)) {
  //       let VehicleType = null;
  //       try {
  //         let VehicleType = this.props.auto_suggestListData.filter(
  //           (service) => service.id === this.props.Data.vehicletype.id
  //         )[0];
  //         this.props.dispatch(
  //           EditTravelSetManualDriver({
  //             manual_activeVehicleType: VehicleType || {
  //               options: [],
  //               ...this.props.Data.vehicletype,
  //             },
  //             manual_code: this.props.Data.driver.username,
  //             manual_family: this.props.Data.driver.familyName,
  //             manual_id: this.props.Data.driverId,
  //             manual_name: this.props.Data.driver.givenName,
  //             manual_number: this.props.Data.driver.phone,
  //           })
  //         );
  //       } catch (error) {
  //         this.props.dispatch(
  //           EditTravelSetManualDriver({
  //             manual_activeVehicleType: VehicleType || {
  //               options: [],
  //               ...this.props.Data.vehicletype,
  //             },
  //             manual_code: this.props.Data.driver.username,
  //             manual_family: this.props.Data.driver.familyName,
  //             manual_id: this.props.Data.driverId,
  //             manual_name: this.props.Data.driver.givenName,
  //             manual_number: this.props.Data.driver.phone,
  //           })
  //         );
  //       }
  //     } else {
  //       let VehicleType = null;
  //       try {
  //         let VehicleType = this.props.auto_suggestListData.filter(
  //           (service) => service.id === this.props.Data.vehicletype.id
  //         )[0];
  //         this.props.dispatch(
  //           EditTravelSetAutoDriver({
  //             auto_activeVehicleType: VehicleType,
  //           })
  //         );
  //       } catch (error) {
  //         this.props.dispatch(
  //           EditTravelSetAutoDriver({
  //             auto_activeVehicleType: null,
  //           })
  //         );
  //       }
  //     }
  //     this.props.Data.options.map((option) => {
  //       return this.props.dispatch(
  //         Edit_Driver_Add_Driver_Option(
  //           option.type === "list"
  //             ? {
  //                 id: option.id,
  //                 item: {
  //                   id: option.dataId,
  //                   title: option.dataTitle,
  //                 },
  //                 name: undefined,
  //                 type: "list",
  //               }
  //             : {
  //                 id: option.id,
  //                 name: undefined,
  //                 type: "boolean",
  //               }
  //         )
  //       );
  //     });
  //     this.props.dispatch(EditTravelFeeChange(this.props.Data.baseFarePrice));
  //     let surcharges = this.props.Data.surcharges.map((item) => {
  //       return { id: item.id, price: item.price };
  //     });
  //     this.props.dispatch(EditTravelSurcharges(this.props.Data.surcharges));
  //     this.props.dispatch(
  //       Edit_Travel_Set_Pickup({
  //         address: this.props.Data.pickup.address,
  //         buildingBlock: this.props.Data.pickup.buildingBlock,
  //         adresss_loc: {
  //           lng: this.props.Data.pickup.coordinates[0],
  //           lat: this.props.Data.pickup.coordinates[1],
  //         },
  //         noteForDriver: this.props.Data.pickup.description,
  //         floor: this.props.Data.pickup.floor,
  //         senderFullName: this.props.Data.pickup.fullName,
  //         senderPhoneNumber: this.props.Data.pickup.phone,
  //         unit: this.props.Data.pickup.room,
  //         scheduleDateAfter: this.props.Data.pickup.scheduleDateAfter,
  //         scheduleDateBefore: this.props.Data.pickup.scheduleDateBefore,
  //       })
  //     );
  //     let otherDropOffs = JSON.parse(JSON.stringify(this.props.Data.dropoffs));
  //     otherDropOffs.shift();
  //     otherDropOffs = otherDropOffs.map((drop) => {
  //       return {
  //         address: drop.address,
  //         adresss_loc: {
  //           lng: drop.coordinates[0],
  //           lat: drop.coordinates[1],
  //         },
  //         id: this.idgenerator(),
  //         block: drop.buildingBlock,
  //         floor: drop.floor,
  //         unit: drop.room,
  //         recieverFullName: drop.fullName,
  //         recieverPhoneNumber: drop.phone,
  //         noteForDriver: drop.customerDescription,
  //         scheduleDateAfter: drop.scheduleDateAfter,
  //         scheduleDateBefore: drop.scheduleDateBefore,
  //       };
  //     });
  //     this.props.dispatch(
  //       Edit_Travel_Set_Dropoffs({
  //         firstDropOff: {
  //           address: this.props.Data.dropoffs[0].address,
  //           adresss_loc: {
  //             lng: this.props.Data.dropoffs[0].coordinates[0],
  //             lat: this.props.Data.dropoffs[0].coordinates[1],
  //           },
  //           scheduleDateAfter: this.props.Data.dropoffs[0].scheduleDateAfter,
  //           scheduleDateBefore: this.props.Data.dropoffs[0].scheduleDateBefore,
  //           block: this.props.Data.dropoffs[0].buildingBlock,
  //           floor: this.props.Data.dropoffs[0].floor,
  //           unit: this.props.Data.dropoffs[0].room,
  //           recieverFullName: this.props.Data.dropoffs[0].fullName,
  //           recieverPhoneNumber: this.props.Data.dropoffs[0].phone,
  //           noteForDriver: this.props.Data.dropoffs[0].customerDescription,
  //         },
  //         otherDropOffs: otherDropOffs,
  //       })
  //     );
  //     this.props.dispatch(
  //       Edit_Travel_PaymentMethod({
  //         paymentMethod: this.props.Data.paymentMethod,
  //         paymentSide: this.props.Data.paymentSide,
  //         title:
  //           this.props.Data.paymentMethod == "Cash" &&
  //           this.props.Data.paymentSide == "Receiver"
  //             ? "Reciver"
  //             : this.props.Data.paymentMethod == "Cash" &&
  //               this.props.Data.paymentSide == "Sender"
  //             ? "Cash"
  //             : this.props.Data.paymentMethod == "Wallet" &&
  //               this.props.Data.paymentSide == "Sender"
  //             ? "Balance"
  //             : "Reciver",
  //       })
  //     );
  //     setTimeout(() => {
  //       this.props.dispatch(OpenCloseEditTravel(this.props.Data.id));
  //     }, 500);
  //   }
  // };

  // idgenerator = () => {
  //   var number = Math.random(); // 0.9394456857981651
  //   number.toString(36); // '0.xtis06h6'
  //   var id = number.toString(36).substr(2, 9); // 'xtis06h6'
  //   return id.toString();
  // };


  priceChange = (key) => {

    this.setState({
      EditPriceOpen: key
    })

    this.setState({ showpoper: false });

  }


  cancelTravel = () => {

    this.setState({ cancelTravel: false });

    Api_Cancel_Order_AGW(
      this.props.dispatch,
      { id: this.props.ID, type: this.props.orderType },
      (res) => {
        this.props.dispatch(UpdateStateOrderInList({
          status: res.status,
          id: this.props.ID,
          data: res.payload
        }))
        Api_Get_AcountInformation_AGW(this.props.dispatch, {}, (call) => { });
      }
    );
  };


  optimizeRoutes = () => {

    this.setState({ loadingOptimizedropoffs: true, showOptimizedropoffs : false});
    Customer_ondemand_optimize_dropoffs_Api(this.props.dispatch , {orderId : this.props.ID}, ()=>{
      this.setState({ loadingOptimizedropoffs: false});
      Api_get_Order_Info_AGW(this.props.dispatch, this.props.ID);

    })
  };



  // finshTravel = () => {
  //   this.props.dispatch(
  //     DoneOrderAction(this.props.dispatch, this.props.Data.id, (call) => {
  //       this.props.dispatch(
  //         OrderInfoAction(this.props.dispatch, {
  //           orderId: this.props.Data.id
  //         })
  //       );
  //     })
  //   );
  // };

  saveToClipboardTrackOrder = () => {
    var copyText = document.getElementById("trackOrder");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.setState({ showpoper: false });
  };


  handleOpenRouteOptimize = () => {

    this.setState({
      showOptimizedropoffs: !this.state.showOptimizedropoffs
    })
  };

  saveToClipboardTrackPickupOrder = () => {
    var copyText = document.getElementById("pickupOrderTrack");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.setState({ showpoper: false });
  };


  saveToClipboardTrackDeliveryOrder = () => {
    var copyText = document.getElementById("deliveryOrderTrack");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");
    this.setState({ showpoper: false });
  };

  feedbackShow = () => {
    this.props.dispatch(
      Setfeedback({
        open: true,
      })
    );
  };


  Shipmentlabel = () => {
    // var copyText = document.getElementById("Shipmentlabel");

    /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    // document.execCommand("copy");
    window.open(this.props.Data.shipmentLabelUrl + "?lng=" + get_lang(), '_blank').focus();
    this.setState({ showpoper: false });

  }

  feeOnchange = (e) => {
    // this.props.dispatch(Edit_Travel_Operate_Changed());

    // this.setState({
    //   showpoper: false
    // });
  };



  render() {
    return (
      <vRFooter
        className={
          isOrderInDone(this.props.Data.status) ||
            isOrderInCancel(this.props.Data.status)
            ? "vRFooterFullHeight"
            : ""
        }
      >
        <img src={priceinfo} onClick={this.priceChange} className="coifhefc" />
        {/* <EditPrice noC open={this.state.EditPriceOpen} priceChange={this.priceChange} /> */}
        <Factor_Price open={this.state.EditPriceOpen} priceChange={this.priceChange} />

        <costConrainer>
          {/* <cost>
            {(this.props.Data &&
              Number(
                this.props.Data.price -
                (this.props.promoCode.length > 0 ? this.props.promoAmount : 0)
              ).toLocaleString(get_lang(), {
                style: "currency",
                currency: get_currencyCode(),
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })) ||
              0}
          </cost> */}

            <cost style={{ fontSize: "13px", color: "red", height: "auto", textDecoration: "line-through" }}>
              {(this.props.Data &&
                Number(
                  this.props.Data.price
                ).toLocaleString(get_lang(), {
                  style: "currency",
                  currency: get_currencyCode(),
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })) ||
                0}
            </cost>
          {/* {(this.props.promoCode.length > 0 ? this.props.promoAmount > 0 : false) &&
            <cost style={{ fontSize: "13px", color: "red", height: "auto", textDecoration: "line-through" }}>
              {(this.props.Data &&
                Number(
                  this.props.Data.price
                ).toLocaleString(get_lang(), {
                  style: "currency",
                  currency: get_currencyCode(),
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })) ||
                0}
            </cost>} */}
        </costConrainer>
        <vr style={{
              float: 'inline-start',
              height: '20px',
              width: '1px',
              backgroundColor: 'rgba(128, 128, 128, 0.2)',
              marginTop: '18px',
              marginInlineEnd: '14px'
        }} />
        {this.props.Data && <ChangeMethod OrderData={this.props.Data} />}

        {(isOrderInDone(this.props.Data.status) ||
          isOrderInCancel(this.props.Data.status)) && (
            <div className={`vRfooterButtonsContainer `}>
              {isOrderInDone(this.props.Data.status) && this.props.orderType == "Ondemand" &&
             /* !this.props.Data.hasFeedbackPageSeen && */ !_.isEmpty(this.props.driver) && (
                  <feedback onClick={this.feedbackShow}>
                    <Trans i18nKey={"feedback"}> </Trans>
                  </feedback>
                )}

              {(isOrderInCancel(this.props.Data.status) || isOrderInDone(this.props.Data.status)) && (
                <orderagain onClick={this.repeatOrder}>
                  <Trans i18nKey={"Orderagain"}> </Trans>
                </orderagain>
              )}
            </div>
          )}



        {!isOrderInDone(this.props.Data.status) &&
          !isOrderInCancel(this.props.Data.status) && (
            <span
              onClick={() => {
                this.setState({ showpoper: true });
              }}
              ref={this.anchorEl}
              className="thdot"
            >
              ...
            </span>
          )}

        {/* {isOrderInDone(this.props.Data.status) && (
          <span
            className="ReceiptContainer"
            onClick={() => {
              try {
                var url = new URL(this.props.Data.customerReceiptUrl);
                url.searchParams.append("lng", get_lang());

                window.open(url, "_blank");
              } catch (error) {}
            }}
          >
            <img src={linkicon} />
            <span>
              {" "}
              <Trans i18nKey={"Receipt"}> </Trans>
            </span>
          </span>
        )} */}

        <input
          type="text"
          value={this.props.Data.trackOrder + "?lng=" + get_lang()}
          id="trackOrder"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        />

        <input
          type="text"
          value={this.props.Data.pickupOrderTrackUrl + "?lng=" + get_lang()}
          id="pickupOrderTrack"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        />


        <input
          type="text"
          value={this.props.Data.deliveryOrderTrackUrl + "?lng=" + get_lang()}
          id="deliveryOrderTrack"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        />

        <Popper
          open={this.state.showpoper}
          anchorEl={this.anchorEl.current}
          role={undefined}
          style={{
            width: "173px",
            marginBottom: "4px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "10px",
            // position: "absolute",
            // left: "17px",
            backgroundColor: "white",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
          }}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Paper>
              <ClickAwayListener
                onClickAway={(e) => {
                  this.setState({ showpoper: false });
                }}
              >
                <div autoFocusItem={this.state.activestop} id="menu-list-grow">
                  {(['Pending', 'Started', 'Arrived', 'Assigned', 'Unassigned'].indexOf(this.props.status) > -1 && this.props.dropoffs.length > 1 ) && <thdotItem onClick={this.handleOpenRouteOptimize}>
                    {" "}
                    <img src={route_optimize} />{" "}
                    <span className="spanblue">
                      {" "}
                      <Trans i18nKey={"Optimizedropoffs"}> </Trans>{" "}
                    </span>
                  </thdotItem>}

                  <ViewOrderEditStatusOrder onclick={()=>{this.setState({ showpoper: false });}} />

                  <thdotItem onClick={this.saveToClipboardTrackOrder}>
                    {" "}
                    <img src={link} />{" "}
                    <span className="spanblue">
                      {" "}
                      <Trans i18nKey={"CopyOrderlink"}> </Trans>{" "}
                    </span>
                  </thdotItem>
                  {(this.props.Data.pickupOrderTrackUrl && this.props.Data.pickupOrderTrackUrl.length > 0) &&
                    <thdotItem onClick={this.saveToClipboardTrackPickupOrder}>
                      {" "}
                      <img src={link} />{" "}
                      <span className="spanblue">
                        {" "}
                        <Trans i18nKey={"CopyPickuplink"}> </Trans>{" "}
                      </span>
                    </thdotItem>
                  }

                  {(this.props.Data.deliveryOrderTrackUrl && this.props.Data.deliveryOrderTrackUrl.length > 0) &&
                    <thdotItem onClick={this.saveToClipboardTrackDeliveryOrder}>
                      {" "}
                      <img src={link} />{" "}
                      <span className="spanblue">
                        {" "}
                        <Trans i18nKey={"CopyDeliverylink"}> </Trans>{" "}
                      </span>
                    </thdotItem>
                  }
                  {/* {CanRepeatTravel(this.props.Data.status) && (
                    <thdotItem onClick={this.repeatOrder}>
                      {" "}
                      <img src={refresh} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"repeatOrder"}> </Trans>
                      </span>
                    </thdotItem>
                  )} */}
                  {/* {CanEditTravel(this.props.Data.status) && (
                    <thdotItem onClick={this.EditTravel}>
                      {" "}
                      <img src={edit} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"Edit"}> </Trans>
                      </span>
                    </thdotItem>
                  )} */}
                  {/* {CanEditTravel(this.props.Data.status) && (
                    <thdotItem onClick={this.EditpriceOnChange}>
                      {" "}
                      <img src={price} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"EditPrice"}> </Trans>
                      </span>
                    </thdotItem>
                  )} */}
                  {/* {CanEndTravel(this.props.Data.status) && (
                    <thdotItem
                      onClick={() => {
                        this.setState({
                          finishTravel: true,
                        });
                      }}
                    >
                      {" "}
                      <img src={end} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"Endtrip"}> </Trans>{" "}
                      </span>
                    </thdotItem>
                  )} */}

                  <thdotItem
                    onClick={this.Shipmentlabel}
                  >
                    {" "}
                    <img src={shipment_label} />{" "}
                    <span>
                      {" "}
                      <Trans i18nKey={"Shipmentlabel"}> </Trans>{" "}
                    </span>
                  </thdotItem>
                  {
                    <thdotItem
                      onClick={() => {
                        this.setState({
                          cancelTravel: true,
                        });
                      }}
                    >
                      {" "}
                      <img src={cancell} />{" "}
                      <span>
                        {" "}
                        <Trans i18nKey={"Cancel"}> </Trans>{" "}
                      </span>
                    </thdotItem>
                  }


                </div>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper>


        {/* <input
          type="text"
          value={this.props.Data.shipmentLabelUrl  + "?lng=" + get_lang()}
          id="Shipmentlabel"
          style={{
            opacity: "0",
            width: "1px",
            height: "1px",
            color: "white",
            border: "none",
            outline: "none",
          }}
        /> */}



        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.cancelTravel}
          onClose={() => {
            this.setState({ cancelTravel: false });
          }}
          className="logoutContainer"
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          {" "}
          <p
            className="logotTitle"
          // style={{
          //   direction: this.props.direction,
          //   lineHeight: "1.7",
          //   width: "auto",
          //   maxWidth: "320px",
          //   marginBottom: "0px",
          //   paddingRight: "21px",
          //   paddingLeft: "21px",
          //   marginTop: "16px",
          //   color: "black",
          //   fontSize: "13px",
          //   fontFamily: "IRANSans",
          //   textAlign: this.props.direction == "Ltr" ? "left" : "right",
          //   fontWeight: "bold",
          // }}
          >
            <Trans i18nKey="Canceltravel"> </Trans>
          </p>
          <DialogContent>
            <DialogContentText
              style={{
                direction: this.props.direction,
                lineHeight: "1.7",
                width: "auto",
                color: "#242424",
                fontSize: "13px",
                fontFamily: "IRANSans",
                textAlign: this.props.direction == "Ltr" ? "left" : "right",
              }}
              id="alert-dialog-description"
            >
              <Trans i18nKey="CancellText"> </Trans>
            </DialogContentText>
          </DialogContent>
          <div className="cancellBtnsContainer">
            <Button
              onClick={this.cancelTravel}
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "white",
                height: "30px",
                minHeight: "30px",
                marginTop: "1px",
                width: "129px",
                fontSize: "12px",
                backgroundColor: "#3583e0",
                border: "none",
              }}
              variant="contained"
              className="confirmCancellOrder"
            >
              <Trans i18nKey="confirmCancel"> </Trans>
            </Button>
            <Button
              onClick={() => {
                this.setState({ cancelTravel: false });
              }}
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "#148be9",
                height: "30px",
                minHeight: "30px",
                marginTop: "1px",
                width: "auto",
                fontSize: "12px",
                backgroundColor: "transparent",
                border: "none",
              }}
              variant="contained"
              className="SkipCancellOrder"
            >
              <Trans i18nKey="Cancel"> </Trans>
            </Button>
          </div>
        </Dialog>



        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.showOptimizedropoffs}
          onClose={() => {
            this.setState({ showOptimizedropoffs: false });
          }}
          className="logoutContainer"
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          {" "}
          <p
            className="logotTitle showOptimizedropoffsTitles"
          >
            <img src={warning} style={{marginBottom:"4px"}} />
            <Trans i18nKey="Optimizedropoffs?"> </Trans>

            <span><Trans i18nKey="OptimizedropoffsDesc"> </Trans>
            </span>
          </p>
          <div className="OptimizedropoffsBtnsContainer">
            <Button
              onClick={this.optimizeRoutes}
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "white",
                height: "30px",
                minHeight: "30px",
                marginTop: "1px",
                fontSize: "12px",
                backgroundColor: "#3583e0",
                border: "none",
                borderRadius:"8px",
                textTransform:"none"
              }}
              variant="contained"
              className="confirmCancellOrder"
            >
              <Trans i18nKey="Optimize"> </Trans>
            </Button>
            <Button
              onClick={() => {
                this.setState({ showOptimizedropoffs: false });
              }}
              style={{
                boxShadow: "none",
                fontFamily: "IRANSans",
                color: "#242424",
                height: "30px",
                minHeight: "30px",
                marginTop: "1px",
                width: "auto",
                fontSize: "12px",
                backgroundColor: "transparent",
                border: "none",
                borderRadius:"8px",
                textTransform:"none"
              }}
              variant="contained"
              className="SkipCancellOrder"
            >
              <Trans i18nKey="Cancel"> </Trans>
            </Button>
          </div>
        </Dialog>

        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.loadingOptimizedropoffs}
          className="logoutContainer"
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
            <CircularProgress size="32px" style={{margin:"24px" , color:"#1080FF"}}  />
        </Dialog>


       

      </vRFooter>
    );
  }
}

const mapStateToProps = (state) => ({
  center: state.Map.center,
  mapRef: state.Map.mapRef,
  promoAmount: state.ViewTravel.pureData ? state.ViewTravel.pureData.promoAmount : 0,
  promoCode: state.ViewTravel.pureData ? state.ViewTravel.pureData.promoCode || "" : "",
  pureData: state.ViewTravel.pureData,
  socket: state.Socket.socket,
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  driver: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driver
      ? state.ViewTravel.pureData.driver
      : null
    : null,
  serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,
  auto_suggestListData:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,
  baseFarePrice: state.ViewTravel.pureData ? state.ViewTravel.pureData.baseFarePrice : 0,
  surcharges: state.ViewTravel.pureData ? state.ViewTravel.pureData.surcharges : [],
  type: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
    : null,
  ID: state.ViewTravel.ID
    ? state.ViewTravel.ID
    : null,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
    dropoffs: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.dropoffs
      ? state.ViewTravel.pureData.dropoffs
      : []
    : [],
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
