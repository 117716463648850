import LogoType from "../../../static/ParcelBeep.png";
import Banner from "../../../static/ParcelBeep_background.png";

import FavIcon from "../../../static/PROJECTS/ParcelBeepFavIcon.png";

export default function ParcelBeep() {
    
    return  global.config = {
        "project": "ParcelBeep",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": LogoType,
        "BaseApi": "https://rest.parcelbeep.com",
        "BaseSocket": "https://socket.parcelbeep.com",
        "BaseTrack": "https://admin.parcelbeep.com",
        "MapCenter": {
            "lat": "5.605217795374032",
            "lng": "-0.18625258232021014"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GH",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "22473321295-4c70bmem00jpk4sg94aaurp692crbno7.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"ParcelBeep",
        registerEnabled: true,
        Secondarycolor: "#F5B201",
        SecondaryDarkColor: "#D39C02",
        SecondaryLightColor: "#FFB900",
        SecondaryUltraLight: "#FEF9EB",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#F5B201",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#000000",
        icon_light_end:"#404142",
        icon_dark_start:"#F5B201",
        icon_dark_end:"#FFB900",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFF6DF",
        loginBackgroundColorEnd:"#FFE49D",
        LoginTextColor :"#404142",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}