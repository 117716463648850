const loadDynamicScript = (url , id , callback) => {
    const existingScript = document.getElementById(id);
    
    if (!existingScript) {
      console.log("loadDynamicScript ",url)
        const script = document.createElement('script');
        script.src = url; // URL for the third-party library being loaded.
        script.id = id; // e.g., googleMaps or stripe
        script.async = "true"; // e.g., googleMaps or stripe
        script.defer = "true"; // e.g., googleMaps or stripe


        document.body.appendChild(script);
    
        script.onload = () => {
          if (callback) callback();
        };
      }
    
      if (existingScript && callback) callback();
    };

    export default loadDynamicScript