
import logotype from "../../../static/Sanic_logotype.png";
import banner from "../../../static/Cp_background_Sanic.png";
import FavIcon from "../../../static/PROJECTS/Sanic.png";

export default function Sanic() {
    
    return global.config = {
        "project": "app.saniclogistics.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Sanic Logistics",
        "LogoType": logotype,
        "BaseApi": "https://rest.saniclogistics.com",
        "BaseSocket": "https://socket.saniclogistics.com",
        "BaseTrack": "https://panel.saniclogistics.com",
        "MapCenter": {
            "lat": -1.2831410178486997,
            "lng": 36.820336478719554
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "KE",
        "AppleLoginCustomerId": 'com.sanic',
        "AppleLoginredirectURI": 'https://app.saniclogistics.com',
        "FacebookLoginappId": null,
        "GoogleLogincustomerId": "322374247775-h61th3t4h81lik9i3c5rnah105fajt2c.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"sanic",
        registerEnabled: true,
        Secondarycolor:"#F6931E",
        SecondaryDarkColor:"#E77D00",
        SecondaryLightColor:"#FFA133",
        SecondaryUltraLight :"#FEF4E8",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#010101",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:logotype,
        apkUrl:null,
        iosUrl:null
} }