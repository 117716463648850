import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../static/chevron-down.svg";
import trash from "../../../../static/remove.svg";
import { ReactComponent as Plus } from "../../../../static/plus.svg";
import { ReactComponent as ChooseonMap } from "../../../../static/icon-ic-pin.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import {
  Add_dropoff,
  Set_DropOff_Inputs,
  Set_Pickup_address,
  Set_SearchAutoCompleteLoading,
  Set_latlng,
  ChooseoOnMapimg,
  SetopenAddressBookInNewOrder,
  SetopenNewAddressBookInNewOrder,
  SetNewAddressBookInNewOrderDetails,
  Set_Map_Geocoding,
} from "../../../../Redux/actions/actionNames";
import // SearchAutoCompleteAction,
  // SearchAddressAction,
  // PlaceIdDetailsAction,
  // getFavAddressInfoAction,
  "../../../../Redux/actions/actions";
import closeCircle from "../../../../static/close-cricle.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import Schedule from "./Schedule";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../helper/UserData/userdate";
import {
  Api_map_geocoding_reverse_AGW,
  Api_PlaceIdDetails_AGW,
  Api_SearchAutoComplete_AGW,
} from "../../../../helper/api/ApiGateway";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { DetectOndemandOrReserve } from "../../../../helper/worker/DetectOndemandOrReserve";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getCenterMap, getCountryWithDomain, setCenterMap } from "../../../../helper/module";
import { ReactComponent as NewAddressbook } from "../../../../static/icon-ic-pin-dark.svg";
import { ReactComponent as Abookmark } from "../../../../static/iconly-bulk-bookmark.svg";
import { ReactComponent as UAbookmark } from "../../../../static/iconly-light-outline-bookmark.svg";
import { DetectOndemandOrPickupDelivery } from "../../../../helper/worker/DetectOndemandOrReserve copy";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from 'uuid';


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      loading: false,
      ordered: true,
      showpoper: false,
      showpoperSaved: false,
      getFavAddressLoading: false,
      searchAddrees: "",
      list: [],
      canSelectReserveForDropOff: false,
      sessionToken: null
    };
    this.anchorEl = React.createRef();
    this.ChooseoOnMapimg = this.ChooseoOnMapimg.bind(this);
  }

  componentDidMount() {
    this.canSelectReserveForDropOff();
    this.CanShowAddDropOff()

  }


  openAddressbook = () => {
    if (this.props.openAddressBook && this.props.addressBookSource) {
      this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
    } else {
      this.props.dispatch(SetopenAddressBookInNewOrder(true, "dropoff", this.props.id, "AddressbookDropOf"))
    }
  };



  handleshowpoper = () => {
    this.setState({
      showpoper: !this.state.showpoper,
      searchAddrees: "",
      showpoperSaved: false,
    });

  };
  handleshowpoperDrop = () => {
    // if (this.props.customerId) {
    //   this.setState({
    //     showpoperSaved: !this.state.showpoperSaved,
    //     searchAddrees: "",
    //     showpoper: false,
    //   });
    // }
  };

  handleSavedDropInput = (e) => {
    this.setState({
      searchAddrees: e.target.value,
    });
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    if (isDesktop || isTablet) {
      if (this.props.activePickupDropOff.id === this.props.id) {
        this.props.handleCollapse({
          type: "dropoff",
          id: this.props.id,
        });

      } else {

        this.props.handleCollapse({
          type: "dropoff",
          id: this.props.id,
        });
      }
    } else {
      this.props.handleCollapse({
        type: "dropoff",
        id: this.props.id,
      });
    }
  };

  handleDropOffInputs = (e, name) => {

    if (e.target.id === "address" || name === "address") {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4()
      })

      this.setState({
        showpoper: true
      })
      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: { lng: "", lat: "" },
          firstDropOff: this.props.id === "dropoff",
          id: this.props.id,
        })
      );
      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "placeId",
          value: "",
          firstDropOff: this.props.id === "dropoff",
          id: this.props.id,
        })
      );
      this.props.dispatch(Set_SearchAutoCompleteLoading(true));
      let center = getCenterMap(this.props.mapRef, this.props.center);
      if (e.target.value != "") {
        e.target.value.length > 2 && Api_SearchAutoComplete_AGW(this.props.dispatch, {
          query: e.target.value,
          latitude: this.props.mapRef.current
            ? center.lat
            : this.props.center.lat,
          longitude: this.props.mapRef.current
            ? center.lng
            : this.props.center.lng,
          sessionToken: this.state.sessionToken
        });

        // this.props.dispatch(
        //   SearchAutoCompleteAction(this.props.dispatch, {
        //     query: e.target.value,
        //     latitude: this.props.mapRef.current.leafletElement.getCenter().lat,
        //     longitude: this.props.mapRef.current.leafletElement.getCenter().lng,
        //   })
        // );
      }
    }
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: name || e.target.id,
        value: e.target.value,
        firstDropOff: this.props.id === "dropoff",
        id: this.props.id,
      })
    );
  };

  handleList = (item) => {

    if (item.coordinates) {
      this.props.dispatch(
        Set_Map_Geocoding({
          address: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
        })
      );
      this.mapSetView({ lng: item.coordinates[0], lat: item.coordinates[1] });
      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
          firstDropOff: this.props.id === "dropoff",
          id: this.props.id,
        })
      );
      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: {
            lng: item.coordinates[0],
            lat: item.coordinates[1],
          },
          firstDropOff: this.props.id === "dropoff",
          id: this.props.id,
        })
      );

      this.props.dispatch(
        Set_latlng({
          lng: item.coordinates[0],
          lat: item.coordinates[1],
        })
      );

      this.setState({
        sessionToken: null
      })


    } else {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4()
      }, () => {

        Api_PlaceIdDetails_AGW(
          this.props.dispatch,
          { placeId: item.placeId, sessionToken: this.state.sessionToken },
          (res) => {
            this.mapSetView({ lng: res.coordinates[0], lat: res.coordinates[1] });
            this.props.dispatch(
              Set_Map_Geocoding({
                address: res.address,
              })
            );
            this.props.dispatch(
              Set_DropOff_Inputs({
                name: "address",
                value: res.address,
                firstDropOff: this.props.id === "dropoff",
                id: this.props.id,
              })
            );
            this.props.dispatch(
              Set_DropOff_Inputs({
                name: "adresss_loc",
                value: {
                  lng: res.coordinates[0],
                  lat: res.coordinates[1],
                },
                firstDropOff: this.props.id === "dropoff",
                id: this.props.id,
              })
            );
            this.props.dispatch(
              Set_latlng({
                lng: res.coordinates[0],
                lat: res.coordinates[1],
              })
            );
          }
        );

      })

    }

    if (this.props.activePickupDropOff.id != this.props.id) {
      this.props.handleCollapse({
        type: "dropoff",
        id: this.props.id,
      });

    }

  };

  componentWillReceiveProps(nextProps) {

    DetectOndemandOrPickupDelivery(
      nextProps.serviceactiveVehicleType ? nextProps.serviceactiveVehicleType.orderTypes : [],

      (callx) => {
        if (callx === "ondeman") {
          this.setState({ CanShowAddDropOff: true })
        } else {
          this.setState({ CanShowAddDropOff: false })

        }
      })


    if (this.props.adresss_loc.lat != nextProps.adresss_loc.lat) {
      this.setState({
        showpoper: false
      });
    }

    this.setState({
      id: nextProps.firstDropOff ? "dropoff" : nextProps.id,
    });
    this.canSelectReserveForDropOff(nextProps);
  }
  canSelectReserveForDropOff = (props) => {
    DetectOndemandOrReserve(
      {
        pickup: props ? props.pickup : this.props.pickup,
        dropOffs: props
          ? [props.firstDropOff, props.otherdropOffs]
          : [this.props.firstDropOff, this.props.otherdropOffs],
      },
      (call) => {

        if (call == "reserve") {
          this.setState({
            canSelectReserveForDropOff: true,
          });
        } else {
          this.setState({
            canSelectReserveForDropOff: false,
          });
        }
      }
    );
  };


  validate(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  resetPickup = () => {
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: "address",
        value: "",
        firstDropOff: this.props.id === "dropoff",
        id: this.props.id,
      })
    );
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: "adresss_loc",
        value: { lng: "", lat: "" },
        firstDropOff: this.props.id === "dropoff",
        id: this.props.id,
      })
    );
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: "placeId",
        value: "",
        firstDropOff: this.props.id === "dropoff",
        id: this.props.id,
      })
    );


  };

  removeDropOff = () => {
    this.props.removeDrop(this.props.id);
  };

  hanldeOpenNewFav = () => {
    // this.props.dispatch(OpenNewFav(true));
  };

  mapSetView = (e) => {
    try {
      setCenterMap(this.props.mapRef, e)
      // this.props.mapRef.current.leafletElement.setView(e, 17);
    } catch (error) {
      this.props.dispatch(
        Set_latlng({
          lng: e.lng,
          lat: e.lat,
        })
      );
    }
  };

  handleListFavAddress = (e) => {
    this.setState({
      showpoperSaved: false,
      getFavAddressLoading: true,
    });
    // address: "Somewhere"
    // customerId: "ckfezc72o01f1xxo81la4bvl9"
    // id: "ckh3mcgda0d847bo87td8fk1v"
    // title: "HomeTown"

    // this.props.dispatch(
    //   getFavAddressInfoAction(
    //     this.props.dispatch,
    //     { customerId: e.customerId, id: e.id },
    //     (res) => {
    //       this.setState({
    //         getFavAddressLoading: false,
    //       });
    //       this.mapSetView({ lng: res.coordinates[0], lat: res.coordinates[1] });

    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "address",
    //           value: res.address,
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );
    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "adresss_loc",
    //           value: { lng: res.coordinates[0], lat: res.coordinates[1] },
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );
    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "block",
    //           value: res.buildingBlock,
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );
    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "floor",
    //           value: res.floor,
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );

    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "unit",
    //           value: res.room,
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );
    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "senderFullName",
    //           value: res.fullName,
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );
    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "senderPhoneNumber",
    //           value: res.phone,
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );
    //       this.props.dispatch(
    //         Set_DropOff_Inputs({
    //           name: "noteForDriver",
    //           value: res.description,
    //           firstDropOff: this.props.id === "dropoff",
    //           id: this.props.id,
    //         })
    //       );
    //     }
    //   )
    // );
  };

  addDropOff = () => {
    this.props.dispatch(Add_dropoff(this.idgenerator()));
  };

  ChooseoOnMapimg = () => {
    let center = getCenterMap(this.props.mapRef, this.props.center);
    Api_map_geocoding_reverse_AGW(this.props.dispatch, {
      latitude: this.props.mapRef.current
        ? center.lat
        : this.props.center.lat,
      longitude: this.props.mapRef.current
        ? center.lng
        : this.props.center.lng,
    });
    this.props.dispatch(
      ChooseoOnMapimg({
        showMap: true,
        pickupDropOff: {
          type: "DropOff",
          id: this.props.id == "dropoff" ? null : this.props.id,
        },
      })
    );
  };

  phoneNumberOnChange = (res, id) => {
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: id,
        value: res,
        firstDropOff: this.props.id === "dropoff",
        id: this.props.id,
      })
    );
  };


  handleNewBookmark = () => {
    if (!this.checkInputFilledByAB()) {
      this.props.dispatch(SetopenNewAddressBookInNewOrder(true, "dropoff"));
      this.props.dispatch(SetopenAddressBookInNewOrder(false, null, null, null))
      let phone = this.props.recieverPhoneNumber;
      if (phone && phone.length > 2 && phone.substring(0, 2) == "00") {
        phone = "+" + phone.substring(2, phone.length)
      }
      this.props.dispatch(
        SetNewAddressBookInNewOrderDetails({
          address: this.props.address,
          adresss_loc: this.props.adresss_loc,
          block: this.props.block,
          floor: this.props.floor,
          unit: this.props.unit,
          FullName: this.props.recieverFullName,
          PhoneNumber: phone,
        })
      );
    }
  }


  checkInputFilledByAB = () => {
    let status = false;
    this.props.Addressbooklist.forEach(element => {
      if (element.address == this.props.address) {
        status = true
      }
    });
    return status
  }


  CanShowAddDropOff = (nextProps) => {
    DetectOndemandOrPickupDelivery(
      this.props.serviceactiveVehicleType ? this.props.serviceactiveVehicleType.orderTypes : [],

      (callx) => {
        if (callx === "ondeman") {
          this.setState({ CanShowAddDropOff: true })
        } else {
          this.setState({ CanShowAddDropOff: false })

        }
      })
  }

  render() {
    const { t } = this.props;
    let destinationShow =
      (this.props.pickupDropOffType == "DropOff" && this.props.id == "dropoff")
        ? this.props.pickupDropOffId == null
        : this.props.pickupDropOffId == this.props.id;

    let isPickupDelivery = (this.props.serviceactiveVehicleType && this.props.serviceactiveVehicleType.orderTypes.indexOf("PickupDelivery") > -1)


    return (
      <passerngerContainer
        className={`dropOffCustomized ${this.props.last && "LastdropOffCustomized"
          }`}
      >
        <pickuptitle ref={this.anchorEl} style={{ height: "auto" }}>
          <span className="pickuptitleTrans">
            <Trans i18nKey={"DropOff"}> </Trans>{" "}
            {this.props.id !== "dropoff" ? this.props.number : ""}
          </span>

          {this.props.id !== "dropoff" ? (
            <img
              onClick={this.removeDropOff}
              src={trash}
              className={`trashDrop addDropOff ${this.props.id === "dropoff" && "displayNone"
                }`}
            />
          ) : null}
          {(this.props.last && Number(global.config.maxDropoffCount) > this.props.length) && this.state.CanShowAddDropOff ? (
            // <img onClick={this.addDropOff} className="addDropOff" src={Plus} />
            <Plus onClick={this.addDropOff} className="addDropOff" />
          ) : null}

          <div
            style={{ float: "left", width: "100%", marginTop: "12px" }}
            className="eppffbkd"
          >
            <span
              className={`ChooseoOnMap ${!destinationShow && "nobackGround"}`}
              onClick={this.ChooseoOnMapimg}
            >
              <ChooseonMap className="ChooseoOnMapimg" />
              <span className="ChooseoOnMapText">
                {" "}
                <Trans i18nKey={"ChooseoOnMap"}> </Trans>{" "}
              </span>
            </span>

            <div
              className={`AddressbookContainerPickup ${this.props.addressBookSource &&
                "AddressbookContainerPickupActive"
                }`}
              onClick={() => this.openAddressbook()}
            >
              <NewAddressbook className="img" />
              <span>
                {" "}
                <Trans i18nKey={"Addressbook"}> </Trans>{" "}
              </span>
            </div>
          </div>
        </pickuptitle>{" "}
        <div className={`pickupInputContainer `}>
          <input
            value={this.props.address}
            onChange={(e) => this.handleDropOffInputs(e, "address")}
            className={`addressPickup ${this.props.adresss_loc.lat !== "" && "negative50"
              }`}
            placeholder={t("address")}
            onClick={this.handleshowpoper}
          />

          {this.checkInputFilledByAB() ? (
            <Abookmark
              onClick={this.handleNewBookmark}
              className={`Abookmark ${(this.props.address.length <= 0 ||
                this.checkInputFilledByAB()) &&
                "UAbookmark"
                }   ${this.checkInputFilledByAB() && "AbookmarkActive"} `}
            />
          ) : (
            <UAbookmark
              onClick={this.handleNewBookmark}
              className={`Abookmark ${(this.props.address.length <= 0 ||
                this.checkInputFilledByAB()) &&
                "UAbookmark"
                } `}
            />
          )}

          {/* <img
        className={`Abookmark ${
          ( this.props.address.length <= 0 || this.checkInputFilledByAB()) && "UAbookmark"
         } `}
         src={this.checkInputFilledByAB() ? Abookmark : UAbookmark}
              onClick={this.handleNewBookmark}
            /> */}
          {this.props.adresss_loc.lat !== "" && (
            <img
              className={"resetPickup"}
              src={closeCircle}
              onClick={this.resetPickup}
            />
          )}
        </div>
        <img
          onClick={this.handleCollapse}
          src={arrow}
          className={`pickupCollIconVR ${this.props.activePickupDropOff.id === this.props.id && "rotate180"
            } `}
        />
        <Collapse
          className="pickupcollapsecontainer"
          in={this.props.activePickupDropOff.id === this.props.id}
          timeout="auto"
          unmountOnExit
        >
          {/* <savedDrop
            onClick={this.handleshowpoperDrop}
            className={` ${!this.props.customerId && "savedDropDisable"}`}
          >
            <span>{t("Saved")}</span>
            <img
              src={savedarrow}
              className={` ${this.state.showpoperSaved && "rotate180"}`}
            />
          </savedDrop> */}
          {this.props.isAddressDetailsActive ? (
            <React.Fragment>
              {this.props.activeVehicleType &&
                this.props.activeVehicleType.setting &&
                this.props.activeVehicleType.setting
                  .isDropoffAddressRoomFloorBlockActive && !isPickupDelivery && (
                  <>
                    <input
                      value={this.props.block}
                      onChange={this.handleDropOffInputs}
                      id={"block"}
                      name={"62fd670b" + this.props.number}
                      autocomplete={"62fd670b" + this.props.number}
                      className="blockPickup"
                      placeholder={t("block")}
                    />
                    <input
                      value={this.props.floor}
                      onChange={this.handleDropOffInputs}
                      id={"floor"}
                      name={"be4c90f8" + this.props.number}
                      autocomplete={"be4c90f8" + this.props.number}
                      className="floorPickup"
                      placeholder={t("Floor")}
                    />
                    <input
                      value={this.props.unit}
                      onChange={this.handleDropOffInputs}
                      id={"unit"}
                      name={"59ee84aa" + this.props.number}
                      autocomplete={"59ee84aa" + this.props.number}
                      className="UnitPickup"
                      placeholder={t("Unit")}
                    />
                  </>
                )}

              {isPickupDelivery && (
                <div className="inputWithRequired">
                  <input
                    value={this.props.addressDetails}
                    onChange={this.handleDropOffInputs}
                    id={"addressDetails"}
                    name={"eb89c0d4+this.props.number"}
                    autocomplete={"eb89c0d4" + this.props.number}
                    className="senderfullnamePickup"
                    placeholder={t("Addressdetails")}
                  />

                </div>
              )}

              {this.props.activeVehicleType &&
                this.props.activeVehicleType.setting &&
                this.props.activeVehicleType.setting
                  .isDropoffAddressNameActive && (
                  <div className="inputWithRequired">
                    <input
                      value={this.props.recieverFullName}
                      onChange={this.handleDropOffInputs}
                      id={"recieverFullName"}
                      name={"eb89c0d4+this.props.number"}
                      autocomplete={"eb89c0d4" + this.props.number}
                      className="senderfullnamePickup"
                      placeholder={t("Recieverfullname")}
                    />
                    {this.props.activeVehicleType &&
                      this.props.activeVehicleType.setting &&
                      this.props.activeVehicleType.setting
                        .isDropoffAddressNameRequired && (
                        <span className="inputRequired">
                          {this.props.t("Required")}
                        </span>
                      )}
                  </div>
                )}

                
              {this.props.activeVehicleType &&
                this.props.activeVehicleType.setting &&
                this.props.activeVehicleType.setting
                  .isDropoffAddressEmailActive && (
                  <div
                    className="inputWithRequired"
                    style={{
                      border:
                        this.props.email.length > 0
                          ? this.validate(this.props.email)
                            ? "solid 1px rgba(128, 128, 128, 0.2)"
                            : "1px solid red"
                          : "solid 1px rgba(128, 128, 128, 0.2)",
                    }}
                  >
                    <input
                      value={this.props.email}
                      onChange={this.handleDropOffInputs}
                      id={"email"}
                      name={"420d2de0" + this.props.number}
                      autocomplete={"420d2de0" + this.props.number}
                      className="notesfordriverPickup"
                      placeholder={t("email")}
                    />
                    {this.props.activeVehicleType &&
                      this.props.activeVehicleType.setting &&
                      this.props.activeVehicleType.setting
                        .isDropoffAddressEmailRequired && (
                        <span className="inputRequired">
                          {this.props.t("Required")}
                        </span>
                      )}
                  </div>
                )}

              {this.props.activeVehicleType &&
                this.props.activeVehicleType.setting &&
                this.props.activeVehicleType.setting
                  .isDropoffAddressPhoneActive && (
                  <div className="inputWithRequired">
                    <PhoneInput
                      defaultCountry={global.config.Country}
                      placeholder="123456789"
                      value={this.props.recieverPhoneNumber}
                      onChange={(PrePhoneInput) =>
                        this.phoneNumberOnChange(
                          PrePhoneInput,
                          "recieverPhoneNumber"
                        )
                      }
                      id={"recieverPhoneNumber"}
                      className="senderphonenumberPickup"
                    />
                    {this.props.activeVehicleType &&
                      this.props.activeVehicleType.setting &&
                      this.props.activeVehicleType.setting
                        .isDropoffAddressPhoneRequired && (
                        <span className="inputRequired">
                          {this.props.t("Required")}
                        </span>
                      )}
                  </div>
                )}


             {!isPickupDelivery && <div className="inputWithRequired">
                <input
                  value={this.props.noteForDriver}
                  onChange={this.handleDropOffInputs}
                  id={"noteForDriver"}
                  name={"420d2de0" + this.props.number}
                  autocomplete={"420d2de0" + this.props.number}
                  className="notesfordriverPickup"
                  placeholder={t("notesfordriver")}
                />
              </div>}
            </React.Fragment>
          ) : null}
          {this.props.serviceactiveVehicleType &&
            this.props.serviceactiveVehicleType.scheduleSetting &&
            this.props.serviceactiveVehicleType.scheduleSetting
              .hasDropoffScheduling && (
              <div style={{ width: "479px" }}>
                {/* {this.state.canSelectReserveForDropOff && ( */}
                <Schedule
                  firstDropOff={this.props.id === "dropoff"}
                  id={this.props.id}
                  scheduleDateBefore={this.props.scheduleDateBefore}
                  scheduleDateAfter={this.props.scheduleDateAfter}
                />
              </div>
            )}
        </Collapse>
        <BackdropCustom
          open={this.state.showpoper}
          onClick={this.handleshowpoper}
        >
          <Popper
            style={{
              width: "475px",
              zIndex: "7",
              marginTop: "0px",
              maxHeight: "150x",
              left: "25px !important",
            }}
            placement={"bottom"}
            id={"passenger"}
            open={this.state.showpoper}
            anchorEl={this.anchorEl.current}
            className=" pickupAddressPoperList"
          >
            <Paper
              className="xxx"
              style={{
                width: "100%",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              <div
                style={{
                  maxHeight: "150px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {this.props.suggestPickUpDropOffListData.length > 0 &&
                  !this.props.suggestListPickUpDropOffLoading &&
                  this.props.suggestPickUpDropOffListData.map((item) => {
                    if (item.secondaryAddress) {
                      return (
                        <favAddressselectorInList
                          onClick={() => {
                            this.handleList(item);
                          }}
                        >
                          <name> {item.address}</name>
                          <addres> {item.secondaryAddress}</addres>
                        </favAddressselectorInList>
                      );
                    } else {
                      return (
                        <serviceselectorInList
                          onClick={() => {
                            this.handleList(item);
                          }}
                        >
                          <addressEllipce> {item.address}</addressEllipce>
                        </serviceselectorInList>
                      );
                    }
                  })}

                {this.props.suggestListPickUpDropOffLoading && (
                  <div>
                    <span
                      className="NotravelsListHeader"
                      style={{ marginTop: "10px" }}
                    >
                      <Trans i18nKey={"poperLoading"}> </Trans>
                    </span>
                    <span className="NotravelsListText">
                      <Trans i18nKey={"fetchingdata"}> </Trans>
                    </span>
                  </div>
                )}

                {!this.props.suggestListPickUpDropOffLoading &&
                  !isEmpty(this.props.address) &&
                  this.props.suggestPickUpDropOffListData.length === 0 && (
                    <div>
                      <span
                        className="NotravelsListHeader"
                        style={{ marginTop: "10px" }}
                      >
                        <Trans i18nKey={"Noinformationfound"}> </Trans>
                      </span>
                      <span className="NotravelsListText">
                        <Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans>
                      </span>
                    </div>
                  )}

                {!this.props.suggestListPickUpDropOffLoading &&
                  isEmpty(this.props.address) &&
                  this.props.suggestPickUpDropOffListData.length === 0 && (
                    <div>
                      <span
                        className="NotravelsListHeader"
                        style={{ marginTop: "10px" }}
                      >
                        <Trans i18nKey={"Pleasetypeyourwordstosearch"}> </Trans>
                      </span>
                      <span className="NotravelsListText"></span>
                    </div>
                  )}
              </div>
            </Paper>
          </Popper>
        </BackdropCustom>
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  pickup: state.NewOrder.newOrder.pickup,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  mapRef: state.Map.mapRef,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  list: state.NewOrder.newOrder.dropOffs.list,
  loading: state.NewOrder.newOrder.dropOffs.loading,
  center: state.Map.center,
  suggestListPickUpDropOffLoading:
    state.NewOrder.newOrder.suggestListPickUpDropOffLoading,
  suggestListPickUpDropOffOrdered:
    state.NewOrder.newOrder.suggestListPickUpDropOffOrdered,
  suggestPickUpDropOffListData:
    state.NewOrder.newOrder.suggestPickUpDropOffListData,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  addressBookSource: state.Addressbook.openAddressBookInNewOrder ? state.Addressbook.sourceOfAddressBookInNewOrder ? (state.Addressbook.sourceOfAddressBookInNewOrder.type == "dropoff" && ownProps.id == state.Addressbook.sourceOfAddressBookInNewOrder.id) : false : false,
  selectedAB: state.Addressbook.selectedAB,
  Addressbooklist: state.Addressbook.list,
  openAddressBook: state.Addressbook.openAddressBookInNewOrder,
  center: state.Map.center,

  pickupDropOffType: state.NewOrder.newOrder.map_Data.pickupDropOff.type,
  pickupDropOffId: state.NewOrder.newOrder.map_Data.pickupDropOff.id,

  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
