import JenaTrucksIGO from "../../../static/JenaTrucks_logotype.jpg";
import JenaTrucks_banner from "../../../static/JenaTrucks_banner.png";

import FavIcon from "../../../static/PROJECTS/JenaTrucks.png";

export default function JenaTrucks() {
    
    return global.config = {
        "project": "Jena Trucks",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Jena Trucks",
        "LogoType": JenaTrucksIGO,
        "BaseApi": "https://rest.jenatrucks.com",
        "BaseSocket": "https://socket.jenatrucks.com",
        "BaseTrack": "https://admin.jenatrucks.com",
        "MapCenter": {
            "lat": 53.479981058722664,
            "lng": -2.24343192045155
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/JenaTrucks.png",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": JenaTrucks_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"JenaTrucks",
        registerEnabled: true,
        Secondarycolor: "#000000",
        SecondaryDarkColor: "#fffff",
        SecondaryLightColor: "#2B2B2B",
        SecondaryUltraLight: "#F5F5F5",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#242424",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#111111",
        icon_light_end:"#373737",
        icon_dark_start:"#858585",
        icon_dark_end:"#CCCCCC",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFFFFF",
        loginBackgroundColorEnd:"#FFFFFF",
        LoginTextColor :"#00000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:JenaTrucksIGO,
        apkUrl:null,
        iosUrl:null
} }