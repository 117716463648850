import BESwiftyIGO from "../../../static/BESwifty_logotype.png";
import BESwifty_banner from "../../../static/BESwifty_background.jpg";

import FavIcon from "../../../static/PROJECTS/BESwifty.png";

export default function BESwifty() {
    
    return  global.config = {
        "project": "app.beswifty.co.uk",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": BESwiftyIGO,
        "BaseApi": "https://rest.beswifty.co.uk",
        "BaseSocket": "https://socket.beswifty.co.uk",
        "BaseTrack": "https://panel.beswifty.co.uk",
        "MapCenter": {
            "lat": 51.51386279703675,
            "lng": -0.13229977613094754
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/BESwifty.png",
        "Country": "GB",
        "AppleLoginCustomerId": "uk.co.beswifty",
        "AppleLoginredirectURI": "https://app.beswifty.co.uk",
        "FacebookLoginappId": "1058026498110220",
        "GoogleLogincustomerId": "202522493658-2s513mh4c56h80nai4890klgd1corf0b.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": BESwifty_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"BESwifty",
        registerEnabled: true,
        Secondarycolor:"#00ABD9",
        SecondaryDarkColor:"#0080A3",
        SecondaryLightColor:"#00B4E4",
        SecondaryUltraLight :"#F3FCFF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#009CC6",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:BESwiftyIGO,
        apkUrl:null,
        iosUrl:null
}}