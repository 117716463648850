import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { SetReferenceID } from "../../../../Redux/actions/actionNames";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.inputOnchange = this.inputOnchange.bind(this)
  }


  inputOnchange(e){
    this.props.dispatch(SetReferenceID(e.target.value))
  }
  


  render() {
    const { t } = this.props;

    if(!(this.props.serviceactiveVehicleType && this.props.serviceactiveVehicleType.setting.isReferenceIdActive) ){
      return null
    }

    return (
      <div className="codContainer" style={{maxWidth:"calc(50% - 10px)"}}>
          <span> {t("ReferenceID")} </span>
          <input min={0} onChange={this.inputOnchange} placeholder={t("EnterId")} style={{maxWidth:"calc(100% - 46px)"}} />
      </div>
    
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
