import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { get_customerPannel_type, get_Id, get_lang, get_LeftRight } from "../../../helper/UserData/userdate";
import close from "../../../static/close.svg";
import {ReactComponent as Wallet} from "../../../static/mutual-wallet-balance.svg";
import icongo from "../../../static/icon-chevron-down.svg";
import { Api_New_Payment_Order_AGW } from "../../../helper/api/ApiGateway";
import useCheckMobileScreen from "../../../helper/hook/useCheckMobileScreen";


var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Inc_Balance: false,
      amountEntered: "",
      selectedGateWay: null,
      
    };
  }
  handleAmountEntered = (e, number) => {
    this.setState({
      amountEntered: number ? number : e.target.value,
    });
  };


  hanlePay = (amount)=>{
    if(this.state.amountEntered.length > 0  || (amount && amount.length > 0)){
     this.props.NewOrderOpenDialoge && this.saveReducerInLocalStorage()
      Api_New_Payment_Order_AGW(this.props.dispatch , this.removeEmpty({
        "customerId": get_Id() ,
        "amount": this.state.amountEntered.length > 0 ? this.state.amountEntered : amount,
        "deviceType": "web",
        "packageName": null,
        "gateway": this.state.selectedGateWay  || this.props.payment[0].gateway 
      })
      )
    }
  }

  saveReducerInLocalStorage = ()=>{
    let data ={
      driver : {
        auto_activeVehicleType: this.props.auto_activeVehicleType ,
        options: this.props.driver_options
      },
      dropOffs : {
        firstDropOff : this.props.NRdropOffAddress,
        otherDropOffs : this.props.NRotherDropOffs
      },
      service:{
        activeVehicleType : this.props.active_service ,
        options: this.props.service_options
      },
      pickup : this.props.NRpickupAddress ,
    }
    localStorage.setItem("draft" , JSON.stringify(data));
  }

  removeEmpty= (obj)=> {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }


  handleShowIncBalance = (bool) => {
    this.setState({
      Inc_Balance: bool,
      amountEntered: "",
    });
    this.props.onClose && this.props.onClose()
  };

  render() {
    return (
      <React.Fragment>
        {this.props.ShowComponent ? (
          <div
            onClick={() => this.handleShowIncBalance(true)}
            className="headerItemContainer"
          >
             <Wallet className="balanceicon" />
            {/* <img src={wallet} /> */}
            <span className="span">
              {" "}
              <Trans i18nKey={"Wallett"}> </Trans>{" "}
            </span>
            <p className="Balance">
              <Trans i18nKey={"Balance"}> </Trans> :{" "}
              <span>
                {" "}
                {this.props.balancecurrencyCode
                  ? Number(this.props.balance).toLocaleString(get_lang(), {
                      style: "currency",
                      currency: this.props.balancecurrencyCode,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : this.props.balance}{" "}
              </span>{" "}
            </p>
          </div>
        ) : null}
        <Slide
          direction={get_LeftRight()}
          in={this.props.OpenDialoge || this.state.Inc_Balance}
          mountOnEnter
          unmountOnExit
          style={{height:"100vh",maxHeight:"100vh"}}
        >
          <div className="NewOrder" style={{height:"100vh",position:(this.props.isMobile) ? "relative" :"fixed",maxHeight:"100vh"}}>
            <nRHeader style={{ boxShadow: "none" }}>
              <img
                onClick={() => this.handleShowIncBalance(false)}
                className="close"
                src={close}
              />
              <span className="title">
                <Trans i18nKey={"Wallett"}> </Trans>
              </span>
            </nRHeader>
            <div className="BalanceContainer BalanceContainerDialoge">
              <Wallet  className="balanceicon" />
              <span className="BalanceText">
                {" "}
                <Trans i18nKey={"Balance"}> </Trans>
              </span>
              <span className="BalanceNumberText">
                {" "}
                {this.props.balancecurrencyCode
                  ? Number(this.props.balance).toLocaleString(get_lang(), {
                      style: "currency",
                      currency: this.props.balancecurrencyCode,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : this.props.balance}
              </span>
            </div>
            <div className="chooseAmountContainer">
              <span className="chooseAmountTitle">
                {" "}
                <Trans i18nKey={"chooseAmount"}> </Trans>
              </span>
              {this.props.defaultsAmount ? (
                <div className="defaultsAmountContainer">
                  {this.props.defaultsAmount.map((item) => {
                    return (
                      <p
                        className="predefinedPaymentValue"
                        onClick={() =>
                          this.hanlePay(String(item))
                        }
                      >
                        {Number(item).toLocaleString(get_lang(), {
                          style: "currency",
                          currency: this.props.currencyCode,
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}  
                         <img src={icongo} />
                      </p>
                   
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              {this.props.payment.length > 1 ? (
                <React.Fragment>
                  <span className="selectPaymentGateway">
                    {" "}
                    <Trans i18nKey={"selectPaymentGateway"}> </Trans>{" "}
                  </span>

                  <div className="PaymentGatewayContainer">
                    {this.props.payment.map((item) => {
                      return (
                        <span
                          onClick={() => {
                            this.setState({
                              selectedGateWay: item.gateway,
                            });
                          }}
                          className={`PaymentGateway ${
                            item.gateway == this.state.selectedGateWay &&
                            "selectedGateway"
                          }`}
                        >
                          {" "}
                          {item.name}
                        </span>
                      );
                    })}
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <div className="enterprefredamount">
                <span className="title">
                  {" "}
                  <Trans i18nKey={"orenterthepreferedamount"}> </Trans>

                  <span className="Balancespan">


                  {this.props.balancecurrencyCode
                  ? Number(this.props.prefered || 0).toLocaleString(get_lang(), {
                      style: "currency",
                      currency: this.props.balancecurrencyCode,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : this.props.prefered || 0}
                    </span>


                </span>
                <input
                  type="number"
                  onChange={this.handleAmountEntered}
                  value={this.state.amountEntered}
                  placeholder={this.props.t("enteramount")}
                />
                <span
                  onClick={this.hanlePay}
                  className={`pay ${
                    this.state.amountEntered.length > 0 && "payalowed"
                  }`}
                >
                  {" "}
                  <Trans i18nKey={"pay"}> </Trans>{" "}
                </span>
              </div>
            </div>
         
          </div>
        </Slide>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
    NewOrderOpenDialoge: state.NewOrder.OpenDialoge,
  balance: state.NewOrder.newOrder.balance.balance,
  balancecurrencyCode: state.NewOrder.newOrder.balance.currencyCode,
  currencyCode: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.currencyCode
    : null,
  payment: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.payment || []
    : [],
  defaultsAmount: state.SharedData.WebSettings
    ? state.SharedData.WebSettings.predefinedPaymentValues
    : null,
    auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
    driver_options:state.NewOrder.newOrder.driver.options,
    NRdropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff,
    NRotherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
    NRpickupAddress: state.NewOrder.newOrder.pickup,
    active_service: state.NewOrder.newOrder.service.activeVehicleType,
    service_options: state.NewOrder.newOrder.service.options,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));



export const MapHoc = ({children}) => {
  let {isMobile , isDesktop , isTablet} = useCheckMobileScreen();


  
  return React.cloneElement(children, { isMobile, isDesktop,isTablet});

};