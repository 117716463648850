import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { Api_Get_Aboutus_AGW } from "../../../../helper/api/ApiGateway";
import refresh from "../../../../static/iconmonstr-refresh-1.svg";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  retry =()=>{
    Api_Get_Aboutus_AGW(this.props.dispatch,{},(call)=>{

    })
  }
  render() {
    if (this.props.error) {
      return (
        <div className="NoOrderContainer">
          <div className="NoOrderInner mrb90">
            <span className="NoOrderTitle">
              {" "}
              <Trans i18nKey={"loadingError"}> </Trans>{" "}
            </span>
            <span className="NoOrderDescription">
              {" "}
              <Trans i18nKey={"retry"}>
                {" "}
              </Trans>{" "}
            </span>
            <img className="NoOrderNewOrder" src={refresh} onClick={this.retry} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  error: state.AboutUS.error
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
