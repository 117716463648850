import { get_accessToken, get_Id } from "../helper/UserData/userdate";
import { store } from "../Redux/store";
const { v4: uuidv4 } = require('uuid');


// import { UpdateTravelInList } from "../Redux/actions/actionNames";

// export function SocketOrderHandle(data) {
//   const state = store.getState();

//   state.Socket.socket.emit(
//     "dispatcher:order:ondemand:handle",
//     data,
//     (res) => {
//       store.dispatch(
//         UpdateTravelInList({
//           id: res.data.id,
//           data: { code: res.data.code, status: res.data.status },
//           full: false,
//         })
//       );
//     }
//   );
// }

export const SendMessageWithSocket=(data , func)=> {
    const state = store.getState();
    let socket = state.Socket.socket
    socket && socket.emit("customer:chat:sendMessage",{
        "accessToken": get_accessToken(),
        "customerId": get_Id(),
        "orderId": data.orderId,
        "text" : data.text ,
        "uid" :data.uid
    },(res)=>{
        func(res.data.message.id)
    })
}