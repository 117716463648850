import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import jalali from "jalali-moment";
import { get_lang } from "../../../../helper/UserData/userdate";
import sicon from "../../../../static/Mutual___ic_calender.svg";
import styles2 from './viewOrder.module.css';
import Typography from "../../../../design-system/lib/Typography";
import Tag from "../../../../design-system/lib/Tag";
import { Deliveryonly_deactive, PickupOnly_Deactive } from "../../../../static";
import question from "../../../../static/question.svg";
import momentJalaali from "moment-jalaali";
import { isDesktop, isTablet } from "react-device-detect";
import { isEmpty } from "lodash";

class App extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className={styles2.statusContainer}>
        {/* <item>
          <stateTag className={`OrderList_${this.props.status || "new"}`}>
            {this.props.statusDescription ? this.props.statusDescription : <Trans i18nKey={"statusPending"}></Trans>}
          </stateTag>
        </item> */}

        <div className={styles2.statusRow}>
          <Typography weight="Body_Middle_Bold" className={`OrderList_${this.props.status || "new"}`} text={this.props.status || "new"}/>
          {this.props.stage && <Tag iconSize="16" backgroundColor="GrayUltraLight" color="TextDark" size="Medium" weight="Body_Small_Regular" text={this.props.stage == "Pickup" ? "Pickup" : "Delivery"} icon={this.props.stage == "Pickup" ? PickupOnly_Deactive : Deliveryonly_deactive } />}
         {this.props.statusUpdatedAt && <Typography weight="Body_Small_Regular" style={{marginInlineStart :"auto" , width:(!isDesktop && !isTablet) ? "100%":""}}  text={`Status Updated at ${momentJalaali(this.props.statusUpdatedAt).locale(get_lang()).format("D MMM HH:mm")}`}/>}

        </div>

        {(this.props.pickup?.failedReason && this.props.stage == "Pickup") &&
          <>
            <div className={styles2.horizLine} />
            <div className={styles2.statusRow}>
            <img src={question} />
            <Typography weight="Body_Small_Regular" text={this.props.pickup?.failedReason?.text}/>
            </div>
          </>
        }

      {(this.props.delivery?.lastAttemptReason && !isEmpty(this.props.delivery?.lastAttemptReason) && this.props.stage == "Delivery") &&
          <>
            <div className={styles2.horizLine} />
            <div className={styles2.statusRow}>
              <img src={question} />
            <Typography weight="Body_Small_Regular" text={this.props.delivery?.lastAttemptReason?.text}/>
            </div>
          </>
        }


        <div className={styles2.horizLine} />


        <div className={styles2.statusRow}>
        <Typography weight="Body_Small_Regular" text={this.props.code}/>
        {this.props.referenceId && <Typography weight="Body_Small_Regular" style={{marginInlineStart :"auto",width:(!isDesktop && !isTablet) ? "100%":""}}  text={`${this.props.referenceId}`}/>}
        </div>

        {/* <item>
          <date className="doprgf">
            {this.props.createdAt && new Date(this.props.createdAt).toLocaleString(get_lang(), {
              hour: "2-digit",
              minute: "2-digit",
              month: 'long',
              day: 'numeric',
              hour12: false
            })

            }
          </date>
        </item> */}
        {/* {this.props.pickupAfter || this.props.pickupBefore ? (
          <div className="scheduledOrderTimeConatiner">
            <img src={sicon} />
            <span>
      
               {moment(Number(this.props.pickupAfter || this.props.pickupBefore)).locale(get_lang()).format("D MMM hh:mm")} 

            </span>
          </div>
        ) : (
            <item>
              <date style={{fontWeight:"100"}}>
                {this.props.createdAt &&
                  jalali(this.props.createdAt)
                    .locale(get_lang())
                    .format("D MMM hh:mm")}
              </date>
            </item>
          )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : "new"
    : "new",

    stage: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.stage
      ? state.ViewTravel.pureData.stage
      : null
    : null,

    delivery: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.delivery
      ? state.ViewTravel.pureData.delivery
      : null
    : null,

    pickup: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickup
      ? state.ViewTravel.pureData.pickup
      : null
    : null,

  statusDescription: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.statusDescription
      ? state.ViewTravel.pureData.statusDescription
      : "new"
    : "new",

    referenceId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.referenceId
      ? state.ViewTravel.pureData.referenceId
      : null
    : null,
  source: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.source
      ? state.ViewTravel.pureData.source
      : false
    : false,
  createdAt: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.createdAt
      ? state.ViewTravel.pureData.createdAt
      : null
    : null,

    statusUpdatedAt: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.statusUpdatedAt
      ? state.ViewTravel.pureData.statusUpdatedAt
      : null
    : null,
  code: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.code
      ? state.ViewTravel.pureData.code
      : "#"
    : "#",
  pickupAfter: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickup
      ? state.ViewTravel.pureData.pickup.scheduleDateAfter
      : 0
    : 0,
  pickupBefore: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickup
      ? state.ViewTravel.pureData.pickup.scheduleDateBefore
      : 0
    : 0,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
