
import AOfferIGO from "../../../static/aOfferLogo.png";
import AOffer_banner from "../../../static/AOfferBanner.png";
import FavIcon from "../../../static/PROJECTS/aOffer.png";

export default function AOffer() {
    
    return  global.config = {
        "project": "AOffer",
        "ShowPowerOnro": false,
        "flag": "",
        "ProjectName": "Customer Panel",
        "LogoType": AOfferIGO,
        "BaseApi": "https://rest.aofferonline.com",
        "BaseSocket": "https://socket.aofferonline.com",
        "BaseTrack": "https://panel.aofferonline.com",
        "MapCenter": {
            "lat": "30.052283288915845",
            "lng": "31.24218992754401"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [ {
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }, {
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }],
        "FavIcon": "/AOffer.png",
        "Country": "EG",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "503990628449283",
        "GoogleLogincustomerId": "285605443646-8upmrufn8qtvkh58kk6s40r38mifomh7.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": AOffer_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: "AOffer",
        customColor:"AOffer",
        registerEnabled: true,
        Secondarycolor: "#FECD06",
        SecondaryDarkColor: "#F2C200",
        SecondaryLightColor: "#FECD06",
        SecondaryUltraLight: "#FFFAE6",
        SecondaryTextColor: "#242424",
        Secondaryonlight: "#242424",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FF4F55",
        icon_light_end:"#FF6A6F",
        icon_dark_start:"#FECD06",
        icon_dark_end:"#FFD320",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#f2f2f2",
        loginBackgroundColorEnd:"#f2f2f2",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:AOfferIGO,
        apkUrl:null,
        iosUrl:null
}}