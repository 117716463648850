import React from "react";
import { connect } from "react-redux";
import Header from './Components/header';
import List from './Components/List';
import DragOver from './Components/DragOver';
import { GetMonitoringVehicleTypeAction, ServicesVehicletypeAction } from "../../Redux/actions/actions";
import { BulkImportList } from "../../helper/api";
import { get_direction, get_lang } from "../../helper/UserData/userdate";
import { withTranslation, Trans } from "react-i18next";
import { setupSocket } from "../../socket/Setup_socket";
import { Api_Get_Services_AGW } from "../../helper/api/ApiGateway";

class App extends React.Component {
    componentDidMount() {
        if (get_lang()) {
            this.props.dispatch({
                type: "changeDirection",
                direction: get_direction(),
                fullDirection: get_direction(),
                lang: get_lang(),
            });
            this.props.i18n.changeLanguage(get_lang());
        }
        BulkImportList(this.props.dispatch, null, null)

        Api_Get_Services_AGW(this.props.dispatch, {lat: this.props.center.lat,
            lng: this.props.center.lng});


        // this.props.dispatch(
        //     ServicesVehicletypeAction(this.props.dispatch, {
        //         lat: this.props.center.lat,
        //         lng: this.props.center.lng
        //     })
        // );

       !this.props.socket && setupSocket(this.props.dispatch);

    }

    render() {
        let { t } = this.props;

        return (
            <div className="bulkImportContainer">
                <Header />
                <div className="maxwidth1366">
                    <List />
                    <DragOver />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    center: state.Map.center,
    socket: state.Socket.socket,

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
