import YONIIGO from "../../../static/VroomVroom_logotype.png";
import Yonibanner from "../../../static/VroomVroom_Cp_background.jpg";

import FavIcon from "../../../static/PROJECTS/VroomVroom.png";

export default function vroomvroom() {
    
    return  global.config = {
        "project": "app.vroomvroom.ph",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": YONIIGO,
        "BaseApi": "https://rest.vroomvroom.ph",
        "BaseSocket": "https://socket.vroomvroom.ph",
        "BaseTrack": "https://panel.vroomvroom.ph",
        "MapCenter": {
            "lat": 14.555765743871088,
            "lng": 121.02359693965525
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/VroomVroom.png",
        "Country": "PH",
        "AppleLoginCustomerId": "ph.vroomvroom",
        "AppleLoginredirectURI": "https://app.vroomvroom.ph",
        "FacebookLoginappId": null,
        "GoogleLogincustomerId": "823811569423-685oddih3clci4tqpifd9gr4od1jbrk1.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Yonibanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"VroomVroom",
        registerEnabled: true,
        Secondarycolor:"#C60C1E",
        SecondaryDarkColor:"#B20C1C",
        SecondaryLightColor:"#DA0F23",
        SecondaryUltraLight :"#FCF0F1",
        SecondaryTextColor:"#FFFFFF",
        Secondaryonlight:"#C60C1E",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:YONIIGO,
        apkUrl:null,
        iosUrl:null
} }