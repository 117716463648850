import DroppXIGO from "../../../static/Munchi_logotype.png";
import DroppX_banner from "../../../static/Munchi_background.jpg";

import FavIcon from "../../../static/PROJECTS/Munchi.png";

export default function Munchi() {
    
    return  global.config = {
        "project": "delivery.gomunchi.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "",
        "LogoType": DroppXIGO,
        "BaseApi": "https://rest.gomunchi.com",
        "BaseSocket": "https://socket.gomunchi.com",
        "BaseTrack": "https://dash.gomunchi.com",
        "MapCenter": {
            "lat": 60.169765,
            "lng": 24.938556
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/Munchi.png",
        "Country": "FI",
        "AppleLoginCustomerId": "com.gomunchi.delivery",
        "AppleLoginredirectURI": "https://delivery.gomunchi.com",
        "FacebookLoginappId": "1313887235707643",
        "GoogleLogincustomerId": "216778461929-669alm1oj6lf0qmicceqgfojprk8krcm.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": DroppX_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"Munchi",
        registerEnabled: true,
        Secondarycolor:"#FF3537",
        SecondaryDarkColor:"#D80002",
        SecondaryLightColor:"#FF484A",
        SecondaryUltraLight :"#FFEFEF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#FF3537",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:DroppXIGO,
        apkUrl:null,
        iosUrl:null
}}