import * as actionTypes from "../actions/actionTypes";

const init = {
  history: 0,
  eventTimeLine: {
    show: false,
    loading: false,
    data: [],
    error: false,
    activeEventCordinator: null,
    newEvent:{
      show:false,
      mapLocation:null,
      canSelectLocation:false
    }
  },
  OpenDialoge: false,
  loading: false,
  error: false,
  ID: null,
  pureData: null,
  estimatedRoute: null,
  feedback:{
    open: false,
    rate:0,
    rateText:""
  },
  newMessage:false
};
export const ViewTravel = (state = init, action) => {
  switch (action.type) {
    case actionTypes.OPENCLOSEVIEWTRAVEL:
      return {
        ...state,
        OpenDialoge: action.state,
        ID: action.order ? action.order : null,
        pureData: null,
        error: false,
      };
    case actionTypes.changeViewTravelHistoryMode:
      return {
        ...state,
        history: action.state,
      };
    case actionTypes.setHistoryData:
      return {
        ...state,
        historyData: {
          ...state.historyData ,
          ...action.state,
        },
      };
    case actionTypes.LOADINGORDERINFO:
      return {
        ...state,
        loading: action.state,
      };
    case actionTypes.SETORDERINFOERROR:
      return {
        ...state,
        error: action.state,
      };
    case actionTypes.SETORDERINFO:
      return {
        ...state,
        pureData: action.state.id == state.ID ? { ...action.state } : null,
        error: false,
      };
    case actionTypes.resetViewTravel:
      return init;

    case actionTypes.setViewTravelEstimated:
      return {
        ...state,
        estimatedRoute: action.state,
      };

      case actionTypes.setfeedback:
        return {
          ...state,
          feedback: {
            ...state.feedback,
            ...action.state,
          },
        };
  
      
    case actionTypes.updateStateorderInList:
      if ( state.ID == action.state.id) {
        return {
          ...state,
          pureData :{
            ...state.pureData,
            ...action.state.data,
            status: action.state.status,

          }
        };
      } else {
        return state
      }

      case actionTypes.updateStateViewOrder:
        if ( state.ID == action.state.id) {
          return {
            ...state,
            pureData :{
              ...state.pureData,
              ...action.state.data,
              status: action.state.status,
  
            }
          };
        } else {
          return state
        }
  
      
      case actionTypes.addnewmessageflagtoorder:
        if ( state.ID == action.state.id) {
          return {
            ...state,
            newMessage: action.state.bool
          };
        } else {
          return state;
        }


        case actionTypes.show_event_TimeLine:
          return {
            ...state,
            eventTimeLine: {
              ...state.eventTimeLine,
              show: action.state
            },
          };
    
        case actionTypes.set_viewOrder_events:
          return {
            ...state,
            eventTimeLine: {
              ...state.eventTimeLine,
              ...action.state
            },
          };
    
    
      
    
    
        

    default:
      return state;
  }
};
