import { RefreshToken } from "../../../../helper/api";
import { Warning_NOi18 } from "../../../../helper/Notification/sendNotification";
import { get_accessToken } from "../../../../helper/UserData/userdate";
import InvoiceDataService from "../../../../service/api/apiInterface/InvoiceDataService";
const FileDownload = require('js-file-download');

export const InvoiceownloadAction = (id, code, dispatch) => {

  // window.open(`${global.config.BaseApi}/api/v1/customer/business/invoice/download?id=${id}&&code=${code}&&token=${get_accessToken()}`, '_blank').focus();
  let InvoiceService = new InvoiceDataService()
  InvoiceService.download(id, code).then((response) => {
    // call(response.data.data)
    FileDownload(response.data, `#${code}-${getRandomInt(10000)}.pdf`)

  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            InvoiceownloadAction(id, code, dispatch);
          });
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};




function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}