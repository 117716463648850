import React, { useState } from "react";
import OrderListMaper from "./OrderListMaper";
import Loading from "./loading";
import Error from "./error";
import { isEmpty } from "lodash";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { Api_Get_List_AGW } from "../../../helper/api/ApiGateway";
import ListNoOrder from "./NoOrder";
import ListError from "./error";
import { isDesktop, isTablet } from "react-device-detect";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loadMore = () => {
    Api_Get_List_AGW(
      this.props.dispatch,
      {
        page: this.props.page,
      },
      (call) => {
      }
    );
  };

  componentDidMount() {
    if (isEmpty(this.props.OrderList) && !this.props.OrderListLoading) {
      this.loadMore();
    }
  }

  render() {
    return (
      <div className="OrderListContainer" style={{height : (this.props.orderTypes?.length == 1 && !isDesktop && !isTablet) && "calc(100% - 62px)"}}>
        <ListError />
        <Loading />
        <ListNoOrder />
        {!this.props.OrderListError && !isEmpty(this.props.OrderList) ? (
          <OrderListMaper loadMore={this.loadMore} />
        ) : null}

        {/* <div  style={{width:"100%" ,height:"50%",float:"left",backgroundColor:"white",position:'absolute',overflowY:"scroll"}}> 
        <span style={{width:"100%",height:"auto",wordBreak:"break-all"}}>  {JSON.stringify({  OrderListLoading: this.props.OrderListLoading,
  OrderListError: this.props.OrderListError,
  page: this.props.page})}
  </span>
  <span id="logshow" style={{width:"100%",height:"auto",wordBreak:"break-all"}}> 
  </span>

        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  OrderList: state.OrderList.OrderList,
  OrderListLoading: state.OrderList.OrderListLoading,
  OrderListError: state.OrderList.OrderListError,
  page: state.OrderList.page,
  orderTypes: state.SharedData.WebSettings ? state.SharedData.WebSettings.orderTypes : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
