import LogoType from "../../../static/J-RUN.png";
import Banner from "../../../static/J-RUN_banner.jpg";

import FavIcon from "../../../static/PROJECTS/J-RUN_fav_icon.png";

export default function JRUN() {
    
    return  global.config = {
        "project": "J-RUN",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "J-RUN Customer Panel",
        "LogoType": LogoType,
        "BaseApi": "https://rest.j-run.az",
        "BaseSocket": "https://socket.j-run.az",
        "BaseTrack": "https://panel.j-run.az",
        "MapCenter": {
            "lat": "40.41731581402783",
            "lng": "49.86628736823992"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "rus",
            "direction": "Ltr",
            "i18Lang": "Russian",
            "cssFlag": "russianflag",
            "country": "RU"
        },{
            "lang": "aze",
            "direction": "Ltr",
            "i18Lang": "Azerbaijani",
            "cssFlag": "azerbaijaniflag",
            "country": "AZ"
        }],
        "FavIcon": "",
        "Country": "AZ",
        "AppleLoginCustomerId": "az.jrun",
        "AppleLoginredirectURI": "https://app.j-run.az",
        "FacebookLoginappId": "495593528644255",
        "GoogleLogincustomerId": "926576045736-r1gsssrfq3htuob0nb4g6tamapmpner3.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "J-RUN Customer Panel",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"JRUN",
        registerEnabled: true,
        Secondarycolor: "#00B2DF",
        SecondaryDarkColor: "#009CC2",
        SecondaryLightColor: "#00CCFF",
        SecondaryUltraLight: "#F5FCFE",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#00B2DF",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#9DDEEE",
        icon_light_end:"#ADEBFB",
        icon_dark_start:"#00B2DF",
        icon_dark_end:"#00CCFF",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}