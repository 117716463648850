import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import time from "../../../../static/icon-es-time.svg";
import path from "../../../../static/icon-ic-distance.svg";
import {ReactComponent as Discount } from "../../../../static/customer-ic-discount.svg";
import {ReactComponent as Cod} from "../../../../static/ic-cod.svg";

import { withTranslation, Trans } from "react-i18next";
import { get_currencyCode, get_lang } from "../../../../helper/UserData/userdate";
import ETA from "./ETA";

class App extends React.Component {
  render() {
    return (
      <extraDetailsContainer>

        <ETA />
        {/* <extraDetail>
          {" "}
          <img src={time} /> <value> 22:34 </value> <br />{" "}
          <key>
            <Trans i18nKey={"Estime"}> </Trans>
          </key>{" "}
        </extraDetail> */}
        {/* <extraDetail>
          {" "}
          <img src={path} />{" "}
          <value>
            {" "}
            {this.props.Espath } <Trans i18nKey={"km"}> </Trans>{" "}
          </value>{" "}
          <br />
          <key>
            <Trans i18nKey={"Espath"}> </Trans>
          </key>{" "}
        </extraDetail> */}

        
        { this.props.Promocode && (
          <extraDetail>
            {" "}
            {/* <img src={discount} />  */}
            <Discount className="img Promocode"/>
           <div style={{display:"flex",flexDirection:"column"}}>
            <value> {this.props.Promocode}</value> 
              <key>
                <Trans i18nKey={"PromotionCode"}> </Trans>
              </key>{" "}
           </div>
          </extraDetail>
        )}

      { Number(this.props.Cod) > 0 && <extraDetail>
          {" "}
          {/* <img src={cod} />  */}
          <Cod  className="img Cod"/>
          <div style={{display:"flex",flexDirection:"column"}}>

        <value> {Number(this.props.Cod).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}</value> 
          <key>
            <Trans i18nKey={"COD"}> </Trans>
          </key>{" "}
          </div>

        </extraDetail>}
      </extraDetailsContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  Promocode: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.promoCode
    : false,
  Espath: state.ViewTravel.estimatedRoute
    ? state.ViewTravel.estimatedRoute.distance || 0
    : 0,
    Cod: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.codAmount
    : 0,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
