import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import star from "./../../../../../src/static/icon-star.svg";
import newMessageIcon from "./../../../../../src/static/cp-ic-messages.svg";
import { ReactComponent as DeliveryProfile } from "./../../../../../src/static/driver_place_holder.svg";

import { getBaseTrackByUrl } from "../../../../../src/helper/module";
import { get_lang } from "../../../../helper/UserData/userdate";
import { AddNewMessageFlagToOrder, OPENCLOSECHATDRIVER } from "../../../../Redux/actions/actionNames";
import arrow from "../../../../static/chevron-down.svg";
import { Collapse } from "newmaterial-ui";
import Tag from "../../../../design-system/lib/Tag";


var _ = require("lodash");

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      driverImageError: false,
      activeCollapse: false,
    }
  }

  handleCollapse = () => {
    this.setState({
      activeCollapse: !this.state.activeCollapse
    })
  };



  serviceOptionsNameExtracter = () => {
    let names = [];
    if (this.props.options) {
      this.props.options.map((option) => {
        if (option.type === "boolean") {
          names.push(option.title);
        } else {
          names.push(option.dataTitle);
        }
      });
      return names.join(" , ");
    } else {
      return "";
    }
  };

  openclosechatDialoge = () => {
    this.props.dispatch(OPENCLOSECHATDRIVER(true))
    this.props.dispatch(AddNewMessageFlagToOrder({
      id: this.props.id,
      bool: false
    }))
  }

  render() {
    if (!_.isEmpty(this.props.driver)) {
      const { t } = this.props;
      return (
        <driverInformation>
          {/* <optionss>{this.serviceOptionsNameExtracter()}</optionss> */}
          {/* <img src={this.props.driver.avatarPhoto.length > 0 ? this.props.driver.avatarPhoto : deliveryProfile} onError={(e)=>{e.target.onerror = null; e.target.src=deliveryProfile}} /> */}
          {/* <img src={deliveryProfile} onError={(e)=>{e.target.onerror = null; e.target.src=deliveryProfile}} /> */}
          {this.props.driver.avatarPhoto.length > 0 && !this.state.driverImageError ?
            <img src={this.props.driver.avatarPhoto} onError={(e) => { this.setState({ driverImageError: true }) }} /> :
            <DeliveryProfile className="img" />
          }
          <name>{this.props.driver.name}</name>
          <number>
            <img src={star} />
            {this.props.driver.rate}
          </number>

          <div className="callmessagebuttonsContainer">


            <a href={`tel:${this.props.driver.phone}`} className="VrCallDriver">
              {" "}
              <Trans i18nKey={"CallDriver"}> </Trans>{" "}
            </a>
            {!this.props.OrderListOpenDialoge && <span className="VrMessageDriver" onClick={this.openclosechatDialoge}>
              {" "}
              {this.props.newMessageFlag && <div src={newMessageIcon} className="ViewOrdernewMessageIcon" ></div>}
              <Trans i18nKey={"Message"}> </Trans>{" "}
            </span>}
          </div>

          <hr />


          <vehicleTypesContainer>
            <img
              className="imgcar"
              src={
                this.props.vehicleType &&
                this.props.vehicleType.icon
              }
              style={{  }}
            />

            <item>
              <key> {this.props.driver.vehicle.plate}</key>
              <value>
                {this.props.driver.vehicle.model +
                  " " +
                  this.props.driver.vehicle.color}
              </value>
            </item>
            <item>
              <key>{this.props.driver.phone}</key>
              <value>
                <Trans i18nKey={"Phone"}> </Trans>
              </value>{" "}
            </item>

            {/* <item
              style={{
                display:
                  this.serviceOptionsNameExtracter().length > 0 ? "" : "none",
              }}
              className="additonalservice"
            >
              <key className="serviceOptionsNameExtracter">
                {this.serviceOptionsNameExtracter()}
              </key>
              <value>
                <Trans i18nKey={"additonalservice"}> </Trans>
              </value>{" "}
            </item> */}

            {this.props.options.length > 0 && <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"8px",marginInlineStart:"auto" }}>
              <Tag size="Medium" weight="Body_Middle_Regular" backgroundColor="GrayUltraLight" color="TextNormal" text={`${this.props.options.length} ${this.props.t("Options")}`}  style={{ marginInlineStart: "auto",marginInlineEnd: "8px", margin: "0px" }} />
              {/* <span>{`${this.props.options.length} ${this.props.t("Options")}`}</span> */}
              <img
                onClick={this.handleCollapse}
                src={arrow}
                className={`vrpickupCollIconVR ${this.state.activeCollapse && "rotate180"
                  }`}

                style={{  alignSelf:"unset", margin: "0px"  }}
              />
            </div>}

          </vehicleTypesContainer>
          <Collapse
            className="pickupcollapsecontainervr"
            in={this.state.activeCollapse}
            timeout="auto"
            unmountOnExit
          >
            <div className="vrPickupCollapseDetail" style={{ height: "auto", width: "100%", marginTop: "0px" }}>

              {this.props.options.map((opt) => {
                return <div style={{ borderBottom: "1px solid #F4F4F7", width: "calc(100%)", marginLeft: "0px", marginRight: "0px", minHeight: "36px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <span style={{ color: "#242424", fontWeight: "500", fontSize: "12px" }}>{opt.title}</span>
                  <span style={{ fontWeight: "500", color: "#242424", fontSize: "12px" }}>{opt.type == "list" ? (opt.dataTitle) : (opt.type == "boolean" ? this.props.t("confirmCancel") : opt.inputValue)}</span>
                </div>
              })}
            </div>
          </Collapse>
        </driverInformation>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  OrderListOpenDialoge: state.OrderHistory.OrderListOpenDialoge,


  id: state.ViewTravel.ID,
  newMessageFlag: state.ViewTravel.newMessage,

  vehicleType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.vehicleType
      ? state.ViewTravel.pureData.vehicleType
      : null
    : null,
  driver: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driver
      ? state.ViewTravel.pureData.driver
      : null
    : null,
  options: state.ViewTravel.pureData && state.ViewTravel.pureData.vehicleType
    ? state.ViewTravel.pureData.vehicleType.options
      ? state.ViewTravel.pureData.vehicleType.options
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
