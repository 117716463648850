import React, { useState } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import debounce from "lodash/debounce";
import Order from "./Order";
import {ReactComponent as Iconplus1} from "../../../../static/plus1.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Fade from "@material-ui/core/Fade";
import { ChangeNewTravelDialoge } from "../../../../Redux/actions/actionNames";
import { Api_GetVehicleTypeByService_AGW, Api_get_balance_AGW, Api_Get_Services_AGW } from "../../../../helper/api/ApiGateway";
import { getCenterMap } from "../../../../helper/module";
import { Link } from 'react-router-dom';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ps = null;
  }

  loadMore = () => {
    this.props.loadMore()
  };

  componentDidMount() {
        const container = document.getElementById("OrderListsx");
        this.ps = new PerfectScrollbar(container);
        container.addEventListener("ps-y-reach-end", () => {
          // this.loadMore();
        });
  }

  getList = () => {
    return Object.keys(this.props.OrderList).map(
      (key) => this.props.OrderList[key]
    );
  };

  openNewTravel=()=>{
    let center = getCenterMap(this.props.mapRef , this.props.center) ;


      this.props.dispatch(ChangeNewTravelDialoge());
      Api_Get_Services_AGW(this.props.dispatch, this.props.mapRef.current ? center  :  this.props.center);

      try {
        Api_GetVehicleTypeByService_AGW(this.props.dispatch, {
          latitude:this.props.mapRef.current ? center.lat : this.props.center.lat,
          longitude:this.props.mapRef.current ? center.lng : this.props.center.lng,
          serviceId: this.props.activeVehicleType.id
      })
      } catch (error) {
        
      }
      Api_get_balance_AGW(this.props.dispatch, {}, (call) => {});
      

  }



  
  render() {
    return (
      <div className="OrderListMaperContainer" style={{marginTop:"0px"}}>
        <div className="OrderLists" id="OrderListsx">
          {this.getList().map((order, i) => {
            return <Order key={i} data={order} {...order} />;
          })}
          
        </div>
        <Link to={`/neworder`} > <Iconplus1 className="OrderListMaperNewOrder"  onClick={this.openNewTravel} />  </Link>
        {/* <img /> */}
        <Fade
          in={this.props.OrderListLoading && !isEmpty(this.props.OrderList)}
        >
          <div
            className="NoOrderContainer"
            style={{ position: "absolute", backgroundColor: "#e3eef76b" }}
          >
            <div className="NoOrderInner mrb90">
              <div className="lds-grid">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </Fade>
        {/* <div style={{width:"100%" ,height:"50%",float:"left",backgroundColor:"white",position:'absolute'}}> 
        <span style={{width:"100%",height:"auto",wordBreak:"break-all"}}>  {JSON.stringify({  OrderListLoading: this.props.OrderListLoading,
  OrderListError: this.props.OrderListError,
  page: this.props.page})}
  </span>

        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  OrderList: state.OrderList.OrderList,
  OrderListLoading: state.OrderList.OrderListLoading,
  OrderListError: state.OrderList.OrderListError,
  page: state.OrderList.page,
  center:state.Map.center ,
  activeVehicleType:state.NewOrder.newOrder.service.activeVehicleType,
  mapRef: state.Map.mapRef,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
