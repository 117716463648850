import NOBODIIGO from "../../../static/EzDelivery_logo.png";
import FavIcon from "../../../static/PROJECTS/EzDelivery.png";
import Banner from "../../../static/EzDelivery_logocp-resource@3x.png";

export default function EzDelivery() {
    
    return  global.config = {
        "project": "EzDelivery",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "EzDelivery",
        "LogoType": NOBODIIGO,
        "BaseApi": "https://rest.ezdeliveryservices.com",
        "BaseSocket": "https://socket.ezdeliveryservices.com",
        "BaseTrack": "https://track.ezdeliveryservices.com",
        "MapCenter": {
          "lat": "40.71272522340908",
          "lng": "-74.0042424056128"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        } ,{
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "DO"
        }],
        "FavIcon": "",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"EzDelivery",
        registerEnabled: true,
        Secondarycolor: "#073692",
        SecondaryDarkColor: "#082E77",
        SecondaryLightColor: "#083FA9",
        SecondaryUltraLight: "#E6EBF4",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#073692",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#B6C3DC",
        icon_light_end:"#D4E1F9",
        icon_dark_start:"#073692",
        icon_dark_end:"#083FA9",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#0b60fe",
        loginBackgroundColorEnd:"#0b60fe",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:NOBODIIGO,
        apkUrl:null,
        iosUrl:null
}}