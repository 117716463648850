import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {ReactComponent as SignOut} from "../../../../../static/icon-ic-sign-out-menu.svg";
//import { LogoutHttp } from "../../../../../helper/api";
import styles from './style.module.css';
import warning from "../../../../../static/warning.svg";
import Typography from "../../../../../design-system/lib/Typography";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  handleOpen = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  Logout=()=>{
    localStorage.clear();
    window.location.reload()
  }

  render() {
    return (
      <React.Fragment>
        <span onClick={this.handleOpen} style={{ width: "100%", float: "left", marginTop: "24px" }}>
          <div className="SignOutContainer">
            <SignOut className="SignOut"  />
            <span className="LogoutText">
              {" "}
              <Trans i18nKey={"Logout"}> </Trans>
            </span>
          </div>
        </span>

        


        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openMenu}
          onClose={this.handleOpen}
          className="logoutContainer"
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
          style={{borderRadius:"10px"}}
        >
          <div className={styles.container}>
            <img src={warning} style={{width:"32px",hheight:"32px",marginBottom:"4px"}} />
            <Typography weight="Heading_Small_Bold" text={this.props.t("logotTitle")} />
            <Typography weight="Body_Middle_Regular" text={this.props.t("logoutDescription")} style={{fontWeight:"200",marginBottom:"6px"}}/>
            <div style={{ direction: "ltr", height: "auto" ,display:"flex" , flexDirection:"row",justifyContent:"center",alignItems:"start",gap:"16px" }}>
            <Button
              onClick={this.Logout}
              className="confirmLogout"
              style={{
                boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                fontFamily: "IRANSans",
                color: "white",
                height: "30px",
                minHeight: "30px",
                marginLeft: "3%",
                marginTop: "1px",
                marginRight: "1%",
                width: "129px",
                fontSize: "12px",
                backgroundColor: "#3583e0",
                border: "none",
                borderRadius: "8px",
                margin:"0"
              }}
              variant="contained"
            >
              <Trans i18nKey={"confirmLogout"}> </Trans>
            </Button>
            <Button
              onClick={this.handleOpen}
              variant="contained"
              className="skipLogout"
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'unset',
              }}
            >
               <Trans i18nKey={"skipLogout"}> </Trans>
            </Button>
          </div>


          </div>
    
        </Dialog>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
