import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import {ReactComponent as Box} from "../../../../static/icon-box-2.svg";
import { isEmpty } from 'lodash'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (!this.props.OrderHistoryListError && !this.props.OrderHistoryListLoading && isEmpty(this.props.OrderHistoryList)) {
      return (
        <div className="NoOrderContainer">
          <div className="NoOrderInner mrb90">
          <Box  className="NoOrderbox" />
            <span className="NoOrderTitle">
              {" "}
              <Trans i18nKey={"Noorder"}> </Trans>{" "}
            </span>
            <span className="NoOrderDescription">
              {" "}
              <Trans i18nKey={"Historyofyourorderswillbehere"}>
                {" "}
              </Trans>{" "}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  OrderHistoryList: state.OrderHistory.OrderHistoryList,
  OrderHistoryListLoading: state.OrderHistory.OrderHistoryListLoading,
  OrderHistoryListError: state.OrderHistory.OrderHistoryListError,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
