import DroppXIGO from "../../../static/GetItPicked_logotype.png";
import DroppX_banner from "../../../static/GetItPicked_background.png";

import FavIcon from "../../../static/PROJECTS/GetItPicked.png";

export default function GetItPicked() {
    
    return  global.config = {
        "project": "GetItPicked",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Portal",
        "LogoType": DroppXIGO,
        "BaseApi": "https://rest.getitpicked.com",
        "BaseSocket": "https://socket.getitpicked.com",
        "BaseTrack": "https://admin.getitpicked.com",
        "MapCenter": {
            "lat": "44.66640107687384",
            "lng": "-63.56726556239336"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/GetItPicked.png",
        "Country": "CA",
        "AppleLoginCustomerId": "com.goGetItPicked.delivery",
        "AppleLoginredirectURI": "https://delivery.goGetItPicked.com",
        "FacebookLoginappId": "1313887235707643",
        "GoogleLogincustomerId": "305072126326-44qhibiimjasv9ijkdq94mobifkk67k3.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": DroppX_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"GetItPicked",
        registerEnabled: true,
        Secondarycolor:"#FF3537",
        SecondaryDarkColor:"#D80002",
        SecondaryLightColor:"#FF484A",
        SecondaryUltraLight :"#FFEFEF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#FF3537",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#B5E8FF",
        loginBackgroundColorEnd:"#93D7FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:DroppXIGO,
        apkUrl:null,
        iosUrl:null,
        bussinessRegisterLink:"https://getitpicked.ca/signupbusiness"
}}