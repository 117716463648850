import { get_customerPannel_type } from "../../helper/UserData/userdate";
import * as actionTypes from "../actions/actionTypes";
var _ = require("lodash");

const init = {
  OrderList: {},
  OrderListLoading: false,
  OrderListError: false,
  page: 0,
};
export const OrderList = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setOrderListData:
      if (action.state.reset) {
        return {
          OrderList: {},
          OrderListLoading: false,
          OrderListError: false,
          page: 0,
        };
      } else {
        return {
          ...state,
          ...action.state.extra,
          OrderList: action.state.list
            ? { ...state.OrderList, ..._.keyBy(action.state.list, "id") }
            : state.OrderList,
        };
      }


    case actionTypes.addnewordertoList:
      return {
        ...state,
        OrderList: {
          [action.state.id]: action.state,
          ...state.OrderList,
        },
      };

    case actionTypes.updateStateorderInList:
      if (state.OrderList[action.state.id]) {
        return {
          ...state,
          OrderList: {
            ...state.OrderList,
            [action.state.id]: {
              ...state.OrderList[action.state.id],
              status: action.state.status,
              id: action.state.id,
              ...action.state.data,
            },
          },
        };
      } else {
        return {
          ...state,
          OrderList: {
            [action.state.id]: {
              ...state.OrderList[action.state.id],
              status: action.state.status,
              id: action.state.id,
              ...action.state.data,
            },
            ...state.OrderList,
          },
        };
      }

    case actionTypes.addnewmessageflagtoorder:
      if (state.OrderList[action.state.id]) {
        return {
          ...state,
          OrderList: {
            ...state.OrderList,
            [action.state.id]: {
              ...state.OrderList[action.state.id],
              newMessage: action.state.bool
            },
          },
        };
      } else {
        return state;
      }

    case actionTypes.DELETETRAVELINLIST:
      if (state.OrderList[action.state]) {
        return {
          ...state,
          OrderList: {
            ...removeByKey(state.OrderList, action.state),
          },
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const removeByKey = (object, deleteKey) => {

  return Object.keys(object)
    .filter((key) => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = object[current];
      return result;
    }, {});
};
