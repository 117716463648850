import * as actionTypes from "../actions/actionTypes";
var _ = require("lodash");

const init = {
  OrderListOpenDialoge: false,
  OrderId: null,
  OrderHistoryList: {},
  OrderHistoryListLoading: false,
  OrderHistoryListError: false,
  page: 0,
};
export const OrderHistory = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setOrderHistoryListData:
      return {
        ...state,
        ...action.state.extra,
        OrderHistoryList: action.state.list
          ? { ...state.OrderHistoryList, ..._.keyBy(action.state.list, "id") }
          : state.OrderHistoryList,
      };
    case actionTypes.openCloseOrderHistory:
      if (!action.state) {
        return { ...init };
      } else {
        return {
          ...state,
          OrderListOpenDialoge: action.state,
          OrderHistoryList: action.state ? {} : state.OrderHistoryList,
          page: action.state ? 0 : state.page,
        };
      }

    default:
      return state;
  }
};
