import AnnanowIGO from "../../../static/Annanow_pro_logotype.png";
import Annanow_banner from "../../../static/Annanow_pro_background.jpg";

import FavIcon from "../../../static/PROJECTS/Annanow_pro.png";

export default function Annanow_pro() {
    
    return  global.config = {
        "project": "app.anna-now.com",
        "ShowPowerOnro": false,
        "flag": "germanyflag",
        "ProjectName": "Annanow Shops",
        "LogoType": AnnanowIGO,
        "BaseApi": "https://rest.anna-now.com",
        "BaseSocket": "https://socket.anna-now.com",
        "BaseTrack": "https://panel.anna-now.com",
        "MapCenter": {
            "lat": 47.3769,
            "lng": 8.5417
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        }],
        "FavIcon": "/Annanow.png",
        "Country": "DE",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Annanow_banner,
        "FavIconWithDomain": FavIcon ,
        registerEnabled: true,
        Secondarycolor:"#000000",
        SecondaryDarkColor:"#1A1A1A",
        SecondaryLightColor:"#272727",
        SecondaryUltraLight :"#F9F5E6",
        SecondaryTextColor:"#CEAF2E",
        Secondaryonlight:"#000000",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        customFontColor:null,
        customColor:false,
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:AnnanowIGO,
        apkUrl:null,
        iosUrl:null
}}