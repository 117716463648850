import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import Business from "./business";
import Indivisual from "./indivisual";
import SwipeableViews from 'react-swipeable-views';
import { connect } from "react-redux";
import ClientAppsDownloadBadge from "../../clientAppsDownloadBadge";
import { Helmet } from "react-helmet";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab:(global.config.isIndividualSignupActive && global.config.isIndividualTypeActive) ? 0 :1
    }
  }

  tabChange = (tab) => {
    this.setState({
      activeTab: tab
    })
  }
  render() {
    let axis = this.props.direction == "Ltr" ? "x" : "x-reverse";

    return <div className="loginRegisterContainer">
            <Helmet
    style={[{
        "cssText": `
        .App {
          overflow-y: scroll;                  
        }
        `
    }]}
/>
      <div className="loginRegisterSwitchTab">
        {(global.config.isIndividualSignupActive && global.config.isIndividualTypeActive) && <span onClick={() => { this.tabChange(0) }} className={`PersonBusiness ${this.state.activeTab == 0 && "ActivePersonBusiness"}`} style={{ width: (global.config.isBusinessTypeActive ? (global.config.isBusinessSignupActive ? false : true ) : true) ? "100%" : ""  }}>  <Trans i18nKey={"individual"}> </Trans>  </span>}
        {(global.config.isBusinessSignupActive && global.config.isBusinessTypeActive) && <span onClick={() => { this.tabChange(1) }} className={`PersonBusiness ${this.state.activeTab == 1 && "ActivePersonBusiness"}`} style={{ width: (global.config.isIndividualTypeActive ? (global.config.isIndividualSignupActive ? false : true ) : true) ? "100%" : "" }}>  <Trans i18nKey={"Business"}> </Trans>  </span>
        }      </div>


      <SwipeableViews axis={axis} index={this.state.activeTab} onChangeIndex={this.handleChangeIndex} className="personBusinessSwipeableViewsContainer">
        {(global.config.isIndividualSignupActive && global.config.isIndividualTypeActive) && <Indivisual LoginIsActive={this.props.LoginIsActive} /> }
        {(global.config.isBusinessSignupActive && global.config.isBusinessTypeActive) && <Business />}
      </SwipeableViews>

      <ClientAppsDownloadBadge />

    </div>;
  }
}
const mapStateToProps = (state) => ({
  authenticated: state.SharedData.access_token,
  direction: state.SharedData.direction,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));


