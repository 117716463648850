
import CarRecoveryIGO from "../../../static/CarRecoveryIGO.png";
import CarRecovery_banner from "../../../static/CarRecoveryBanner.png";
import FavIcon from "../../../static/PROJECTS/CarRecovery.png";

export default function CarRecovery() {
    
    return  global.config = {
        "project": "911 Car Recovery",
        "ShowPowerOnro": false,
        "flag": "",
        "ProjectName": "Customer Panel",
        "LogoType": CarRecoveryIGO,
        "BaseApi": "https://rest.aladden.org",
        "BaseSocket": "https://socket.aladden.org",
        "BaseTrack": "https://panel.aladden.org",
        "MapCenter": {
            "lat": "30.0444117209904",
            "lng": "31.235573120930503"
        },
        "Lang": "ar",
        "Direction": "Rtl",
        "AllLanguages": [{
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }, {
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/CarRecovery.png",
        "Country": "EG",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": CarRecovery_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: "",
        customColor:"CarRecovery",
        registerEnabled: true,
        Secondarycolor: "#FCCE1B",
        SecondaryDarkColor: "#EBC11D",
        SecondaryLightColor: "#FFD533",
        SecondaryUltraLight: "#FFFAE8",
        SecondaryTextColor: "#242424",
        Secondaryonlight: "#242424",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#000000",
        icon_light_end:"#333333",
        icon_dark_start:"#FCCE1B",
        icon_dark_end:"#FFD533",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFEA9B",
        loginBackgroundColorEnd:"#FFE88F",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:CarRecoveryIGO,
        apkUrl:null,
        iosUrl:null
}}