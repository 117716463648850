import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { IC_SEARCH, Plus_Dark, filterPlus , ic_circle_close} from '../../../static';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../../design-system/lib/DropDown';
import { Add_ActiveFilter, clearAll_ActiveFilter } from '../../../Redux/actions/actionNames';
import Typography from '../../../design-system/lib/Typography';
import { initTable } from '../../../Redux/reducers/table';
import _ from 'lodash';
import IconProvider from '../../../design-system/lib/IconProvider';


function Addilter({heightOnChange}) {
  const activeFilters = useSelector((state) => state.Table.activeFilters)
  const filters = useSelector((state) => state.Table.filters)

  const dispatch = useDispatch();

  // const [filter1, setFilter1] = useState(null);

  const handleSelectFilter = React.useCallback((id) => {
    dispatch(Add_ActiveFilter({
      ...id,
      title :id.stage
    }))
  }, [activeFilters]);


  const filterList = React.useCallback((list) => {
    return list.filter((item)=>{
      return activeFilters.findIndex((itemx) => { return item.stage == itemx.stage }) == -1
    })
  }, [activeFilters]);

  const canClearAll = React.useCallback(() => {
    let DEFf = JSON.parse(JSON.stringify(initTable.filters))
    delete DEFf.type
    delete DEFf.cursor
    delete DEFf.cursorSort

    let NEWf = JSON.parse(JSON.stringify(filters))
    delete NEWf.type
    delete NEWf.cursor
    delete NEWf.cursorSort

    return !_.isEqual(DEFf , NEWf)
  }, [filters]);

  const ClearAll = React.useCallback((id) => {
    dispatch(clearAll_ActiveFilter())
  }, [activeFilters]);


  filterList(List())
  return (  
    <>
     { filterList(List()).length > 0 && <DropDown placement='bottomEnd' noArrowIcon={true} icon={filterPlus} size='Small' data={filterList(List())} text={'More'} onClick={handleSelectFilter} className={styles.DropDown}  checkSelected='full' />}
      {canClearAll() && <Typography onClick={ClearAll} className='filterClearAll' weight='Body_Tiny_Bold' textColor='Primary' text='Clear All' style={{cursor:"pointer"}} />}
    </>
  
  )
}


export default Addilter


const List = () => [
  {
    title: "referenceId",
    stage: "referenceId",
    type: "regex"
  },
  
  {
    title: "note",
    stage: "note",
    type: "regex"
  },
  {
    title: "pickupAddress",
    stage: "pickupAddress",
    type: "regex"
  },
  {
    title: "deliveryAddress",
    stage: "deliveryAddress",
    type: "regex"
  },
  // {
  //   title: "driverUsername",
  //   stage: "driverUsername",
  //   type: "regex"
  // },

  // {
  //   title: "driverName",
  //   stage: "driverName",
  //   type: "regex"
  // },
  // {
  //   title: "driverPhone",
  //   stage: "driverPhone",
  //   type: "regex"
  // },
  // {
  //   title: "customerEmail",
  //   stage: "customerEmail",
  //   type: "regex"
  // },
  // {
  //   title: "customerName",
  //   stage: "customerName",
  //   type: "regex"
  // },
  // {
  //   title: "customerPhone",
  //   stage: "customerPhone",
  //   type: "regex"
  // },
  {
    title: "price",
    stage: "price",
    type: "range"
  },
  {
    title: "codAmount",
    stage: "codAmount",
    type: "range"
  },
  {
    title: "paymentMethods",
    stage: "paymentMethods",
    type: "checkbox"
  },
  {
    title: "pickupAddressDetail",
    stage: "pickupAddressDetail",
    type: "regex"
  },
  {
    title: "pickupcompleteAfter",
    stage: "pickupcompleteAfter",
    type: "range"
  },
  {
    title: "pickupcompleteBefore",
    stage: "pickupcompleteBefore",
    type: "range"
  },
  {
    title: "pickupFullName",
    stage: "pickupFullName",
    type: "regex"
  },
  {
    title: "pickupPhone",
    stage: "pickupPhone",
    type: "regex"
  },
  
  {
    title: "deliveryAddressDetail",
    stage: "deliveryAddressDetail",
    type: "regex"
  },

   {
    title: "deliverycompleteAfter",
    stage: "deliverycompleteAfter",
    type: "range"
  },
  {
    title: "deliverycompleteBefore",
    stage: "deliverycompleteBefore",
    type: "range"
  },
  {
    title: "deliveryFullName",
    stage: "deliveryFullName",
    type: "regex"
  },
  {
    title: "deliveryPhone",
    stage: "deliveryPhone",
    type: "regex"
  },
  // {
  //   title: "deliveryAttemptNumber",
  //   stage: "deliveryAttemptNumber",
  //   type: "regex"
  // },
  // {
  //   title: "deliveryLastAttemptReason",
  //   stage: "deliveryLastAttemptReason",
  //   type: "regex"
  // },
  // {
  //   title: "pickupFailedReason",
  //   stage: "pickupFailedReason",
  //   type: "regex"
  // },
  // {
  //   title: "code",
  //   stage: "code",
  //   type: "regex"
  // },






  // {
  //   title: "pickupDescription",
  //   stage: "pickupDescription",
  //   type: "regex"
  // },
  // {
  //   title: "address",
  //   stage: "address",
  //   type: "regex"
  // },






  // {
  //   title: "routeName",
  //   stage: "routeName",
  //   type: "regex"
  // },
  // {
  //   title: "services",
  //   stage: "services",
  //   type: "checkbox"
  // },
  // {
  //   title: "vehicleTypes",
  //   stage: "vehicleTypes",
  //   type: "checkbox"
  // },
  // {
  //   title: "zones",
  //   stage: "zones",
  //   type: "checkbox"
  // },
  // {
  //   title: "type",
  //   stage: "type",
  //   type: "radioButton"
  // },
  // {
  //   title: "createdAt",
  //   stage: "createdAt",
  //   type: "range"
  // },
  // {
  //   title: "statusUpdatedAt",
  //   stage: "statusUpdatedAt",
  //   type: "range"
  // },

  // {
  //   title: "dueDate",
  //   stage: "dueDate",
  //   type: "range"
  // },

  
  // {
  //   title: "pickupzones",
  //   stage: "pickupzones",
  //   type: "checkbox"
  // },

  // {
  //   title: "deliveryzones",
  //   stage: "deliveryzones",
  //   type: "checkbox"
  // },

].map((item)=>{
  return {
     title: <> <IconProvider size='14' Icon={filterPlus} /> {item.title}</>,
     stage: item.stage,
     type: item.type
  }
})