import React, { useState, useEffect } from "react";

import Select, { components } from 'react-select';
import { getCenterMap, isRtl, setCenterMap } from "../../../../helper/module";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Api_SearchAutoComplete_AGW, Api_PlaceIdDetails_AGW } from "../../../../helper/api/ApiGateway";
import {
  ChooseoOnMapimg,
  OnChangeNewAddressBookInNewOrderDetails,
  SetDetailsEdit,
  Set_latlng,
} from "../../../../Redux/actions/actionNames";

const filterOptions = (candidate, input) => {
  return true;
};

const customStyles = {
  input: () => ({
    outline: "none",
  }),
  input: () => ({
    outline: "none",
  }),
  control: (provided) => ({
    ...provided,
    outline: "none",
    border: "solid 1px rgba(128, 128, 128, 0.2)",
    backgroundColor: "rgba(128, 128, 128, 0.04)",
    borderRadius: "8px"
  }),
  container: (provided) => ({
    ...provided,
    float: "left",
    width: "calc(100% - 39px)",
    marginLeft: "20px",
    marginRight: "0px",
  }),
};


// const CustomOption = ({ children, ...props }) => {
//   return (
//     <components.Option {...props}>
//       {children}
//     </components.Option>
//   );
// };

const CustomOption = props => {
  const { innerProps, innerRef } = props;
  console.log("CustomOption ", props)
  return (
    <favAddressselectorInList ref={innerRef} {...innerProps} >
      <name> {props.data.address}</name>
      {props.data.secondaryAddress && <addres> {props.data.secondaryAddress}</addres>}
    </favAddressselectorInList>

    // <article ref={innerRef} {...innerProps} className="custom-option">
    //   <h4>{props.data.artist}</h4>
    //   <div className="sub">{props.data.title} </div>
    // </article>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultInputValue: props.address,
      options: [],
    };
  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    if (selectedOption) {

      if (!selectedOption.coordinates) {
        Api_PlaceIdDetails_AGW(
          this.props.dispatch,
          { placeId: selectedOption.placeId },
          (res) => {
            this.props.dispatch(
              SetDetailsEdit({
                address: res.address
              })
            );
            try {
              setCenterMap(this.props.mapRef, {
                lng: res.coordinates[0],
                lat: res.coordinates[1],
              })
              // this.props.mapRef.current.leafletElement.setView(
              //   {
              //     lng: res.coordinates[0],
              //     lat: res.coordinates[1],
              //   },
              //   17
              // );
            } catch (error) {
              this.props.dispatch(
                Set_latlng({
                  lng: res.coordinates[0],
                  lat: res.coordinates[1],
                })
              );
            }


            this.props.dispatch(
              SetDetailsEdit({
                coordinates: [res.coordinates[0], res.coordinates[1]],
              })
            );

          })

      } else {
        this.props.dispatch(
          SetDetailsEdit({
            address: selectedOption.address
          })
        );
        try {
          setCenterMap(this.props.mapRef, {
            lng: selectedOption.coordinates[0],
            lat: selectedOption.coordinates[1],
          })
          // this.props.mapRef.current.leafletElement.setView(
          //   {
          //     lng: selectedOption.coordinates[0],
          //     lat: selectedOption.coordinates[1],
          //   },
          //   17
          // );
        } catch (error) {
          this.props.dispatch(
            Set_latlng({
              lng: selectedOption.coordinates[0],
              lat: selectedOption.coordinates[1],
            })
          );
        }


        this.props.dispatch(
          SetDetailsEdit({
            coordinates: [selectedOption.coordinates[1], selectedOption.coordinates[0]],
          })
        );
      }


      this.props.dispatch(
        ChooseoOnMapimg({
          showMap: false,
          pickupDropOff: {
            type: null,
            id: null,
          },
        })
      );
    } else {
      this.props.dispatch(
        SetDetailsEdit({
          coordinates: null,
        })
      );
    }
  };

  handleInputChange = (value) => {
    this.props.dispatch(
      SetDetailsEdit({
        coordinates: null,
      })
    );

    let center = getCenterMap(this.props.mapRef, this.props.center);
    if (value && value != "" && value != undefined) {

      this.props.dispatch(
        SetDetailsEdit({ "address": value })
      );
      Api_SearchAutoComplete_AGW(
        this.props.dispatch,
        {
          query: value,
          latitude: this.props.mapRef.current
            ? center.lat
            : this.props.center.lat,
          longitude: this.props.mapRef.current
            ? center.lng
            : this.props.center.lng,
        },
        (call) => {
          this.SetOptions(call);
        }
      );
    }
  };

  SetOptions = (arr) => {
    this.setState({
      options: arr.map((item, i) => {
        return {
          ...item, label: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address, value: i
        };
      }),
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.forceLoading) {
      setTimeout(() => {
        this.props.dispatch(
          SetDetailsEdit({
            forceLoading: false
          })
        );
      }, 500);
    }
  }


  render() {
    const { t } = this.props;

    let defaultInputValue = this.props.address || "";

    if (this.props.forceLoading) {
      return <input
        value={this.props.t("poperLoading")}
        className="NewAddressbookAddressdetailsAddress"
        placeholder={t("address")}
        disabled
      />
    } else {
      return (
        <Select
          defaultInputValue={this.props.address}
          styles={customStyles}
          defaultValue={""}
          isClearable
          isSearchable
          name="color"
          options={this.state.options}
          filterOption={filterOptions}
          inputId="NewAddressbookAddressdetailsAddress"
          classNamePrefix="Select"
          isRtl={isRtl()}
          placeholder={<div>{t("address")}</div>}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          noOptionsMessage={() => "....."}
          components={{ Option: CustomOption }}
        />
      );
    }
  }
}

const mapStateToProps = (state) => ({
  mapRef: state.Map.mapRef,
  address: state.Addressbook.EditAddressBookInMenuDetails.address,
  forceLoading: state.Addressbook.EditAddressBookInMenuDetails.forceLoading,
  center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
