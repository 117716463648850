import LogoType from "../../../static/FlytoCourier.png";
import Banner from "../../../static/Flyto_background.png";

import FavIcon from "../../../static/PROJECTS/FlytoCourierFavIcon.png";

export default function Flyto() {
    
    return  global.config = {
        "project": "Flyto",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Asiakasportaali",
        "LogoType": LogoType,
        "BaseApi": "https://rest.flyto.fi",
        "BaseSocket": "https://socket.flyto.fi",
        "BaseTrack": "https://admin.flyto.fi",
        "MapCenter": {
            "lat": "60.16945646726855",
            "lng": "24.936962147076756"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "FI",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Asiakasportaali",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Flyto",
        registerEnabled: true,
        Secondarycolor: "#35327F",
        SecondaryDarkColor: "#B72472",
        SecondaryLightColor: "#6763C2",
        SecondaryUltraLight: "#FFF0F8",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#6763C2",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FFC1E0",
        icon_light_end:"#EEC0E5",
        icon_dark_start:"#C22370",
        icon_dark_end:"#343280",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}