import QuickSendIGO from "../../../static/QuickSend_logotype.png";
import QuickSend_banner from "../../../static/QuickSend_background.jpg";

import FavIcon from "../../../static/PROJECTS/QuickSend.png";

export default function QuickSend() {
    
    return  global.config = {
        "project": "app.quicksend.ug",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": QuickSendIGO,
        "BaseApi": "https://rest.quicksend.ug",
        "BaseSocket": "https://socket.quicksend.ug",
        "BaseTrack": "https://panel.quicksend.ug",
        "MapCenter": {
            "lat": 0.33544504527718905,
            "lng": 32.58207742203401
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/QuickSend.png",
        "Country": "UG",
        "AppleLoginCustomerId": null,
        "AppleLoginredirectURI": null,
        "FacebookLoginappId": "455979896323618",
        "GoogleLogincustomerId": "629387166788-vhpvoa581p5qep8c2ra0p6cc13l3jddc.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": QuickSend_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"QuickSend",
        registerEnabled: true,
        Secondarycolor:"#1080FF",
        SecondaryDarkColor:"#0055cb",
        SecondaryLightColor:"#25B7FF",
        SecondaryUltraLight :"#D9EAFF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#1080FF",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:QuickSendIGO,
        apkUrl:null,
        iosUrl:null
}}