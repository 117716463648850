import LogoType from "../../../static/Blinkds_logotype.png";
import Banner from "../../../static/Blinkds_Cp_background.png";

import FavIcon from "../../../static/PROJECTS/Blinkds.png";

export default function Blinkds() {

    return global.config = {
        "project": "Blinkds",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "BDS",
        "LogoType": LogoType,
        "BaseApi": "https://rest.blinkds.com",
        "BaseSocket": "https://socket.blinkds.com",
        "BaseTrack": "https://track.blinkds.com",
        "MapCenter": {
        "lat": "25.20748918126513",
        "lng": "55.27108994012899"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [
            {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
                "cssFlag": "englishflag",
                "country": "US"
            }],
        "FavIcon": "",
        "Country": "AE",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor: "Blinkds",
        registerEnabled: true,
        Secondarycolor: "#283673",
        SecondaryDarkColor: "#09164E",
        SecondaryLightColor: "#374A9E",
        SecondaryUltraLight: "#EEEFF4",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#283673",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        enabledCustomColor: true,
        mapType: "osm", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFFADB",
        loginBackgroundColorEnd:"#FFF09A",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
    }
}