import LogoType from "../../../static/PickNDrop.png";
import Banner from "../../../static/PickNDrop@3x.png";

import FavIcon from "../../../static/PROJECTS/PickNDrop_fav_icon.png";

export default function PickNDrop() {
    
    return  global.config = {
        "project": "PickNDrop",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "PickNDrop",
        "LogoType": LogoType,
        "BaseApi": "https://rest.pickndrop.io",
        "BaseSocket": "https://socket.pickndrop.io",
        "BaseTrack": "https://track.pickndrop.io",
        "MapCenter": {
            "lat": 33.893913785443885,
            "lng": 35.502049555898594
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "SA"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        },
        {
            "lang": "zh",
            "direction": "Ltr",
            "i18Lang": "ChineseSimplified",
            "cssFlag": "ChineseSimplifiedflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "LB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "PickNDrop",
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"PickNDrop",
        registerEnabled: true,
        Secondarycolor:"#552583",
        SecondaryDarkColor:"#3B0F65",
        SecondaryLightColor:"#6D31A7",
        SecondaryUltraLight :"#EEE9F3",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#552583",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#EEE9F3",
        loginBackgroundColorEnd:"#EBD7FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}