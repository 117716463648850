import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import Typography from '../../../design-system/lib/Typography';
import IconProvider from '../../../design-system/lib/IconProvider';
import Button from '../../../design-system/lib/Button';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ChangeNewTravelDialoge, SetBulkImportOpen } from '../../../Redux/actions/actionNames';
import { Api_GetVehicleTypeByService_AGW, Api_Get_Services_AGW, Api_get_balance_AGW } from '../../../helper/api/ApiGateway';
import { getCenterMap } from '../../../helper/module';


import {ReactComponent as Ic_box} from '../../../static/ic_box.svg';
import {ReactComponent as Ic_add}  from "../../../static/plus_white.svg";
import {ReactComponent as Import2}  from "../../../static/import2.svg";


function TableNoOrder() {
  const dispatch = useDispatch();
  const ref = useRef(null); // ref => { current: null }
  const [openDropDown, setOpenDropDown] = useState(false);
  const { t, i18n } = useTranslation();
  const center = useSelector((state) => state.Map.center)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const mapRef = useSelector((state) => state.Map.mapRef)



  function NewTravelHandler() {
    dispatch(ChangeNewTravelDialoge());

    Api_Get_Services_AGW(dispatch,mapRef.current ? getCenterMap(mapRef , center) : center);

    try {
      let center = getCenterMap(mapRef , center) ;


      Api_GetVehicleTypeByService_AGW(dispatch, {
        latitude:mapRef.current ? center.lat : center.lat,
        longitude:mapRef.current ? center.lng : center.lng,
        serviceId: activeVehicleType.id,
      });
    } catch (error) {}
    Api_get_balance_AGW(dispatch, {}, (call) => {});
  };


  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100vw", flexDirection: 'column', transition: "all .3s ease-in-out" ,justifyContent:"center",alignItems:"center"}}>
              <div className={styles.dialog}>
              <Ic_box style={{ marginBottom: "16px" }} />
        <Typography weight='Body_Middle_Bold' text='Create your order' style={{ textAlign: "center", marginBottom: "8px" }} />
        <Typography weight='Body_Tiny_Regular' text='Your orders will be shown here' style={{ textAlign: "center", marginBottom: "16px", color: "rgba(128, 128, 128, 1)" }} />
        <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:"10px"}}>
        <Link to={`/neworder`} ><div className={styles.buttonx +" NewOrderBtnsHeader "} onClick={NewTravelHandler}>
        <Ic_add />
            <Typography weight={"Body_Middle_Medium"} text={"New"} textColor={"White"} className={styles.buttonText} onClick={() => { }} />
          </div>
      </Link>
          <div className={styles.button+ " orderTypesHeaderTabNotActive"} onClick={() => { dispatch(SetBulkImportOpen(true)) }}>
          <Import2 />
            <Typography weight={"Body_Middle_Medium"} text={"Import"} textColor={"TextDark"} className={styles.buttonText} onClick={() => { }} />
          </div>

        </div>
      </div>
    </div>
  )
}
export default TableNoOrder