import LogoType from "../../../static/Degrees3_logotype.png";
import Banner from "../../../static/Degrees3_Cp_background.png";

import FavIcon from "../../../static/PROJECTS/Degrees3.png";

export default function Degrees3() {

    return global.config = {
        "project": "ODOS Last Mile",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "ODOS Last Mile",
        "LogoType": LogoType,
        "BaseApi": "https://rest.odos.tech",
        "BaseSocket": "https://socket.odos.tech",
        "BaseTrack": "https://track.odos.tech",
        "MapCenter": {
            "lat": "-19.004371991153743",
            "lng": "29.87113559472689"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [
            {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
                "cssFlag": "englishflag",
                "country": "US"
            }],
        "FavIcon": "",
        "Country": "ZW",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor: "Degrees3",
        registerEnabled: true,
        Secondarycolor: "#47A2B5",
        SecondaryDarkColor: "#127C92",
        SecondaryLightColor: "#47A2B5",
        SecondaryUltraLight: "#F0F8F9",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#47A2B5",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        enabledCustomColor: true,
        mapType: "osm", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText:true,
        loginBackgroundColorStart:"#E5FAFF",
        loginBackgroundColorEnd:"#C3F5FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
    }
}