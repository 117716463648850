import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Remove_Service_Option, Add_Service_Option } from "../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active:false,
      selected:null
    };
    this.anchorEl = React.createRef();
  }

  handleactive = () => {
    if (this.optionisSaved()) {
      this.props.dispatch(Remove_Service_Option(this.props.id))
    } else {
      this.props.dispatch(Add_Service_Option({
        name:this.props.name,
        id:this.props.id,
        type:"boolean"
      }))
    }

  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment >
        <clickableservice ref={this.anchorEl} id={this.props.id} onClick={this.handleactive} className={`${this.optionisSaved() && "avtiveServiceOptions"}`}
        style={{margin:"0px"}}>
          {this.props.title}
        </clickableservice>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options:state.NewOrder.newOrder.service.options

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
