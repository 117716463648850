import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import {
  get_customerPannel_type,
  get_Id,
  get_LeftRight,
} from "../../../../helper/UserData/userdate";
import close from "../../../../static/close.svg";
import {
  ADDMESSAGETOCHATS,
  OPENCLOSECHATDRIVER,
} from "../../../../Redux/actions/actionNames";
import call from "../../../../static/icon-call.svg";
import {ReactComponent as Send} from "../../../../static/icon-send.svg";

import Fade from "@material-ui/core/Fade";
import loading from "../../../../static/loading.gif";
import reload from "../../../../static/reload.png";
import Messages from "./chatWithDriverMessage";
import { animateScroll } from "react-scroll";
import Swiper from "react-id-swiper";
import {
  Api_get_chats_AGW,
  Api_get_quick_message_AGW,
  Api_seen_message_AGW,
  Api_Send_Message_AGW,
} from "../../../../helper/api/ApiGateway";
import { SendMessageWithSocket } from "../../../../socket/Emit_handelers";
import { Bussiness_Send_Message_Action } from "../../../../Redux/actions/actions";
const { v4: uuidv4 } = require("uuid");

var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
    };
  }

  scrollToBottom() {
    animateScroll.scrollToBottom({
      containerId: "messages-holder",
    });
  }
  inputOnChange = (e) => {
    this.setState({
      input: e.target.value,
    });
  };
  opencloseDialoge = () => {
    this.props.dispatch(OPENCLOSECHATDRIVER(false));
  };
  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.OpenDialoge && !this.props.OpenDialoge) {
      Api_get_chats_AGW(nextProps.dispatch, nextProps.id, null);
    }
    
   if(nextProps.messages.length > 0){
    let lastMessage = nextProps.messages[nextProps.messages.length - 1]

    if(lastMessage.source == "driver" && !lastMessage.isSeen){
      Api_seen_message_AGW(this.props.dispatch,{
        "orderId": this.props.id,
        "messageId": lastMessage.id
      },()=>{})
    }
   }
  }

  componentDidMount() {
    Api_get_quick_message_AGW(this.props.dispatch, null, null);
    Api_get_chats_AGW(this.props.dispatch, this.props.id, null);
    window.addEventListener("online", () => {
      Api_get_chats_AGW(this.props.dispatch, this.props.id, null);
    });
  }

  sendMessage = () => {
    let uuid = uuidv4();


    Api_Send_Message_AGW(
      this.props.dispatch,
      {
        orderId: this.props.id,
        text: this.state.input,
        uid: uuid,
        quickMessageId: "",
        customerId: get_Id(),
      },
      (id) => {
        this.props.dispatch(
          ADDMESSAGETOCHATS({
            text: this.state.input,
            createdAt: new Date(),
            source: get_customerPannel_type() == "individual" ? "customer" : "business",
            uid: uuid,
            id,
          })
        );
        this.setState({
          input: "",
        });
      }
    );

    // if (get_customerPannel_type() == "individual") {
    //   SendMessageWithSocket(
    //     {
    //       orderId: this.props.id,
    //       text: this.state.input,
    //       uid: uuid,
    //     },
    //     (id) => {
    //       this.props.dispatch(
    //         ADDMESSAGETOCHATS({
    //           text: this.state.input,
    //           createdAt: new Date(),
    //           source: "customer",
    //           uid: uuid,
    //           id
    //         })
    //       );
    //       this.setState({
    //         input: "",
    //       });
    //     }
    //   );
    // } else {
    //   this.props.dispatch(
    //     Bussiness_Send_Message_Action(
    //       this.props.dispatch,
    //       {
    //         orderId: this.props.id,
    //         text: this.state.input,
    //         uid: uuid,
    //         quickMessageId: "",
    //         customerId: get_Id(),
    //       },
    //       (id) => {
    //         this.props.dispatch(
    //           ADDMESSAGETOCHATS({
    //             text: this.state.input,
    //             createdAt: new Date(),
    //             source: "business",
    //             uid: uuid,
    //             id
    //           })
    //         );
    //         this.setState({
    //           input: "",
    //         });
    //       }
    //     )
    //   );
    // }
  };

  PreDefiendsendMessage = (item) => {
    let uuid = uuidv4();

    Api_Send_Message_AGW(
      this.props.dispatch,
      {
        orderId: this.props.id,
        text: item.text,
        uid: uuid,
        quickMessageId: "",
        customerId: get_Id(),
      },
      (id) => {
        this.props.dispatch(
          ADDMESSAGETOCHATS({
            text: item.text,
            createdAt: new Date(),
            source: get_customerPannel_type() == "individual" ? "customer" : "business",
            uid: uuid,
            id,
          })
        );
        this.setState({
          input: "",
        });
      }
    );


    // if (get_customerPannel_type() == "individual") {
    
    //     SendMessageWithSocket(
    //   {
    //     orderId: this.props.id,
    //     text: item.text,
    //     uid: uuid,
    //   },
    //   (id) => {
    //     this.props.dispatch(
    //       ADDMESSAGETOCHATS({
    //         text: item.text,
    //         createdAt: new Date(),
    //         source: "customer",
    //         uid: uuid,
    //         id
    //       })
    //     );
    //     this.setState({
    //       input: "",
    //     });
    //   }
    // );
    // }else{
    //   this.props.dispatch(
    //     Bussiness_Send_Message_Action(
    //       this.props.dispatch,
    //       {
    //         orderId: this.props.id,
    //         text: item.text,
    //         uid: uuid,
    //         quickMessageId: "",
    //         customerId: get_Id(),
    //       },
    //       (id) => {
    //         this.props.dispatch(
    //           ADDMESSAGETOCHATS({
    //             text: item.text,
    //             createdAt: new Date(),
    //             source: "business",
    //             uid: uuid,
    //             id
    //           })
    //         );
    //         this.setState({
    //           input: "",
    //         });
    //       }
    //     )
    //   );
    // }

  };

  retryOrderInfo = () => {
    Api_get_chats_AGW(this.props.dispatch, this.props.id, null);
  };


  checkCanEnter = (e) => {
    if(e.key === "Enter" && this.state.input.length > 0){
      this.sendMessage()
    }
  //  this.state.input.length > 0
  
  };

  render() {
    let lastMessage =this.props.messages.length > 0 ? this.props.messages[this.props.messages.length - 1] : null
    let showSeen = lastMessage && !lastMessage.source == "driver" && lastMessage.isSeen


    const params = {
      slidesPerView: "auto",
      spaceBetween: 9,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };

    return (
      <Slide
        direction={get_LeftRight()}
        in={this.props.OpenDialoge}
        mountOnEnter
        unmountOnExit
      >
        <div className="NewOrder cwdc">
          <nRHeader style={{ boxShadow: "none" }} className="cwdcHeader">
            <img
              onClick={this.opencloseDialoge}
              className="close closee"
              src={close}
            />
            <span className="chatWdrivertitle">
              {this.props.driver && this.props.driver.name}
            </span>
            <p className="chatWdriverSubtitle">
              {`${this.props.driver && this.props.driver.vehicle.model} - `}{" "}
              <span style={{ float: get_LeftRight() }}>{`${
                this.props.driver && this.props.driver.vehicle.plate
              }`}</span>
            </p>

            <a
              href={`tel:${this.props.driver && this.props.driver.phone}`}
              style={{ textDecoration: "none" }}
            >
              <img src={call} className="calldriver" />
            </a>
          </nRHeader>
          {!this.props.loading && !this.props.error && (
            <div
              className={`chatWithDriver_MContainer ${
                this.props.predefinedMessages.length == 0 &&
                "nopredefinedMessages"
              }`}
              id="messages-holder"
            >
              {this.props.messages.map((item, i ) => {
                return <Messages {...item} last={i == this.props.messages.length - 1} />;
              })}

             

            </div>
          )}

          {!this.props.loading &&
            !this.props.error &&
            this.props.predefinedMessages.length > 0 && (
              <Swiper
                {...params}
                containerClass="chatWithDriver_PredefiendMessages_Container"
              >
                {this.props.predefinedMessages.map((item) => {
                  return (
                    <p
                      onClick={() => this.PreDefiendsendMessage(item)}
                      className="PredefiendMessage"
                    >
                      {" "}
                      {item.text}{" "}
                    </p>
                  );
                })}
              </Swiper>
            )}

          {this.props.loading && (
            <React.Fragment>
              <img src={loading} className="gifLoading" />
              <span className="loading">
                <Trans i18nKey={"Loadingmessages"}> </Trans>
              </span>
            </React.Fragment>
          )}

          {!this.props.loading && this.props.error && (
            <React.Fragment>
              <img
                onClick={this.retryOrderInfo}
                src={reload}
                className="errorrELOAD"
              />
              <span className="loading">
                <Trans i18nKey={"orderInfoError"}> </Trans>
              </span>
            </React.Fragment>
          )}
          {!this.props.loading && !this.props.error && (
            <div className="chatWithDriverFooter">
              <div className="container">
                <input
                  value={this.state.input}
                  onChange={this.inputOnChange}
                  placeholder={this.props.t("TypeHere")}
                  onKeyDown={this.checkCanEnter}
                />
                <Fade in={this.state.input.length > 0}>
                  <Send onClick={this.sendMessage} className="imgsend"  />
                  {/* <img onClick={this.sendMessage} className="imgsend" src={send} /> */}
                </Fade>
              </div>
            </div>
          )}
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.ViewTravel.ID,

  OpenDialoge: state.chatWithDriver.openDialoge,
  driver: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driver
      ? state.ViewTravel.pureData.driver
      : null
    : null,
  messages: state.chatWithDriver.messages,
  predefinedMessages: state.chatWithDriver.predefinedMessages,
  loading: state.chatWithDriver.loading,
  error: state.chatWithDriver.error,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
