import * as actionTypes from "../actions/actionTypes";

const init = {
  socket: null
};
export const Socket = (state = init, action) => {
  switch (action.type) {
    case actionTypes.SETSOCKET :
      return {
          ...state,
          socket :action.state
      };
    default:
      return state;
  }
};

export default Socket;
