import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { RecoverTimeMachine } from "../../../../Redux/actions/actionNames";

class App extends React.Component {
  checkDraftIsInLocalStorage = () => {
    if (localStorage.getItem("draft")) {
      return true;
    } else {
      return false;
    }
  };


  recoverData =()=>{
    let data = JSON.parse(localStorage.getItem("draft"))
    this.props.dispatch(RecoverTimeMachine(data))
    this.deleteData()
  }


  deleteData =()=>{
    localStorage.removeItem("draft");
    this.setState({
      off:true
    })
  }

  render() {
    const { t } = this.props;

    if (this.checkDraftIsInLocalStorage()) {
      return <div className="Draft_Time_Machine">

        <span className="Time_MachineText">  <Trans i18nKey={"Time_MachineText"}> </Trans> </span>
        <span onClick={this.recoverData} className="Time_MachineTextConfirm"><Trans i18nKey={"yes"}> </Trans>  </span> 
        <span onClick={this.deleteData} className="Time_MachineTextConfirmignore"><Trans i18nKey={"ignore"}> </Trans>  </span> 

      </div>;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  mapRef: state.Map.mapRef,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  options: state.NewOrder.newOrder.service.options,
  serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,
  center: state.Map.center,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
