import myWorker from "./CanOrderCalculateTravelCost.worker";

export const CanOrderCalculateTravelCost = (prevData, NextData ,call) => {
  const worker = new myWorker();
  worker.postMessage({
    prevData, NextData
  });

  worker.addEventListener("message", (event) => {
    if (event.data.succeessfull) {
      call(true)
    } else {
      call(false)
    }

    worker.terminate();
  });
};
