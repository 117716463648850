import emrazgoIGO from "../../../static/emrazgoLogo.png";
import emrazgo_banner from "../../../static/emrazgo_banner.png";
import FavIcon from "../../../static/PROJECTS/emrazgo.png";

export default function emrazgo() {
    
    return global.config = {
        "project": "app.emrazgo.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "EMRAZGO Customer Panel",
        "LogoType": emrazgoIGO,
        "BaseApi": "https://rest.emrazgo.com",
        "BaseSocket": "https://socket.emrazgo.com",
        "BaseTrack": "https://panel.emrazgo.com",
        "MapCenter": {
            "lat": 2.944190,
            "lng": 101.698162
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/emrazgo.png",
        "Country": "US",
        "AppleLoginCustomerId": "CWSKAB8XQ4",
        "AppleLoginredirectURI": "https://app.emrazgo.com",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "208573143740-kcg0p71t0hgnj03absv0m0ggqjjf7uea.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": emrazgo_banner,
        "FavIconWithDomain": FavIcon ,
        customFontColor: null,
        customColor:"emrazgo",
        registerEnabled: true,
        Secondarycolor:"#D03227",
        SecondaryDarkColor:"#A61E15",
        SecondaryLightColor:"#EA614F",
        SecondaryUltraLight :"#FCEFEE",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#D03227",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:emrazgoIGO,
        apkUrl:null,
        iosUrl:null
} }