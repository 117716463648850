import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {ReactComponent as Language} from "../../../../../static/icon-ic-language.svg";
import { get_lang, set_lang, set_direction ,set_cssFlag ,set_country} from "../../../../../helper/UserData/userdate";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import IconClose from "../../../../../static/icon-close.svg";
import tik from "../../../../../static/icon-ic-check.svg";

import { getAllLanguages } from "../../../../../helper/module";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#00000066",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  updateSocket = (lang) => {
    this.props.socket.query['language'] = lang ;
    this.props.socket.disconnect().connect()
  };

  handleOpen = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  handleLanguage = (lang)=>{
    this.props.dispatch({
      type: "changeDirection",
      direction: lang.direction,
      language: lang.lang,
    });
    set_lang(lang.lang);
    set_direction(lang.direction);
    set_cssFlag(lang.cssFlag);
    set_country(lang.country);
    this.props.i18n.changeLanguage(lang.lang);
    this.setState({ state: this.state });
    this.setState({ openMenu: !this.state.openMenu });
    this.updateSocket(lang.lang)
  }

  getActiveLang = ()=>{
   if(global.config.AllLanguages.find((ll)=>{
    return ll.lang == get_lang()
   })){
    return global.config.AllLanguages.find((ll)=>{
      return ll.lang == get_lang()
     }).i18Lang
   }else{
    return global.config.AllLanguages[0].i18Lang
   }
  }



  render() {
    return (
      <React.Fragment>
        <span onClick={this.handleOpen} className={`langContainer ${this.props.className}`}>
          <div className="SignOutContainer">
            <Language className="SignOut"  />
            <span className="LogoutText">
              {" "}
              <Trans i18nKey={"Language"}> </Trans>
            </span>
            <span className="CurrLanguage">
              {" "}
              <Trans i18nKey={this.getActiveLang()}> </Trans>
            </span>
          </div>
        </span>

        {this.state.openMenu && (
          <BackdropCustom open={this.state.openMenu}>
            <Paper
              style={{ width: "340px", height: "auto", borderRadius: "8px" ,paddingBottom:"10px" }}
            >
              <ClickAwayListener onClickAway={this.handleOpen}>
                <div style={{ width: "100%", height: "100%" }}>
                  <div className="podheader">
                    <img
                      src={IconClose}
                      className="close"
                      onClick={this.handleOpen}
                    />
                    <span className="textheader"> {this.props.t("Language")} </span>
                  </div>

                    {global.config.AllLanguages.map((item)=>{
                     return (
                       <div
                         className={
                           item.lang == get_lang()
                             ? "itemLanguageActive"
                             : "itemLanguageInActive"
                         }
                         onClick={()=>{this.handleLanguage(item)}}
                       >
                         <span className="languageText">
                           {" "}
                           <Trans i18nKey={item.i18Lang}> </Trans>
                         </span>
                         {item.lang == get_lang() ? (
                           <img className="tik" src={tik} />
                         ) : null}
                       </div>
                     );  
                    })}

                </div>
              </ClickAwayListener>
            </Paper>
          </BackdropCustom>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  socket: state.Socket.socket,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
