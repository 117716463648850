import React from "react";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import { get_LeftRight } from "../../../../helper/UserData/userdate";
import { withHooksHOC } from "../../../../helper/hoc/withHooksHOC";

class App extends React.Component {
  render() {
    console.log("hoc ",this.props)
    if (this.props.isMobile) {
      return (
        <Slide
          timeout={650}
          direction={get_LeftRight()}
          in={this.props.open}
          mountOnEnter
          unmountOnExit
        >
          {this.props.children}
        </Slide>
      );
    }else{
      return <React.Fragment>{this.props.children}</React.Fragment>;
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
    open : state.NewOrder.newOrder.map_Data.showMap 
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withHooksHOC(App));
