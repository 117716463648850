import LogoType from "../../../static/Flech.png";
import Banner from "../../../static/Flech_banner.png";

import FavIcon from "../../../static/PROJECTS/Flech_fav_icon.png";

export default function Flech() {
    
    return  global.config = {
        "project": "Flech",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "myflech",
        "LogoType": LogoType,
        "BaseApi": "https://rest.flech.io",
        "BaseSocket": "https://socket.flech.io",
        "BaseTrack": "https://panel.flech.io",
        "MapCenter": {
            "lat": "5.362952186131165",
            "lng": "-4.009174619711591"
        },
        "Lang": "fr",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        },{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        } ],
        "FavIcon": "",
        "Country": "CI",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "myflech",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Flech",
        registerEnabled: true,
        Secondarycolor: "#000406",
        SecondaryDarkColor: "#1D0333",
        SecondaryLightColor: "#242424",
        SecondaryUltraLight: "#EBEBEB",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#000406",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#000406",
        icon_light_end:"#242424",
        icon_dark_start:"#1DA1F2",
        icon_dark_end:"#32AFFC",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}