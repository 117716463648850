import React, { useState } from "react";
import NoOrder from "./NoOrder";
import OrderHistoryListMaper from "./OrderHistoryListMaper";
import Header from "./header";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_Id, get_LeftRight } from "../../../helper/UserData/userdate";
import Loading from "./loading";
import Error from "./error";
import { Api_Get_History_List_AGW } from "../../../helper/api/ApiGateway";
import { isEmpty } from "lodash";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loadMore = () => {
    Api_Get_History_List_AGW(
      this.props.dispatch,
      {
        page: this.props.page
      },
      (call) => {
      }
    );
  };

  componentDidMount() {
    if (
      isEmpty(this.props.OrderHistoryList) &&
      !this.props.OrderHistoryListLoading &&
      this.props.open
    ) {
      this.loadMore();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.loadMore();
    }
  }

  render() {
    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.open && !this.props.ViewOrderOpenDialoge}
        mountOnEnter
        unmountOnExit
      >
        <div className="OrderHistoryContainer">
          <Header />
          <NoOrder />
          <Error loadMore={this.loadMore}  />
          <Loading />
          {!this.props.OrderHistoryListError &&
          !isEmpty(this.props.OrderHistoryList) ? (
            <OrderHistoryListMaper loadMore={this.loadMore} />
          ) : null}
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.OrderHistory.OrderListOpenDialoge,
  OrderHistoryList: state.OrderHistory.OrderHistoryList,
  OrderHistoryListLoading: state.OrderHistory.OrderHistoryListLoading,
  OrderHistoryListError: state.OrderHistory.OrderHistoryListError,
  page: state.OrderHistory.page,
  ViewOrderOpenDialoge: state.ViewTravel.OpenDialoge,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
