import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../static/close.svg";
import {
  ChangeNewTravelDialoge,
  ResetNewOrder,
  SetopenAddressBookInNewOrder,
} from "../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
//import { ServicesVehicletypeAction } from "../../../../Redux/actions/actions";
import { isMobile } from "react-device-detect";
import momentJalaali from "moment-jalaali";
import { DetectOndemandOrReserve } from "../../../../helper/worker/DetectOndemandOrReserve";
import { Api_Create_Ondemand_Order_AGW, Api_Create_PickupDelivery_Order_AGW } from "../../../../helper/api/ApiGateway";
import { get_customerPannel_type, get_Id } from "../../../../helper/UserData/userdate";
import { v4 as uuidv4 } from 'uuid';
import history from "../../../../service/history";

class App extends React.Component {
  NewTravelHandler = () => {
    this.props.dispatch(ChangeNewTravelDialoge());
    this.props.dispatch(ResetNewOrder());
    this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));

    history.goBack()
    // this.props.dispatch(ResetNewOrder());
    // this.props.dispatch(
    //   ServicesVehicletypeAction(this.props.dispatch, this.props.center)
    // );
  };

  SendOrder = () => {
    if (
      this.props.sendOrder.canSaveOrder &&
      !this.props.sendOrder.loading
    ) {
      DetectOndemandOrReserve(
        {
          pickup: this.props.pickup,
          dropOffs: [this.props.firstDropOff, this.props.otherdropOffs],
        },
        (call) => {
          if (call === "ondeman") {
            Api_Create_Ondemand_Order_AGW(
                this.props.dispatch,
                this.removeEmpty({
                  customerId: get_Id() ,
                  uid:uuidv4(),
                  pickup: {
                    address: this.props.pickup.address,
                    coordinates: [
                      this.props.pickup.adresss_loc.lng,
                      this.props.pickup.adresss_loc.lat,
                    ],
                    buildingBlock: this.props.pickup.block,
                    description: this.props.pickup.noteForDriver,
                    floor: this.props.pickup.floor,
                    fullName: this.props.pickup.senderFullName,
                    phone: this.props.pickup.senderPhoneNumber,
                    placeId: "",
                    room: this.props.pickup.unit,
                  },
                  dropoffs: [
                    {
                      address: this.props.firstDropOff.address,
                      coordinates: [
                        this.props.firstDropOff.adresss_loc.lng,
                        this.props.firstDropOff.adresss_loc.lat,
                      ],
                      buildingBlock: this.props.firstDropOff.block,
                      floor: this.props.firstDropOff.floor,
                      fullName: this.props.firstDropOff.recieverFullName,
                      phone: this.props.firstDropOff.recieverPhoneNumber,
                      placeId: "",
                      room: this.props.firstDropOff.unit,
                      customerDescription: this.props.firstDropOff.noteForDriver,
                    },
                    ...this.props.otherdropOffs.map((drop) => {
                      return {
                        address: drop.address,
                        coordinates: [
                          drop.adresss_loc.lng,
                          drop.adresss_loc.lat,
                        ],
                        buildingBlock: drop.block,
                        floor: drop.floor,
                        fullName: drop.recieverFullName,
                        phone: drop.recieverPhoneNumber,
                        placeId: "",
                        room: drop.unit,
                        customerDescription: drop.noteForDriver,
                      };
                    }),
                  ],
                  options: [
                    ...this.props.driveroptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "" };
                    }),
                  ],
                  service: {
                    id: this.props.serviceactiveVehicleType.id,
                    options: [
                      ...this.props.serviceoptions.map((op) => {
                        return { id: op.id, dataId: op.item ? op.item.id : "" };
                      }),
                    ],
                  },
                  paymentMethod: this.props.paymentMethod,
                  paymentSide: this.props.paymentSide,
                  vehicletypeId: this.props.auto_activeVehicleType &&
                      this.props.auto_activeVehicleType.id,
                }),
                { service :{icon:this.props.serviceactiveVehicleType.icon , title : this.props.serviceactiveVehicleType.title} ,
                vehicletype :{icon:this.props.auto_activeVehicleType.icon , title : this.props.auto_activeVehicleType.title} 
               }
              );
          } else {
            Api_Create_PickupDelivery_Order_AGW(
                this.props.dispatch,
                {
                  customerId: get_Id() ,
                  uid:uuidv4(),
                  pickup: {
                    address: this.props.pickup.address,
                    coordinates: [
                      this.props.pickup.adresss_loc.lng,
                      this.props.pickup.adresss_loc.lat,
                    ],
                    buildingBlock: this.props.pickup.block,
                    description: this.props.pickup.noteForDriver,
                    floor: this.props.pickup.floor,
                    fullName: this.props.pickup.senderFullName,
                    phone: this.props.pickup.senderPhoneNumber,
                    placeId: "",
                    room: this.props.pickup.unit,
                    schedulePickupNow:   this.props.pickup.scheduleDateAfter || this.props.pickup.scheduleDateBefore ? false : true ,
                    scheduleDateAfter: momentJalaali(
                      this.props.pickup.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                          Number(this.props.pickup.scheduleDateAfter + "000")
                        ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      this.props.pickup.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                          Number(this.props.pickup.scheduleDateBefore + "000")
                        ).unix()
                      : 0,
                  },
                  dropoffs: [
                    {
                      address: this.props.firstDropOff.address,
                      coordinates: [
                        this.props.firstDropOff.adresss_loc.lng,
                        this.props.firstDropOff.adresss_loc.lat,
                      ],
                      buildingBlock: this.props.firstDropOff.block,
                      floor: this.props.firstDropOff.floor,
                      fullName: this.props.firstDropOff.recieverFullName,
                      phone: this.props.firstDropOff.recieverPhoneNumber,
                      placeId: "",
                      room: this.props.firstDropOff.unit,
                      customerDescription: this.props.firstDropOff.noteForDriver,
                      scheduleDateAfter: momentJalaali(
                        this.props.firstDropOff.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                            Number(
                              this.props.firstDropOff.scheduleDateAfter + "000"
                            )
                          ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        this.props.firstDropOff.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                            Number(
                              this.props.firstDropOff.scheduleDateBefore + "000"
                            )
                          ).unix()
                        : 0,
                    },
                    ...this.props.otherdropOffs.map((drop) => {
                      return {
                        address: drop.address,
                        coordinates: [
                          drop.adresss_loc.lng,
                          drop.adresss_loc.lat,
                        ],
                        buildingBlock: drop.block,
                        floor: drop.floor,
                        fullName: drop.recieverFullName,
                        phone: drop.recieverPhoneNumber,
                        placeId: "",
                        room: drop.unit,
                        customerDescription: drop.noteForDriver,
                        scheduleDateAfter: momentJalaali(
                          drop.scheduleDateAfter
                        ).isValid()
                          ? momentJalaali(
                              Number(drop.scheduleDateAfter + "000")
                            ).unix()
                          : 0,
                        scheduleDateBefore: momentJalaali(
                          drop.scheduleDateBefore
                        ).isValid()
                          ? momentJalaali(
                              Number(drop.scheduleDateBefore + "000")
                            ).unix()
                          : 0,
                      };
                    }),
                  ],
                  options: [
                    ...this.props.driveroptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "" };
                    }),
                  ],
                  service: {
                    id: this.props.serviceactiveVehicleType.id,
                    options: [
                      ...this.props.serviceoptions.map((op) => {
                        return { id: op.id, dataId: op.item ? op.item.id : "" };
                      }),
                    ],
                  },
                  paymentMethod: this.props.paymentMethod,
                  paymentSide: this.props.paymentSide,
                  vehicletypeId: this.props.auto_activeVehicleType &&
                      this.props.auto_activeVehicleType.id,
                },
               { service :{icon:this.props.serviceactiveVehicleType.icon , title : this.props.serviceactiveVehicleType.title} ,
                 vehicletype :{icon:this.props.auto_activeVehicleType.icon , title : this.props.auto_activeVehicleType.title} 
                }
    
              )
          }
        }
      );
      localStorage.removeItem("draft");

    }
  };
  removeEmpty= (obj)=> {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }


  render() {
    return (
      <nRHeader>
        <img onClick={this.NewTravelHandler} className="close" src={close} />
        <span className="title">
          <Trans i18nKey={"Neworder"}> </Trans>
        </span>

        {/* {isMobile && (
          <sendOrder 
            className={` mrt16px ${
              (!this.props.sendOrder.canSaveOrder ||
                this.props.sendOrder.loading) &&
              "grey"
            }`}
            onClick={this.SendOrder}
          >
            {this.props.sendOrder.loading ? (
              <Trans i18nKey="poperLoading"> </Trans>
            ) : (
              <Trans i18nKey={"SendOrder"}> </Trans>
            )}
          </sendOrder>
        )} */}
      </nRHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  center: state.Map.center,
  price: state.NewOrder.newOrder.price,
  sendOrder: state.NewOrder.newOrder.sendOrder,
  promoCodeValid: state.NewOrder.newOrder.promotionCode.valid,
  promoCode: state.NewOrder.newOrder.promotionCode.code,
  customer: state.NewOrder.newOrder.customer,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  // manual_name: state.NewOrder.newOrder.driver.manual_driver.manual_name,
  // manual_family: state.NewOrder.newOrder.driver.manual_driver.manual_family,
  // manual_id: state.NewOrder.newOrder.driver.manual_driver.manual_id,
  // manual_activeVehicleType:
  //   state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
