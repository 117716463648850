import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { get_customerPannel_type, get_Id, get_lang, get_LeftRight } from "../../../../helper/UserData/userdate";
import close from "../../../../static/close.svg";
import Loading from "./loading";
import { Api_Add_Payment_Method_AGW, Api_Delete_Payment_Method_AGW, Api_Get_Payment_Methods_AGW } from "../../../../helper/api/ApiGateway";
import PaymentIcon from "react-payment-icons-inline";
import {ReactComponent as AddcardIcon} from "../../../../static/ic-add-credit-card.svg";
import cancell from "../../../../static/ic-close.svg";
import error from "../../../../static/ic-info-error.svg";
import info_error from "../../../../static/info_error.svg";

import {ReactComponent as NocardIcon} from "../../../../static/ic-credit-card-menu.svg";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    ElementsConsumer,
    CardElement,
} from "@stripe/react-stripe-js";
import Dialog from "@material-ui/core/Dialog";
import Form from "../../NewOrder/components/changeMethod/stripe/form";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Button } from "@material-ui/core";
import Typography from "../../../../design-system/lib/Typography";




var _ = require("lodash");

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            cardInUseAlert : false ,
            cards: [],
            openDialog: false,
            cardStatus: null,
            ready: false,
            clientSecret: null,
            cardInfo: null,
            openDialogDeleteCard: false
        };
    }

    handleOpenDialoge = (reset) => {
        this.setState({
            openDialog: !this.state.openDialog
        }, () => {
            if (reset) {
                this.getCards()
            }
            if(!this.state.openDialog){
                Api_Add_Payment_Method_AGW((data) => {
                    this.setState({
                        clientSecret: data.clientSecret
                    })
                }, this.props.dispatch)
            }
        })
        this.getCards()
        
    }


    handleOpenDialogeCardInfo = (card) => {
        this.setState({
            openDialogCardInfo: !this.state.openDialogCardInfo,
            cardInfo: card ? card : null
        })
    }

    handleOpenDialogDeleteCard = (card) => {
        this.setState({
            openDialogDeleteCard: !this.state.openDialogDeleteCard
        })
    }

    handleOpenDialogDeleteInUSeCard = (card) => {
        this.setState({
            cardInUseAlert: !this.state.cardInUseAlert,
        })
    }

    setCards = (cards) => {
        this.setState({
            cards,
            loading: false
        })
    }

    getCards = () => {
        Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
    }

    removeCard=()=>{
        let data = this.state.cardInfo ;
        this.setState({
            openDialogCardInfo: !this.state.openDialogCardInfo,
            openDialogDeleteCard: !this.state.openDialogDeleteCard,
            cardInfo: null
        },()=>{
     
            Api_Delete_Payment_Method_AGW(this.props.dispatch , data.id , (call , status , mesage)=>{

                console.log("Api_Delete_Payment_Method_AGW" ,call , status , mesage)
                if(status == 400) {
                    this.setState({
                        cardInUseAlert:true,
                        cardInUseError:mesage
                    })
                }
                this.getCards()
            })
        })
        
    }



    componentDidMount() {
        Api_Add_Payment_Method_AGW((data) => {
            this.setState({
                clientSecret: data.clientSecret
            })
        }, this.props.dispatch)

        if ((global.config.stripePublicApiKey && global.config.stripePublicApiKey.length > 0) && get_Id() && this.state.cards.length == 0) {
            Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
        }

    }


    componentWillReceiveProps(nextProps){
            if(nextProps.OpenDialoge ){
                Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
            }
    }


    getStripePromise(){
        return loadStripe(String(global.config.stripePublicApiKey));
    }


    render() {
        // const stripePromise = loadStripe(String(global.config.stripePublicApiKey));


        return (
            <React.Fragment>


                <Slide
                    direction={get_LeftRight()}
                    in={this.props.OpenDialoge || this.state.Inc_Balance}
                    mountOnEnter
                    unmountOnExit
                >
                    <div className="NewOrder" style={{ backgroundColor: "white" }}>
                        <nRHeader style={{ boxShadow: "none" }}>
                            <img
                                onClick={this.props.onclose}
                                className="close"
                                src={close}
                            />
                            <span className="title">
                                <Trans i18nKey={"paymentmethods"}> </Trans>
                            </span>
                        </nRHeader>

                        {this.state.loading ? <Loading /> : (
                            this.state.cards.length > 0 ? (
                                <div className="OrderListMaperContainer">

                                    <div className="OrderLists OrderHistory pCardsContainer" id="OrderHistoyList">

                                        {this.state.cards.map((card, i) => {
                                            return <cardItem onClick={() => this.handleOpenDialogeCardInfo(card)}>
                                                <PaymentIcon
                                                    icon={card.brand}
                                                    className="ofgtwpi"
                                                />
                                                <span> {`**** ${card.lastNumbers}`} </span>

                                            </cardItem>
                                        })}
                                        <cardItem className="cardItempatchAdd" onClick={this.handleOpenDialoge}>
                             
                                            <AddcardIcon className="ofgtwpi"/>
                                            <span> <Trans i18nKey={"AddCreditCard"}> </Trans>{" "}</span>

                                        </cardItem>

                                    </div>

                                </div>
                            ) :
                                (
                                    <div className="noCardsConrainer">
                                        <NocardIcon className="NoCardsIcon" />
                                        {/* <img className="NoCardsIcon" src={NocardIcon} /> */}
                                        <p className="NoCards"> <Trans i18nKey={"NoCards"}> </Trans> </p>
                                        <p className="Listofyourcardswillbehere"> <Trans i18nKey={"Listofyourcardswillbehere"}> </Trans> </p>
                                        <p className="AddCreditCard" onClick={this.handleOpenDialoge}> <Trans i18nKey={"AddCreditCard"}> </Trans> </p>
                                    </div>
                                )
                        )

                        }



                    </div>
                </Slide>


           { this.state.openDialog &&    <Elements stripe={this.getStripePromise()} options={{ locale: get_lang() }} >

                    <Dialog
                        style={{}}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        open={this.state.openDialog}
                        onClose={this.handleOpenDialoge}
                        disableAutoFocus
                        disableEnforceFocus 
                        disableRestoreFocus 
                    >
                        <div className="EditDriverContainer addCreditMobileContainer">
                            <div className="header">
                                <img
                                    className="close"
                                    src={cancell}
                                    onClick={this.handleOpenDialoge}
                                />
                                <span className="title">
                                    {this.props.t("AddCreditCard")}
                                </span>
                            </div>
                            <Form clientSecret={this.state.clientSecret} onClose={this.handleOpenDialoge} />



                        </div>
                    </Dialog>
                </Elements>
}

                {this.state.cardInfo && <Dialog
                    transitionDuration={0}
                    style={{}}
                    fullWidth={!isDesktop && !isTablet}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    open={this.state.openDialogCardInfo}
                    onClose={this.handleOpenDialogeCardInfo}
                    disableAutoFocus
                    disableEnforceFocus 
                    disableRestoreFocus 
                >
                    <div className="EditDriverContainer addCreditMobileContainer" >
                        <div className="header">
                            <img
                                className="close"
                                src={cancell}
                                onClick={this.handleOpenDialogeCardInfo}
                            />
                            <span className="title">
                                {this.props.t("CardInfo")}
                            </span>
                        </div>
                        <div className="CardInfoGreyContainer">
                            <div className="CardInfoWhiteContainer">
                                <span className="card1x">
                                    <PaymentIcon
                                        icon={this.state.cardInfo.brand}
                                        className="card1ximg"
                                    />
                                    {this.state.cardInfo.brand}  </span>

                                <span className="card2X">{`**** **** **** ${this.state.cardInfo.lastNumbers}`}</span>
                                <div className="card3XContainer">
                                    <div className="card3XItem">
                                        <span className="card3XItem1">
                                            {this.state.cardInfo.expMonth + "/" + this.state.cardInfo.expYear}
                                        </span>

                                        <span className="card3XItem2">
                                            <Trans i18nKey={"ExpireDate"}> </Trans>
                                        </span>
                                    </div>

                                    {/* <div className="card3XItem">
                                                    <span className="card3XItem1">
                                                    {this.state.cardInfo.lastNumbers}
                                                        </span>

                                                        <span className="card3XItem2">
                                                        <Trans i18nKey={"CVC"}> </Trans> 
                                                        </span>
                                                    </div>

                                                    <div className="card3XItem">
                                                    <span className="card3XItem1">
                                                    {this.state.cardInfo.lastNumbers}
                                                        </span>

                                                        <span className="card3XItem2">
                                                        <Trans i18nKey={"PostalCode"}> </Trans> 
                                                        </span>
                                                    </div> */}


                                </div>


                            </div>
                        </div>
                        <div className="footer">
                            <sendOrder
                                onClick={this.handleOpenDialogDeleteCard}
                                className={`creditcardaddBtn Remove`}
                            >
                                <Trans i18nKey={"Remove"}> </Trans>
                            </sendOrder>
                        </div>


                    </div>
                </Dialog>}



                <Dialog
                    transitionDuration={0}
                    fullWidth={!isDesktop && !isTablet}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    open={this.state.cardInUseAlert}
                    onClose={this.handleOpenDialogDeleteInUSeCard}
                    disableAutoFocus
                    disableEnforceFocus 
                    disableRestoreFocus 
                >
                    <div style={{ maxWidth: "288px", padding: "12px 16px 20px 16px" }} id="remodeCardStripe">
                        <img src={error} />
                        <p1><Trans i18nKey={"Cardisinuse"}> </Trans></p1>
                        <p2>{this.state.cardInUseError}</p2>
                        <p className="patch766x">
                            <span1 onClick={this.handleOpenDialogDeleteInUSeCard}><Trans i18nKey={"ok"}> </Trans></span1>
                        </p>
                      
                    </div>
                </Dialog>


                <Dialog
                    transitionDuration={0}
                    fullWidth={!isDesktop && !isTablet}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    open={this.state.openDialogDeleteCard}
                    onClose={this.handleOpenDialogDeleteCard}
                    disableAutoFocus
                    disableEnforceFocus 
                    disableRestoreFocus 
                    className="logoutContainer"
                >
                    
                    <div style={{  width: '296px',  height: 'auto', display: 'flex',flexDirection: 'column',gap: '12px', justifyContent: 'center', alignItems: 'start', padding: '12px 16px 20px'}}>
                        <img src={info_error} style={{width:"32px",hheight:"32px",marginBottom:"4px"}} />
                        <Typography weight="Heading_Small_Bold" text={this.props.t("RemoveCardq")} />
                        <Typography weight="Body_Middle_Regular" text={this.props.t("RemoveCardA")} style={{fontWeight:"200",marginBottom:"6px"}}/>
                        <div style={{ direction: "ltr", height: "auto" ,display:"flex" , flexDirection:"row",justifyContent:"center",alignItems:"start",gap:"16px" }}>
                        <Button
                        onClick={this.handleOpenDialogDeleteCard}
                        className="confirmLogout"
                        style={{
                            boxShadow: "0 0 2px 0 rgba(191, 191, 191, 0.5)",
                            fontFamily: "IRANSans",
                            color: "white",
                            height: "30px",
                            minHeight: "30px",
                            marginLeft: "3%",
                            marginTop: "1px",
                            marginRight: "1%",
                            width: "80px",
                            fontSize: "12px",
                            backgroundColor: "#3583e0",
                            border: "none",
                            borderRadius: "8px",
                            margin:"0"
                        }}
                        variant="contained"
                        >
                        <Trans i18nKey={"No"}> </Trans>
                        </Button>
                        <Button
                        onClick={this.removeCard}
                        variant="contained"
                        className="skipLogout"
                        style={{
                            backgroundColor: 'transparent',
                            boxShadow: 'unset',
                            color:"#D01400",
                            width:"auto"
                        }}
                        >
                        <Trans i18nKey={"YesRemove"}> </Trans>
                        </Button>
                    </div>


                    </div>

                </Dialog>


            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
