import LogoType from "../../../static/Superkiwi.png";
import Banner from "../../../static/Superkiwi_banner.jpg";

import FavIcon from "../../../static/PROJECTS/Superkiwi_fav_icon.png";

export default function Superkiwi() {

    return global.config = {
        "project": "Superkiwi",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Superkiwi Logistics",
        "LogoType": LogoType,
        "BaseApi": "https://rest.sklogistics.do",
        "BaseSocket": "https://socket.sklogistics.do",
        "BaseTrack": "https://panel.sklogistics.do",
        "MapCenter": {
            "lat": -33.448735182836245,
            "lng": -70.65675064128112
        },
        "Lang": "es",
        "Direction": "Ltr",
        "AllLanguages": [
            {
                "lang": "es",
                "direction": "Ltr",
                "i18Lang": "spanish",
                "cssFlag": "Spanishflag",
                "country": "DO"
            },
            {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
                "cssFlag": "englishflag",
                "country": "US"
            }],
        "FavIcon": "",
        "Country": "DO",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor: "Superkiwi",
        registerEnabled: true,
        Secondarycolor: "#FFD700",
        SecondaryDarkColor: "#F6D000",
        SecondaryLightColor: "#FFDF36",
        SecondaryUltraLight: "#FFFBE5",
        SecondaryTextColor: "#11293B",
        Secondaryonlight: "#11293B",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        enabledCustomColor: false,
        mapType: "osm", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
    }
}