import React from "react";
import Osm from "./osm/index";
import Google from "./googleMaps/index";



class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapReady: false
        }
        this.intervalMap = null
    }

    componentDidMount() {
        if (!global.config.applicationMap) {
            this.intervalMap = setInterval(() => {
                if (global.config.applicationMap) {
                    clearInterval(this.intervalMap)
                    this.intervalMap = null;
                    this.setState({
                        mapReady: true
                    })
                }
            }, 200);
        } else {
            this.setState({
                mapReady: true
            })
        }
    }


    render() {
        // return <Osm />

        if (this.state.mapReady) {
            if(global.config.applicationMap.type == "google"){
                return <Google />
            }else{
                return <Osm />
            }
        } else {
            return (null)
        }

    }
}


export default (App);
