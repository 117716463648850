import NOBODIIGO from "../../../static/Swishr_logo.png";
import FavIcon from "../../../static/PROJECTS/Swishr.png";
import Banner from "../../../static/SwishrBanner.png";

export default function Swishr() {
    
    return  global.config = {
        "project": "Swishr",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Dashboard",
        "LogoType": NOBODIIGO,
        "BaseApi": "https://rest.swishrcourier.com",
        "BaseSocket": "https://socket.swishrcourier.com",
        "BaseTrack": "https://admin.swishrcourier.com",
        "MapCenter": {
            "lat": 53.193877365220686,
            "lng": -2.8932032527133087
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Swishr",
        registerEnabled: true,
        Secondarycolor: "#7F44F8",
        SecondaryDarkColor: "#6A39CE",
        SecondaryLightColor: "#9A6BFC",
        SecondaryUltraLight: "#F5F0FE",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#6A39CE",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#D4BFFF",
        icon_light_end:"#E7DCFF",
        icon_dark_start:"#7F44F8",
        icon_dark_end:"#9A6BFC",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#F0E9FF",
        loginBackgroundColorEnd:"#E2D4FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:NOBODIIGO,
        apkUrl:null,
        iosUrl:null
}}