import EaglePostIGO from "../../../static/EaglePostlogotype.png";
import EaglePost_banner from "../../../static/EaglePostdelivery5.png";
import FavIcon from "../../../static/PROJECTS/EaglePostfav_icon.png";

export default function EaglePost() {
    
    return  global.config = {
        "project": "app.EaglePost.com",
        "ShowPowerOnro": false,
        "flag": "arabicflag",
        "ProjectName": "Eagle Post",
        "LogoType": EaglePostIGO,
        "BaseApi": "https://rest.EaglePost.com",
        "BaseSocket": "https://socket.eaglepost.com",
        "BaseTrack": "https://track.EaglePost.com",
        "MapCenter": {
            "lat": 36.18881826183549,
            "lng": 44.009864343939064
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "SA"
        }],
        "FavIcon": "/EaglePostfav_icon.png",
        "Country": "IQ",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": EaglePost_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"EaglePost",
        registerEnabled: true,
        Secondarycolor:"#FFCC00",
        SecondaryDarkColor:"#EEBF00",
        SecondaryLightColor:"#FFCF0D",
        SecondaryUltraLight :"#FFFAE5",
        SecondaryTextColor:"#242424",
        Secondaryonlight:"#0033A8",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFF7D8",
        loginBackgroundColorEnd:"#FFF0B5",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:EaglePostIGO,
        apkUrl:null,
        iosUrl:null
}}