import LogoType from "../../../static/Maydaycourier.png";
import Banner from "../../../static/Maydaycourier_background.png";

import FavIcon from "../../../static/PROJECTS/MaydaycourierFavIcon.png";

export default function Maydaycourier() {
    
    return  global.config = {
        "project": "Maydaycourier",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer panel",
        "LogoType": LogoType,
        "BaseApi": "https://rest.maydaycourier.com",
        "BaseSocket": "https://socket.maydaycourier.com",
        "BaseTrack": "https://admin.maydaycourier.com",
        "MapCenter": {
            "lat": "38.96960512649281",
            "lng": "-77.38817802204392"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "",
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Maydaycourier",
        registerEnabled: true,
        Secondarycolor: "#0551AF",
        SecondaryDarkColor: "#004073",
        SecondaryLightColor: "#0070FB",
        SecondaryUltraLight: "#EBF1F9",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#0551AF",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#4FC9DE",
        icon_light_end:"#5CE0E5",
        icon_dark_start:"#0551AF",
        icon_dark_end:"#0070FB",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#96FAFE",
        loginBackgroundColorEnd:"#4FC9DE",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}