import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import HOC from "./HOC";
import "./index.css";
import { isMobile } from "react-device-detect";
import NewTravelPinsOnMap from "./New_Travel_Pins_On_Map";
import ViewTravelPinsOnMap from "./ViewTravelPinsOnMap";
import AdddressBookPinOnMap from "./AdddressBookPinOnMap";

import New_Travel_OriginDestinationIconGenerator from "./New_Travel_OriginDestinationIconGenerator";
import AdddressBookPinSetLocation from "./AdddressBookPinSetLocation";
import { Api_GetVehicleTypeByService_AGW, Api_map_geocoding_reverse_AGW } from "../../../../helper/api/ApiGateway";
import { Set_latlng } from "../../../../Redux/actions/actionNames";
import TrackDriver from "./trackDriver";
import { getCenterMap } from "../../../../helper/module";
import { isNewOrderOpen } from "../../../ViewOrder/Map/osm";
// import EstimatedRoute_panel from "./estimatedRoute_panel";
// import TravelRoute from "./travelRoute";

const Leaflet = window.L;
var _ = require("lodash");


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 14,
      finishedmoving: false,
    };
    this.lastMove = 0;

    this.handleMove = this.handleMove.bind(this);
  }


  userIsOnPinSelectState = () => {
    if (
      (this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "Pickup") ||
      (this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "DropOff" &&
        !this.props.pickupDropOffId) ||
      (this.props.pickupDropOffType &&
        this.props.pickupDropOffType == "DropOff" &&
        this.props.pickupDropOffId)
    ) {
      return true;
    } else {
      return false;
    }
  };


  throttleHandleChange(e) {
    this.lastMove = Date.now();

    if(this.props.openAddAddressBookInMenu || this.props.openEditAddressBookInMenu){
      Api_map_geocoding_reverse_AGW(this.props.dispatch, {
        latitude: e.target.getCenter().lat,
        longitude: e.target.getCenter().lng,
      });
    }

    if (isNewOrderOpen() && this.userIsOnPinSelectState()) {
      Api_map_geocoding_reverse_AGW(this.props.dispatch, {
        latitude: e.target.getCenter().lat,
        longitude: e.target.getCenter().lng,
      });
    }

    if(!this.boundsGenerator()){
        this.props.dispatch(
      Set_latlng({
        lng: e.target.getCenter().lng,
        lat: e.target.getCenter().lat,
      })
    );
    }
  

    if (this.props.NRpickupAdresss_loc.lng == "" && this.props.NROpenDialoge && this.props.activeVehicleType) {
      Api_GetVehicleTypeByService_AGW(this.props.dispatch, {
        latitude: e.target.getCenter().lat,
        longitude: e.target.getCenter().lng,
        serviceId: this.props.activeVehicleType.id,
      });
    }
    // this.props.dispatch(
    //   ServicesVehicletypeAction(this.props.dispatch, this.props.center)
    // );
  }

  componentDidMount() {
    // this.props.dispatch(
    //   GetLocationPlacenameAction(this.props.dispatch, {
    //     latitude: get_defaultLocation() ?  get_defaultLocation().lat : global.config.MapCenter.lat,
    //     longitude: get_defaultLocation() ?  get_defaultLocation().lng : global.config.MapCenter.lng,
    //   })
    // );
    let center = getCenterMap(this.props.mapRef , this.props.center);

    Api_map_geocoding_reverse_AGW(this.props.dispatch, {
      latitude:this.props.mapRef.current ? center.lat : this.props.center.lat,
      longitude:this.props.mapRef.current ? center.lng : this.props.center.lng,
    });
  }

  //   componentWillReceiveProps(nextProps) {
  //     this.props.mapRef.current.leafletElement._onResize();
  //     if (
  //       nextProps.OpenNRDialoge !== this.props.OpenNRDialoge ||
  //       nextProps.IsShowFilterRow !== this.props.IsShowFilterRow
  //     ) {
  //       try {
  //         document
  //           .getElementsByClassName("leaflet-map-pane")[0]
  //           .classList.add("mapcollapseCss");
  //         setTimeout(() => {
  //           this.props.mapRef.current.leafletElement._onResize();
  //         }, 250);
  //       } catch (error) {}
  //       setTimeout(() => {
  //         try {
  //           document
  //             .getElementsByClassName("leaflet-map-pane")[0]
  //             .classList.remove("mapcollapseCss");
  //         } catch (error) {}
  //       }, 600);
  //     }
  //   }

  handleMove(e) {

    if (e.target.getCenter().lng != this.props.center.lng) {
      this.throttleHandleChange(e);
    }
  }

  // boundsGenerator = () => {
  //   if (
  //     this.props.OpenVRDialoge &&
  //     !_.isEmpty(this.props.VRpureData) &&
  //     this.props.VRpureData.pickup
  //   ) {
  //     let arr = [
  //       [
  //         this.props.VRpureData.pickup.coordinates[1],
  //         this.props.VRpureData.pickup.coordinates[0],
  //       ],
  //     ];
  //     this.props.VRpureData.dropoffs.map((drop, i) => {
  //       arr.push([drop.coordinates[1], drop.coordinates[0]]);
  //     });

  //     return Leaflet.latLngBounds(arr);
  //   } else if (this.props.OpenNRDialoge) {
  //     if (
  //       this.props.NRpickupAdresss_loc.lng !== "" &&
  //       this.props.NRdropOffsAdresss_loc.lng !== ""
  //     ) {
  //       let arr = [
  //         [
  //           this.props.NRpickupAdresss_loc.lat,
  //           this.props.NRpickupAdresss_loc.lng,
  //         ],
  //         [
  //           this.props.NRdropOffsAdresss_loc.lat,
  //           this.props.NRdropOffsAdresss_loc.lng,
  //         ],
  //       ];
  //       this.props.NRotherDropOffs.map((drop) => {
  //         if (drop.adresss_loc.lng !== "") {
  //           arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
  //         }
  //       });

  //       return Leaflet.latLngBounds(arr);
  //     } else {
  //       return null;
  //     }
  //   }
  // };


  boundsGenerator = () => {
  if(["PickupDelivery"].indexOf(this.props.orderType) > -1){
      if (this.props.EROpenERDialoge) {
        if (
          this.props.ERpickupAdresss_loc.lng !== "" &&
          this.props.ERdropOffsAdresss_loc.lng !== ""
        ) {
          let arr = [
            [
              this.props.ERpickupAdresss_loc.lat,
              this.props.ERpickupAdresss_loc.lng,
            ],
            [
              this.props.ERdropOffsAdresss_loc.lat,
              this.props.ERdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.ERotherDropOffs.map((drop) => {
            if (drop.adresss_loc.lng !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });
  
          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      } else if (
        this.props.OpenVRDialoge &&
        !this.props.EROpenERDialoge &&
        !_.isEmpty(this.props.VRpureData) &&
        this.props.VRpureData.pickup
      ) {
        let arr = [
          [
            this.props.VRpureData.pickup.coordinates[1],
            this.props.VRpureData.pickup.coordinates[0],
          ],
        ];
          arr.push([this.props.VRpureData.dropoff.coordinates[1], this.props.VRpureData.dropoff.coordinates[0]]);

  
        return Leaflet.latLngBounds(arr);
      } else if (isNewOrderOpen()) {
        if (
          this.props.NRpickupAdresss_loc.lng !== "" &&
          this.props.NRdropOffsAdresss_loc.lng !== ""
        ) {
          let arr = [
            [
              this.props.NRpickupAdresss_loc.lat,
              this.props.NRpickupAdresss_loc.lng,
            ],
            [
              this.props.NRdropOffsAdresss_loc.lat,
              this.props.NRdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.NRotherDropOffs.map((drop) => {
            if (drop.adresss_loc.lng !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });
  
          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      }
    }else{
      if (this.props.EROpenERDialoge) {
        if (
          this.props.ERpickupAdresss_loc.lng !== "" &&
          this.props.ERdropOffsAdresss_loc.lng !== ""
        ) {
          let arr = [
            [
              this.props.ERpickupAdresss_loc.lat,
              this.props.ERpickupAdresss_loc.lng,
            ],
            [
              this.props.ERdropOffsAdresss_loc.lat,
              this.props.ERdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.ERotherDropOffs.map((drop) => {
            if (drop.adresss_loc.lng !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });
  
          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      } else if (
        this.props.OpenVRDialoge &&
        !this.props.EROpenERDialoge &&
        !_.isEmpty(this.props.VRpureData) &&
        this.props.VRpureData.pickup
      ) {
        let arr = [
          [
            this.props.VRpureData.pickup.coordinates[1],
            this.props.VRpureData.pickup.coordinates[0],
          ],
        ];
        this.props.VRpureData.dropoffs.map((drop, i) => {
          arr.push([drop.coordinates[1], drop.coordinates[0]]);
        });
  
        return Leaflet.latLngBounds(arr);
      } else if (isNewOrderOpen()) {
        if (
          this.props.NRpickupAdresss_loc.lng !== "" &&
          this.props.NRdropOffsAdresss_loc.lng !== ""
        ) {
          let arr = [
            [
              this.props.NRpickupAdresss_loc.lat,
              this.props.NRpickupAdresss_loc.lng,
            ],
            [
              this.props.NRdropOffsAdresss_loc.lat,
              this.props.NRdropOffsAdresss_loc.lng,
            ],
          ];
          this.props.NRotherDropOffs.map((drop) => {
            if (drop.adresss_loc.lng !== "") {
              arr.push([drop.adresss_loc.lat, drop.adresss_loc.lng]);
            }
          });
  
          return Leaflet.latLngBounds(arr);
        } else {
          return null;
        }
      }
    }

  };

  render() {
    const position = [-8.882507, -46.351563];
    const position2 = [-8.852507, -45.351563];
    const bounds = Leaflet.latLngBounds([position, position2]);

    return (
      <HOC>
        <div
          className="MapContainer"
          style={{
            textAlign: "center",
          }}
        >
          <New_Travel_OriginDestinationIconGenerator />
          <AdddressBookPinSetLocation />
          {/* <EstimatedRoute_panel /> */}
          <Map
            style={{ zIndex: "5" }}
            maxZoom={18}
            inertia={true}
            animate={true}
            duration={0.5}
            onmoveend={this.handleMove.bind(this)}
            easeLinearity={0.25}
            zoomControl={false}
            bounds={this.boundsGenerator()}
            boundsOptions={{
              paddingBottomRight: [30, 30],
              paddingTopLeft: [30, 30],
            }}
            length={4}
            zoom={this.state.zoom}
            center={
              this.props.defaultLocation
                ? {
                    lng: this.props.defaultLocation[0],
                    lat: this.props.defaultLocation[1],
                  }
                : this.props.center
            }
            ref={this.props.mapRef}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {/* <Marker position={this.props.center}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}
            <NewTravelPinsOnMap />
            <ViewTravelPinsOnMap />
            <AdddressBookPinOnMap />
            {/* <TravelRoute /> */}
            {this.props.OpenVRDialoge && <TrackDriver />}
          </Map>
        </div>
      </HOC>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  mapRef: state.Map.mapRef,
  OpenNRDialoge: state.NewOrder.OpenDialoge,
  NRactivePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  NRpickupAddress: state.NewOrder.newOrder.pickup.address,
  NRpickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  NRdropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  NRdropOffsAdresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  NRotherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  center: state.Map.center,
  mapRef: state.Map.mapRef,
  defaultLocation: state.SharedData.WebSettings? state.SharedData.WebSettings.defaultLocation :null,
  activeVehicleType:state.NewOrder.newOrder.service.activeVehicleType,
  NROpenDialoge: state.NewOrder.OpenDialoge,



  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  VRpureData: state.ViewTravel.pureData,
  orderType: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
    ? state.ViewTravel.pureData.type
    : null
  : null,

  
  pickupDropOffType: state.NewOrder.newOrder.map_Data.pickupDropOff.type,
  pickupDropOffId: state.NewOrder.newOrder.map_Data.pickupDropOff.id,

  openAddAddressBookInMenu: state.Addressbook.openAddAddressBookInMenu ,
  openEditAddressBookInMenu: state.Addressbook.openEditAddressBookInMenu ,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
