import LogoType from "../../../static/DMO.png";
import Banner from "../../../static/DMO_banner.jpg";

import FavIcon from "../../../static/PROJECTS/DMO_fav_icon.png";

export default function Swiftee() {
    
    return  global.config = {
        "project": "DMO",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Dashboard",
        "LogoType": LogoType,
        "BaseApi": "https://rest.dmoapp.in",
        "BaseSocket": "https://socket.dmoapp.in",
        "BaseTrack": "https://panel.dmoapp.in",
        "MapCenter": {
            "lat": 23.34558865814294,
            "lng": 85.30900950575472
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "IN",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"DMO",
        enabledCustomColor: false,
        registerEnabled: true,
        Secondarycolor:"#F2E201",
        SecondaryDarkColor:"#EFE00F",
        SecondaryLightColor:"#FCEC09",
        SecondaryUltraLight :"#FFFDE1",
        SecondaryTextColor:"#4A4A4A",
        Secondaryonlight:"#4A4A4A",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}