import QuickSendIGO from "../../../static/ASKERI_logotype.png";
import QuickSend_banner from "../../../static/ASKERI_background.jpg";

import FavIcon from "../../../static/PROJECTS/ASKERI.png";

export default function ASKERI() {
    
    return  global.config = {
        "project": "members.askeriglobal.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "ASKERI GLOBAL",
        "LogoType": QuickSendIGO,
        "BaseApi": "https://rest.askeriglobal.com",
        "BaseSocket": "https://socket.askeriglobal.com",
        "BaseTrack": "https://admin.askeriglobal.com",
        "MapCenter": {
            "lat": 51.507357639804184,
            "lng": -0.12757305191165574
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/ASKERI.png",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": QuickSend_banner,
        "FavIconWithDomain": FavIcon ,
        registerEnabled: false,
        Secondarycolor: "#000000",
        SecondaryDarkColor: "#1A1A1A",
        SecondaryLightColor: "#272727",
        SecondaryUltraLight: "#F9F5E6",
        SecondaryTextColor: "#CEAF2E",
        Secondaryonlight: "#000000",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        icon_light_start:"#CEAF2E",
        icon_light_end:"#DFBE33",
        icon_dark_start:"#000000",
        icon_dark_end:"#272727",
        customFontColor:"ASKERI",
        customColor:"ASKERI",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:false,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:QuickSendIGO,
        apkUrl:null,
        iosUrl:null
}}