import couriers24IGO from "../../../static/couriers24_logo.png";
import couriers24_banner from "../../../static/couriers24_banner.png";
import FavIcon from "../../../static/PROJECTS/couriers24.png";

export default function couriers24() {
    
    return  global.config = {
        "project": "24couriers",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "24 Couriers",
        "LogoType": couriers24IGO,
        "BaseApi": "https://rest.24couriers.co.uk",
        "BaseSocket": "https://socket.24couriers.co.uk",
        "BaseTrack": "https://admin.24couriers.co.uk",
        "MapCenter": {
            "lat": 51.508346164191124,
            "lng": -0.12357130681050861
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/couriers24.png",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": couriers24_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"couriers24",
        registerEnabled: true,
        Secondarycolor: "#131313",
        SecondaryDarkColor: "#2A2A2A",
        SecondaryLightColor: "#242424",
        SecondaryUltraLight: "#F5F5F5",
        SecondaryTextColor: "#F5F5F5",
        Secondaryonlight: "#242424",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FFA3A6",
        icon_light_end:"#FFC9CB",
        icon_dark_start:"#FA232A",
        icon_dark_end:"#FF7478",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#131313",
        loginBackgroundColorEnd:"#131313",
        LoginTextColor :"#F5F5F5",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:couriers24IGO,
        apkUrl:null,
        iosUrl:null
} }