import LogoType from "../../../static/Cityspider.png";
import Banner from "../../../static/Cityspider_banner.jpg";

import FavIcon from "../../../static/PROJECTS/Cityspider_fav_icon.png";

export default function Cityspider() {
    
    return  global.config = {
        "project": "Cityspider",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Dashboard",
        "LogoType": LogoType,
        "BaseApi": "https://rest.rybit-cityspider.com",
        "BaseSocket": "https://socket.rybit-cityspider.com",
        "BaseTrack": "https://panel.rybit-cityspider.com",
        "MapCenter": {
            "lat": 51.835847464863505,
            "lng": 5.838369016918429
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "nl",
            "direction": "Ltr",
            "i18Lang": "Dutch",
            "cssFlag": "dutchflag",
            "country": "NL"
        }],
        "FavIcon": "",
        "Country": "NL",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "Customer panel",
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"",
        registerEnabled: true,
        Secondarycolor:"#FFD700",
        SecondaryDarkColor:"#F6D000",
        SecondaryLightColor:"#FFDF36",
        SecondaryUltraLight :"#FFFBE5",
        SecondaryTextColor:"#11293B",
        Secondaryonlight:"#11293B",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}