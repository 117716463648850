import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import styles2 from '../../../../shared/table/style.module.css';

import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../design-system/lib/Modal';
import Typography from '../../../../../design-system/lib/Typography';
import DropDown from '../../../../../design-system/lib/DropDown';
import { ADD_SQUARE, EDIT, Edit, ROUTE_OPTIMIZE, Routes_deactive,  QUESTION,  ic_close_red } from '../../../../../static';
import Button from '../../../../../design-system/lib/Button';
import clsx from 'clsx';
import { Table_ClearSelectedOrders } from '../../../../../Redux/actions/actionNames';
import { snack } from '../../../../../helper/module/SnackbarUtilsConfigurator';
import { BatchUpdateStatus, GetCancellationReasonsList } from '../../../../../helper/api';
import _ from 'lodash';
import { get_Id } from '../../../../../helper/UserData/userdate';
import { isDesktop, isTablet } from 'react-device-detect';

import {ReactComponent as Pen}  from "../../../../../static/IconProvider/pen.svg";
import {ReactComponent as Ic_check_circle}  from "../../../../../static/IconProvider/ic_check_circle.svg";
import {ReactComponent as Status}  from "../../../../../static/IconProvider/status.svg";


// const statusWithSource = [{ status: "PickupFailed", type: "PickupFailure", source: null },
// { status: "NotDelivered", type: "DeliveryFailure", source: null },
// { status: "CustomerCanceled", type: "Cancellation", source: "customer" }

// ]


function TableEditStatusSelectedOrders(props) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routesList, setRoutesList] = useState([]);
  const [openEditStatus, setOpenEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failureReasonsList, setFailureReasonsList] = useState([]);
  const [selectedFailureReason, setSelectedFailureReason] = useState(null);

  const dispatch = useDispatch();


  const handleEditStatus = React.useCallback(async () => {

    if (!openEditStatus) {
      setRoutesList([])
      setSelectedRoute(null)
      setSelectedStatus(null)
      setOpenEditStatus(!openEditStatus)

    } else {
      setOpenEditStatus(!openEditStatus)
    }

    // let Route = new RouteApi()
    // let data = await Route.getRoutesList()
    // setRoutesList(data)

  }, [openEditStatus]);



  function handleAddRote(e, route) {
    // setShowAddRoute(e)

    if (route) {
      setSelectedRoute(route)
    }
  }






  const canSelectFaildReason = React.useCallback(() => {

    let index = selectedStatus == "CustomerCanceled"
    if (index) {
      return true
    } else {

      return false
    }

  }, [selectedStatus]);


  useEffect(() => {

    (async () => {

      let index = selectedStatus == "CustomerCanceled"

      if (index && failureReasonsList?.length == 0) {
        setFailureReasonsList([])
        let dataP = await GetCancellationReasonsList()
        if (dataP) {
          setFailureReasonsList(dataP);
        }
      } else {
        setFailureReasonsList([])
      }


    })();



  }, [selectedStatus])



  const handleSave = React.useCallback(async () => {
    let data = await BatchUpdateStatus({
      "ids": Object.keys(selectedOrders),
      "status": selectedStatus,
      "customerId": get_Id(),
      ...(selectedFailureReason && {failureReasonId : String(selectedFailureReason.id) , failureReasonText:selectedFailureReason.text})

    })

    if (data) {
      // setLoading(false)
      // setOpenEditStatus(false)
      snack.BatchOperationalSnackbar(JSON.stringify({
        "id": data.id,
        "status": data.status,
        "action": data.action,
        "totalRecordsCount": data.totalRecordsCount,
        "successfulRecordsCount": data.successfulRecordsCount,
        "failedRecordsCount": data.failedRecordsCount,
        "lastProcessedRecordIndex": data.lastProcessedRecordIndex,
        "records": data.records,
        "createdAt": data.createdAt,
        "doneAt": data.doneAt,
      }))

    }


    dispatch(Table_ClearSelectedOrders())



  }, [selectedStatus, selectedRoute, selectedOrders , selectedFailureReason]);


  const hasDriverAssignError = React.useCallback(() => {

    if (selectedStatus && selectedStatus.route && selectedRoute) {
      if (selectedStatus.driver && !selectedRoute.driver) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }


  }, [selectedStatus, selectedRoute]);

  const hasRouteAssignError = React.useCallback(() => {


    if (selectedStatus && selectedStatus.route && selectedRoute) {
      if (!selectedStatus.driver && !!selectedRoute.driver) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  }, [selectedStatus, selectedRoute]);

  // useEffect(async () => {
  //   let Route = new RouteApi()
  //   let data = await Route.getRoutesList()
  //   setRoutesList(data)
  // }, [])

  // useEffect(async () => {

  // }, [openEditStatus])




  const canDraft = React.useCallback(() => {
    let status = ['Draft', 'Confirmed', 'CustomerCanceled']
    return _.toArray(selectedOrders).every(obj => status.indexOf(obj.status) > -1);
  }, [selectedOrders]);

  const canConfirmed = React.useCallback(() => {
    let status = ['Draft', 'Confirmed', 'CustomerCanceled']
    return _.toArray(selectedOrders).every(obj => status.indexOf(obj.status) > -1);
  }, [selectedOrders]);


  const canCustomerCanceled = React.useCallback(() => {
    let status = ['Draft', 'Confirmed', 'CustomerCanceled', 'PickupRouted', 'ReadyForPickup']
    return _.toArray(selectedOrders).every(obj => status.indexOf(obj.status) > -1);
  }, [selectedOrders]);



  return (
    <>
      {canDraft() && <Button size='Medium' type='Secondary' text={!isDesktop && !isTablet ? null : 'Draft'} IconComponent={Pen} onClick={() => { setOpenEditStatus(true); setSelectedStatus('Draft') }} />}
      {canConfirmed() && <Button size='Medium' type='Secondary' text={!isDesktop && !isTablet ? null : 'Confirm'} IconComponent={Ic_check_circle} onClick={() => { setOpenEditStatus(true); setSelectedStatus('Confirmed'); }} />}
      {canCustomerCanceled() && <Button size='Medium' type='Secondary' text={!isDesktop && !isTablet ? null : 'Cancel'} icon={ic_close_red} onClick={() => { setOpenEditStatus(true); setSelectedStatus('CustomerCanceled'); }} />}
      {/* <Button size='Medium' type='Secondary' text='Edit Status' icon={Edit} onClick={() => { handleEditStatus() }} /> */}
      {(openEditStatus) && <Modal ConfirmationLoading={loading} ConfirmationDisabled={((!selectedStatus || selectedStatus?.route ? selectedRoute == null : false) || (hasDriverAssignError() || hasRouteAssignError()) || (canSelectFaildReason() ? !selectedFailureReason : false))} size='Large' title='Edit Status' ConfirmationText='Save' onclose={() => { handleEditStatus() }} ConfirmationOnClick={() => { handleSave() }}

        body={<div className={styles.bodyContainer}>
          <div className={styles.firstRow}>
           {_.toArray(selectedOrders).length == 1 && <span className={styles.P_Hub_D}> <Typography weight='Body_Small_Bold' textColor='TextDark' text={_.toArray(selectedOrders)[0].code} /> </span>}
            <GetGroups selectedOrders={selectedOrders} />
          </div>

          <div className={styles.divider} />

          <DropDown placement='bottomStart' IconComponent={Status} size='Medium' data={GetStatusList(selectedOrders)} text={selectedStatus ? selectedStatus : 'Select Status'} onClick={item => {
            setSelectedStatus(item)
            setSelectedFailureReason(null)
            // setFailureReasonsList([])

          }} className={styles.DropDown} checkSelected='full' />

          {(canSelectFaildReason() && failureReasonsList.length > 0) && <DropDown style={{ marginTop: "12px" }} placement='bottomStart' icon={QUESTION} size='Medium' data={failureReasonsList.map((item) => { return { ...item, title: item.text } })} text={selectedFailureReason ? selectedFailureReason.title : 'Select Reason'} onClick={item => {
            setSelectedFailureReason(item)
          }} className={styles.DropDown} checkSelected='full' />}

          {(hasDriverAssignError() || hasRouteAssignError()) && <Typography weight='Body_Middle_Regular' textColor='Red' text={hasDriverAssignError() ? 'You must assign driver to select this route' : 'You must choose an unassigned route or unassign selected route'} className={styles.error} />}
        </div>} />}


      {/* {showAddRoute && <TableAddRoute onClose={handleAddRote} />} */}

    </>

  )
}


export default TableEditStatusSelectedOrders




function ItemsList(data, selectedRoute, setSelectedRoute) {
  return (
    <div className={styles.itemsConatiner}>
      {data.map((route) => {
        return <div onClick={() => { setSelectedRoute(route) }}>

          <img src={Routes_deactive} />
          <Typography weight={selectedRoute?.id == route.id ? "Body_Middle_Bold" : 'Body_Middle_Regular'} textColor='TextNormal' text={route.name} className={styles.itemName} />
          <Typography weight='Body_Small_Regular' textColor='TextLight' text={route.driver ? route.driver?.name : "Unassigned"} className={styles.itemDriverName} />

        </div>
      })}
    </div>
  )
}


function GetGroups({ selectedOrders }) {

  var objectToArray = Object.keys(selectedOrders).map((key) => selectedOrders[key]);

  const result = Object.groupBy(objectToArray, ({ status }) => status);


  return (
    <div className={styles.groupStatus}>

      {Object.keys(result).map((group) => {
        return <div className={clsx(styles.groupStatusdiv, styles2[group])}>
          <Typography weight='Body_Small_Medium' textColor='TextDark' text={group} />
          {(result[group].length > 1 || Object.keys(result).length > 1) && <span className={styles.groupStatusspan}>
            <Typography weight='Body_Tiny_Bold' textColor='TextDark' text={result[group].length} />
          </span>}
        </div>
      })}


    </div>
  )
}


export const GetStatusList = (selectedOrders) => {
  let xx = [];

  let status = ['Draft', 'Confirmed', 'CustomerCanceled']
  if(_.toArray(selectedOrders).every(obj => status.indexOf(obj.status) > -1)){
    xx.push('Draft')
  }

  let status2 = ['Draft', 'Confirmed', 'CustomerCanceled']
  if(_.toArray(selectedOrders).every(obj => status2.indexOf(obj.status) > -1)){
    xx.push('Confirmed')
  }

  let status3 = ['Draft', 'Confirmed', 'CustomerCanceled', 'PickupRouted', 'ReadyForPickup']
  if(_.toArray(selectedOrders).every(obj => status3.indexOf(obj.status) > -1)){
    xx.push('CustomerCanceled')
  }

return xx
}