import LogoType from "../../../static/STRIKEAFRICA_logotype.png";
import Banner from "../../../static/STRIKEAFRICA_banner.png";
import FavIcon from "../../../static/PROJECTS/STRIKEAFRICA.png";

export default function STRIKEAFRICA() {
    
    return  global.config = {
        "project": "STRIKEAFRICA",
        "ShowPowerOnro": false,
        "flag": "frenchflag",
        "ProjectName": "Portail Client",
        "LogoType": LogoType,
        "BaseApi": "https://rest.strikeapp.link",
        "BaseSocket": "https://socket.strikeapp.link",
        "BaseTrack": "https://panel.strikeapp.link",
        "MapCenter": {
            "lat": 5.361650994487029,
            "lng": -4.005867157045573
        },
        "Lang": "fr",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "CI"
        }],
        "FavIcon": "",
        "Country": "CI",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"STRIKEAFRICA",
        enabledCustomColor: true,
        registerEnabled: true,
        Secondarycolor: "#423D98",
        SecondaryDarkColor: "#302C7A",
        SecondaryLightColor: "#4944A5",
        SecondaryUltraLight: "#F0EFF7",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#423D98",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#B9B5FF",
        icon_light_end:"#CFCCFF",
        icon_dark_start:"#423D98",
        icon_dark_end:"#4944A5",
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#EFEEFF",
        loginBackgroundColorEnd:"#E3E1FF",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}
