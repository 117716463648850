import React from "react";

 const PickupPin = ({color ="red" , style , bigSize}) => {

    return   <svg
    xmlns="http://www.w3.org/2000/svg"
    width={bigSize ? "30" : "20"}
    height={bigSize ? "54" : "36"} 
    fill="none"
    viewBox="0 0 21 37"
    style={{...style}}

  >
    <path
    fill={global.config.Secondarycolor}
      d="M9.277 19.318a1 1 0 112 0v13a1 1 0 11-2 0v-13z"
    ></path>
    <circle cx="10.277" cy="10.318" r="10" fill={global.config.Secondarycolor}></circle>
    <circle cx="10.277" cy="10.318" r="3" fill="#fff"></circle>
  </svg>


};

export default PickupPin



