import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import {ReactComponent as Card} from "../../../../static/box_outline.svg";
import { isEmpty } from 'lodash'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (!this.props.ListError && !this.props.ListLoading && this.props.data.length == 0 ) {
      return (
        <div className="NoOrderContainer">
          <div className="NoOrderInner mrb90">
            {/* <img src={box} className="NoOrderbox" /> */}
            <Card  className="NoOrderbox NoTransactionCard" />
            <span className="NoOrderTitle">
              {" "}
              <Trans i18nKey={"NoMessages"}> </Trans>{" "}
            </span>
            <span className="NoOrderDescription">
              {" "}
              <Trans i18nKey={"Yourmessageswillbehere"}>
                {" "}
              </Trans>{" "}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  open: state.MessageBox.openDialoge,
  data: state.MessageBox.data,
  ListLoading: state.MessageBox.ListLoading,
  ListError: state.MessageBox.ListError,
  page: state.MessageBox.page,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
