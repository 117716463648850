import LogoType from "../../../static/Swiftee.png";
import Banner from "../../../static/Swiftee_banner.png";

import FavIcon from "../../../static/PROJECTS/Swiftee_fav_icon.png";

export default function Swiftee() {
    
    return  global.config = {
        "project": "Swiftee",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Dashboard",
        "LogoType": LogoType,
        "BaseApi": "https://rest.swiftee.co.uk",
        "BaseSocket": "https://socket.swiftee.co.uk",
        "BaseTrack": "https://admin.swiftee.co.uk",
        "MapCenter": {
            "lat": 51.507357639804184,
            "lng": -0.12757305191165574
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GB",
        "AppleLoginCustomerId": "uk.co.swiftee",
        "AppleLoginredirectURI": "https://dashboard.swiftee.co.uk",
        "FacebookLoginappId": "1184807042333185",
        "GoogleLogincustomerId": "1043003291558-epcm6499k1bjmpmjaaral0i819r7ed2n.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: "Swiftee",
        customColor:"swiftee",
        registerEnabled: true,
        Secondarycolor: "#FFD700",
        SecondaryDarkColor: "#F6D000",
        SecondaryLightColor: "#FFDF36",
        SecondaryUltraLight: "#FFFBE5",
        SecondaryTextColor: "#11293B",
        Secondaryonlight: "#11293B",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        icon_light_start:"#FFD700",
        icon_light_end:"#FFDF36",
        icon_dark_start:"#11293B",
        icon_dark_end:"#214158",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#fff2c2",
        loginBackgroundColorEnd:"#fff2c2",
        LoginTextColor :"#11293B",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}