import 'moment/locale/ar'
import 'moment/locale/el'
import 'moment/locale/pt'
import 'moment/locale/fa'
import 'moment/locale/fr'
import 'moment/locale/ro'
import 'moment/locale/nl'
import 'moment/locale/es'
import 'moment/locale/az'
import 'moment/locale/de'
import 'moment/locale/ru'
import './helper/module/trusted-security-policies.js';
import React, { useEffect, useState } from "react";
import "./App.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
// import * as Sentry from '@sentry/browser';
// import { Integrations } from "@sentry/tracing";
import { RtlLtrCssSelector } from "./helper/RtlLtrCssSelector";
import packageJson from "../package.json";
import { CheckNewVersion, getDirectionByUrl, getProjectNameByUrl, i18Constructor } from "./helper/module";
import LoginRegister from "./Pages/LoginRegister";
import Main from "./Pages/Main";
import ViewOrder from "./Pages/ViewOrder";
import TableOrders from "./Pages/TableOrders";
import VerificationCode from "./Pages/VerificationCode";
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { setupSocket } from "./socket/Setup_socket";
import { Helmet } from "react-helmet";
import BulkImport from "./Pages/BulkImport";
import { SnackbarProvider } from "notistack3";
import { BatchOperationalSnackbar } from './components/shared/batch-operational-snackbar/index.js';
import { SnackbarUtilsConfigurator } from './helper/module/SnackbarUtilsConfigurator.js';
import history from './service/history/index.js';
import NewOrderPage from './Pages/NewOrder/index.js';
import { Api_Get_Web_Setting_AGW } from './helper/api/ApiGateway.js';
// import LtrLayout from './helper/RtlLtrCssSelector/layout/LtrLayout.js';
// import RtlLayout from './helper/RtlLtrCssSelector/layout/RtlLayout.js';
import loading from "./static/loading.gif";

global.appVersion = packageJson.version;
global.local = packageJson.local;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
} else {
  // production code
  // Sentry.init({
  //   dsn: "https://f2bdc4c7ae3049cb8ba64245b933c3d4@sentry.qcompany.org/15",
  //   enableInExpoDevelopment: true,
  //   debug: true, // If `true`, Sentry will try to print out useful debugging
  //   integrations: [new Integrations.BrowserTracing()],
  //   tracesSampleRate: 1.0,
  // });
}

function App(props) {
  const [i18Init, seti18Init] = React.useState(false);
  const [globalLoaded, setGlobalLoaded] = React.useState(false);

  const orderTypes = useSelector((state) => state.SharedData.WebSettings?.orderTypes)
  const dispatch = useDispatch()

  CheckNewVersion();
  !i18Init && i18Constructor(props.dispatch, props.i18n, props.language, seti18Init);

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  useEffect(() => {
    if (global?.config?.Direction) {
      setGlobalLoaded(true)
    }else{
      const interval = setInterval(() => {
        if (global?.config?.Direction) {
          setGlobalLoaded(true)
          clearInterval(interval);
        }
      }, 500);
      return () => clearInterval(interval);
    }

  }, []);

  useEffect(() => {
    if (props.authenticated) {
      Api_Get_Web_Setting_AGW(dispatch, null, null)
    }

  }, [props.authenticated]);


  useEffect(() => {
    if (orderTypes && orderTypes.length == 1 && orderTypes.findIndex(element => element == "Ondemand") == -1 && props.authenticated) {
      history.push("/table")
    }
  }, [orderTypes, props.authenticated]);


  if (!props.authenticated && globalLoaded) {
    return <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      Components={{
        BatchOperationalSnackbar: BatchOperationalSnackbar
      }}
    >
      <SnackbarUtilsConfigurator />
      {/* {(props.direction || global.config.Direction) == "Ltr" ? <LtrLayout /> : <RtlLayout /> } */}
      <RtlLtrCssSelector Direcrion={props.direction || global.config.Direction}>
        <Helmet>
          <title> {global.config.ProjectName}</title>
        </Helmet><LoginRegister />      
        </RtlLtrCssSelector>

    </SnackbarProvider>
  }else if ((!orderTypes && props.authenticated) || !globalLoaded ) {
    return <div style={{ backgroundColor: "white", width: '100vw', height: "100vh", position: "absolute", left: "0", top: "0", zIndex: "500" }}>
      <img style={{ width: "110px", marginLeft: "calc(50% - 55px)", float: "left", marginTop: "calc(50vh - 100px)" }} src={loading} />
    </div>
  }else{
    return (
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        Components={{
          BatchOperationalSnackbar: BatchOperationalSnackbar
        }}
      >
        <SnackbarUtilsConfigurator />
        {/* {(props.direction || global.config.Direction) == "Ltr" ? <LtrLayout /> : <RtlLayout /> } */}
        <RtlLtrCssSelector Direcrion={props.direction || global.config.Direction}>
          <Helmet>
            <title> {global.config.ProjectName}</title>
          </Helmet>
          <Router history={history}>
            <Switch>
              <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/neworder"
                dispatch={props.dispatch}
                name="neworder"
              >
                <NewOrderPage />
                {/* <TableOrders  isTable={false}/> */}

              </PrivateRoute>
              <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/order/:id"
                dispatch={props.dispatch}
                name="orderDetails"
              >
                <ViewOrder />
                {/* <TableOrders  isTable={false}/> */}

              </PrivateRoute>
              {orderTypes.findIndex(element => element == "PickupDelivery") > -1 && <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/table"
                dispatch={props.dispatch}
                name="table"
              >
                <TableOrders isTable={true} />
              </PrivateRoute>}
              {orderTypes.findIndex(element => element == "Ondemand") > -1 && <PrivateRoute
                authenticated={props.authenticated}
                VerificationCode_step={props.VerificationCode_step}
                path="/"
                dispatch={props.dispatch}
                name="Home"
              >
                <Main />
                {/* <TableOrders  isTable={false}/> */}
  
              </PrivateRoute>}
  
  
  
            </Switch>
          </Router>
        </RtlLtrCssSelector>
  
      </SnackbarProvider>
  
    );
  }




}

const PrivateRoute = ({
  authenticated,
  VerificationCode_step,
  dispatch,
  component: Component,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      exact
      render={(props) => {
        if (authenticated) {
          return children;
        } else {
          return /* VerificationCode_step ? <VerificationCode/> : */ <LoginRegister />;
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  authenticated: state.SharedData.access_token,
  VerificationCode_step: state.LoginRegister.VerificationCode.VerificationCode_step,
  direction: state.SharedData.direction,
  language: state.SharedData.language
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
