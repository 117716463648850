import "./index.css";

import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { DropDownProps } from "./DropDown.types";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import { ARROW_DOWN_BOLD } from "../../../static/index";
import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography";


const DropDown: React.FC<DropDownProps> = ({IconComponent , noArrowIcon = false , ItemsList, checkSelected = "hybrid", titleOnClick, clearOnClick, clearText, data, placement, text, badgeText, title, style, className, ref, onClick, size, icon, isSelected, isRequired, requiredText }: DropDownProps) => {
    const [isOpen, setIsOpen] = useState(false);



    const dropdownNode: any = useClickOutside(() => {
        setIsOpen(false);
    })


    return (
        <div ref={dropdownNode} onClick={() => { setIsOpen(!isOpen); titleOnClick && titleOnClick() }} className={clsx(styles[`${size}`], isSelected && styles[`${size}selected`], className && className)}
            style={style ? style : {}} >

            {(icon) && <IconProvider Icon={icon} size="16" />}
            {(IconComponent) && <IconComponent />}
            {(text) && <span className={styles.title}> {text}
                {(isRequired && !isSelected) && <span className={styles.isRequired}> {requiredText} </span>}
                {(badgeText) && <span className={styles.isRequired}> {badgeText} </span>}

            </span>}


            {(data && data.length > 0 && !noArrowIcon) && <IconProvider Icon={ARROW_DOWN_BOLD} size="12" style={{ width: "10px", height: "10px" }} />}

            <CSSTransition
                in={isOpen && (data && data.length > 0)}
                timeout={300}
                classNames="dropdown-animation"
                unmountOnExit
                onEnter={() => setIsOpen(true)}
                onExited={() => setIsOpen(false)}
            >
                <div className={clsx(styles.dropdownBox, styles[placement])}>



                   {ItemsList ? ItemsList :  <div className={styles.itemsConatiner}>
                        {data.map((serv: any) => {
                            // return <div onClick={() => { onClick && onClick(serv) }} className={clsx((serv.title ? serv.title : serv) == text && styles.selectedDropItem)}>
                            return <div onClick={() => { onClick && onClick(serv) }} className={clsx((checkSelected == "hybrid" ? text?.includes((serv.title ? serv.title : serv)) : text == (serv.title ? serv.title : serv)) && styles.selectedDropItem)}>

                                {serv.icon && <img src={serv.icon} />}
                                {serv.title ? serv.title : serv}

                            </div>
                        })}
                    </div>}



                    {(clearText && isSelected) && <div style={{ borderTop: "1px solid #F4F4F7", padding: "16px 0 8px", width: "100%" }} onClick={() => { clearOnClick && clearOnClick() }} >
                        <Typography weight="Body_Middle_Medium" text={clearText} />
                    </div>}

                </div>
            </CSSTransition>
        </div>

    );
};

export default DropDown;



const useClickOutside = (handler: any) => {
    const domNode = useRef<any>();
    useEffect(() => {
        const eventHandler = (event: any) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}