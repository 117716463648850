import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../static/arrow-triangle-down.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItem from "./serviceOptions/list";
import InputItem from "./serviceOptions/input";
import SelectableItem from "./serviceOptions/selectable";

import { withTranslation, Trans } from "react-i18next";
import {
  Warning_NOi18,
  UnSucceessFul,
} from "../../../../helper/Notification/sendNotification";
import {
  New_Travel_PaymentMethod,
  Set_Service_ActiveVehicleType,
  New_Order_Reset_DropOffs,
  Set_Driver_auto_VehicleTypes,
  Set_Auto_ActiveVehicleType,
} from "../../../../Redux/actions/actionNames";
import { GetVehicletypeByServiceAction } from "../../../../Redux/actions/actions";
import { getBaseTrackByUrl, getCenterMap } from "../../../../helper/module";
import { get_lang } from "../../../../helper/UserData/userdate";
import { Api_GetVehicleTypeByService_AGW } from "../../../../helper/api/ApiGateway";
import { DetectOndemandOrPickupDelivery } from "../../../../helper/worker/DetectOndemandOrReserve copy";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      activeVehicleTypes: [
        {
          type: "list",
          title: "weight",
          id: "weight",
          data: [
            {
              name: "under 1 kg",
              id: "12qceqd",
            },
            {
              name: "under 2 kg",
              id: "12rcesd",
            },
            {
              name: "under 3 kg",
              id: "dwdqced",
            },
            {
              name: "under 4 kg",
              id: "1dwdced",
            },
          ],
        },
        {
          type: "boolean",
          title: "need packed",
          id: "need packed",
        },
      ],
    };
    this.anchorEl = React.createRef();
  }

  handleshowpoper = () => {
    if (this.props.serviceVehicleTypes.length > 0) {
      this.setState({
        showpoper: !this.state.showpoper,
      });
    } else {
      Warning_NOi18(
        <Trans i18nKey={"poperLoading"}> </Trans>,
        "networkError",
        <Trans i18nKey={"close"}> </Trans>,
        () => {
          this.props.dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    }
  };

  handleServiceService = (service) => {
    // this.props.dispatch(
    //   New_Travel_PaymentMethod({
    //     paymentSide: "Sender",
    //     paymentMethod: "Wallet",
    //   })
    // );

    this.props.dispatch(Set_Driver_auto_VehicleTypes([]));
    this.props.dispatch(Set_Auto_ActiveVehicleType(null));

    this.props.dispatch(Set_Service_ActiveVehicleType(service));
    this.checkSelectedPaymnetMethod(service)

    let center = getCenterMap(this.props.mapRef , this.props.center) ;
    
    Api_GetVehicleTypeByService_AGW(this.props.dispatch, {
      latitude:this.props.mapRef.current ? center.lat : this.props.center.lat,
      longitude:this.props.mapRef.current ? center.lng : this.props.center.lng,
      serviceId: service.id,
    });


    DetectOndemandOrPickupDelivery(
      service.orderTypes ,

      (callx) => {
        if (callx !== "ondeman") {
          this.props.dispatch(New_Order_Reset_DropOffs())
        }
      })


  };


  checkSelectedPaymnetMethod(service) {
    if(this.props.paymentSide + this.props.paymentMethod == "SenderCash"){
      if(!service.setting.isCashBySenderActive){
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      }
    }else if(this.props.paymentSide + this.props.paymentMethod == "ReceiverCash") {
     if(!service.setting.enableReceiverCash){
      this.props.dispatch(
        New_Travel_PaymentMethod({
          paymentSide: "Sender",
          paymentMethod: "Wallet",
        })
      );
      }
    }

  }


  render() {
    const { t } = this.props;
    return (
      <passerngerContainer
        style={{ boxShadow: "rgba(128, 128, 128, 0.16) 0px 1px 6px 0px" }}
        className="serviceCustomized"
      >
        <passerngertitle>
          <Trans i18nKey={"Service"}> </Trans>
        </passerngertitle>

        <serviceselector ref={this.anchorEl} onClick={this.handleshowpoper}>
          {this.props.activeVehicleType &&
          this.props.serviceVehicleTypes.length > 0 ? (
            <React.Fragment>
              <img
                src={ this.props.activeVehicleType.icon}
                className="serviceimage"
              />
              <servicename>
                {this.props.activeVehicleType.title}
              </servicename>
            </React.Fragment>
          ) : (
            <servicename style={{ marginLeft: "19px" }}>
              {" "}
              <Trans i18nKey={"poperLoading"}> </Trans>
            </servicename>
          )}
          <img
            src={arrow}
            className={` servicearrow ${this.state.showpoper && "rotate180"}`}
          />
        </serviceselector>

        <serviceOptions
          className={
            !this.props.activeVehicleType ||
            (this.props.activeVehicleType &&
              this.props.activeVehicleType.options.length == 0)
              ? "displayNone"
              : " NewOrderServiceOptions"
          }
        >
          {this.props.activeVehicleType &&
            this.props.activeVehicleType.options.map((item) => {
              return item.type === "list" ? (
                <ListItem {...item} />
              ) : ( item.type === "input" ? <InputItem  {...item} /> :
                <SelectableItem {...item} />
              );
            })}
        </serviceOptions>

        <BackdropCustom
          open={
            this.state.showpoper && this.props.serviceVehicleTypes.length > 0
          }
          onClick={this.handleshowpoper}
        >
          <Popper
            style={{
              width: "475px",
              zIndex: "7",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom"}
            id={"passenger"}
            open={
              this.state.showpoper && this.props.serviceVehicleTypes.length > 0
            }
            anchorEl={this.anchorEl.current}
            className="servicepaperdialoge"
          >
            <Paper
              style={{
                width: "476px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {this.props.serviceVehicleTypes.map((item) => {
                return (
                  <serviceselectorInList
                    onClick={() => this.handleServiceService(item)}
                  >
                    <img
                      src={ item.icon}
                      className="serviceimage"
                    />
                    <servicename>{item.title}</servicename>
                  </serviceselectorInList>
                );
              })}
            </Paper>
          </Popper>
        </BackdropCustom>
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  mapRef: state.Map.mapRef,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  options: state.NewOrder.newOrder.service.options,
  serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,
  center: state.Map.center,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
