import React from 'react';
import { useDispatch } from 'react-redux';
import {ViewHandler} from './viewHandler';

export const Invoive = (props) => {
    const dispatch = useDispatch()
    return (
        <ViewHandler open={props.open} handleOpen={props.handleOpen} dispatch={dispatch}/>
    );
};



