import onroIGO from "../../../static/logotype-on-light-client.svg";
import lightLogoType from "../../../static/onro-logotype.svg";
import darkLogoType from "../../../static/onro-logotype.svg";

import Defaultbanner from "../../../static/cp-resource@3x.png";

import FavIcon from "../../../static/PROJECTS/onro.png";

export default function onroORG() {

    return global.config = {
        "project": "app.onro.org",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Onro",
        "LogoType": darkLogoType,
        "BaseApi": "https://rest.onro.org",
        "BaseSocket": "https://socket.onro.org",
        "BaseTrack": "https://panel.onro.org",
        "MapCenter": {
            "lat": 27.709345,
            "lng": 85.327736
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [
            {
                "lang": 'de-AT',
                "direction": "Ltr",
                "i18Lang": "AustrianGerman",
                "cssFlag": "AustrianGermanflag",
                "country": "AT"
            },
            {
                "lang": "ar",
                "direction": "Rtl",
                "i18Lang": "Arabic",
                "cssFlag": "arabicflag",
                "country": "SA"
            },{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "el",
            "direction": "Ltr",
            "i18Lang": "Greek",
            "cssFlag": "Greekflag",
            "country": "GR"
        }, {
            "lang": "fa",
            "direction": "Rtl",
            "i18Lang": "Persian",
            "cssFlag": "persianflag",
            "country": "IR"
        }, {
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        },{
            "lang": "pt",
            "direction": "Ltr",
            "i18Lang": "Portuguese",
            "cssFlag": "Portugueseflag",
            "country": "PT"
        }  ,{
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "DO"
        },
        {
            "lang": "zh",
            "direction": "Ltr",
            "i18Lang": "ChineseSimplified",
            "cssFlag": "ChineseSimplifiedflag",
            "country": "US"
        }],
        "FavIcon": "/onro.png",
        "Country": "US",
        "AppleLoginCustomerId": "6N993LX47C",
        "AppleLoginredirectURI": "https://app.onro.io",
        "FacebookLoginappId": "666630160807861",
        "GoogleLogincustomerId": "285605443646-agaiiq26gutukqcbpftaua72es7lu2rr.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor: "onroORG",
        registerEnabled: true,
        Secondarycolor: "#1080FF",
        SecondaryDarkColor: "#0055cb",
        SecondaryLightColor: "#25B7FF",
        SecondaryUltraLight: "#D9EAFF",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#1080FF",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        enabledCustomColor: true,
        mapType: "google", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText: true,
        loginBackgroundColorStart:"#1080FF",
        loginBackgroundColorEnd:"#58A7FF",
        LoginTextColor :"#FFFFFF",
        showLightLogoType: true,
        lightLogoType: lightLogoType,
        darkLogoType:darkLogoType,
        apkUrl: null,
        iosUrl: null
    }

}