import GripxIGO from "../../../static/Gripx.png";
import FavIcon from "../../../static/PROJECTS/FavIcon_Gripx.png";
import Banner from "../../../static/Gripx_banner.png";

export default function Gripx() {
    
    return  global.config = {
        "project": "GRIPX",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Portal",
        "LogoType": GripxIGO,
        "BaseApi": "https://rest.gripx.ph",
        "BaseSocket": "https://socket.gripx.ph",
        "BaseTrack": "https://backoffice.gripx.ph",
        "MapCenter": {
            "lat": 14.599484846179033,
            "lng": 120.98419354674783
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "PH",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"Gripx",
        registerEnabled: true,
        Secondarycolor: "#FE5900",
        SecondaryDarkColor: "#CB5914",
        SecondaryLightColor: "#F8A16D",
        SecondaryUltraLight: "#FFE6D7",
        SecondaryTextColor: "#292728",
        Secondaryonlight: "#F37021",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FDB388",
        icon_light_end:"#FFD0B5",
        icon_dark_start:"#FE5900",
        icon_dark_end:"#F8A16D",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#f97126",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:GripxIGO,
        apkUrl:null,
        iosUrl:null
}}