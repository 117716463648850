import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import moment from 'jalali-moment'
import { get_lang } from "../../../../helper/UserData/userdate";

var _ = require("lodash");

class App extends React.Component {
  constructor(props){
    super(props);

    this.urlify=this.urlify.bind(this)
  }

   urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    })

  }


  render() {
    let showSeen = this.props.last && this.props.source != "driver" && this.props.isSeen
    return (
      <div className="Message">
        <div className={`bubble ${this.props.source == "driver" && "bubbleDriver"}`}>
          <div className="text" dangerouslySetInnerHTML={{ __html: this.urlify(this.props.text) }}></div>
          <p className="time"> {moment(Number(this.props.createdAt)).locale(get_lang()).format("HH:mm")} </p>
          {showSeen &&
            <p className="seen">  <Trans i18nKey={"seen"}> </Trans> </p>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));


