import hellocourierIGO from "../../../static/hellocourier_logo.png";
import hellocourier_banner from "../../../static/hellocourier_banner.png";
import FavIcon from "../../../static/PROJECTS/hellocourier.png";

export default function hellocourier() {
    
    return  global.config = {
        "project": "order.hellocourier.co.ke",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": hellocourierIGO,
        "BaseApi": "https://rest.hellocourier.co.ke",
        "BaseSocket": "https://socket.hellocourier.co.ke",
        "BaseTrack": "https://panel.hellocourier.co.ke",
        "MapCenter": {
            "lat": -1.2864,
            "lng": 36.8172
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/hellocourier.png",
        "Country": "KE",
        "AppleLoginCustomerId": "GB7R35FBDP",
        "AppleLoginredirectURI": "https://app.hellocourier.co.ke",
        "FacebookLoginappId": "291484626052759",
        "GoogleLogincustomerId": "611337293224-mk5js5i58r7bsibjqr4ri7m3ban92u6l.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": hellocourier_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"hellocourier",
        registerEnabled: true,
        Secondarycolor:"#88A31E",
        SecondaryDarkColor:"#617807",
        SecondaryLightColor:"#BDD040",
        SecondaryUltraLight :"#F6F8ED",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#88A31E",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:hellocourierIGO,
        apkUrl:null,
        iosUrl:null
} }