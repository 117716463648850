import FAMIGO from "../../../static/FAMlogotype.png";
import Defaultbanner from "../../../static/FAMbanner.png";
import FavIcon from "../../../static/PROJECTS/FAM.png";


export default function FAM() {

    return  global.config = {
        "project": "F.A.M. Valet",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Customer Web App",
        "LogoType": FAMIGO,
        "BaseApi": "https://rest.famvalet.com",
        "BaseSocket": "https://socket.famvalet.com",
        "BaseTrack": "https://admin.famvalet.com",
        "MapCenter": {
            "lat": 34.08419623458191,
            "lng": -118.24144442934359
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/FAM.png",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"FAM",
        registerEnabled: true,
        Secondarycolor: "#272261",
        SecondaryDarkColor: "#1D185E",
        SecondaryLightColor: "#443F7E",
        SecondaryUltraLight: "#EEEDF2",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#272261",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#272261",
        icon_light_end:"#464085",
        icon_dark_start:"#A3A5A8",
        icon_dark_end:"#BBBCBD",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#CBD8F0",
        loginBackgroundColorEnd:"#D1DEFF",
        LoginTextColor :"#272261",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:FAMIGO,
        apkUrl:null,
        iosUrl:null
}}