import React, { useEffect, useState } from "react";
import Header from "../Main/Header";
import Table from "../../components/shared/table";
import styles from './main.module.css';

import { connect, useDispatch, useSelector } from "react-redux";
import {
  Api_Get_Aboutus_AGW,
  Api_Get_Addressbook_list_AGW,
  Api_get_balance_AGW,
  Api_get_Order_Info_AGW,
  Api_Get_Services_AGW,
  Api_Get_Support_Message_AGW,
  Api_Get_Web_Setting_AGW,
} from "../../helper/api/ApiGateway";
import { setupSocket } from "../../socket/Setup_socket";
import { faviconUpdate } from "../../helper/module";
import loading from "../../../src/static/loading.gif";
import BulkImport from "../BulkImport";
import Menu from "../Main/Menu";
import { initTable } from "../../Redux/reducers/table";
import { ChangeViewTravelDialoge, Set_Table_ActiveOrderType, Table_Init } from "../../Redux/actions/actionNames";
import _ from 'lodash';
import TableList from "../../components/shared/table";
import { getTableDefaultColumn } from "../../components/shared/table/action/getTableDefaultColumn";
import AboutUs from "../Main/AboutUs";
import OrderHistory from "../Main/OrderHistory";
import TableFilter from "../../components/table/filter";
import TransactionsList from "../Main/TransactionsList";
import MessagesBoxlist from "../Main/MessagesBoxlist";
import Settings from "../Main/Settings";
import Support from "../Main/Support";
import TableNoOrder from "../../components/table/table-noOrder";
import TableError from "../../components/table/table-error";
import TableNoOrderFilter from "../../components/table/table-noOrder-filter";
import TableSelectedOrders from "../../components/table/selectedOrders";
import NewOrder from "../Main/NewOrder";
import OrderList from "../Main/OrderList";
import Map from "../Main/Map";
import EditStatusSummary from "../../components/table/EditStatusSummary";
import ViewOrder from "../Main/ViewOrder";


// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       loaded: false ,
//       windowLoaded : false
//     };
//     this.handleLoad = this.handleLoad.bind(this)

//   }

//   handleLoad() {
//     this.setState({
//       windowLoaded: true
//     })
//   }

//   componentDidMount() {
//     document.addEventListener('applicationMap', (detail)=>{
//       // console.log("applicationMap",detail.detail.applicationMap )
//       this.setState({
//         loaded: true
//       })
//     } )
//     // window.addEventListener("load", this.handleLoad);

//     faviconUpdate()
//     Api_Get_Web_Setting_AGW(dispatch, null, null)
//     Api_Get_Aboutus_AGW(dispatch, {}, (call) => { });
//     Api_get_balance_AGW(dispatch, {}, (call) => { });
//     Api_Get_Addressbook_list_AGW(dispatch, { page: 1, perpage: 10, searchRegex: "" }, false);
//     setupSocket(dispatch);
//     Api_Get_Support_Message_AGW(dispatch)

//     document.addEventListener('visibilitychange', function (ev) {
//     });


//     window.onfocus = () => {
//       Api_get_balance_AGW(dispatch, {}, (call) => { });
//     };

//   }
//   render() {
//     return (
//       <div className="MainContainer">
//         {/* <DevelopmentCenterControlPanel /> */}

//         {(!this.state.loaded ) &&

//           <div style={{ backgroundColor: "white", width: '100vw', height: "100vh", position: "absolute", left: "0", top: "0", zIndex: "500" }}>
//             <img style={{ width: "110px", marginLeft: "calc(50% - 55px)", float: "left", marginTop: "calc(50vh - 100px)" }} src={loading} />
//           </div>
//         }
//         {this.state.loaded  &&

//           <React.Fragment>
//             {/* <Table /> */}
//             <Menu />
//             <Header />
//             {/* <AboutUs /> */}
//             {/* <OrderHistory /> */}
//             {/* <OrderList /> */}
//             {/* <TransactionsList /> */}
//             {/* <MessagesBoxlist /> */}
//             {/* <Settings /> */}
//             {/* <Support /> */}
//             {/* <NewOrder /> */}
//             {/* <ViewOrder /> */}
//             {/* <Map /> */}
//           </React.Fragment>
//         }

//         {this.props.IsOpen && <BulkImport />}

//       </div>
//     );
//   }
// }

// const mapStateToProps = (state) => ({
//   IsOpen : state.BulkImport.IsOpen
// });
// const mapDispatchToProps = (dispatch) => ({
//   dispatch: dispatch,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(App);


function NewOrderPage(props) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const TableisLoading = useSelector((state) => state.Table.isLoading)
  const TablehasError = useSelector((state) => state.Table.hasError)
  const TableListData = useSelector((state) => state.Table.list.orders)
  const IsOpen = useSelector((state) => state.BulkImport.IsOpen)
  const type = useSelector((state) => state.Table.filters.type)
  const selectedBulkEditStatusSummary = useSelector((state) => state.Table.selectedBulkEditStatusSummary)

  const hasNextPage = useSelector((state) => state.Table.list.hasNextPage)
  const hasPreviousPage = useSelector((state) => state.Table.list.hasPreviousPage)
  const totalCount = useSelector((state) => state.Table.list.totalCount)
  const perPage = useSelector((state) => state.Table.perPage)
  const page = useSelector((state) => state.Table.page)
  const table = useSelector((state) => state.Table.tableInit)
  const tableFilters = useSelector((state) => state.Table.filters)
  const OpenDialogeViewTravel = useSelector((state) => state.ViewTravel.OpenDialoge)
  const WebSettings = useSelector((state) => state.SharedData.WebSettings)

  const [loaded, setLoaded] = useState(false);
  const [isViewOrder, setIsViewOrder] = useState(true);
  const mainLoaded = useSelector((state) => state.SharedData.mainLoaded)
  const dispatch = useDispatch();
  const [tableLayoutUsedHeight, setTableLayoutUsedHeight] = useState(0)

  const socket = useSelector((state) => state.Socket.socket)

  useEffect(() => {
    if (!socket) {
      faviconUpdate()
      Api_Get_Web_Setting_AGW(dispatch, null, null)
      Api_Get_Aboutus_AGW(dispatch, {}, (call) => { });
      Api_get_balance_AGW(dispatch, {}, (call) => { });
      Api_Get_Addressbook_list_AGW(dispatch, { page: 1, perpage: 10, searchRegex: "" }, false);
      setupSocket(dispatch);
      Api_Get_Support_Message_AGW(dispatch)


      document.addEventListener('applicationMap', (detail) => {
        setLoaded(true)
      })
      return () => {
        document.removeEventListener('applicationMap', (detail) => {
          setLoaded(true)
        })
      };
    }else{
      setLoaded(true)
    }

  }, [WebSettings]);




  if (loaded || WebSettings) {

    return <div
      className={styles.App}
    >
      {/* {IsOpen && <BulkImport />} */}
      {/* {selectedBulkEditStatusSummary && <EditStatusSummary />} */}

      <div className={styles.innerApp}>
        <NewOrder />
        <Map />
      </div>


    </div>
  } else {
    return <div style={{ backgroundColor: "white", width: '100vw', height: "100vh", position: "absolute", left: "0", top: "0", zIndex: "500" }}>
      <img style={{ width: "110px", marginLeft: "calc(50% - 55px)", float: "left", marginTop: "calc(50vh - 100px)" }} src={loading} />
    </div>


  }


}


export default NewOrderPage