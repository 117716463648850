import darkLogoType from "../../../static/BIG_TIC_IT_logotype.png";

import Defaultbanner from "../../../static/BIG_TIC_IT_Cp_background.png";

import FavIcon from "../../../static/PROJECTS/BIG_TIC_IT_fav_icon.png";

export default function BIG_TIC_IT() {
    
    return global.config = {
        "project": "BIG_TIC_IT",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "BIG TIC-IT",
        "LogoType": darkLogoType,
        "BaseApi": "https://rest.bigtic-it.com",
        "BaseSocket": "https://socket.bigtic-it.com",
        "BaseTrack": "https://admin.bigtic-it.com",
        "MapCenter": {
            "lat": "49.28428085843864",
            "lng": "-123.12012744926915"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "fr",
            "direction": "Ltr",
            "i18Lang": "French",
            "cssFlag": "frenchflag",
            "country": "SN"
        }],
        "FavIcon": "/onro.png",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Defaultbanner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"BIG_TIC_IT",
        registerEnabled: true,
        Secondarycolor: "#131313",
        SecondaryDarkColor: "#2A2A2A",
        SecondaryLightColor: "#242424",
        SecondaryUltraLight: "#F5F5F5",
        SecondaryTextColor: "#F5F5F5",
        Secondaryonlight: "#242424",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#FFA3A6",
        icon_light_end:"#FFC9CB",
        icon_dark_start:"#FA232A",
        icon_dark_end:"#FF7478",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true ,
        showLoginText:true,
        loginBackgroundColorStart:"#131313",
        loginBackgroundColorEnd:"#131313",
        LoginTextColor :"#F5F5F5",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:darkLogoType,
        apkUrl:null,
        iosUrl:null

    }
}