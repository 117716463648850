import React, { useState } from "react";
import close from "../../../../static/ic-close.svg";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { openCloseOrderHistory } from "../../../../Redux/actions/actionNames";

class App extends React.Component {

  openOrderHistory=()=>{
    this.props.dispatch(openCloseOrderHistory(false))
  }

  render() {
    return (
      <div className="OrderListHeader">
        <img onClick={this.openOrderHistory} src={close} className="OrderListHeaderClose" />
        <span className="OrderListHeaderTitle">
          <Trans i18nKey={"Orderhistory"}>
          </Trans>
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
