
import GeenstressIGO from "../../../static/geenstress_logotype.png";
import Geenstress_banner from "../../../static/geenstressBanner.png";
import FavIcon from "../../../static/PROJECTS/geenstress.png";

export default function Geenstress() {
    
    return  global.config = {
        "project": "app.geenstress.amsterdam",
        "ShowPowerOnro": true,
        "flag": "dutchflag",
        "ProjectName": "Dashboard",
        "LogoType": GeenstressIGO,
        "BaseApi": "https://rest.geenstress.amsterdam",
        "BaseSocket": "https://socket.geenstress.amsterdam",
        "BaseTrack": "https://panel.geenstress.amsterdam",
        "MapCenter": {
            "lat": 27.709345,
            "lng": 85.327736
        },
        "Lang": "nl",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "nl",
            "direction": "Ltr",
            "i18Lang": "Dutch",
            "cssFlag": "dutchflag",
            "country": "NL"
        }, {
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/geenstress.png",
        "Country": "NL",
        "AppleLoginCustomerId": "6N993LX47C",
        "AppleLoginredirectURI": "https://app.onro.io",
        "FacebookLoginappId": "666630160807861",
        "GoogleLogincustomerId": "49663940387-k08t0lc82equ88n64epp9klqspeatc27.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Geenstress_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"geenstress",
        registerEnabled: true,
        Secondarycolor:"#1080FF",
        SecondaryDarkColor:"#0055cb",
        SecondaryLightColor:"#25B7FF",
        SecondaryUltraLight :"#D9EAFF",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#1080FF",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:GeenstressIGO,
        apkUrl:null,
        iosUrl:null
}}