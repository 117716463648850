import NOBODIIGO from "../../../static/NOBODI.png";
import FavIcon from "../../../static/PROJECTS/FavIcon_NOBODI.png";
import Banner from "../../../static/NOBODI_banner.png";

export default function NOBODI() {
    
    return  global.config = {
        "project": "NOBODI",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Portal",
        "LogoType": NOBODIIGO,
        "BaseApi": "https://rest.nobodi.com",
        "BaseSocket": "https://socket.nobodi.com",
        "BaseTrack": "https://admin.nobodi.com",
        "MapCenter": {
            "lat": 51.50713950458875,
            "lng": -0.12564119077725647
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "GB",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "792718684600-sh0jb17va0csl3qanj0bvaqllfg3pkhf.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"NOBODI",
        registerEnabled: true,
        Secondarycolor: "#000000",
        SecondaryDarkColor: "#2A2A2A",
        SecondaryLightColor: "#2B2B2B",
        SecondaryUltraLight: "#F5F5F5",
        SecondaryTextColor: "#ffffff",
        Secondaryonlight: "#242424",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        icon_light_start:"#111111",
        icon_light_end:"#373737",
        icon_dark_start:"#858585",
        icon_dark_end:"#CCCCCC",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#000000",
        loginBackgroundColorEnd:"#000000",
        LoginTextColor :"#11293B",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:NOBODIIGO,
        apkUrl:null,
        iosUrl:null
}}