import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {ReactComponent as Icon_discount} from "../../../../../static/icon-ic-discount.svg";
import icon_done from "../../../../../static/icon-ic-done.svg";
import icon_ic_fail from "../../../../../static/icon-ic-fail.svg";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Fade";
import {
  get_customerPannel_type,
  get_Id,
  get_LeftRight,
} from "../../../../../helper/UserData/userdate";
import { DetectOndemandOrReserve } from "../../../../../helper/worker/DetectOndemandOrReserve";
import {
  Api_get_Order_Info_AGW,
  Api_ondemand_promotion_set_AGW,
  Api_ondemand_promotion_verify_AGW,
  Api_pickupDelivery_promotion_set_AGW,
  Api_pickupDelivery_promotion_verify_AGW,
} from "../../../../../helper/api/ApiGateway";
import momentJalaali from "moment-jalaali";
import { ResetNewOrder, Set_PromoCode } from "../../../../../Redux/actions/actionNames";
import { isOrderInCancel, isOrderInDone, isOrderInProgress } from "../../../../../helper/module";
import debounce from "lodash/debounce";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openPromo: false,
    };

    this.debounceSave = debounce(this.Save.bind(this), 1000);

  }

  HandlePropmoOpen = () => {
    this.props.code.length == 0 &&
      this.setState({
        openPromo: !this.state.openPromo,
      });
  };

  promoCodeOnchange = (e) => {
    this.props.dispatch(
      Set_PromoCode({
        code: e.target.value,
        valid: false,
        data: null,
      })
    );
    this.debounceSave()

  };

  promoCodeDelete = () => {
    this.props.dispatch(
      Set_PromoCode({
        code: "",
        valid: false,
        data: null,
      })
    );
  };

  removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  };

  Save = () => {
    if (this.props.type == "Ondemand") {
      Api_ondemand_promotion_set_AGW(
        this.props.dispatch,
        {
          orderId: this.props.ID,
          promoCode: this.props.code,
          customerId: get_Id()
        },
        () => {
          this.props.dispatch(ResetNewOrder());
          Api_get_Order_Info_AGW(this.props.dispatch , this.props.ID)

        }
      );    
    } else {
      Api_pickupDelivery_promotion_set_AGW(
        this.props.dispatch,
        {
          orderId: this.props.ID,
          promoCode: this.props.code,
          customerId: get_Id()
        },
        () => {
          this.props.dispatch(ResetNewOrder());
          Api_get_Order_Info_AGW(this.props.dispatch , this.props.ID)

        }
      );
    }
  };

  render() {
    const { t } = this.props;


    if (!!this.props.Promocode || isOrderInDone(this.props.status) || isOrderInCancel(this.props.status) ) {
      return null;
    }
    if(isOrderInProgress(this.props.status) && !this.state.openPromo){
      return (
        <div onClick={this.HandlePropmoOpen} className="ohdowhow">
          <Icon_discount className="img" />
          {/* <img src={icon_discount} /> */}
          <span> {t("PromotionCode")} </span>
        </div>
      )
    }
    if(isOrderInProgress(this.props.status) && this.state.openPromo){
      return (
        <div className="PromotionCodeContainer PromotionCodeOrderInfoContainer">
            <span  onClick={this.HandlePropmoOpen}> {t("PromotionCode")} </span>
            <div>
              <input onChange={this.promoCodeOnchange}  value={this.props.code} placeholder={t("EnterAmount")}  /> 
                {this.props.code.length > 0 && <img src={this.props.valid ? icon_done : icon_ic_fail} className={"icon_discount"} /> }
            </div>
      </div>
      );
    }else{
      return null;
    }


  //   <Slide
  //   timeout={650}
  //   direction={get_LeftRight()}
  //   in={this.props.canShowPromoCode}
  //   mountOnEnter
  //   unmountOnExit
  // >
  //   <div
  //     className={`PromotionCodeContainer ${
  //       this.props.code.length > 0
  //         ? "ActivePromotionCodeContainer"
  //         : this.state.openPromo
  //         ? "ActivePromotionCodeContainer"
  //         : ""
  //     }`}
  //   >
  //     <img
  //       onClick={this.HandlePropmoOpen}
  //       src={this.props.valid ? icon_done : icon_discount}
  //       className={"icon_discount"}
  //     />
  //     <span
  //       onClick={this.HandlePropmoOpen}
  //       className="icon_discountHeaderText"
  //     >
  //       <Trans i18nKey={"PromotionCode"}> </Trans>{" "}
  //     </span>

  //     <div className="promoCodeFileldsContainer">
  //       <input
  //         onChange={this.promoCodeOnchange}
  //         value={this.props.code}
  //         placeholder={this.props.t("PromotionCode")}
  //       />
  //       <div className="promoCodeButtonsContainer">
  //         {!this.props.valid && (
  //           <span className="buttonpromocode" onClick={this.Save}>
  //             {" "}
  //             <Trans i18nKey={"Save"}> </Trans>{" "}
  //           </span>
  //         )}
  //         <span
  //           className={`buttonpromocode deletebuttonpromocode ${
  //             this.props.valid && "mrglr0px"
  //           }`}
  //           onClick={this.promoCodeDelete}
  //         >
  //           {" "}
  //           <Trans i18nKey={"Delete"}> </Trans>{" "}
  //         </span>
  //       </div>
  //     </div>
  //   </div>
  // </Slide>
  
  }
}

const mapStateToProps = (state) => ({
  ID: state.ViewTravel.ID,
  type: state.ViewTravel.pureData ? state.ViewTravel.pureData.type : null,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : null,
  code: state.NewOrder.newOrder.promotionCode.code,
  valid: state.NewOrder.newOrder.promotionCode.valid,
  canShowPromoCode: true,
  Promocode: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.promoCode
    : false,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
