import { get_accessToken } from "../../helper/UserData/userdate";
import * as actionTypes from "../actions/actionTypes";
const init = {
  direction : "Ltr" ,
  language:"en",
  access_token : get_accessToken(),
  openMenu:false ,
  WebSettings: null
};
export const SharedData = (state = init, action) => {
  switch (action.type) {
    case "changeDirection":
      return {
        ...state,
        direction : action.direction ,
        language:action.language
      };
      case actionTypes.set_access_token:
        return {
          ...state,
          access_token : action.state
        };
        case actionTypes.openCloseMenu:
          return {
            ...state,
            openMenu : action.state
          };

          case actionTypes.setWebSettings:
            return {
              ...state,
              WebSettings : {...action.state ,
                orderTypes: action.state.orderTypes.filter((item)=> ["Ondemand", "PickupDelivery"].indexOf(item) > -1)
              }
            };
    default:
      return state;
  }
};

