import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import Business from "./business";
import Indivisual from "./indivisual";
import SwipeableViews from 'react-swipeable-views';
import { connect } from "react-redux";
import ClientAppsDownloadBadge from "../../clientAppsDownloadBadge";
import loadDynamicScript from "../../../../helper/module/loadDynamicScript";
import axios from "axios";
import { Helmet } from "react-helmet";

class App extends React.Component {
    constructor(props){
        super(props);
        this.state={
            activeTab : global.config.isIndividualTypeActive ?  0 : 1
        }
    }

    tabChange=(tab)=>{
        this.setState({
            activeTab : tab
        })
    }

    // componentDidMount(){
    //   loadDynamicScript(
    //     `https://www.google.com/recaptcha/enterprise.js?render=${global.config.googleReCaptchaSiteKey}`,
    //     "recaptchaEnterprise",
    //     async ()  => {
    //       window.grecaptcha.enterprise.ready(function () {
    //         window.grecaptcha.enterprise
    //           .execute(global.config.googleReCaptchaSiteKey, {
    //             action: "create_account"
    //           })
    //           .then(function (token) {
    //             console.log(token)

    //           })
    //           .catch(function (error) {
    //             console.log(error);
    //           });
    //       });
    //     }
    //   );
    // }


  render() {
    let axis= this.props.direction == "Ltr" ?"x" :"x-reverse" ;

    return <div className="loginRegisterContainer">
      <Helmet
    style={[{
        "cssText": `
        .App {
          overflow-y: scroll;                  
        }
        `
    }]}
/>
       {/* <Helmet>
           <style type="text/css">{`
            .App {
              overflow-y: scroll;                  
            }
         `}</style> 
        </Helmet> */}
        <div className="loginRegisterSwitchTab"> 
            {global.config.isIndividualTypeActive && <span onClick={()=>{this.tabChange(0)}} className={`PersonBusiness ${this.state.activeTab==0 && "ActivePersonBusiness"}`}  style={{ width: !global.config.isBusinessTypeActive ? "100%" : "" }}>  <Trans i18nKey={"individual"}> </Trans>  </span>}
            {global.config.isBusinessTypeActive && <span onClick={()=>{this.tabChange(1)}} className={`PersonBusiness ${this.state.activeTab==1 && "ActivePersonBusiness"}`} style={{  width: !global.config.isIndividualTypeActive ? "100%" : ""  }}>  <Trans i18nKey={"Business"}> </Trans>  </span>}
         </div>   


      <SwipeableViews axis={axis} index={this.state.activeTab} onChangeIndex={this.handleChangeIndex} className="personBusinessSwipeableViewsContainer" >
          {global.config.isIndividualTypeActive && <Indivisual  LoginIsActive={this.props.LoginIsActive} activeTab={this.state.activeTab} />}
          {global.config.isBusinessTypeActive && <Business activeTab={this.state.activeTab} />}
        </SwipeableViews>

        <ClientAppsDownloadBadge />

    </div>;
  }
}
const mapStateToProps = (state) => ({
    authenticated: state.SharedData.access_token,
    direction: state.SharedData.direction,
  });
  const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
  
  
  