import React, { useState } from "react";
import NoOrder from "./NoOrder";
import OrderListMaper from "./OrderListMaper";
import Header from "./header";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import { get_LeftRight } from "../../../helper/UserData/userdate";
import Loading from "./loading";
import Error from "./error";
import { Api_Get_Transactions_AGW } from "../../../helper/api/ApiGateway";
import { isEmpty } from "lodash";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canLoadmore:true
    };
    this.loadMore = this.loadMore.bind(this)
  }

  loadMore = () => {
    Api_Get_Transactions_AGW(
      this.props.dispatch,
      {
        page: this.props.page,
      },
      (err) => {
        console.log("loadMore ",err )

        if(err){
          console.log("loadMore ",err )
          this.setState({
            canLoadmore:false
          })
        }
      }
    );
  };

  componentDidMount() {

    if (isEmpty(this.props.OrderList) && !this.props.OrderListLoading && this.state.canLoadmore) {
      this.loadMore();
    }
  }

  render() {
    return (
      <Slide
        timeout={650}
        direction={get_LeftRight()}
        in={this.props.open}
        mountOnEnter
        unmountOnExit
      >
        <div className="OrderHistoryContainer">
          <Header />
          <NoOrder />
          <Error />
          <Loading />

          {!this.props.OrderListError && this.props.open && this.props.OrderList.length != 0 ? (
          <OrderListMaper loadMore={this.loadMore} />
        ) : null}


        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.Transactions.OpenDialoge,
  OrderList: state.Transactions.OrderList,
  OrderListLoading: state.Transactions.OrderListLoading,
  OrderListError: state.Transactions.OrderListError,
  page: state.Transactions.page,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
