import veeIGO from "../../../static/vee_logotype.png";
import vee_banner from "../../../static/vee_banner.jpg";

import FavIcon from "../../../static/PROJECTS/vee.png";

export default function vee() {
    
    return  global.config = {
        "project": "now.veedelivery.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Customer Panel",
        "LogoType": veeIGO,
        "BaseApi": "https://rest.veedelivery.com",
        "BaseSocket": "https://socket.veedelivery.com",
        "BaseTrack": "https://nowadmin.veedelivery.com",
        "MapCenter": {
            "lat": 31.922914,
            "lng": 35.911963
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }, {
            "lang": "ar",
            "direction": "Rtl",
            "i18Lang": "Arabic",
            "cssFlag": "arabicflag",
            "country": "JO"
        }],
        "FavIcon": "/vee.png",
        "Country": "JO",
        "AppleLoginCustomerId": "98UM2SD42G",
        "AppleLoginredirectURI": "https://now.veedelivery.com",
        "FacebookLoginappId": "2952150521767160",
        "GoogleLogincustomerId": "577874740908-uk2jhdirkfrg0hem785s4rgmb2c082dt.apps.googleusercontent.com",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": true,
        "LoginBannerWithDomain": vee_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"vee",
        registerEnabled: true,
        Secondarycolor:"#4067B1",
        SecondaryDarkColor:"#20458C",
        SecondaryLightColor:"#6E8ECC",
        SecondaryUltraLight :"#F4F6FA",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#4067B1",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: false,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:veeIGO,
        apkUrl:null,
        iosUrl:null
} }