import LogoType from "../../../static/DLX.png";
import Banner from "../../../static/DLX_banner.jpg";

import FavIcon from "../../../static/PROJECTS/DLX_fav_icon.png";

export default function DLX() {

    return global.config = {
        "project": "DLX",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Welcome to DLX “Making everyday better”",
        "LogoType": LogoType,
        "BaseApi": "https://rest.dlxapp.online",
        "BaseSocket": "https://socket.dlxapp.online",
        "BaseTrack": "https://admin.dlxapp.online",
        "MapCenter": {
            "lat": 33.687953,
            "lng": 73.09043045128278
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "",
        "Country": "PK",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor: "DLX",
        enabledCustomColor: false,
        registerEnabled: true,
        Secondarycolor: "#E64296",
        SecondaryDarkColor: "#CD1E78",
        SecondaryLightColor: "#F550A5",
        SecondaryUltraLight: "#FDF0F7",
        SecondaryTextColor: "#FFFFFF",
        Secondaryonlight: "#E64296",
        TextDark: "#242424",
        TextNormal: "#484848",
        TextLight: "#808080",
        mapType: "osm", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
    }
}