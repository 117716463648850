import LogoType from "../../../static/Deliveryondemand.png";
import Banner from "../../../static/Deliveryondemand-resource@3x.png";

import FavIcon from "../../../static/PROJECTS/Deliveryondemand_fav_icon.png";

export default function Deliveryondemand() {
    
    return  global.config = {
        "project": "deliveryondemand",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Delivery On Demand",
        "LogoType": LogoType,
        "BaseApi": "https://rest.deliveryondemand.co",
        "BaseSocket": "https://socket.deliveryondemand.co",
        "BaseTrack": "https://track.deliveryondemand.co",
        "MapCenter": {
            "lat": 28.537489344437816,
            "lng": -81.37801038842399
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        },{
            "lang": "es",
            "direction": "Ltr",
            "i18Lang": "spanish",
            "cssFlag": "Spanishflag",
            "country": "DO"
        }],
        "FavIcon": "",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "ShowCustomerPanelTitleText": "",
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"deliveryondemand",
        registerEnabled: true,
        Secondarycolor:"#FF8C00",
        SecondaryDarkColor:"#D37400",
        SecondaryLightColor:"#FFA333",
        SecondaryUltraLight :"#FFF6EB",
        SecondaryTextColor:"#FFFFFF",
        Secondaryonlight:"#FF8C00",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFE3C1",
        loginBackgroundColorEnd:"#FFD5A0",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
}}