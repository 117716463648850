import React, { useState } from "react";
import Header from "./Header";
import OrderList from "./OrderList";
import OrderHistory from "./OrderHistory";
import AboutUs from "./AboutUs";
import TransactionsList from "./TransactionsList";
import MessagesBoxlist from "./MessagesBoxlist";
import Menu from "./Menu";
import Settings from "./Settings";
import Support from "./Support";
import { connect } from "react-redux";
import {
  Api_Get_Aboutus_AGW,
  Api_Get_Addressbook_list_AGW,
  Api_get_balance_AGW,
  Api_get_Order_Info_AGW,
  Api_Get_Services_AGW,
  Api_Get_Support_Message_AGW,
  Api_Get_Web_Setting_AGW,
} from "../../helper/api/ApiGateway";
import NewOrder from "./NewOrder";
import ViewOrder from "./ViewOrder";

import Map from "./Map";
import { setupSocket } from "../../socket/Setup_socket";
import { faviconUpdate } from "../../helper/module";
import DevelopmentCenterControlPanel from "../../Dev/DevelopmentCenterControlPanel";
import loading from "../../../src/static/loading.gif";
import BulkImport from "../BulkImport";
import { ChangeViewTravelDialoge, Set_Table_ActiveOrderType } from "../../Redux/actions/actionNames";
import { withRouter } from "react-router";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false ,
      windowLoaded : false
    };
    this.handleLoad = this.handleLoad.bind(this)

  }

  handleLoad() {
    this.setState({
      windowLoaded: true
    })
  }

  componentWillUnmount(){
    this.props.dispatch(ChangeViewTravelDialoge(false));

  }

  componentDidMount() {
    const orderId = this.props.match.params.id;

    Api_get_balance_AGW(this.props.dispatch, {}, (call) => { });

    this.props.dispatch(ChangeViewTravelDialoge(true, orderId));
    Api_get_Order_Info_AGW(this.props.dispatch, orderId);
    // this.props.dispatch(OrderInfoAction(this.props.dispatch,{
    //   orderId : this.props.id
    // }))
    Api_Get_Services_AGW(this.props.dispatch, null);


    if(!this.props.socket){
      document.addEventListener('applicationMap', (detail)=>{
      // console.log("applicationMap",detail.detail.applicationMap )
      this.setState({
        loaded: true
      })
    } )
    // window.addEventListener("load", this.handleLoad);

    faviconUpdate()
    Api_Get_Web_Setting_AGW(this.props.dispatch, null, null)
    Api_Get_Aboutus_AGW(this.props.dispatch, {}, (call) => { });
    Api_get_balance_AGW(this.props.dispatch, {}, (call) => { });
    Api_Get_Addressbook_list_AGW(this.props.dispatch, { page: 1, perpage: 10, searchRegex: "" }, false);
    setupSocket(this.props.dispatch);
    Api_Get_Support_Message_AGW(this.props.dispatch)

    document.addEventListener('visibilitychange', function (ev) {
    });


    window.onfocus = () => {
      Api_get_balance_AGW(this.props.dispatch, {}, (call) => { });
    };

    }else{
      this.setState({
        loaded: true
      })
    }
    
  }
  render() {
    return (
      <div className="MainContainer">
        {/* <DevelopmentCenterControlPanel /> */}

        {(!this.state.loaded ) &&

          <div style={{ backgroundColor: "white", width: '100vw', height: "100vh", position: "absolute", left: "0", top: "0", zIndex: "500" }}>
            <img style={{ width: "110px", marginLeft: "calc(50% - 55px)", float: "left", marginTop: "calc(50vh - 100px)" }} src={loading} />
          </div>
        }
        {this.state.loaded  &&

          <React.Fragment>
            <ViewOrder />
            <Map />
          </React.Fragment>
        }


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  IsOpen : state.BulkImport.IsOpen ,
  WebSettings : state.SharedData.WebSettings ,
  socket: state.Socket.socket,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
