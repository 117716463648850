import CouriersExpressIGO from "../../../static/CouriersExpresslogotype.png";
import CouriersExpress_banner from "../../../static/CouriersExpressdelivery5.png";
import FavIcon from "../../../static/PROJECTS/CouriersExpressfav_icon.png";

export default function CouriersExpress() {
    
    return  global.config = {
        "project": "app.couriersexpress.com",
        "ShowPowerOnro": false,
        "flag": "englishflag",
        "ProjectName": "Couriers Express Australia",
        "LogoType": CouriersExpressIGO,
        "BaseApi": "https://rest.couriersexpress.com",
        "BaseSocket": "https://socket.couriersexpress.com",
        "BaseTrack": "https://admin.couriersexpress.com",
        "MapCenter": {
            "lat": "-27.473174937694935",
            "lng": "153.0300163671119"
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "Ltr",
            "i18Lang": "English",
            "cssFlag": "englishflag",
            "country": "US"
        }],
        "FavIcon": "/CouriersExpressfav_icon.png",
        "Country": "AU",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": true,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": CouriersExpress_banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor:"CouriersExpress",
        registerEnabled: true,
        Secondarycolor:"#CC0000",
        SecondaryDarkColor:"#A70000",
        SecondaryLightColor:"#E22828",
        SecondaryUltraLight :"#FAE5E5",
        SecondaryTextColor:"#ffffff",
        Secondaryonlight:"#CC0000",
        TextDark:"#242424",
        TextNormal:"#545454",
        TextLight:"#808080",
        enabledCustomColor: true,
        mapType :"osm", /* osm | google */ 
        googleApiKey : "",
        showLogotypeInLogin:true,
        showLoginText:true,
        loginBackgroundColorStart:"#FFF8F8",
        loginBackgroundColorEnd:"#FFDCDC",
        LoginTextColor :"#242424",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:CouriersExpressIGO,
        apkUrl:null,
        iosUrl:null
}}