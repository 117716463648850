import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import {ReactComponent as Profile} from "../../../../static/profile_place_holder (2).svg";
import cancell from "../../../../static/ic-close.svg";
import SignOut from "./logout";
import Language from "./Language";
import {
  get_bussiness_title,
  get_familyName,
  get_givenName,
  get_lang,
  get_name,
  get_username,
} from "../../../../helper/UserData/userdate";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popover from "@material-ui/core/Popover";
import { withStyles } from "@material-ui/core/styles";
import { CanShowNameFamilyProfile } from "../../../../helper/module";

const styles = (theme) => ({
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  handleOpen = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Profile className="ProfileMenuIcon"
          onClick={this.handleOpen}/>
       

        <Paper>
          <Popover
            classes={classes}
            className="ProfileMenucc"
            onClose={this.handleOpen}
            open={this.state.openMenu}
          >
            <div className={`ProfileMenuContainer ${"CanShowNameFamilyProfile"}`}>
                <span
                  style={{ width: "100%", float: "left", marginTop: "16px" }}
                >
                  <Profile className="USERProfileiMAGE" />
                  <span className="OpeartorName">
                    {get_givenName() ||  get_username()}
                  </span>
                  <span className="OpeartorUserName">
                    {get_familyName() ||  get_bussiness_title()}
                  </span>
                </span>

              <SignOut />

              <Language />
            </div>
          </Popover>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles)(App)));
