import { get_accessToken, get_Id, get_lang } from "../../../helper/UserData/userdate";
import {httpCustomer} from "../axios";


class BatchService {

  async getBatchOperationInfo(data) {
    try {

      const response = await httpCustomer.request({
        method: 'get',
        url: `/api/v1/customer/${get_Id()}/batch/${data}`
      });

      return response.data.data

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }


}

export default BatchService;