import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { get_lang } from "../../../../../../helper/UserData/userdate";
import Tag from "../../../../../../design-system/lib/Tag";
import { SCHEDULE } from "../../../../../../static";
// import momentJalaali from "moment-jalaali";
import moment from 'moment'




class App extends Component {

  getTime=(time)=>{

    moment.locale(String(get_lang()).slice(0, 2));
    const daysAgo = moment(time).format("D MMM hh:mm")

    return daysAgo
  }


  render() {
    return (
      <pickupropoffsContainer>
        
        <pinsContainer>
          <circleBlue />
          <squre />
        </pinsContainer>

        <addresss>
          <pickupAddress>
            
            <span style={{textDecoration:"none",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden",height:"100%"}}>{this.props.pickup?.address}</span>      {this.props.pickup ? (this.props.pickup.scheduleDateAfter ||
            this.props.pickup.scheduleDateBefore ? (
            <Tag backgroundColor="GrayUltraLight" color="TextDark" size="Medium" weight="Body_Small_Regular" icon={SCHEDULE} text={this.getTime(Number(
              this.props.pickup.scheduleDateAfter ||
              this.props.pickup.scheduleDateBefore
            ))} />
          ) : null) : null}</pickupAddress>
          <dropoffAddress>
          <span style={{textDecoration:"none",textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden",height:"100%"}}>{this.props.dropoffs ? this.props.dropoffs[0]?.address : ""} </span>      
          {this.props.dropoffs ? (this.props.dropoffs[0].scheduleDateAfter ||
            this.props.dropoffs[0]?.scheduleDateBefore ? (
            <Tag backgroundColor="GrayUltraLight" color="TextDark" size="Medium" weight="Body_Small_Regular" icon={SCHEDULE} text={this.getTime(Number(
              this.props.dropoffs[0].scheduleDateAfter ||
              this.props.dropoffs[0].scheduleDateBefore
            ))
} />
          ) : null) : null}
          </dropoffAddress>
        </addresss>
      </pickupropoffsContainer>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
