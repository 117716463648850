import * as actionTypes from "../actions/actionTypes";
const init = {
  openDialoge: false,
  error: false,
  loading: false,
  data: null,
};
export const AboutUS = (state = init, action) => {
  switch (action.type) {
    case actionTypes.openCloseAboutUs:
      return {
        ...state,
        openDialoge: action.state,
      };

      case actionTypes.setAboutusData:
        return {
          ...state,
          ...action.state.extra ,
          data : action.state.data ? action.state.data: state.data
        
        };
  

          
    default:
      return state;
  }
};
