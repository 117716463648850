import React, { useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { isEmpty } from 'lodash'


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (this.props.ListLoading && isEmpty(this.props.data)) {
      return (
        <div className="NoOrderContainer">
          <div className="NoOrderInner mrb90">
          <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> 
            <span className="NoOrderTitle">
              {" "}
              <Trans i18nKey={"loadingList"}> </Trans>{" "}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  open: state.MessageBox.openDialoge,
  data: state.MessageBox.data,
  ListLoading: state.MessageBox.ListLoading,
  ListError: state.MessageBox.ListError,
  page: state.MessageBox.page,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
