import { RefreshToken } from "../../../../helper/api";
import { Warning_NOi18 } from "../../../../helper/Notification/sendNotification";
import InvoiceDataService from "../../../../service/api/apiInterface/InvoiceDataService";

export const GetInvoiceListAction = (page , perpage ,dispatch ,call) => {

  let InvoiceService = new InvoiceDataService()
    InvoiceService.getAll(page , perpage ).then((response) => {
    call(response.data.data)
  })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          RefreshToken(() => {
            GetInvoiceListAction(page , perpage ,dispatch ,call);
          });
        } else {
          Warning_NOi18(
            error.response.data.message,
            "networkError",
            "close",
            () => {
              dispatch({
                type: "removeNetworkErrorSnack",
                key: "networkError",
              });
            },
            new Date().getTime() + Math.random()
          );
        }
      }
    });
};

