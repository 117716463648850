export const ChineseSimplifiedLabel = {
    "Cutomerpanel": "账户管理后台",
    "login": "登录",
    "Register": "注册",
    "en": "英语",
    "fa": "波斯语",
    "Person": "个人",
    "Business": "商业",
    "Phonenumber": "电话号码",
    "Continue": "继续",
    "phonenumberhasnotregisterd": "电话号码尚未注册",
    "Username": "用户名",
    "title": "头衔",
    "Password": "密码",
    "ExOnro": "例如：公司名称",
    "Exstart": "例如：******",
    "googleConnected": "已连接谷歌",
    "facebookConnected": "已连接面子书",
    "appleConnected": "已连接苹果 ID",
    "plsenterphonnumber": "请输入您的电话号码",
    "firstnnameloginplaceholder": "例如：约翰",
    "businessNamePlaceholder": "Ex. ABC Company",
    "registerBusinessNameLabel":"Business Name",
    "lastnameloginplaceholder": "例如：杜",
    "Firstname": "名字",
    "Lastname": "姓氏",
    "email": "电子邮件",
    "emailPlaceholder": "（可选）例如：example@example.com",
    "comingsoon": "即将推出",
    "comingsoondesc": "如要创建商业帐户，请与我们联系",
    "CustomerAlreadyRegistered": "帐户已经注册，请登录",
    "registerCompleted": "请输入验证码",
    "googletokenerror": "谷歌令牌有问题",
    "facebooktokenerror": "面子书令牌有问题",
    "appletokenerror": "苹果令牌有问题",
    "CustomerNotRegistered": "账户未注册",
    "fr": "法国",
    "de": "德国",
    "InvalidVerificationCode": "验证码无效",
    "a": "找不到您的信息",
    "welcome": "欢迎",
    "SmsLimitationError": "请稍后再试",
    "resendCodeWithTime": "重新发送验证码（xxx）",
    "resendCode": "重新发送验证码",
    "VerificationcodePlaceHolder": "例如：1234",
    "Verificationcode": "验证码",
    "VerificationcodeText": "请输入发送到 xxx 的验证码",
    "VerificationCodeExpired": "验证码已过期",
    "Pending": "待定",
    "Unassigned": "未分配",
    "Assigned": "已分配",
    "PickedUp": "已发件",
    "Started": "已开始",
    "statusInProgress": "配送进行中",
    "Done": "完成",
    "CustomerCanceled": "顾客已取消",
    "DriverCanceled": "司机已取消",
    "SupportCanceled": "客服已取消",
    "Arrived": "已抵达",
    "new": "新的",
    "Historyofyourorderswillbehere": "在此查看订单记录",
    "otherdropoffs": "其他收件地址",
    "Orderhistory": "订单记录",
    "Noorder": "没有订单",
    "loadingList": "获取信息中，请耐心等候",
    "loadingError": "获取信息时出现问题",
    "retry": "请再试一次",
    "Create_your_first_requst": "创建新订单",
    "List_of_you_active_orders_will_be_here": "在此查看在线订单",
    "Aboutus": "关于我们",
    "Readour": "请阅读我们的",
    "privaypolicy": "隐私政策",
    "Transactions": "交易记录",
    "NoTransaction": "目前没有任何交易记录",
    "Historyofyourransactionswillbehere": "在此查看交易记录",
    "Settings": "设置",
    "Language": "语言",
    "English": "英语",
    "Persian": "波斯语",
    "French": "法语",
    "Logout": "登出",
    "logotTitle": "从帐户登出？",
    "logoutDescription": "您将从帐户登出，请稍后再登录",
    "confirmLogout": "是的，请登出",
    "skipLogout": "取消登出",
    "savesettings": "保存设置",
    "emailInvalid": "电子邮件无效",
    "Neworder": "新订单",
    "Service": "服务",
    "poperLoading": "请稍等",
    "Pickup": "发件",
    "SetPickup": "发件设置",
    "ChooseoOnMap": "地图定位",
    "Fornow": "目前",
    "Scheduled": "预定",
    "before": "之前：",
    "after": "之后：",
    "confirm": "确认",
    "Settime": "设置时间",
    "fo": "对于",
    "Cancel": "取消",
    "Pleasetryanotherkeyword": "请尝试其他关键字",
    "Noinformationfound": "找不到信息",
    "address": "地址",
    "block": "区域",
    "Floor": "楼层",
    "Unit": "单位",
    "senderfullname": "发件人姓名",
    "senderphonenumber": "发件人电话号码",
    "notesfordriver": "司机备注",
    "DropOff": "收件",
    "SetDropOff": "收件设置",
    "fetchingdata": "获取信息中...",
    "Recieverfullname": "收件人姓名",
    "Recieverphonenumber": "收件人电话号码",
    "undefined": "服务类别",
    "PromotionCode": "优惠码",
    "Delete": "删除",
    "Save": "保存",
    "PromoCodeValid": "优惠码已保存",
    "SenderWallet": "电子钱包",
    "SenderCash": "现金",
    "ReceiverCash": "收件人支付",
    "lowBalance": "余额不足",
    "Balance": "钱包",
    "Methods": "方法",
    "balanceError": "您的余额必须至少等于订单价格",
    "SendOrder": "发送订单",
    "feedback": "反馈",
    "Feedback": "反馈",
    "Orderagain": "重复订单",
    "Createdsuccessfully": "成功创建",
    "chooseAmount": "选择金额",
    "orenterthepreferedamount": "首选金额：",
    "enteramount": "您期望的价格",
    "pay": "支付",
    "selectPaymentGateway": "请选择支付方式",
    "Time_MachineText": "您是否想要恢复先前未注册的订单信息？",
    "yes": "是的",
    "ignore": "忽略",
    "Order": "订单",
    "statusPending": "待定",
    "statusUnassigned": "未分配",
    "statusAssigned": "已分配",
    "statusStarted": "已开始",
    "statusPickedUp": "已发件",
    "statusDone": "完成配送",
    "statusCustomerCanceled": "顾客已取消",
    "statusDriverCanceled": "司机已取消",
    "statusArrived": "已抵达",
    "statusSupportCanceled": "客服已取消",
    "statusCanceled": "取消",
    "Phone": "电话",
    "additonalservice": "附加服务",
    "CallDriver": "呼叫司机",
    "Message": "信息",
    "orderInfoError": "获取信息时出现错误",
    "support": "客服",
    "map": "地图",
    "receipt": "收据",
    "photo": "照片",
    "ServicePhoto": "服务照片",
    "Addphoto": "添加照片",
    "Estime": "预计时间",
    "Espath": "预计路线",
    "pod": "收件证明",
    "Notes": "备注",
    "Photo": "照片",
    "Signature": "签名",
    "Nonotes": "没有备注",
    "Nosignature": "没有签名",
    "Nophoto": "没有照片",
    "Price": "价格",
    "Receiver": "由收件人支付",
    "Sender": "由发件人支付",
    "Cash": "现金",
    "Canceltravel": "取消请求",
    "CancellText": "取消后，司机的订单也会被取消",
    "Estimated": "预计",
    "Wallet": "余额",
    "Copylink": "复制链接",
    "repeatOrder": "重复订单",
    "Edit": "编辑",
    "confirmCancel": "是的",
    "paymentmethod": "支付方式",
    "SucceessFul": "完成配送",
    "errorinServer": "服务器出现问题",
    "Comment": "评语",
    "Exfeedbackinput": "例如：他的配送服务非常快速",
    "Wallett": "钱包",
    "TypeHere": "在此输入",
    "Loadingmessages": "正在加载信息",
    "PoweredBy": "由 Annanow 提供",
    "newincomemessage": "您收到一条新信息",
    "newincomemessageanother": "您在另外一个订单中有一条新信息",
    "youhaveunreadmessage": "未读信息",
    "Vehicletype": "车辆类型",
    "Pickup2": "发件",
    "Dropoff2": "收件",
    "Events": "活动",
    "allEvents": "所有活动",
    "star": "星星",
    "Yourrate": "您的评分",
    "CopyOrderlink": "复制订单链接",
    "CopyPickuplink": "复制发件链接",
    "CopyDeliverylink": "复制配送链接",
    "Nointernetconnection": "无互联网连接",
    "close": "关闭",
    "seen": "已读",
    "controlThatbeforeLoweEqualAfter": "“之前:” 的时间：不能短于 “之后:“ 的时间",
    "controlThatbeforeAndAfterMustBiggerthanCurrentDate": "“之前:” 和 “之后:” 的时间不能短于当前时间",
    "errorInPanelSetting": "预订设置错误，请联系客服",
    "Arabic": "阿拉伯语",
    "ar": "阿拉伯语",
    "veeDeliveryLoginText1": "发送任何东西",
    "veeDeliveryLoginText2": "给任何人，现在！",
    "Schedule": "预定",
    "Date": "日期",
    "Time": "时间",
    "noDateToShow": "没有可显示的日期",
    "noTimeToShow": "没有可显示的时间",
    "Apply": "提交",
    "Increaseyourbalance": "增加您的余额",
    "AddBalance": "添加余额",
    "Addressbook": "地址簿",
    "AddressbookSearchbar": "按地址，名称和邮政编号搜索",
    "Removedefault": "按地址，名称和邮政编号搜索",
    "Setdefaultpickup": "设置默认发件地址",
    "Remove": "删除",
    "newAddress": "新地址",
    "NewAddressbookInfoTitle": "信息",
    "Addressdetails": "地址信息",
    "Title": "名称",
    "Code": "编号（可选）",
    "editAddress": "编辑地址",
    "AddressbookDropOf": "地址簿（收件人）",
    "AddressbookPickup": "地址簿（发件人）",
    "hide": "隐藏",
    "Nosavedaddress": "没有保存的地址",
    "NosavedaddressDesc": "您可以点击地址旁的书签或到地址簿创建一个",
    "Romanian": "罗马尼亚语",
    "ro": "罗马尼亚语",
    "Dutch": "荷兰语",
    "Shipmentlabel": "发货标签",
    "Name": "名字",
    "CashOnDelivery": "货到付款",
    "EnterAmount": "请输入金额",
    "COD": "货到付款",
    "Surchargexxx": "附加费 xxx",
    "Pricedetails": "价格信息",
    "minxxx": "xxx 分钟",
    "toPickup": "发件",
    "toDropOff": "收件",
    "AddCreditCard": "添加信用卡/借记卡",
    "CustomerWallet": "顾客钱包",
    "Add": "添加",
    "Addedsuccessfully": "已成功添加",
    "paymentmethods": "支付方式",
    "NoCards": "未添加任何卡",
    "Listofyourcardswillbehere": "您所添加的卡将显示在此处",
    "CardInfo": "卡的信息",
    "ExpireDate": "到期日期",
    "CVC": "卡验证码（CVC）",
    "PostalCode": "邮政编号",
    "RemoveCardq": "删除所添加的卡？",
    "RemoveCardA": "该卡将被删除并且不能再使用",
    "YesRemove": "是的，请删除",
    "Cardisinuse": "所添加的卡正在使用中",
    "ok": "确定",
    "Pleasetypeyourwordstosearch": "请键入您的关键字进行搜索",
    "spanish": "西班牙语",
    "es": "西班牙语",
    "Invoices": "发票",
    "noInvoice": "编号 xxx",
    "germany": "德国",
    "nl": "荷兰语",
    "downloadClientApplication": "请下载 xxx 应用程序",
    "AssignedAt": "已分配至 xxx",
    "StartedAt": "已开始至 xxx",
    "ArrivedAt": "已抵达至 xxx",
    "PickedUpAt": "在 xxx 发件",
    "DoneAt": "在 xxx 完成配送",
    "Russian": "俄罗斯语",
    "Azerbaijani": "阿塞拜疆语",
    "rus": "俄罗斯语",
    "aze": "阿塞拜疆语",
    "Support": "客服",
    "SendRequest": "发送请求",
    "CallTheSupport": "致电客服",
    "Choosethesubject": "选择主题",
    "Seefrequentquestions": "查阅常见问题",
    "Details": "详细信息",
    "Send": "发送",
    "MessageBox": "信息收件箱",
    "NoMessages": "无信息",
    "Yourmessageswillbehere": "您的信息将显示在这里",
    "Portuguese": "葡萄牙语",
    "pt": "葡萄牙语",
    "Greek": "希腊语",
    "el": "希腊语",
    "individual": "个人",
    "comingsoondesc2": "如要创建商业帐户，请与我们联系",
    "contactUs": "联系我们",
    "changePassword": "更改密码",
    "changePasswordp1": "您将登出所有的登录，除了这个，以保护您的帐户，防止被盗",
    "changePasswordp2": "您的密码应至少包含6个字符",
    "currentPassword": "当前密码",
    "newpassword": "新密码",
    "Retypenewpassword": "再次输入新密码",
    "account": "账户（钱包）",
    "Required": "必要的",
    "registerBussinessUsername": "用户名（至少5个字符）",
    "registerBussinessPassword": "密码（至少6个字符）",
    "ReferenceID": "参考编号",
    "EnterId": "请输入 ID",
    "Orderid": "订单编号",
    "EnterCode": "输入编号",
    "AustrianGerman": "奥地利 德语",
    "deu": "奥地利 德语",
    "RequiredText": "简单的",
    "Options": "选项",
    "zh": "中文简体",
    "pop": "提货证明",
    "Optimizedropoffs": "优化客流量",
    "PleaseSelect": "请选择",
    "OptimizedropoffsDesc": "还车顺序将更改为优化顺序，并且可能会改变价格",
    "Optimize": "优化",
    "Optimizedropoffs?": "优化掉线？",
    "ChineseSimplified": "中文简体",
    "driverPhoto":"Driver Photo",
    "yourPhoto":"Your Photo",
    "UploadPhoto":"Upload Photo",
    "Photos":"Photos",
   
   
   
    "TrackLink":"Track Link",
    "ShipmentLabel":"Shipment Label",
    "Draft":"Draft",
    "Receipt":"Receipt",
    "Confirm":"Confirm",
    "Cancel":"Cancel",
    "BulkEditStatusSummary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "OrderID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "New":"New",
    "Import":"Import",
    "Apply":"Apply",
    "Clear":"Clear",
    "Noresultfound":"No result found",
    "Typeyourkeyword":"Type your keyword",
    "Typeintheinputtostartsearch":"Type in the input to start search",
    "OnDemand":"On Demand",
    "P_Hub_D":"P_Hub_D",
    "AddressDetails":"Address Details",
    "Map":"Map",
    "Support":"Support",
    "BulkEditStatusxxx/yyy":"Bulk Edit Status xxx/yyy",
    "xxxSuccessfull,yyyFailed":"xxx Successfull, yyy Failed",
    "xxxfromyyy":"xxx from yyy",
    "xxxrows":"xxx rows",
    "xxxselected":"xxx selected",
    "xxxdropoffs":"+xxx dropoffs",
    "Status Updated at xxx":"Status Updated at xxx",
    'More':'More',
    'Summary':'Summary',
    'Prev':'Prev',
    'Next':'Next',
    'Done':'Done',
    "ClearAll":"Clear All",
    'Errorinloadingdata':'Error in loading data',
    'Therewasaproblemloadingdatapleasetryagainorcontactsupport':'There was a problem loading data. please try again or contact support',
    'Tryagain':'Try again',
    'ConfigTable' :'Config Table' ,
    'ActiveColumns':'Active Columns',
    'Createyourorder':'Create your order',
    'Yourorderswillbeshownhere':'Your orders will be shown here',
    'Pleasecheckyourfiltersorkeyword':'Please check your filters or keyword',
   "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
    'CustomerPhoto':'Customer Photo',
    "NoAttachments":"No Attachments",
    "AllAttachmentswillbeshownhere":"All Attachments will be shown here"
}