import { combineReducers } from "redux";
import { LoginRegister } from "./LoginRegister";
import { notifications } from "./notifications";
import { SharedData } from "./SharedData";
import { OrderHistory } from "./OrderHistory";
import { OrderList } from "./OrderList";
import { AboutUS } from "./aboutus";
import { Transactions } from "./Transactions";
import { Settings } from "./Settings";
import { Map } from "./map";
import { NewOrder } from "./NewTravel";
import { Socket } from "./socket";
import {ViewTravel } from "./viewTravel";
import {chatWithDriver } from "./chatWithDriver";
import {Addressbook } from "./addressbook";
import {Support } from "./support";
import {MessageBox } from "./messageBox";
import { BulkImport } from "./BulkImport";
import Table from "./table";

export const reducers = combineReducers({
  LoginRegister,
  notifications: notifications,
  SharedData,
  OrderHistory,
  OrderList,
  AboutUS,
  Transactions,
  Settings,
  Map: Map,
  Socket: Socket,
  NewOrder,
  ViewTravel: ViewTravel,
  chatWithDriver,
  Addressbook,
  Support ,  
  BulkImport,
  MessageBox,
  Table,
});
