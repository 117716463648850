import { store } from "../../Redux/store/index";
import {
  ChangeViewTravelDialoge,
  ResetViewTravel,
  ResetNewFav,
  ChangeNewTravelDialoge,
  Set_Passenger_Na_Fa_Ph,
  SetServiceVehicleTypesRepeatData,
  Set_Manual_ActiveVehicleType,
  Set_Auto_Driver,
  Set_Auto_ActiveVehicleType,
  Set_Pickup_Inputs,
  NewTravelPickupSetReserve,
  Set_DropOff_Inputs,
  NewTravelDropOffSetReserve,
  Add_dropoff,
  RecoverTimeMachine,
  ReOrder,
} from "../../Redux/actions/actionNames";
import history from "../../service/history";

const _ = require("lodash");


export const repeatReuqestModule = (pureData, dispatch) => {
  let data = JSON.parse(JSON.stringify(pureData));
  let storeData = store.getState();
  let vehicleTypes = [];
  let activevehicleTypes = null;
  let Services = [];
  let activeService = null ;
  let activeServiceOptions = null ;

 
  

  let FirstDrop = data.type == "Ondemand" ? data.dropoffs.shift() : data.delivery;
  let otherDropOffs = [];
  otherDropOffs = data.dropoffs || [];

  try {
    vehicleTypes = storeData.NewOrder.newOrder.driver.auto_driver.auto_suggestListData;
    if(pureData.vehicleType && pureData.vehicleType.id){
      vehicleTypes.map((element)=>{
        if(element.id == pureData.vehicleType.id){
          activevehicleTypes = element
      }
    })
    }
    Services = storeData.NewOrder.newOrder.service.serviceVehicleTypes;
    Services.map((element)=>{
      if(element.id == pureData.service.id){
        activeService = element
     }
   })

  } catch (error) {
    vehicleTypes = [];
  }


  // dispatch(SetServiceVehicleTypesRepeatData(data.service.id, data.service.options));

  let vehicleTypeoptions = [];

  // vehicleTypes.map((item) => {
  //   if (item.id == data.vehicletype.id) {
  //     vehicleTypeoptions = item.options;
  //   }
  // });

  // if (!_.isEmpty(data.driver)) {
  //   //driver is manual
  //   dispatch(Set_Auto_Driver(false));
  //   dispatch(
  //     Set_Manual_ActiveVehicleType({
  //       manual_activeVehicleType: {
  //         id: data.vehicletype.id,
  //         icon: data.vehicletype.icon,
  //         title: data.vehicletype.title,
  //         options: vehicleTypeoptions,
  //       },
  //       manual_name: data.driver.givenName,
  //       manual_family: data.driver.familyName,
  //       manual_number: data.driver.phone,
  //       manual_id: data.driverId,
  //       manual_code: data.driver.username,
  //     })
  //   );
  // } else {
  //   //driver is auto
  //   dispatch(Set_Auto_Driver(true));

  //   dispatch(
  //     Set_Auto_ActiveVehicleType({
  //       id: data.vehicletype.id,
  //       icon: data.vehicletype.icon,
  //       title: data.vehicletype.title,
  //       options: vehicleTypeoptions,
  //     })
  //   );
  // }

  //pickup

  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "address",
  //     value: data.pickup.address,
  //   })
  // );
  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "adresss_loc",
  //     value: {
  //       lng: data.pickup.coordinates[0],
  //       lat: data.pickup.coordinates[1],
  //     },
  //   })
  // );
  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "block",
  //     value: data.pickup.buildingBlock,
  //   })
  // );
  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "floor",
  //     value: data.pickup.floor,
  //   })
  // );

  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "unit",
  //     value: data.pickup.room,
  //   })
  // );
  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "senderFullName",
  //     value: data.pickup.fullName,
  //   })
  // );
  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "senderPhoneNumber",
  //     value: data.pickup.phone,
  //   })
  // );
  // dispatch(
  //   Set_Pickup_Inputs({
  //     name: "noteForDriver",
  //     value: data.pickup.description,
  //   })
  // );

  // dispatch(
  //   NewTravelPickupSetReserve({
  //     name: "scheduleDateBefore",
  //     value:
  //       data.pickup.scheduleDateBefore != 0
  //         ? data.pickup.scheduleDateBefore
  //         : null,
  //   })
  // );
  // dispatch(
  //   NewTravelPickupSetReserve({
  //     name: "scheduleDateAfter",
  //     value:
  //       data.pickup.scheduleDateAfter != 0
  //         ? data.pickup.scheduleDateAfter
  //         : null,
  //   })
  // );

  // //first fropOff

  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "address",
  //     value: FirstDrop.address,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );
  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "adresss_loc",
  //     value: { lng: FirstDrop.coordinates[0], lat: FirstDrop.coordinates[1] },
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );
  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "block",
  //     value: FirstDrop.buildingBlock,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );
  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "floor",
  //     value: FirstDrop.floor,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );

  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "unit",
  //     value: FirstDrop.room,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );
  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "recieverFullName",
  //     value: FirstDrop.fullName,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );
  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "recieverPhoneNumber",
  //     value: FirstDrop.phone,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );
  // dispatch(
  //   Set_DropOff_Inputs({
  //     name: "noteForDriver",
  //     value: FirstDrop.customerDescription,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );

  // dispatch(
  //   NewTravelDropOffSetReserve({
  //     name: "scheduleDateBefore",
  //     value:
  //       FirstDrop.scheduleDateBefore != 0 ? FirstDrop.scheduleDateBefore : null,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );
  // dispatch(
  //   NewTravelDropOffSetReserve({
  //     name: "scheduleDateAfter",
  //     value:
  //       FirstDrop.scheduleDateAfter != 0 ? FirstDrop.scheduleDateAfter : null,
  //     firstDropOff: true,
  //     id: "dropoff",
  //   })
  // );

  //other drop offs

  // otherDropOffs.map((item) => {
  //   let dropId = idgenerator();
  //   dispatch(Add_dropoff(dropId))

  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "address",
  //       value: item.address,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "adresss_loc",
  //       value: { lng: item.coordinates[0], lat: item.coordinates[1] },
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "block",
  //       value: item.buildingBlock,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "floor",
  //       value: item.floor,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );

  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "unit",
  //       value: item.room,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "recieverFullName",
  //       value: item.fullName,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "recieverPhoneNumber",
  //       value: item.phone,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  //   dispatch(
  //     Set_DropOff_Inputs({
  //       name: "noteForDriver",
  //       value: item.customerDescription,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );

  //   dispatch(
  //     NewTravelDropOffSetReserve({
  //       name: "scheduleDateBefore",
  //       value: item.scheduleDateBefore != 0 ? item.scheduleDateBefore : null,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  //   dispatch(
  //     NewTravelDropOffSetReserve({
  //       name: "scheduleDateAfter",
  //       value: item.scheduleDateAfter != 0 ? item.scheduleDateAfter : null,
  //       firstDropOff: false,
  //       id: dropId,
  //     })
  //   );
  // });

  dispatch(ChangeViewTravelDialoge(false));
  // dispatch(ResetViewTravel());
  // dispatch(ResetNewFav());
  dispatch(ChangeNewTravelDialoge(true));


  // let DropOffs= JSON.parse(JSON.stringify(pureData.dropoffs))
  // let firstDropOff = DropOffs[0];
  // DropOffs.shift();
  // let otherDropOffs = DropOffs || [];
  

  let dataa = {
    service: {
      activeVehicleType: activeService,
      options: [],
    },
    driver:{
      auto_activeVehicleType : activevehicleTypes
    },
    dropOffs: {
      firstDropOff: {
        address: FirstDrop.address,
        adresss_loc: { lng: FirstDrop.coordinates[0], lat: FirstDrop.coordinates[1] },
        block: FirstDrop.buildingBlock,
        floor: FirstDrop.floor,
        unit: FirstDrop.room,
        email: FirstDrop.email,
        recieverFullName: FirstDrop.fullName,
        recieverPhoneNumber: (FirstDrop.phone && FirstDrop.phone.length > 0) ? "+" + String(FirstDrop.phone).slice(2, FirstDrop.phone.length) : "",
        noteForDriver: FirstDrop.customerDescription,
        scheduleDateAfter: FirstDrop.scheduleDateAfter ? FirstDrop.scheduleDateAfter : null,
        scheduleDateBefore: FirstDrop.scheduleDateBefore ? FirstDrop.scheduleDateBefore : null ,
        schedulePickupNow: FirstDrop.scheduleDateAfter || FirstDrop.scheduleDateBefore ? false : true
      },
      otherDropOffs: otherDropOffs.map((item) => {
        return {
          address: item.address,
          adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
          block: item.buildingBlock,
          floor: item.floor,
          unit: item.room,
          email: item.email,
          recieverFullName: item.fullName,
          recieverPhoneNumber: (item.phone && item.phone.length > 0) ? "+" + String(item.phone).slice(2, item.phone.length) : "",
          noteForDriver: item.customerDescription,
          scheduleDateAfter: item.scheduleDateAfter,
          scheduleDateBefore: item.scheduleDateBefore,
          schedulePickupNow: item.scheduleDateAfter || item.scheduleDateBefore ? false : true
        }
      })
    }
    ,
    pickup: {
      address: pureData.pickup.address,
      adresss_loc: {
        lng: pureData.pickup.coordinates[0],
        lat: pureData.pickup.coordinates[1],
      },
      block: pureData.pickup.buildingBlock,
      floor: pureData.pickup.floor,
      email: pureData.pickup.email,
      unit: pureData.pickup.room,
      senderFullName: pureData.pickup.fullName,
      senderPhoneNumber: (pureData.pickup.phone && pureData.pickup.phone.length > 0) ? "+" + String(pureData.pickup.phone).slice(2, pureData.pickup.phone.length) : "",
      noteForDriver: pureData.pickup.description,
      scheduleDateAfter: pureData.pickup.scheduleDateAfter,
      scheduleDateBefore: pureData.pickup.scheduleDateBefore,
      schedulePickupNow: pureData.scheduleDateAfter || pureData.scheduleDateBefore ? false : true
    },
    paymentMethod: {
      paymentMethod: null,
      paymentSide: null,
      reorder:true
    },
  }
  dispatch(ReOrder(dataa))
  history.push('/neworder')



};




export const idgenerator = () => {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36); // '0.xtis06h6'
  var id = number.toString(36).substr(2, 9); // 'xtis06h6'
  return id.toString();
};
