import React, { useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { InputProps } from "./Input.types";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import Button from "../Button";
import Typography from "../Typography";
import { CLOSE } from "../../../static";


const Input: React.FC<InputProps> = ({ className, IconStyle, ContainerclassName, onChange, placeHolder, title, value, iconStart, type, inputStyle, isRequired, isRequiredText, error }: InputProps) => {
  const [focus, setFocus] = useState(false);

  return <div className={clsx(styles.input, ContainerclassName && ContainerclassName)}> 
  
  <div className={clsx(styles.inputContainer, focus && styles.focus, (error && (value || String(value).length > 0)) && styles.errorBorder)}>
    {iconStart && <IconProvider Icon={iconStart} style={IconStyle ? IconStyle : {}} />}
    <input type={type} placeholder={placeHolder} onFocus={() => { setFocus(true) }} onBlur={() => { setFocus(false) }} className={className} value={value} onChange={(e) => { onChange(e); setFocus(true) }} style={inputStyle && inputStyle} onClick={() => { setFocus(true) }} />
    {(isRequired && (!value || String(value).length == 0)) && <span className={styles.isRequired}> {isRequiredText} </span>}
    {(value != null && String(value).length > 0) && <IconProvider Icon={CLOSE} size="12" style={{ cursor: "pointer" }} onClick={()=>{onChange({target:{value:null}}) ; setFocus(false) ; }} />}

  </div>
    {(error && (value || String(value).length > 0)) && <span className={styles.error}> {error} </span>}

  </div>
};

export default Input;

