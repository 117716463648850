import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import ListEvent from "./listEvent";

class App extends React.Component {
  constructor(props){
    super(props);
    this.state={
      createDialoge : false
    }
  }




  render() {
    return (
      <div className="EventTimeLineContainer">
      <ListEvent showCreateDialoge={this.handleShowCreateDialoge}/>
    </div>
    );
  }
}

const mapStateToProps = (state) => ({
    showEventTimeLine:state.ViewTravel.eventTimeLine.show,
    showNewEventTimeLine:state.ViewTravel.eventTimeLine.newEvent.show,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
