import myWorker from "./CalculatePrice_PickupDelivery_Worker.worker";
// import { CalculatePriceAction, CalculatePriceReserveAction } from "../../../Redux/actions/actions";
import { SetPriceNewOrder } from "../../../Redux/actions/actionNames";
import { Api_calculateOrderCost_PickupDelivery_AGW } from "../../api/ApiGateway";
import { get_customerPannel_type } from "../../UserData/userdate";

export const CalculatePrice_PickupDelivery_Worker = (data, dispatch, noHttpOrder) => {
  const worker = new myWorker();
  worker.postMessage({...data,get_customerPannel_type:get_customerPannel_type()});
  worker.addEventListener("message", (event) => {

    if (event.data.succeessfull) {
      if (noHttpOrder) {
        dispatch(SetPriceNewOrder({}, { done: true }));
      } else {
        Api_calculateOrderCost_PickupDelivery_AGW(dispatch, event.data.data);
      }
    } else {
      dispatch(SetPriceNewOrder({ done: false }, { done: false }));
    }
    worker.terminate();
  });
};
