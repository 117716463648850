import * as actionTypes from "../actions/actionTypes";

const init = {
  openDialoge: false,
  error: false,
  loading: false,
  ListLoading: false,
  ListError: false,
  data: [],
  page: 0,

};

export const MessageBox = (state = init, action) => {
  switch (action.type) {
    case actionTypes.openCloseMessageBox:
      if (!action.state) {
        return { ...init };
      } else {
        return {
          ...state,
          openDialoge: action.state,
        };
      }

      case actionTypes.setMessageBoxData:
        return {
          ...state,
          ...action.state.extra,
          data: action.state.data
            ?  [...state.data, ...action.state.data ]
            : state.data,
        };

        
    default:
      return state;
  }
};
