import * as actionTypes from "../actions/actionTypes";

const init = {
  openDialoge: false,
  error: false,
  loading: false,
  data: null,
  selectedSubject: null
};

export const Support = (state = init, action) => {
  switch (action.type) {
    case actionTypes.openCloseSupport:
      return {
        ...state,
        openDialoge: action.state,
        selectedSubject:null
      };

    case actionTypes.setSupportData:
      return {
        ...state,
        data: action.state
      };

      
    case actionTypes.setSelectedSupportData:
      return {
        ...state,
        selectedSubject: action.state
      };

    default:
      return state;
  }
};
