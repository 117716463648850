import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';



export function GetDefaultMaincss() {
  const [data, setData] = useState(null);
  const direction = useSelector((state) => state.SharedData.direction)


  
  useEffect(() => {

    if (!data) {
      if (direction) {
        const Maincss = require(`!!raw-loader!../../helper/RtlLtrCssSelector/Css/${direction}.css`);
        setData(Maincss?.default)
      } else {
        const Maincss = require(`!!raw-loader!../../helper/RtlLtrCssSelector/Css/${global?.config?.Direction || "Ltr"}.css`);
        setData(Maincss?.default)
      }

    }


  }, [direction]);


  useEffect(() => {

    if (direction) {
      const Maincss = require(`!!raw-loader!../../helper/RtlLtrCssSelector/Css/${direction}.css`);
      setData(Maincss?.default)
    } else {
      const Maincss = require(`!!raw-loader!../../helper/RtlLtrCssSelector/Css/${global?.config?.Direction || "Ltr"}.css`);
      setData(Maincss?.default)
    }

  }, []);


  return data;
};

