import LogoType from "../../../static/WeeDeliver_logotype.png";
import Banner from "../../../static/WeeDeliver_Cp_background.jpg";

import FavIcon from "../../../static/PROJECTS/WeeDeliver_fav_icon.png";

export default function WeeDeliver() {

    return global.config = {
        "project": "WeeDeliver",
        "ShowPowerOnro": true,
        "flag": "englishflag",
        "ProjectName": "Service Request Panel",
        "LogoType": LogoType,
        "BaseApi": "https://rest.weedeliver.live",
        "BaseSocket": "https://socket.weedeliver.live",
        "BaseTrack": "https://admin.weedeliver.live",
        "MapCenter": {
            "lat": 39.95272956692207,
            "lng": -75.16731254160085
        },
        "Lang": "en",
        "Direction": "Ltr",
        "AllLanguages": [
            {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
                "cssFlag": "englishflag",
                "country": "US"
            }],
        "FavIcon": "",
        "Country": "US",
        "AppleLoginCustomerId": "",
        "AppleLoginredirectURI": "",
        "FacebookLoginappId": "",
        "GoogleLogincustomerId": "",
        "ShowCustomerPanelTitle": false,
        "thirdPartyLoginSupport": false,
        "LoginBannerWithDomain": Banner,
        "FavIconWithDomain": FavIcon,
        customFontColor: null,
        customColor: "",
        registerEnabled: true,
        Secondarycolor: "#FFD700",
        SecondaryDarkColor: "#F6D000",
        SecondaryLightColor: "#FFDF36",
        SecondaryUltraLight: "#FFFBE5",
        SecondaryTextColor: "#11293B",
        Secondaryonlight: "#11293B",
        TextDark: "#242424",
        TextNormal: "#545454",
        TextLight: "#808080",
        enabledCustomColor: false,
        mapType: "osm", /* osm | google */
        googleApiKey: "",
        showLogotypeInLogin: true,
        showLoginText:true,
        loginBackgroundColorStart:"#ffffff",
        loginBackgroundColorEnd:"#ffffff",
        LoginTextColor :"#000000",
        showLightLogoType:false ,
        lightLogoType: null,
        darkLogoType:LogoType,
        apkUrl:null,
        iosUrl:null
    }
}