import React from "react";

const DropOffPinWithNumber = ({ color = "red", style ,number ,numberStyle , bigSize , ...props}) => {

  return <div style={{width:"30px",height:"54px",position:"relative",...style}} {...props} >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={bigSize ? "30" : "20"}
      height={bigSize ? "54" :"36" }
      fill="none"
      viewBox="0 0 30 54"
      
    >
      <path
        fill={global.config.Secondarycolor}
        fillRule="evenodd"
        d="M6 0a6 6 0 00-6 6v18a6 6 0 006 6h7.5v22.5a1.5 1.5 0 003 0V30H24a6 6 0 006-6V6a6 6 0 00-6-6H6z"
        clipRule="evenodd"
      ></path>
      <rect width="20" height="20" x="5" y="5" fill="#fff" rx="4"></rect>
    </svg>
    <span style={{...numberStyle ,color:global.config.Secondarycolor}}>{number}</span>
  </div>

};

export default DropOffPinWithNumber



