import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import turnOn from "../../../../static/turn-on.svg";
import close from "../../../../static/icon-close.svg";
import calendar from "../../../../static/ic-calendar.svg";
import time from "../../../../static/ic-time.svg";

import Dialog from "@material-ui/core/Dialog";
import { Calendar } from "react-datepicker2";
import momentJalaali from "moment-jalaali";
import moment from "moment";
import TimePicker from "rc-time-picker";
import {
  NewTravelPickupSetReserve,
  NewTravelDropOffSetReserve,
} from "../../../../Redux/actions/actionNames";
import {
  get_calendarType,
  get_lang,
} from "../../../../helper/UserData/userdate";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { Warning_NOi18 } from "../../../../helper/Notification/sendNotification";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import jalali from "jalali-moment";
import { DetectOndemandOrPickupDelivery } from "../../../../helper/worker/DetectOndemandOrReserve copy";
import { snack } from "../../../../helper/module/SnackbarUtilsConfigurator";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      needShowPickupNowVAR:false,
      showTimePickerDialoge: false,
      TimeSelected: false,
      forNow:
        props.scheduleDateAfter || props.scheduleDateBefore ? false : true,
      Dialoge: false,
      Date: momentJalaali(),
      init: moment(),
      scheduleDateBefore: null,
      scheduleDateAfter: null,
      activeDialoge: null,
      rangeTime : [],
      selectedTime:null ,
      min: moment(
        Date.now() +
          props.activeVehicleType.scheduleSetting.scheduleDateRangeAfter
      ),
      max: moment(
        Date.now() +
          props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ),
    };
    this.confirm = this.confirm.bind(this);
    this.timePickerRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.needShowPickupNow(nextProps)
    try {
      if (
        nextProps.activeVehicleType &&
        nextProps.activeVehicleType.scheduleSetting
      ) {
        this.setState({
          min: moment(
            Date.now() +
              nextProps.activeVehicleType.scheduleSetting.scheduleDateRangeAfter
          ),
          max: moment(
            Date.now() +
              nextProps.activeVehicleType.scheduleSetting
                .scheduleDateRangeBefore
          ),
        });
      }
    } catch (error) {}
  }

  handleToggle = (b) => {
    if (
      parseInt(
        this.props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ) == 0
    ) {
      Warning_NOi18(
        <Trans i18nKey={"errorInPanelSetting"}> </Trans>,
        "networkError",
        <Trans i18nKey={"close"}> </Trans>,
        () => {
          this.props.dispatch({
            type: "removeNetworkErrorSnack",
            key: "networkError",
          });
        },
        new Date().getTime() + Math.random()
      );
    } else {
      this.setState({
        showTimePickerDialoge: b,
      });
    }
  };

  componentDidMount() {
    this.needShowPickupNow()
  }

  handleDialoge = (e) => {
    //   if (this.props.activePickupDropOff.type === "pickup") {

    this.setState({
      Date: moment(),
      ActiveFild: e,
      activeDialoge: e,
      scheduleDateBefore: momentJalaali(this.props[e]).isValid()
        ? this.props[e]
        : momentJalaali(),
      scheduleDateAfter: momentJalaali(this.props[e]).isValid()
        ? this.props[e]
        : momentJalaali(),
      Dialoge: !this.state.Dialoge,
      TimeSelected: false,
    });
    //  }
  };

  handleSwitch = () => {
    if (this.props.activePickupDropOff.type === "pickup") {
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateBefore",
          value: null,
        })
      );
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateAfter",
          value: null,
        })
      );
    } else {
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateBefore",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateAfter",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
    }

    this.setState({
      forNow: !this.state.forNow,
    });
  };

  checkSekectedTime=(time)=>{
      if(this.state.selectedTime){
        if(this.state.selectedTime.scheduleDateAfter.hour == moment(time[0]).format("HH") &&  this.state.selectedTime.scheduleDateAfter.minute == moment(time[0]).format("mm")){
            return true
        }
      }else{
        return false
      }
  }

  SetTime = (date) => {
    //   if (this.props.activePickupDropOff.type === "pickup") {

    let scheduleDateBefore = moment(this.state.scheduleDateBefore).set({
      hour: moment(date[1]).format("HH"),
      minute: moment(date[1]).format("mm"),
    });
    let scheduleDateAfter = moment(this.state.scheduleDateAfter).set({
      hour: moment(date[0]).format("HH"),
      minute: moment(date[0]).format("mm"),
    });

    this.setState(
      {
        scheduleDateBefore: scheduleDateBefore,
        scheduleDateAfter: scheduleDateAfter,
        TimeSelected: true,
        selectedTime: {
          scheduleDateBefore: {
            hour: moment(date[1]).format("HH"),
            minute: moment(date[1]).format("mm"),
          },
          scheduleDateAfter: {
            hour: moment(date[0]).format("HH"),
            minute: moment(date[0]).format("mm"),
          },
        },
      },
      () => {
        this.handleToggle(false);
      }
    );
    // }
  };;

  SetDate = (e) => {

    // if (this.props.activePickupDropOff.type === "pickup") {
    let dateStr = momentJalaali(e).format("YYYY-MM-DD"),
      timeStr = momentJalaali(this.state[this.state.ActiveFild]).format(
        "HH:mm"
      ),
      date = moment(dateStr),
      time = moment(timeStr, "HH:mm");

    date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: time.get("second"),
    });
    this.setState({
      Date: date,
      scheduleDateBefore: date,
      scheduleDateAfter: date,
      TimeSelected: false,
      selectedDate: e,
      selectedTime:null
    },()=>{
      this.TimesProvider()
    });
    // this.props.dispatch(
    //   NewTravelPickupSetReserve({
    //     name: this.state.ActiveFild,
    //     value: date,
    //   })
    // );
    //  }
  };

  confirm = () => {
    // if(this.controlThatbeforeAndAfterMustBiggerthanCurrentDate()){
    // ok
    // if (this.controlThatbeforeLoweEqualAfter()) {

    if (this.props.activePickupDropOff.type === "pickup") {

      if(this.props.firstDropOffScheduleDateAfter || this.props.firstDropOffScheduleDateBefore){
        if(Number(this.props.firstDropOffScheduleDateAfter) <  Number(this.state["scheduleDateBefore"]) ){
          snack.error(this.props.t("Scheduleoverlap"))

        }else{
          this.setState({
            Dialoge: false,
          });
          this.props.dispatch(
            NewTravelPickupSetReserve({
              name: "scheduleDateBefore",
              value: this.state["scheduleDateBefore"],
            })
          );
          this.props.dispatch(
            NewTravelPickupSetReserve({
              name: "scheduleDateAfter",
              value: this.state["scheduleDateAfter"],
            })
          );
        }

      }else{
        this.setState({
          Dialoge: false,
        });
        this.props.dispatch(
          NewTravelPickupSetReserve({
            name: "scheduleDateBefore",
            value: this.state["scheduleDateBefore"],
          })
        );
        this.props.dispatch(
          NewTravelPickupSetReserve({
            name: "scheduleDateAfter",
            value: this.state["scheduleDateAfter"],
          })
        );
      }




    } else {

      if(this.props.pickupScheduleDateAfter || this.props.pickupScheduleDateBefore){
        if(Number(this.state["scheduleDateAfter"]) <  Number(this.props.pickupScheduleDateBefore) ){
          snack.error(this.props.t("Scheduleoverlap"))

        }else{
          this.setState({
            Dialoge: false,
          });
          this.props.dispatch(
            NewTravelDropOffSetReserve({
              name: "scheduleDateBefore",
              value: this.state["scheduleDateBefore"],
              firstDropOff: this.props.firstDropOff,
              id: this.props.firstDropOff ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            NewTravelDropOffSetReserve({
              name: "scheduleDateAfter",
              value: this.state["scheduleDateAfter"],
              firstDropOff: this.props.firstDropOff,
              id: this.props.firstDropOff ? "dropoff" : this.props.id,
            })
          );
        }
      }else{
        this.setState({
          Dialoge: false,
        });
        this.props.dispatch(
          NewTravelDropOffSetReserve({
            name: "scheduleDateBefore",
            value: this.state["scheduleDateBefore"],
            firstDropOff: this.props.firstDropOff,
            id: this.props.firstDropOff ? "dropoff" : this.props.id,
          })
        );
        this.props.dispatch(
          NewTravelDropOffSetReserve({
            name: "scheduleDateAfter",
            value: this.state["scheduleDateAfter"],
            firstDropOff: this.props.firstDropOff,
            id: this.props.firstDropOff ? "dropoff" : this.props.id,
          })
        );
      }



    }

    //     } else {
    //       Warning_NOi18(
    //         <Trans i18nKey={"controlThatbeforeLoweEqualAfter"}> </Trans>,
    //         "networkError",
    //         <Trans i18nKey={"close"}> </Trans>,
    //         () => {
    //           this.props.dispatch({
    //             type: "removeNetworkErrorSnack",
    //             key: "networkError",
    //           });
    //         },
    //         new Date().getTime() + Math.random()
    //       );
    //     }
    // }else{
    //   // error

    //   Warning_NOi18(
    //     <Trans i18nKey={"controlThatbeforeAndAfterMustBiggerthanCurrentDate"}> </Trans>,
    //     "networkError",
    //     <Trans i18nKey={"close"}> </Trans>,
    //     () => {
    //       this.props.dispatch({
    //         type: "removeNetworkErrorSnack",
    //         key: "networkError",
    //       });
    //     },
    //     new Date().getTime() + Math.random()
    //   );
    // }
  };

  cancel = () => {
    this.setState({
      [this.state.activeDialoge]: null,
    });
    if (this.props.activePickupDropOff.type === "pickup") {
      this.setState({
        Dialoge: false,
      });
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateBefore",
          value: null,
        })
      );
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateAfter",
          value: null,
        })
      );
    } else {
      this.setState({
        Dialoge: false,
      });
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateBefore",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );

      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateAfter",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
    }
  };

  controlThatbeforeLoweEqualAfter = () => {
    if (!this.state.scheduleDateAfter || !this.state.scheduleDateBefore) {
      return true;
    } else if (
      moment(this.state.scheduleDateBefore).isAfter(
        this.state.scheduleDateAfter
      ) ||
      moment(this.state.scheduleDateBefore).isSame(this.state.scheduleDateAfter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  controlThatbeforeAndAfterMustBiggerthanCurrentDate = () => {
    if (
      (this.state.scheduleDateBefore
        ? moment(this.state.scheduleDateBefore).isAfter(new Date())
        : true) &&
      (this.state.scheduleDateAfter
        ? moment(this.state.scheduleDateAfter).isAfter(new Date())
        : true)
    ) {
      return true;
    } else {
      return false;
    }
  };

  diffDays = (IsfirstDay, IslastDay, IsOneDay, start, end, step) => {
    const today = start;
    const endDate = end;
    const minutes = Math.ceil(
      (Math.abs(endDate.unix() - today.unix()) / (1000 * 60)) % 60
    );
    const allSteps = Math.ceil(
      Math.abs(endDate.unix() - today.unix()) / 60 / step
    );
    // console.log("Schedule today",today.format("HH:mm jMM/jDD "))
    // console.log("Schedule endDate",endDate.format("HH:mm jMM/jDD "))
    // console.log("Schedule today.unix",today.unix())
    // console.log("Schedule endDate.unix",endDate.unix())
    // console.log("Schedule allSteps",allSteps)
    // console.log("Schedule step",step)
    // console.log(moment(today).format("HH:mm MM/DD ") , moment(endDate).format("HH:mm MM/DD "))

    let arr = [];
    arr.push([today, moment(today).add(step, "minute")]);

    for (let i = 1; i < allSteps; i++) {
      if (i == allSteps - 1) {
        if (!IsfirstDay && !IslastDay && !IsOneDay) {
          arr.push([
            moment(arr[arr.length - 1][1]),
            moment(endDate).set({ hour: 23, minute: 59 }),
          ]);
        } else if (IsfirstDay) {
          arr.push([moment(arr[arr.length - 1][1]), endDate]);
        }
      } else {
        arr.push([
          moment(arr[arr.length - 1][1]),
          moment(arr[arr.length - 1][1]).add(step, "minute"),
        ]);
      }
    }

    // arr.forEach(element => {
    //   console.log(moment(element[0]).format("HH:mm MM/DD") , moment(element[1]).format("HH:mm MM/DD"))
    // });

    // return arr;
    this.setState({
      rangeTime :arr
    })
  };

  TimesProvider = () => {
    const step =
      this.props.activeVehicleType.scheduleSetting.scheduleDateStep / 1000 / 60;
    if (
      parseInt(
        this.props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ) == 0
    ) {
      return [];
    } else if (
      moment(this.state.min).format("MM/DD") ==
      moment(this.state.max).format("MM/DD")
    ) {
      //One Day
      return this.diffDays(
        false,
        false,
        true,
        moment(Date.now()).add(step, "minute"),
        moment(this.state.max),
        step
      );
    } else if (
      moment(this.state.init).format("MM/DD") ==
      moment(this.state.Date).format("MM/DD")
    ) {
      //the first
      return this.diffDays(
        true,
        false,
        false,
        moment(Date.now()).add(step, "minute"),
        moment(this.state.min).add(step, "minute").endOf("day"),
        step
      );
    } else if (
      moment(this.state.max).format("MM/DD") ==
      moment(this.state.Date).format("MM/DD")
    ) {
      //the last
      return this.diffDays(
        false,
        true,
        false,
        moment(this.state.max).startOf("day"),
        moment(this.state.max),
        step
      );
    } else {
      //the midd
      return this.diffDays(
        false,
        false,
        false,
        moment(this.state.Date).startOf("day"),
        moment(this.state.Date).endOf("day").add(-3, "minute"),
        step
      );
    }
  };

  getDaysOfDate = () => {
 try {
     let diff = moment(this.state.max).diff(moment(this.state.min), "days");
 
     let diffminute = moment(this.state.max).diff(moment(this.state.min), "minute");
 
 
     let arr = [];
     let min = moment(this.state.min).startOf("day");
     let max = moment(this.state.max).startOf("day");
 
     if (diff > 0) {
       arr.push(min);
       for (let i = 0; i < diff; i++) {
         arr.push(moment(min).add(i + 1, "day"));
       }
       return arr;
     } else {
       let step = this.props.activeVehicleType?.scheduleSetting?.scheduleDateStep / 1000 / 60;
       if (diffminute = moment(this.state.max).diff(moment(this.state.min), "minute") >= step) {
         arr.push(min);
         if (momentJalaali(this.state.max).format(
           get_lang() == "fa" ? "jMM/jDD" : "MM/DD"
         ) != momentJalaali(this.state.min).format(
           get_lang() == "fa" ? "jMM/jDD" : "MM/DD"
         )) {
           arr.push(max);
           return arr;
         } else {
           return arr;
         }
       } else {
         return arr;
       }
 
     }
 } catch (error) {
  return null
 }
  };

  checkSekectedDate = (day) => {
    if (this.state.selectedDate) {
      if (
        moment(day).format("MM/DD") ==
        moment(this.state.selectedDate).format("MM/DD")
      ) {
        return true
      }
    } else {
      return false;
    }
  };

  needShowPickupNow = (nextProps) => {
    DetectOndemandOrPickupDelivery(
      nextProps ? nextProps.activeVehicleType ? nextProps.activeVehicleType.orderTypes : [] :  this.props.activeVehicleType ? this.props.activeVehicleType.orderTypes : [],
      (call) => {
        if (call == "ondeman") {
            this.setState({
              needShowPickupNowVAR : true
            })
        }else{
          this.setState({
            needShowPickupNowVAR : false
          })
        }
      }
      )

  }

  componentWillReceiveProps(nextProps){
    this.needShowPickupNow(nextProps)
  }


  render() {
    // this.diffDays();
    const { t } = this.props;
    let DaysOfDate = this.getDaysOfDate()
    // let TimesProvider = this.TimesProvider()

    return (
      <div className="ScheduleContainer">
        { this.state.needShowPickupNowVAR && <div
          className={`Manual_Automatic_Switch ScheduleContainerSwitch  ${
            !this.state.forNow && "Manual_Automatic_SwitchActive"
          }`}
          onClick={this.handleSwitch}
        >
          <img
            className={`Manual_Automatic_SwitchImage ${
              !this.state.forNow ? "Manual_Automatic_SwitchImageActive" : ""
            }`}
            alt=""
            src={turnOn}
          />
          <span
            className={`Manual_Automatic_SwitchText ${
              !this.state.forNow ? "Manual_Automatic_SwitchTextActive" : ""
            }`}
          >
            {" "}
            {this.state.forNow ? (
              <Trans i18nKey={"Fornow"}> </Trans>
            ) : (
              <Trans i18nKey={"Scheduled"}> </Trans>
            )}
          </span>
        </div>}

        {(!this.state.forNow || !this.state.needShowPickupNowVAR) ? (
          <React.Fragment>
            {/* <schedulebeforeContainer
              onClick={() => this.handleDialoge("scheduleDateBefore")}
            >
              <textt>
                {" "}
                <Trans i18nKey={"before"}> </Trans>
              </textt>
              <span ref="datepicker">
                {momentJalaali(this.props.scheduleDateBefore).isValid() ? (
                  momentJalaali(this.props.scheduleDateBefore).format(
                    get_lang() == "fa" ? "HH:mm jMM/jDD" : "MM/DD HH:mm"
                  )
                ) : (
                  <Trans i18nKey={"Settime"}> </Trans>
                )}
              </span>
            </schedulebeforeContainer> */}
            <scheduleafterContainer
              onClick={() => this.handleDialoge("scheduleDateAfter")}
            >
              <span ref="datepicker">
                {momentJalaali(this.props.scheduleDateAfter).isValid() &&
                momentJalaali(this.props.scheduleDateBefore).isValid() ? (
                  // momentJalaali(this.props.scheduleDateAfter).format(
                  //   get_lang() == "fa" ? "HH:mm jMM/jDD" : "MM/DD HH:mm"
                  // )
                  `${jalali(this.props.scheduleDateAfter)
                    .locale(get_lang())
                    .format("MM/DD HH:mm")} - ${jalali(
                    this.props.scheduleDateBefore
                  )
                    .locale(get_lang())
                    .format("HH:mm")}`
                ) : (
                  // new Date(this.props.scheduleDateAfter).toLocaleDateString(get_lang(), { hour:'numeric', minute:'numeric' , month: 'long', day: 'numeric'})
                  <Trans i18nKey={"Settime"}> </Trans>
                )}
              </span>
              {/* <textt>
                {" "}
                <Trans i18nKey={"after"}> </Trans>
              </textt> */}
            </scheduleafterContainer>
            <Dialog
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              open={this.state.Dialoge}
              onClose={this.handleDialoge}
              fullScreen={!isDesktop && !isTablet}
              disableAutoFocus
              disableEnforceFocus 
              disableRestoreFocus 
            >
              <div className="ScheduleDialogeContainer">
                <div className="ScheduleDialogeHeaderContainer">
                  <p className={"title"}>
                    <Trans i18nKey={"Schedule"}> </Trans>
                  </p>
                  <img onClick={this.cancel} src={close} />
                </div>

                <div className={"ScheduleDialogeDateContainer"}>
                  <p className={"ScheduleDialogeDateTimeHeader"}>
                    {" "}
                    <img src={calendar} /> <Trans i18nKey={"Date"}> </Trans>{" "}
                  </p>

                  <div className="ScheduleDialogeDateTimeItemsContainer">
                    {DaysOfDate.map((day) => {
                      return (
                        <div
                          className={`ScheduleDialogeDateTimeItem ${
                            this.checkSekectedDate(day) &&
                            "ScheduleDialogeDateTimeItemActive"
                          }`}
                          onClick={() => {
                            this.SetDate(day);
                          }}
                        >
                          {new Date(day).toLocaleDateString(get_lang(), {
                            day: "numeric",
                            month: "short",
                          })}
                        </div>
                      );
                    })}

                    {DaysOfDate.length == 0 && (
                      <p className="noDateToShow">
                        {" "}
                        <Trans i18nKey={"noDateToShow"}> </Trans>{" "}
                      </p>
                    )}
                  </div>
                </div>

                <div className={"ScheduleDialogeTimeContainer"}>
                  <p className={"ScheduleDialogeDateTimeHeader"}>
                    {" "}
                    <img src={time} /> <Trans i18nKey={"Time"}> </Trans>{" "}
                  </p>

                  <div className="ScheduleDialogeDateTimeItemsContainer">
                    {DaysOfDate.length > 0 &&
                      this.state.rangeTime.map((item) => {
                        return (
                          <div
                            className={`ScheduleDialogeDateTimeItem ${
                              this.checkSekectedTime(item) &&
                              "ScheduleDialogeDateTimeItemActive"
                            }`}
                            onClick={() => this.SetTime(item)}
                          >
                            {`${moment(item[0]).format("HH:mm")} - ${moment(
                              item[1]
                            ).format("HH:mm")}`}
                          </div>
                        );
                      })}

                    {DaysOfDate.length == 0 ? (
                      <p className="noDateToShow">
                        {" "}
                        <Trans i18nKey={"noDateToShow"}> </Trans>{" "}
                      </p>
                    ) : this.state.rangeTime.length == 0 ? (
                      <p className="noTimeToShow">
                        {" "}
                        <Trans i18nKey={"noTimeToShow"}> </Trans>{" "}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="ScheduleDialogeFooterContainer">
                  <div className="ScheduleDialogeFooterDateTime">
                    <p className="date">
                      {" "}
                      {jalali(this.state.selectedDate)
                        .locale(get_lang())
                        .format("DD/MM/YYYY")}{" "}
                    </p>
                    {this.state.selectedTime ? (
                      <p className="time">
                        {moment(this.state.scheduleDateAfter).format("HH:mm")} -{" "}
                        {moment(this.state.scheduleDateBefore).format("HH:mm")}
                      </p>
                    ) : (
                      "- - - - - - "
                    )}
                  </div>

                  <span className="ScheduleDialogeButttonContainer">
                    <Button
                      onClick={this.state.selectedTime ? this.confirm : null}
                      style={{
                        cursor: this.state.selectedTime ? "" : "not-allowed",
                      }}
                      className="confirmReserve"
                      variant="outlined"
                      color="primary"
                    >
                      <Trans i18nKey={"confirm"}> </Trans>
                    </Button>
                    <Button
                      onClick={this.cancel}
                      className="CancelReserve"
                      variant="outlined"
                    >
                      <Trans i18nKey={"Cancel"}> </Trans>
                    </Button>
                  </span>
                </div>
                {/* <Calendar
                  userLocal={get_lang()}
                  min={momentJalaali(moment(this.state.min).startOf("day"))}
                  max={this.state.max}
                  persianDigits={false}
                  isGregorian={get_calendarType() !== "jalali"}
                  className="noBoxShadow"
                  value={this.state[this.state.ActiveFild]}
                  onChange={this.SetDate}
                /> */}
                {/* <div className="ScheduleDialogeRightContainer">
              
                  <Button
                    style={{
                      fontSize: "17px",
                      fontWeight: "bold",
                      margin: "auto",
                      display: "block",
                      float: "unset",
                    }}
                    variant="outlined"
                    ref={this.timePickerRef}
                    aria-controls={
                      this.state.showTimePickerDialoge
                        ? "menu-list-grow"
                        : undefined
                    }
                    aria-haspopup="true"
                    onClick={() => this.handleToggle(true)}
                  >
                    {this.state.TimeSelected &&
                    this.state.scheduleDateBefore &&
                    this.state.scheduleDateAfter
                      ? `${moment(this.state.scheduleDateAfter).format(
                          "HH:mm"
                        )} - ${moment(this.state.scheduleDateBefore).format(
                          "HH:mm"
                        )}`
                      : this.props.t("Settime")}
                  </Button>

                  <Popper
                    open={this.state.showTimePickerDialoge}
                    anchorEl={this.timePickerRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: "1" }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                            maxWidth: "140px",
                            zIndex: "1",
                            overflowX: "hidden",
                          }}
                        >
                          <ClickAwayListener
                            onClickAway={() => this.handleToggle(false)}
                          >
                            <MenuList
                              autoFocusItem={this.state.showTimePickerDialoge}
                              id="menu-list-grow"
                              style={{
                                textAlign: "center",
                                width: "126px",
                                zIndex: "1",
                              }}
                            >
                              {this.TimesProvider().map((item) => {
                                return (
                                  <MenuItem
                                    onClick={() => this.SetTime(item)}
                                  >{`${moment(item[0]).format(
                                    "HH:mm"
                                  )} - ${moment(item[1]).format(
                                    "HH:mm"
                                  )}`}</MenuItem>
                                );
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <span
                    className="selectedDate"
                    style={{ opacity: this.state.TimeSelected ? "1" : "0" }}
                  >
                    {this.state.scheduleDateAfter &&
                      this.state.scheduleDateBefore &&
                      `${jalali(this.state.scheduleDateAfter)
                        .locale(get_lang())
                        .format("MM/DD HH:mm")} - ${jalali(
                        this.state.scheduleDateBefore
                      )
                        .locale(get_lang())
                        .format("HH:mm")}`}
                  </span>
                  <span className="ScheduleDialogeButttonContainer">
                    <Button
                      onClick={this.cancel}
                      className="confirmCancelReserve"
                      variant="outlined"
                    >
                      <Trans i18nKey={"Cancel"}> </Trans>
                    </Button>
                    <Button
                      onClick={this.state.TimeSelected ? this.confirm : null}
                      className="confirmCancelReserve"
                      variant="outlined"
                      color="primary"
                      disabled={!this.state.TimeSelected}
                    >
                      <Trans i18nKey={"confirm"}> </Trans>
                    </Button>
                  </span>
                </div> */}
              </div>
            </Dialog>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,

  pickupScheduleDateBefore : state.NewOrder.newOrder.pickup.scheduleDateBefore,
  pickupScheduleDateAfter : state.NewOrder.newOrder.pickup.scheduleDateAfter,

  firstDropOffScheduleDateBefore : state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateBefore,
  firstDropOffScheduleDateAfter : state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateAfter
  


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
