import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../../../static/arrow-triangle-down.svg";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Add_Service_Option_bulkImport, Remove_Service_Option_bulkImport } from "../../../../../../../Redux/actions/actionNames";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active: false,
      selected: null,
    };
    this.anchorEl = React.createRef();
  }

  handleshowpoper = () => {
    if (this.optionisSaved()) {
      this.props.dispatch(Remove_Service_Option_bulkImport(this.props.id,this.props.bulkId))
    } else {
      this.setState({
        showpoper: !this.state.showpoper,
      });
    }
  };

  handleSelectedItem = (item) => {
    this.props.dispatch(Add_Service_Option_bulkImport({
      name:this.props.name,
      id:this.props.id,
      item,
      type:"list"
    },this.props.bulkId))
    this.setState({
      selected: item ,
      showpoper:false
    });
  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  optionText(option_id) {
    let booll = false;
    booll = this.props.options.find((item) => {
     return item.id === this.props.id;
   });
   if(booll){
     return booll.item.title
   }else{
     return false
   }
  }


  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <clickableservice
          ref={this.anchorEl}
          id={this.props.id}
          onClick={this.handleshowpoper}
          className={`blimopit ${this.optionisSaved() && "avtiveServiceOptions blimopitActive"}`}
          style={{margin:"0px"}}
        >
          <img
            src={arrow}
            className={`${this.state.showpoper && "rotate180"}`}
          />
          {this.optionisSaved() ? this.optionText() :   this.props.title}
        </clickableservice>

        <Popper
          open={this.state.showpoper}
          anchorEl={this.anchorEl.current}
          role={undefined}
          placement={"bottom"}

          style={{
            zIndex: 6,
            marginTop: "10px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
          }}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              timeout={{ enter: 150, exit: 150 }}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleshowpoper}>
                  <MenuList
                    autoFocusItem={this.state.activestop}
                    id="menu-list-grow"
                    onKeyDown={(e) => {
                      this.handleListKeyDownTime(e);
                    }}
                  >
                    {this.props.data.map((itemM, i) => {
                      return (
                        <MenuItem
                          className="serviceListOption"
                          onClick={() => this.handleSelectedItem(itemM)}
                        >
                          {itemM.title}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state , ownProps) => ({
  activeVehicleType: ownProps.ActiveItem.service.selectedService ,
  options:ownProps.ActiveItem.service.options,
  bulkId : ownProps.ActiveItem.id

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
